import React, { Component } from 'react';
import { Button, Container, Dropdown, Form, Row } from "react-bootstrap";
import {SmallButton} from '../atoms';

export class Paginator2 extends Component {

    constructor(props) {
        super(props);

        //const { page } = this.props.filter;
        this.app = window.app;
        //this.pageIndex = pageIndex ? pageIndex : 0; // we need a immediately available property to have 100% error proof code execution
        //this.pageIndexInput = page.index ? page.index + 1 : 1; // we need a immediately available property to have 100% error proof code execution
        ////console.log('Paginator2 constructor. this.pageIndexInput: ' + this.pageIndexInput);
        let value = '';
        // Try to take the initial value from the props (e.g. we could have restored
        // the text value from configuration before rendering this component):
        if (this.props.filter)
            value = this.getInputText(this.props.filter.page.index);
        //value = page.inputText ? page.inputText : '';

        this.inputConfirmed = false;

        // keep it so later we can check if the page changed on parent component, so we can use the new parent value and overwrite this.state.text
        this.lastPageIndex = this.props.filter.page.index;

        //this.totalPages = totalPages ? totalPages : 0; // when totalPages is zero, it means we don't know the total number of items
        this.state = {
            //filter: this.props.filter,
            text: value
        //    pageIndex, // the last state value can be not immediately available, as state pushes are being gathered and possibly executed later
        //    pageIndexInput: 1
        };
    }

    //componentDidUpdate(nextProps, nextState) {

    //    if (nextProps.filter) {
    //        // reset the page index input
    //        if (nextProps.filter.text && nextProps.filter.text != this.props.filter.text) {
    //            //this.pageIndex = this.props.pageIndex;
    //            //this.setState({
    //            //   pageIndex: this.props.pageIndex
    //            //});                
    //            //filter.page.inputText = nextProps.filter.page.index + 1;
                
    //        }
    //    }        
    //}

    prev = (e) => {
        //console.log('paginator: on prev');
        if (e) e.preventDefault();
        if (e) e.stopPropagation();

        const filter = this.props.filter;
        const { page } = filter;

        if (page.index > 0) {
            page.index--;// better
            page.inputText = this.getInputText(page.index);
            this.state.text = page.inputText;//this.getInputText(page.index);
            this.props.onChange();
        }
    }

    next = (e) => {
        //console.log('paginator: on next');
        if (e) e.preventDefault();
        if (e) e.stopPropagation();

        const filter = this.props.filter;
        const { page } = filter;

        //if (items.length > pageSize) {   // this condition is always false, use other information, like this.totalPages (when it's higher than 0), or so :
        // if (!this.totalPages || this.pageIndex + 1 <= thistotalPages) 
            page.index++;
            page.inputText = this.getInputText(page.index);
            this.state.text = page.inputText;//this.getInputText(page.index);
            this.props.onChange();
        //}
    }

    onChange = (e, model) => {
        //console.log('paginator: on text change');
        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        const filter = this.props.filter;
        const { page } = filter;

        
       
        if (e && e.target) {
            const value = e.target.value;
            if (this.state.text !== value) {
                //console.log('paginator. onChange: value changed: old value, new value: ', this.state.text, value);
                this.setState({ text: value }); // state update is necessary
                //this.state.text = value; // will it work? no state update?

                // setting the state is necessary to update the control visual state (to re-render)
                //this.setState({ filter });
            }
            
            //this.state.text = value; // no need to re-render anything on this event, wait for confirmation
            //if (page.input !== e.target.value) {
            //    //this.inputConfirmed = false;
            //    page.inputText = e.target.value;
            //}            
        }
        
    }

    getInputText(index) {

        return '' + (index + 1);
    }

    onInputConfirmed = (e, model) => {

        //console.log('paginator: on text input confirmed');
        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        const filter = this.props.filter;
        const { page } = filter;

        //this.inputConfirmed = true;

        if (e.target) {

            const name = e.target.name;
            const value = e.target.value;
            
            let index = page.index; // default value: the existing page index
            if (value && !isNaN(value)) {
                index = Math.floor(value) - 1;
                if (index < 0) { index = 0; }
                if (page.showTotalPages && index >= page.totalPages) { index = page.totalPages - 1; }                
            }
            // always assing the text: in case the value introduced is NaN or so, 
            // we will make sure to set the text back using the existing page index
            //this.state.text = this.getInputText(index);
            const text = this.getInputText(index);
            if (this.state.text !== text) {
                this.setState({ text });
            }
            // if the new text is different, set the state and make a callback to parent component
            if (page.index !== index) {
                page.index = index;
                //console.log('paginator: on text input confirmed: text has changed');
                this.props.onChange();                
                this.setState({ filter });
            }
        }
    }

    // when user pressed a key
    onKeyPress = (e) => {

        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        if (e.key === "Enter") {
            this.onInputConfirmed(e);
        }
    }
    
    // when user blurs the input field
    onBlur = (e) => {

        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        //console.log('paginator: on blur');
        //if (!this.inputConfirmed) {
            
            this.onInputConfirmed(e);
        //}
    }
    // when user focuses the input field
    onFocus = (e) => {

        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        // remove the error state so errors are not rendered when user is typing again, he could be correcting the errors
        if (this.state.error)
            this.setState({ error: false });
    }

    render() {
        
        const { filter } = this.props;
        const { page } = filter;

        // do not render if there are no items or less items or equal than the page size
        //console.log('paginator.render. items: ', filter.items);
        if (!filter.items || filter.items.length <= page.size)
            return null;

        if (filter.items) 
            page.totalPages = Math.ceil(filter.items.length / page.size);
        if (!page.totalPages) page.totalPages = 1;
        
        const prevDisabled = (!page.index) ? 'disabled' : '';
        const prevContainerClass = 'page-navigation-button left ' + prevDisabled;
        const prevButtonClass = prevDisabled;
        const prevOnClick = (!page.index) ? null : (e) => this.prev(e);

        const hasMorePages = (page.index + 1 < page.totalPages) // default mode, when we load all pages 
            ||
            (this.props.onlyCurrentPageLoaded && page.items && page.items.length > page.size); // for documents: currently we load (page size + 1) items, so we should have the current page + 1 more page with 1 result

        const nextDisabled = hasMorePages ? '' : 'disabled';
        const nextContainerClass = 'page-navigation-button right ' + nextDisabled;
        const nextButtonClass = nextDisabled;
        const nextOnClick = hasMorePages ? (e) => this.next(e) : null;

        // rendering the page.index and not the page.inputText will not allow to delete or type other characters,
        // as the index contain a formatted page index, not the current text. If we wnt to see the current text,
        // it's necessary to always render the 'page.inputText' (below, in the second line):
        //let currentPageTextBefore = (page.showPageInput) ? (this.app.R.Page + ' ') : (this.app.R.Page + ' ' + (page.index + 1));
        let currentPageTextBefore = (page.showPageInput) ? (this.app.R.Page + ' ') : (this.app.R.Page + ' ' + page.inputText);
        let currentPageTextAfter = (page.showPageInput && page.showTotalPages) ? (' ' + this.app.R.Of + ' ' + page.totalPages) : '';

        // should we reset the state text? (e.g. user changed the document type 
        // and the page was reset to 1: this component would keep its state)
        if (this.lastPageIndex !== page.index)
            this.state.text = page.index + 1; // reset it
        this.lastPageIndex = this.props.filter.page.index; // update the value

        // how many characters has the input field? adjust its width dynamically
        let inputText = '' + this.state.text;//page.inputText;
        let inputWidth = inputText.length ? inputText.length + 1 : 1;

        //return items && (total Items > pageSize) && (
        return page.items && (
            <div className='pagination-container'>                   
                <div className="paginationB">

                    <div className={prevContainerClass} onClick={prevOnClick}>

                        <SmallButton
                            className={prevButtonClass}
                            contentClass='icon-prev'
                            disabled={!page.index}                            
                        />
                        {
                            //<div className='label'>{this.app.R.Previous}</div>
                        }
                    </div>

                    <div className="label count-label ">

                        {currentPageTextBefore}
                       
                        {page.showPageInput &&
                        <Form.Control
                            style={{ width: '' + inputWidth + '.5rem' }}
                            className='inline page-index'
                            type="name"
                            name='page_index'
                            value={this.state.text}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                            onKeyPress={this.onKeyPress}
                            >
                        </Form.Control>}
                        
                        {currentPageTextAfter}
                    </div>

                    <div className={nextContainerClass} onClick={nextOnClick}>
                        {
                            //<div className='label'>{this.app.R.Next}</div>
                        }
                        <SmallButton
                            className={nextButtonClass}
                            contentClass='icon-next'                            
                            disabled={page.items.length < page.size}
                        />                        
                    </div>
                </div>
            </div>
        )
    }
}