import { callServer, launchEnqueuedTask } from './taskManager';

import { routes } from '../common/routes';
import { RequestType, TaskState, TaskSubState } from '../common/constants';

/**
 * This is the Store module.
 * It has a state which keeps all data collections we get from server
 * It has functions to subscribe for state updates, unscubscribe,
 * and to set the state
 */
export class TaskProcessor {

    constructor(app) {

        this.app = app;
        
        // the task collection
        this.tasks = [];

        // counter used for UI
        this.counter = {};

        // initialize the default state
        this.initialize();
    }

    initialize() {

        // reset the task collection
        this.tasks = [];

        this.counter = {
            // total count since start, increased with each new task added
            total: 0, 
            // current tasks of file type (protect/unprotect/file info)
            file: {
                total: 0, 
                processing: 0,
                pending: 0
            },
            // current tasks of data type (policies/users/docs...)
            data: {
                total: 0, 
                pending: 0
            }
        };
    }

    //enqueue(task) {

    //    console.log('TaskProcessor.enqueue');
    //    // check if task is already on the list
    //    let found = false;
    //    this.items.map(item => { if (item === task) found = true; });
        
    //    if (!found) {        
    //        // add it to the list
    //        this.items.push(task);  
            
    //        // call the task manager using the 'show only' flag so the task will
    //        // be rendered on the list but the server will not be called yet: this
    //        // processor will decide when the task is launched to server in the
    //        // 'next' method(which follows below)
    //        this.push(task);
    //    }
    //    this.next();

    //}


    //// on complete: remove the task and call 'next'
    //complete(task) {

    //    console.log('TaskProcessor.complete');
    //    task.processing = false;

    //    let found = false;
    //    this.items.map(item => { if (item === task) found = true; });

    //    // remove the task
    //    //let tasksAfterRemoval = [];       
    //    //for (var i = 0; i < this.items.length; i++) {
    //    //    let item = this.items[i];
    //    //    if (task !== item) {
    //    //        tasksAfterRemoval.push(item);
    //    //    }            
    //    //}
    //    //// change the reference to the new 
    //    //this.queue = tasksAfterRemoval;

    //    // better use some new-gen filter method here instead of iterating
    //    this.items = this.items.filter(i => i !== task);

    //    // we don't push next tasks if the task is not found on the queue, in which case we ignore it     
    //    if (found) {
    //        // push next task or tasks.            
    //        this.next();
    //    }
    //}


    // resume of task properties:

    //task.progress = 0.0; // reset the progress value

    //task.completed = false; // reset the 'completed' flag
    //task.waitingForAuthorization = false; // set the initial state, we set it to true when we need to relaunch the task after 401

    // reset error state
    //task.error = null;
    //task.hasError = false;

    // Currently we don't allow to click on running tasks. Some tasks are clickable when completed
    //task.clickable = false; 

    /// 
    /// params: 
    // task to be pushed on the list to be executed
    // immediately: execute the task immediately (the flag is added especially for the
    // authorization "pre-request": when it completes, the 'main' document task has to be lauched without waiting)

    push(task, immediately) {

        //console.log('TaskProcessor . push task: ', task);
        const app = window.app;

        // set all flags to starting values:
        //
        // task name should be assigned before, when we create the FormData or Ajax data objects if that param is necessary for endpoints.
        // here we assign it only for UI and debugging, in cas its not yet assigned
        //if (!task.typeName)
        //    task.typeName = getKeyByValue(RequestType, task.type); // convert type id (int) to type name (string)
        //

        task.progress = 0.0; // reset the progress value
        task.state = TaskState.None;// ??? use a different state here as a substitute of 'complete' flag set to false?   // completed = false; // reset the 'completed' flag
        task.substate = TaskSubState.None;// ??? use a different state here as a substitute of 'complete' flag set to false?   // completed = false; // reset the 'completed' flag
        task.waitingForAuthorization = false; // set the initial state, we set it to true when we need to relaunch the task after 401
        task.error = null;
        task.hasError = false;
        task.clickable = false; // currently we don't allow to click on running tasks, only some tasks are clickable when completed

        // check if the task is already on the list
        let exists = false;
        this.tasks.map(existingTask => {
            if (task === existingTask) {
                exists = true;
            }
        })

        if (exists) {
            //console.log('relaunch an existing task: ', task);
        }
        else {
            //console.log('push a new task: ', task);
            task.id = this.counter.total++; // increase the id counter, so each task has a different id
            //task.css_showing = true;
            task.css_visible = false;
            task.css_showing = true;

            // new rule: add the task to the list only if it's not a sub-task, as these belong to their parents' list
            if (!task.parent) {
                this.tasks.push(task);
            }
            // additional state update to force an extra render of the tasks
            setTimeout(() => {
                task.css_visible = true;
                task.css_showing = false;
                // update the task counter and tasks UI
                updateTasks(app);
            }, 150);
        }

        //console.log('push task: ', task);

        // is it a task that will produce a server request?
        if (task.ajax) {

            // set the HTTP method to POST automatically if not specified (the requests are
            // sent as POST, because we are sending additional data in the request body)
            if (!task.ajax.method)
                task.ajax.method = 'POST'

            // set empty headers in case it's not set already
            if (!task.ajax.headers)
                task.ajax.headers = {}

            // initialize the object with params that will be sent to the server with AJAX request
            if (!task.ajax.data)
                task.ajax.data = {}

            // data can be a string (for authorization/token calls) or an object (for the rest of calls)
            if (typeof (task.ajax.data) == 'object') {
                // set the task type in the data that will be sent to the server  
                task.ajax.data.Type = task.type
            }

            //    // (do not send type name now, it's not used yet on server -in future we could
            //    // use it to save the request type name in the DB along the numeric type.
            //    // task.ajax.data.TypeName = taskManager.getKeyByValue(RequestType, task.type) // convert type id (int) to type name (string)

            // make the ajax call?
            if (task.enqueued && !immediately) {

                // set the waiting state + substate
                this.setWaitingState(task);

                // try to push the queue
                this.next();
            }
            else {
                // set the starting state + substate + progress
                this.setStartingState(task);

                // (new behavior) call the intermediate method that will check if the task needs any other data - it is 
                // made for external folders, where the folder items need to be retrieved and pushed as subtasks.
                // The folder operations are JIT to avoid loading folder contents long before actually launching it.
                launchEnqueuedTask(task); // --> in taskManager.js
            }
            // update the task counter and tasks UI
            updateTasks(app);
        }
        else {
            task.info = 'ok';
            this.onComplete(task);
        }

    }

    // Rules for sub-tasks:
    // 1. When a task (that is not completed) has sub-tasks: all sub-tasks that are not-completed are lauched
    // 2. It goes on recursively, including the embedded sub-tasks (the sub-folders will get its own children
    // converted to sub-tasks)
    // 3. The percentage of parent task completion is equal to the percentage of its children.
    // 4. When a parent task is marked as completed. the sub-tasks will not be processed anymore.


    // push next task (or tasks, if allowed) from the queue
    next() {

        //console.log('TaskProcessor.next');

        const options = {            
            processingTasksCount: 0,
            maxConcurrentTasks: 1
        };
        // try to get the max concurent tasks value from the configuration
        //var optionsMaxConcurrentTasks = window.app.store.state.options.appSettings.Local.MaxConcurrentFileTasks;
        //if (optionsMaxConcurrentTasks) {
        //    options.maxConcurrentTasks = optionsMaxConcurrentTasks;
        //}

        // count the tasks being processed now
        this.tasks.map(task => {
            this.countProcessingTasks(task, options)
        }); 
        //console.log('TaskProcessor.processingTasksCount: ', options.processingTasksCount);

        // try to launch the tasks
        this.tasks.map(task => {
            this.tryToLaunchTask(task, options)
        });                    
        
    }

    //-------------------------------------------------------------------
    // recursive method that counts the tasks being processed now
    countProcessingTasks = (task, options) => {
        
        // have sub-tasks?
        if (task.tasks) {
            // try to launch one of sub-tasks
            for (var i = 0; i < task.tasks.length; i++) {
                const subtask = task.tasks[i];
                this.countProcessingTasks(subtask, options);
            }
        }
        else {
            // the current task 'processing' flag is respected only when there are no children
            if (task.state === TaskState.Processing) options.processingTasksCount++;
        }
    }
    //-------------------------------------------------------------------
    // recursive method that counts the tasks being processed now
    setProgress = (task, progress) => {

        task.progress = progress;
        // have a parent task?
        if (task.parent) {
            // update the parent task progress
            this.tryUpdateParentProgress(task.parent);
        }

        // update the store
        //window.app.store.setState({ tasks: this.tasks });
        updateTasks(this.app);  
    }
    //-------------------------------------------------------------------
    // recursive method that goes up the parent hierarchy, si it can start
    //updating the progress from the highest node
    tryUpdateParentProgress = (task) => {

        // have a parent task?
        if (task.parent) {
            // update the parent task progress
            this.tryUpdateParentProgress(task.parent);
        }
        else {
            // we reached the highest parent node in the hierarchy: update it using
            // the 'updateProgress' method, which will go all way down when counting
            // the children progress.
            this.updateProgress(task);
        }
    }


    //-------------------------------------------------------------------
    // recursive method that goes all way down the hierarchy when counting the children progress
    updateProgress = (task) => {

        // have sub-tasks?
        if (task.tasks) {
            // the progres of the parent task is a medium progress of its sub-tasks
            let sum = 0.0;
            let counter = 0;
            // update the sub-tasks first
            for (var i = 0; i < task.tasks.length; i++) {
                let subtask = task.tasks[i];
                if (subtask) {
                    counter++;
                    this.updateProgress(subtask);
                    sum += subtask.progress;
                }                
            }
            // calclulate the medium progress 
            if (counter > 0) {
                task.progress = sum / counter;
                //console.log('parent taks.progress = ', task.progress);
            }
            else {
                // looks like there are no children: set progress to 100
                task.progress = 100.0;
            }
        }
        else {
            // update the current task progress only when the completed flag was set,
            // otherwise keep the current task's progress value
            if (task.state === TaskState.Completed) {
                task.progress = 100.0;
            }
        }        
    }

    //-------------------------------------------------------------------
    // recursive method that tries to launch the current task or its children tasks (or sub-tasks)
    tryToLaunchTask = (task, options) => {

        if (options.processingTasksCount < options.maxConcurrentTasks) {

            //console.log('tryToLaunchTask: ', task);

            // have sub-tasks?
            if (task.tasks) {
                // try to launch one of sub-tasks
                for (var i = 0; i < task.tasks.length; i++) {
                    const subtask = task.tasks[i];
                    this.tryToLaunchTask(subtask, options);
                }
            }
            else {
                // try to launch this individual task
                if (task.enqueued && task.state !== TaskState.Completed) {

                    // increase the counter
                    options.processingTasksCount++;

                    this.setStartingState(task);
                    // Call the intermediate method that will check if the task needs any other data - it is 
                    // made for external folders, because the folder items need to be retrieved first and then pushed as sub-tasks.
                    // The folder operations are JIT to avoid loading folder contents long before actually launching it.
                    launchEnqueuedTask(task); // --> in taskManager.js
                }
            }
        }        
    }

    //-------------------------------------------------------------------
    /// set the task state to the waiting state + substate
    setWaitingState = (task) => {
        // Set the sub-state to 'waiting', so the tasks rendered on the list
        // can have the correct text applied in "Tasks.js". Later the state
        // will change when the task processing is started.
        task.substate = TaskSubState.Waiting;
    }

    //-------------------------------------------------------------------
    /// set the task state to the starting sate + substate + set progress to 0
    setStartingState = (task) => {

        // change the task state
        task.state = TaskState.Processing;

        // change the task sub-state (sub-state is used for UI rendering)
        task.substate = TaskSubState.Starting;

        // reset the progress (TODO: check if still needed when full subtask progress is implemented)
        task.progress = 0;
    }
    //-------------------------------------------------------------------
    // update task state
    onComplete = (task) => {

        //console.log('onComplete. task: ', task);
        let app = window.app;
        let auth = app.auth;

        //call the set method as it will call the 'try update parent' method
        this.setProgress(task, 100.0);

        // change the task state
        task.state = TaskState.Completed;

        // pending tasks are marked to be relaunched when task returns 401: it means
        // we need to authenticate and get tokens, then they will be relaunched
        // when user is authenticated and validated (after user is valid).
        if (task.waitingForAuthorization) {
            // abort task execution here, wait for relaunch 
            //console.log('onComplete. abort further task execution: task.waitingForAuthorization = true')
        }
        else {
            
            if (task.hasError) { // task completed with an error:

                // set the substate to error
                task.substate = TaskSubState.Error

                // --> error message formatting is moved to the task status rendering code 
                // set a task message depending on the result code
                // Remember: we don't want users to get scared by too much technical information, if they 
                // can't do much to resolve the error, just tell them that request couldn't be processed.
                //let info = '';
                //if (task.error) {
                //    if (task.error.error_code < 0) {
                //        info = ResultCodeInfo(window.app, task.error.error_code);
                //    } console.log('render task: error code: ' + task.error.error_code + ', info: ' + info);
                //}
                //if (info && info != '') {
                //    //task.info = this.app.R.Error + ' : ' + info;
                //    task.info = info;
                //}

                // for many tasks we need to show an error dialog, quick start with one by one processing, 
                // and try to refactor it to a common pattern, like showing a common error component in the app component
                if (task.type === RequestType.RemovePolicy
                    || task.type === RequestType.CustomConfiguration
                    || task.type === RequestType.CustomColorsCss
                    || task.type === RequestType.CustomImagesCss
                    || task.type === RequestType.CustomInvitations
                    || task.type === RequestType.CustomLanguage
                    || task.type === RequestType.CustomSiteCss
                    || task.type === RequestType.OrgCustomConfiguration
                    || task.type === RequestType.OrgCustomColorsCss
                    || task.type === RequestType.OrgCustomImagesCss
                    || task.type === RequestType.OrgCustomInvitations
                    || task.type === RequestType.OrgCustomLanguage
                    || task.type === RequestType.OrgCustomSiteCss
                ) {
                    //console.log('task ended with error, make a callback anyway. task: ', task);
                    if (task.onComplete) {
                        try {
                            task.onComplete(task);
                        }
                        catch (error) {
                            // Note - error messages will vary depending on browser
                            console.error(error);
                        }
                    }
                }

                if (window.location.pathname === routes.login()) {

                    //console.log('window.location.pathname === routes.login()', task.hasError)
                    app.setComponentState(window.app.locator.loginPage, { error: task.hasError }); // this would work if login wouldn't be reconstructed- try to fix it with routing, or use the solution below            
                }

                // does the task has the 'on error' callback?
                if (task.onError) {
                    task.onError(task);
                }
            }
            else {
                // all is ok, call the task 'onComplete':
                if (task.onComplete) {
                    try {
                        task.onComplete(task);
                    }
                    catch (error) {
                        // Note - error messages will vary depending on browser
                        console.error(error);
                    }
                }
            }
        }

        // try to push the queue
        this.next();

        //app.store.setState({ tasks: this.tasks });
        updateTasks(app);  
    }

    // called when user selects new document type to load - we
    // cancel the pending document tasks to avoid race condition
    cancelPendingDocumentTasks() {

        this.tasks.map(task => {
            if (task.type == RequestType.DocumentsAccessed ||
                task.type == RequestType.DocumentsProtected ||
                task.type == RequestType.DocumentsWarnings ||
                task.type == RequestType.DocumentInfo ||
                task.type == RequestType.DocumentTracking ||
                task.type == RequestType.DocumentWarnings
            ) {                
                task.substate = TaskSubState.Cancelled;
                this.closeTask(null, task);
            }
        })
    }
    //-------------------------------------------------------------------
    // called after user is authenticated and validated, so 401 tasks can be relaunched

    relaunchPendingTasks(app) {

        //console.log('relaunch pending tasks: ', app.tasks);
        // 1. set a 'clickable' on followed tasks that got 401 error and that were clickable before
        // 2. try to re-launch tasks that got 401 error

        // TO DO: NEED TO BE FINE-TUNED, maybe we could set a 401 error status on a task to distinguish it?
        // -> now there's a mess with task '.relaunch', '.completed' and 'error' attributes: clean it!
        this.tasks.map(task => {

            if (task.waitingForAuthorization) {
                // exclude the authorization tasks (they have their own flow) and relaunch all other types
                // TO DO: just add a task.isAuth = true for these types: we will set it when we launch them, and later we will only need to check that param
                if (task.type !== RequestType.GetTokenWithAuthorizationCode &&
                    task.type !== RequestType.GetTokenWithRefreshToken &&
                    task.type !== RequestType.Preauthorize &&
                    task.type !== RequestType.AuthorizeWithCookie &&
                    task.type !== RequestType.AuthorizeWithUserNamePassword &&
                    task.type !== RequestType.UserIsValid) {

                    task.waitingForAuthorization = false;
                    this.push(task);
                }
            }
        })
    }

    //-------------------------------------------------------------------
    closeTask(e, task) {

        //console.log('closeTask', task);
        if (e) e.stopPropagation();
        let app = window.app;

        // should cancel tasks that are already downloading?
        // e.g. when user clicks on download and wants to close the notification, but not to cancel the operation?
        if (task.substate === TaskSubState.Downloading) {

            // if already downloading: do nothing
        }
        else {
            // set some cancel state: cancel Axios request? how they are cancelled on the XRH level?
            // or just set a task flag, so when it gets completed in HTTP client, the callbacks will be ignored?
            task.state = TaskState.Completed; // the completed task will be ignored when the response is received   
        }

        if (!task.css_showing) {

            removeTaskInner();
        }
        // wait some time to let it show before we strat the hide transition,
        // otherwise the task disappears at once instead of hiding
        else {
            //console.log('closeTask. wait until shown, then -removeTaskInner-');
            // set timeout to remove the item when the hide transition is done
            setTimeout(() => {
                removeTaskInner();
            }, 450);
        }

        function removeTaskInner() {
            //console.log('removeTaskInner');
            // now remove the task from the list
            //app.tasks.items = app.tasks.items.filter(item => item !== task); -->
            // rather then removing it directly: set a hidden style to have a css transition 
            // (triggered by 'visible' flag on task rendering), then remove it after a timeout
            task.css_visible = false;
            // set timeout to remove the item when the hide transition is done
            setTimeout(removeTaskAfterDelay, 250);
            updateTasks(app); // this will force the state update
        }

        function removeTaskAfterDelay() {
            // use global reference as "this" will be undefined in the timer callback
            app.taskProcessor.tasks = app.taskProcessor.tasks.filter(item => item !== task);
            
            // try to push the queue
            app.taskProcessor.next();
            
            updateTasks(app);            
        }
    }

    //-------------------------------------------------------------------
    // process the error: set the task info or show error dialogs
    onTaskError(task) {

        let app = window.app;
        // update UI    
        //task.info = app.R.Error;
    }

    //-------------------------------------------------------------------
    onTaskClicked(e, app, task) {

        if (e) e.stopPropagation();

        if (task.clickable) {
            if (task.type === RequestType.Download) {
                task.enqueued = false; // this task should not be put on queue but launched directly
                this.push(task);
            }
            else if (task.type === RequestType.DocumentInfo) {
                // open document info component, using the task.result
                //seeDocumentInfo(task); // support removed for now, sending files to server for info is expensive
            }
        }
    }

    retryFailedTasks() {
        // find all failed tasks and try to repeat them?

        //this.taskProcessor.push(task);

    }
}
//=====================================================================================
// "UpdateTasks" and the following functions are defined out of the Processor class so they can be 
// used as "timer" callbacks, without using "this." for timer callbacks, as "this" would be "undefined" on callback!
export const updateTasks = (app) => {
    //console.log("updateTasks");
    updateTaskCounter(app);
    // update the loading icon state
    
    if (app.taskProcessor.counter.data.pending === 0) {
        // hide the loading icon
        app.setComponentState(app.locator.loader, { visible: false });
    }
    else {
        // show the loading icon
        app.setComponentState(app.locator.loader, { visible: true });
    }
    //console.log('app.taskProcessor.tasks: ', app.taskProcessor.tasks);
    // update and render the task list
    app.store.setState({ tasks: app.taskProcessor.tasks });
}


//-------------------------------------------------------------------
// update the count of tasks being processed - the counter is used to show/hide
// the UI loading spinner, and to show additional information for the task list

const updateTaskCounter = (app) => {

    if (!app.taskProcessor) return;

    const counter = app.taskProcessor.counter;

    if (!counter) return;

    // update the count of file processing tasks
    counter.file.total = 0;
    counter.file.pending = 0;
    counter.file.processing = 0; // for how many file tasks (from the queue) are being processed now

    // update the count of data tasks
    counter.data.total = 0;
    counter.data.pending = 0;

    app.taskProcessor.tasks.map(task => {

        // update the count of data tasks
        //if (checkIfTaskIsDataType(task)) { // TO DO: not implemented --> currently data tasks are not being UI-followed, so use it as the indicator (temporal fix)
        if (!task.followed) {
            counter.data.total++;            
            // get the number of pending data tasks (tasks not completed yet)
            if (task.state !== TaskState.Completed) {
                counter.data.pending++;
            }
        }
        
        // update the count of file tasks
        // if (checkIfTaskIsFileType(task)) {  // TO DO: not implemented --> currently file tasks are the ones being UI - followed, so use it as the indicator(temporal fix)
        if (task.followed) {
            counter.file.total++;
            // get the number of file tasks from queue being currently processed (while other tasks from queue are waiting)
            if (task.state === TaskState.Processing) {
                counter.file.processing++;
            }
            // get the number of pending file tasks (tasks not completed yet)
            if (task.state !== TaskState.Completed) {
                counter.file.pending++;
            }
        }
    })
}

