import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { routes } from "../../common/routes";
export const PrivateRoute = ({ component: Component, authed, ...rest }) => {
    //console.log('private route: authed: ' + authed + ' component: ');
    //console.log(Component);

    // old static path: '/login'
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : window.location.pathname !== routes.login() ? 


                    < Redirect to={{ pathname: routes.login(), state: { from: props.location } }} /> : null
}
        />
    )
};