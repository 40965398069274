import { deleteSession, resetApp, saveAppSettings, saveSession } from './appManager';

import { callServer } from './taskManager';
import { loadAllUserData, loadUserData } from './userManager';
import { RequestType } from '../common/constants';
import { routes } from '../common/routes';
import { api } from '../common/api';
import loadScript from 'load-script';

//---------------------------------------------------------------------------------------

export const signOut = (app) => {

    //console.log('sign out...');
    let task = {
        app: app,
        onComplete: onSignOutCompleted,
        type: 'SignOut'
    }
    // for a moment skip caling the server, wait to see if this is necessary
    //(e.g. for deleting a server session or for invalidating the SSO cookie)
    //task.ajax = { // create the content object for 'fetch' method
    //    url: '/api/signout'
    //};

    app.taskProcessor.push(task); // call it without any url now    
}

const onSignOutCompleted = (task) => {

    //console.log('sign out completed');
    let app = window.app;

    deleteSession(app); 
    resetApp(app);
    saveAppSettings(app);

    // redirect to login (1)
    //setAuthenticated(app, false);
    // redirect to login (2)?
    ////console.log('user has signed out');
    if (app.history) { app.history.push(routes.login())}
}

var loginCallCount = 0;
//-------------------------------------------------------------------
export const loginOriginal = (task) => {

    let app = window.app;
    let config = app.store.state.options.appSettings;
    loginCallCount++;
    if (loginCallCount > 1) {
        return;
    }

    let formData = new FormData();

    let key = 'username';
    let value = 'adspadmin@lab.com';
    formData.append(key, value);

    key = 'password';
    value = 'Password10?';
    formData.append(key, value);

    key = 'submit.Signin';
    value = 'Sign In';
    formData.append(key, value);

    var a = {
        url: trimBadKatanaUrl(api().auth.login()),
    };
    //var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    //a.url = proxyUrl + a.url;
    
    a.content = { // create the content object for 'fetch' method 
        //mode: 'no-cors',
        body: formData
    };

    app.taskProcessor.push(a);
    //fetch(a.url, a.content);
}
//-------------------------------------------------------------------
//export const callLogin = (app, username, password) => {

//    let config = app.store.state.options.appSettings;
//    loginCallCount++;
//    if (loginCallCount > 1) {
//        return;
//    }

//    let formData = new FormData();

//    let key = 'username';
//    let value = username;
//    formData.append(key, value);

//    key = 'password';
//    value = password;
//    formData.append(key, value);

//    key = 'submit.Signin';
//    value = 'Sign In';
//    formData.append(key, value);

//    let task = {
//        app: app,
//        onComplete: onLoginCompleted,
//        type: 'SignInAttempt',        
//        url: trimBadKatanaUrl(api().auth.login()),
//    }

//    task.content = { // create the content object for 'fetch' method
//        method: 'POST',
//        headers: {},
//        //credentials: 'include',
//        body: formData
//    };

//    //var a = {
//    //    url: 'https://pilot.lab.com/adfs/account/login2'
//    //    
//    //};
//    //var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
//    //a.url = proxyUrl + a.url;

//    //a.content = { // create the content object for 'fetch' method 
//    //    method: 'POST',
//    //    //mode: 'no-cors',
//    //    headers: {},
//    //    body: formData
//    //};

//    // add this to Authorization Server for development CORS
//    //<system.webServer>
//    //    <httpProtocol>
//    //        <customHeaders>
//    //            <add name="Access-Control-Allow-Origin" value="*" />
//    //            <add name="Access-Control-Allow-Methods" value="*" />
//    //            <add name="Access-Control-Allow-Headers" value="*" />
//    //        </customHeaders>
//    //    </httpProtocol>

//    app.taskProcessor.push
//}

//const onLoginCompleted = (task, result) => {

//    let app = window.app;
//    if (result) {
//        if (result.user) {
//            // ...
//        }
//    }

//    authManager.authorize(task);
//}
//-------------------------------------------------------------------

export const preauthorize = (username) => {

    //console.log('preauthorize: username: ', username);
    let app = window.app;
    let config = app.store.state.options.appSettings;
    let url = null;
    let requestType = null;

    username = encodeURIComponent(username);
    requestType = RequestType.Preauthorize;
    url = trimBadKatanaUrl(api().auth.preauthorize());

    if (!config.Auth) {
        //console.log('config.Auth is missing! config: ', config);
    }

    let client_id = config.Auth ? config.Auth.client_id : "";
    let redirect_uri = config.Auth ? config.Auth.redirect_uri : "";
    //let resource = config.Auth ? config.Auth.resource : "";

    let params = {
        response_type: 'code',
        client_id: client_id,
        redirect_uri: redirect_uri
        //state: 'abcdefgh12345678'//'YT1odHRwcyUzQSUyRiUyRnBpbG90My5zZWFscGF0aC5jb20lMkZhZGZzJnI9YXBpLnJtcy5yZXN0LmNvbQ',        
    };

    // a step is necessary now to convert params object into a query string that will be inserted into body:
    const credentials = {
        username: username        
    };
    let credentialsText = '';
    Object.keys(credentials).forEach(key => credentialsText += (key + '=' + credentials[key] + '&'));
    //credentialsText = 'username=' + username + '&password=' + password;
    let task = {
        app: app,
        onComplete: onPreauthorizeCompleted,
        type: requestType
    }

    task.ajax = { // create the content object for 'fetch' method
        url: url,
        params: params,
        //data: formData,
        data: credentialsText,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded charset=UTF-8' // WebAdmin is using jQuery, which adds the "UTF-8" to the "url-encoded" header
        }
    };

    // set the user object, we need to keep the username even if he's not yet authorized
    if (!app.user) {
        app.user = {}
    }
    app.user.U = username; // provisional
    //console.log('preauthorize: task: ', task);
    app.taskProcessor.push(task);
}

const onPreauthorizeCompleted = (task) => {

    let result = task.result;
    //task.info = 'ok';
    // update the Login window
    task.app.store.setState({ isPreauthorized: true });
}

//-------------------------------------------------------------------

export const authorize = (username, password, code) => {

    //console.log('authorize: username: ', username);
    //console.log('authorize: password: ', password);
    //console.log('authorize: code: ', code);
    let app = window.app;
    let config = app.store.state.options.appSettings;
    let url = null;
    let requestType = null;

    code = code ? code : '';

    username = encodeURIComponent(username);
    password = encodeURIComponent(password); //console.log('encodeURIComponent. password: ', password);
    code = encodeURIComponent(code);

    // what is the criteria? a configuration?
    if (true) { 
        requestType = RequestType.AuthorizeWithUserNamePassword;
        url = trimBadKatanaUrl(api().auth.authorize());
    }
    else {
        requestType = RequestType.AuthorizeWithCookie;
        url = trimBadKatanaUrl(api().auth.authorize()); // set another endpoint here in config
    }
    //mobile office: let params = '?response_type=code&client_id=d3590ed6-52b3-4102-aeff-aad2292ab01c&resource=api.rms.rest.com&redirect_uri=launch-word%3A%2F%2Fcom.microsoft.Office.Word&state=YT1odHRwcyUzQSUyRiUyRnBpbG90My5zZWFscGF0aC5jb20lMkZhZGZzJnI9YXBpLnJtcy5yZXN0LmNvbQ&x-client-SKU=iOS&x-client-Ver=2.0.0&x-client-OS=9.3.5&x-client-CPU=arm32&x-client-DM=iPad&nux=1&client-request-id=ABBD3170-E6B5-4AAB-8BC6-EF2794F0E2A5';
    // add the query parameters:

    if (!config.Auth) {
        console.log('config.Auth is missing! config: ', config);
    }

    let client_id = config.Auth ? config.Auth.client_id : "";
    let redirect_uri = config.Auth ? config.Auth.redirect_uri : "";
    let resource = config.Auth ? config.Auth.resource : "";

    let params = {
        response_type: 'code',
        client_id: client_id, //'abcd1234-abcd-abcd-abcd-abcd1234abcd',
        //resource: config.Auth.resource,//'api.rms.rest.com',
        //redirect_uri: 'https%3A%2F%2F' + app.store.state.options.appSettings.AuthorizationServerUrl + '%2Fadfs%2Foauth2%2Fcode',//'launch-word%3A%2F%2Fcom.microsoft.Office.Word',
        //redirect_uri: 'launch-word%3A%2F%2Fcom.microsoft.Office.Word', 
        redirect_uri: redirect_uri//, = 'sealpath: web: app',
        //state: 'abcdefgh12345678'//'YT1odHRwcyUzQSUyRiUyRnBpbG90My5zZWFscGF0aC5jb20lMkZhZGZzJnI9YXBpLnJtcy5yZXN0LmNvbQ',        
    };
    //url += '?';
    //Object.keys(params).forEach(key => url += (key + '=' + params[key] + '&'));

    //let formData = new FormData();

    //let key = 'username';
    ////let value = 'forcefail' + username;
    //let value = username;
    //formData.append(key, value);

    //key = 'password';
    //value = password;
    //formData.append(key, value);

    //key = 'submit.Signin';
    //value = 'Sign In';
    //formData.append(key, value);
    // a step is necessary now to convert params object into a query string that will be inserted into body:
    const credentials = {
        username: username,
        password: password,
        code: code ? code : ''
    };
    let credentialsText = '';
    Object.keys(credentials).forEach(key => credentialsText += (key + '=' + credentials[key] + '&'));
    //credentialsText = 'username=' + username + '&password=' + password;
    let task = {
        app: app,
        onComplete: onAuthorizeCompleted,
        type: requestType
    }

    task.ajax = { // create the content object for 'fetch' method
        url: url,
        params: params,
        //data: formData,
        data: credentialsText,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded charset=UTF-8' // WebAdmin is using jQuery, which adds the "UTF-8" to the "url-encoded" header
        }
    };

    // set the user object, we need to keep the username even if he's not yet authorized
    if (!app.user) {
        app.user = {}}
    app.user.U = username; // provisional

    app.taskProcessor.push(task);
}

const onAuthorizeCompleted = (task) => {

    let result = task.result;
    //task.info = 'ok';

    ////console.log('on authorize completed. response: ' + result);
    if (result == 'INVALID_USER') {
        ////console.log("Invalid username or password");
    }
    //if (result) {
        //appManager.//logObject(result, 'login result');
        //if (result.user) {
        //    //logObject(result.user, 'authorize result.user');
        //}
    //}
    getToken();

    ////console.log('window.app.history = ', window.app.history)
    // 
    // We are not ready yet to go to the Home - the 'onAuthorizeCompleted' is called 
    // when we obtain the authorization code. Now we need to change it for tokens (OAuth flow)
    // then we will call 'is user valid' endpoint.
    // then, if user is valid, will call 'user is valid' which should really start the user flow,
    // possibly going to the 'Home', although I will probably stay on the login window UNTILL
    // all user data is loaded

    //window.app.history.push(routes.home());
}

//-------------------------------------------------------------------
export function trimBadKatanaUrl(url) {
    // Katana doesn't accept the '/' character at the endpoint's path, let's fix it here:
    url = url.replace(/\/$/, '');
    return url;
}

export const getToken = () => {

    //console.log('get token');

    // example of a token response from Katana auth server:
    //app.tokens = {
    //    "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImZESHR1c0Z3TnRBd0cwejB2RzA2V2MyNkVZVSJ9.eyJlbWFpbCI6ImFkc3BhZG1pbkBsYWIuY29tIiwidXBuIjoiYWRzcGFkbWluQGxhYi5jb20iLCJhdXRoX3RpbWUiOiIyMDE5LTAyLTIwVDE5OjI0OjE0Ljk3MVoiLCJhdXRobWV0aG9kIjoidXJuOm9hc2lzOm5hbWVzOnRjOlNBTUw6Mi4wOmFjOmNsYXNzZXM6UGFzc3dvcmRQcm90ZWN0ZWRUcmFuc3BvcnQiLCJ2ZXIiOiIxLjAiLCJhcHBpZCI6IkVFNkY4QTM2LUZCMzQtNEFGNC1BMUIzLTVERjBGMTlDRDJCMiIsImlhdCI6IjE1NTA2OTA2NTUiLCJpc3MiOiJodHRwczovL2RjLTEubGFiLmNvbS9hZGZzL3NlcnZpY2VzL3RydXN0IiwiYXVkIjoibWljcm9zb2Z0OmlkZW50aXR5c2VydmVyOmFwaS5ybXMucmVzdC5jb20iLCJleHAiOjE1NTA2OTE4NTQsIm5iZiI6MTU1MDY5MDY1NH0.mCl__e1kiXsIYDNJL1Uv7ulQCxH8wjfcP_NYq0gCNJO39dpn3-YhwniZhuaiKwGlOj0DU43f8hcsxYi0ohE3leOBWwfY4gsWl7SkvEBlZFzCubUkNN4cLLPTXgfC6Rdx-93x4I5YrInqipHSokYAd6han5BGMXBaEIyLYXtoxiCEtovxDID2uDFN-8EtoHgZLD5jGXRLBP5cdffEy1i_ZkHaCGS37948FMZNvIr7BnB9DK9e4Krx_yYhwyLn0gSGsMjY_u-GM9EPf3mSFwf0YVdqVU3tIPODwmXUBJ4f5QfhBlfUUxlrToXuHrPndVN3zQ2it8jmzNGWz5NecvMDKw",
    //    "token_type": "bearer",
    //    "expires_in": 1199,
    //    "refresh_token": "UFXH667e2uWb48PB_tPasX0fKsxc26gv-ulR7dR9qMoSmca3lzT4r9ZKAJUDetMVVeNyo5z90MVsSKTRJhx0Ybtj32Ep7KVj8OblmCxB1B65ihnh8Kn6sMQjf6qJlY5hg4sS2Vje7ax7UnxnaxBF6A6NjA323flGU4fxn-7lpqyQUdbgXGx-PCLsDfPACuLVFaYoCZBm6Wq3EnRdfTVNnsFv7nux61-sOHQVBS3OnJ_LRznCxfDA0zqcedmy_o3OVNhzczavmSJapJWa_S4YcmEZ5fqigDzXbwsKRkyyLnz7obVG"
    //};

    const app = window.app;
    //const { auth, tokens } = app;
    const auth = app.auth;
    const tokens = auth.tokens;

    let config = app.store.state.options.appSettings;
    let url = trimBadKatanaUrl(api().auth.token());
    let requestType = null;
    //redirect_uri = launch - word % 3A % 2F % 2Fcom.microsoft.Office.Word
    //    & grant_type=authorization_code
    //        & client_id=d3590ed6 - 52b3 - 4102 - aeff - aad2292ab01c

    if (!config.Auth) {
        console.log('config.Auth is missing! config: ', config);
    }
    
    let client_id = config.Auth ? config.Auth.client_id : "";
    let redirect_uri = config.Auth ? config.Auth.redirect_uri : "";
    let resource = config.Auth ? config.Auth.resource : "";

    //// prepare the params to be inserted into body by axios:
    let params = {
        client_id: client_id, //client_id: 'd3590ed6-52b3-4102-aeff-aad2292ab01c',        
        //client_secret: config.Auth.client_secret,
        resource: resource,
        redirect_uri: redirect_uri,
        //redirect_uri: 'launch-word://com.microsoft.Office.Word', //'launch-word%3A%2F%2Fcom.microsoft.Office.Word',
        state: 'abcdefgh12345678',
        //client_request_id: getRequestId //'ABBD3170-E6B5-4AAB-8BC6-EF2794F0E2A5'        
    };
    // choose the token
    //console.log(app.tokens, 'app.tokens');
    let code = tokens.authorization_code;
    let refresh_token = tokens.refresh_token;
    // refresh token has the priority:
    if (refresh_token) {
        if (refresh_token != '') {
            //console.log('get token: we have refresh token: ' + refresh_token);
            params.refresh_token = refresh_token;
            params.grant_type = 'refresh_token';
            requestType = RequestType.GetTokenWithRefreshToken;
            // this refresh token won't be used anymore: reset it
            tokens.refresh_token = null;
        }
    }
    // if refresh token is null or empty, use the auth code instead:
    else if (code) {        
        if (code != '') {
            //console.log('get token: we have auth code: ' + code);
            params.code = code;
            params.grant_type = 'authorization_code';
            requestType = RequestType.GetTokenWithAuthorizationCode;
            // this authorization code won't be used anymore: reset it
            tokens.authorization_code = null;
        }
    }
    // a step is necessary now to convert params object into a query string that will be inserted into body:
    let paramsText = '';
    Object.keys(params).forEach(key => paramsText += (key + '=' + params[key] + '&'));

    // prepare the task object:
    let task = {
        app: app,
        onComplete: onGetTokenCompleted,
        type: requestType
    }

    task.ajax = {
        url: url,
        headers: {},
        data: paramsText
    };

    auth.isWaitingForAuthorization = true;

    app.taskProcessor.push(task);
}

const onGetTokenCompleted = (task) => {

    //task.info = 'ok';

    // tokens are refreshed automatically, so before we continue making
    // calls we make additional step to check if user is a valid SP user:
    checkIfUserIsValid();    
}

//-------------------------------------------------------------------

// this method adds the access token to the request (the other tokens: authorization 
// code and refresh token are added in the 'authorize' and 'refresh token' methods):

export const addAuthorization = (app, task) => {

    const auth = app.auth;
    const tokens = auth.tokens;
    // prepare the OAuth requests
    if (tokens !== null) {
        if (tokens.access_token !== null) {           
            task.ajax.headers.Authorization = 'bearer ' + tokens.access_token;
        }       
    }    
    let sessionId = app.settings.sessionId;
    if (!sessionId) { sessionId = ''; }       
    //console.log('addAuthorization: adding session-id header')
    task.ajax.headers['session-id'] = sessionId;        
    
    //if (app.settings.codeId) {
    //    task.ajax.headers['code-id'] = app.settings.codeId;
    //}   
    task.tokenId = auth.tokenId; // set the current token id, so when task fails cause of 401 we will check if we have newer tokens
}

// get the authorization data (auth code, access token or refresh token) from the request.
export const extractAuthorization = (task, response) => {

    //console.log('extractAuthorization: ');
    //console.log('task', task);
    //console.log('response', response);
    //console.log(response.headers, 'response.headers');

    const app = window.app;
    const auth = app.auth;
    const tokens = auth.tokens;

    let data = task.result;

    // response example:
    //app.tokens = {
    //    "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImZESHR1c0Z3TnRBd0cwejB2RzA2V2MyNkVZVSJ9.eyJlbWFpbCI6ImFkc3BhZG1pbkBsYWIuY29tIiwidXBuIjoiYWRzcGFkbWluQGxhYi5jb20iLCJhdXRoX3RpbWUiOiIyMDE5LTAyLTIwVDE5OjI0OjE0Ljk3MVoiLCJhdXRobWV0aG9kIjoidXJuOm9hc2lzOm5hbWVzOnRjOlNBTUw6Mi4wOmFjOmNsYXNzZXM6UGFzc3dvcmRQcm90ZWN0ZWRUcmFuc3BvcnQiLCJ2ZXIiOiIxLjAiLCJhcHBpZCI6IkVFNkY4QTM2LUZCMzQtNEFGNC1BMUIzLTVERjBGMTlDRDJCMiIsImlhdCI6IjE1NTA2OTA2NTUiLCJpc3MiOiJodHRwczovL2RjLTEubGFiLmNvbS9hZGZzL3NlcnZpY2VzL3RydXN0IiwiYXVkIjoibWljcm9zb2Z0OmlkZW50aXR5c2VydmVyOmFwaS5ybXMucmVzdC5jb20iLCJleHAiOjE1NTA2OTE4NTQsIm5iZiI6MTU1MDY5MDY1NH0.mCl__e1kiXsIYDNJL1Uv7ulQCxH8wjfcP_NYq0gCNJO39dpn3-YhwniZhuaiKwGlOj0DU43f8hcsxYi0ohE3leOBWwfY4gsWl7SkvEBlZFzCubUkNN4cLLPTXgfC6Rdx-93x4I5YrInqipHSokYAd6han5BGMXBaEIyLYXtoxiCEtovxDID2uDFN-8EtoHgZLD5jGXRLBP5cdffEy1i_ZkHaCGS37948FMZNvIr7BnB9DK9e4Krx_yYhwyLn0gSGsMjY_u-GM9EPf3mSFwf0YVdqVU3tIPODwmXUBJ4f5QfhBlfUUxlrToXuHrPndVN3zQ2it8jmzNGWz5NecvMDKw",
    //    "token_type": "bearer",
    //    "expires_in": 1199,
    //    "refresh_token": "UFXH667e2uWb48PB_tPasX0fKsxc26gv-ulR7dR9qMoSmca3lzT4r9ZKAJUDetMVVeNyo5z90MVsSKTRJhx0Ybtj32Ep7KVj8OblmCxB1B65ihnh8Kn6sMQjf6qJlY5hg4sS2Vje7ax7UnxnaxBF6A6NjA323flGU4fxn-7lpqyQUdbgXGx-PCLsDfPACuLVFaYoCZBm6Wq3EnRdfTVNnsFv7nux61-sOHQVBS3OnJ_LRznCxfDA0zqcedmy_o3OVNhzczavmSJapJWa_S4YcmEZ5fqigDzXbwsKRkyyLnz7obVG"
    //};

    if (task.type == RequestType.Preauthorize || task.type == RequestType.AuthorizeWithCookie || task.type == RequestType.AuthorizeWithUserNamePassword) {

        //console.log('get authorization code from <authorize> response');
        //console.log(response, 'response');

        // render the headers
        let headers = response.headers;
        //console.log(headers, 'headers');
        //if (response.ok) {
            // the auth code is sent in the response headers
            if (headers.code) {
                let code = headers.code;
                //console.log('extract auth: code: ' + code);
                tokens.authorization_code = code; // remember that the auth code is single-use only! (do not save it for later after it's used next)
                //getToken(task); // this call will be executed from the onAuthorizeCompleted
            }            
        //}
        //else {
            // the response is not ok: render a warning and throw and error
            // ...
            // or just leave the error processing to the taskManager.callServer
        //}
    }
    else if (task.type == RequestType.GetTokenWithAuthorizationCode || task.type == RequestType.GetTokenWithRefreshToken) {

        //console.log('extract authorization data from <get token> response');
        //console.log('data: ', data);
        //console.log('data.access_token: ', data.access_token);
        //console.log('tokens: ', tokens);
        //console.log('tokens.access_token: ', tokens.access_token);
        // the tokens are sent in the response body
        //////console.log('app.state.authenticated: true');
        tokens.access_token = data.access_token;
        //console.log('received access token: ' + tokens.access_token);
        // !!! for tests: kill access token to test refresh token interchange after next 401 request
        //app.tokens.access_token = null;

        tokens.refresh_token = data.refresh_token;
        
        //console.log('received refesh token: ' + tokens.refresh_token);

        // for previewing only
        //var decoded = parseJwt(data.access_token);
        //console.log(decoded, 'token payload');

        //console.log('extract authorization data from <get token> response: auth.tokenId: ', auth.tokenId);
        saveSession(app); // save the tokens
    }
}

//// get the authorization data (auth code, access token or refresh token) from the request.
//export const extractCodeTypeAndSetLoginError = (app, task, response, shouldSetError) => {

//    const auth = app.auth;
//    const tokens = auth.tokens;
//    let data = task.result;

//    if (task.type == RequestType.Preauthorize || task.type == RequestType.AuthorizeWithCookie || task.type == RequestType.AuthorizeWithUserNamePassword) {

//        console.log('get required code type from <authorize> response');
//        console.log(response, 'response');
//        // render the headers
//        let headers = response.headers;        
//        //console.log(headers, 'headers');        
//        // this will set a code type so UI can show captcha o two-factor input field
//        if (headers['session-id']) {
//            app.settings.sessionId = headers['session-id'];
//            console.log('extract auth: session-id: ' + app.settings.sessionId);
//        }
//        if (headers['code-id']) {
//            app.settings.codeId = headers['code-id'];
//            console.log('extract auth: code-id: ' + app.settings.codeId);            
//        }
//        if (headers['code-type']) {
//            app.settings.codeType = headers['code-type'];
//            console.log('extract auth: code-type: ' + app.settings.codeType);
//        }
//        // should we reset the isPreauhtorized state? it can happen if the previous session has expired
//        let isPreauthorized = app.store.state.isPreauthorized;
//        if (app.settings.sessionId == '') {
//            isPreauthorized = false;
//        }
//        if (shouldSetError) {
//            // to force render in case of login error, when one code is alread yrenderd, use the fake  counter:        ;       
//            app.store.setState({ isPreauthorized: isPreauthorized, loginError: true, codeId: app.settings.codeId, codeType: app.settings.codeType, sesionId: app.settings.sessionId, lastTask: task, stateId: app.stateId++ });
//        }
//        else {
//            app.store.setState({ isPreauthorized: isPreauthorized, codeId: app.settings.codeId, codeType: app.settings.codeType, sesionId: app.settings.sessionId, lastTask: task, stateId: app.stateId++ });
//        }
//        saveAppSettings(app); // the required code type should be persisted, otherwise it gets lost on page reload and UI is not rendered while server keeps expecting the code
//    }    
//}

// get the authorization data (auth code, access token or refresh token) from the request.
export const setLoginError = (app, task, response, shouldSetError) => {

    const auth = app.auth;
    const tokens = auth.tokens;
    let data = task.result;

    if (task.type == RequestType.Preauthorize || task.type == RequestType.AuthorizeWithCookie || task.type == RequestType.AuthorizeWithUserNamePassword) {
        // to force render in case of login error, when one code is alread yrenderd, use the fake  counter:        ;       
        app.store.setState({ loginError: true });        
    }
}

// get the authorization data (auth code, access token or refresh token) from the request.
export const extractSessionAndCodeData = (app, task, response) => {

    const auth = app.auth;
    const tokens = auth.tokens;
    let data = task.result;

    if (task.type == RequestType.Preauthorize || task.type == RequestType.AuthorizeWithCookie || task.type == RequestType.AuthorizeWithUserNamePassword) {

        //console.log('get session and code data from <pre/authorize> response');
        // render the headers
        let headers = response.headers;       
   
        
        if (headers['session-id']) {
            app.settings.sessionId = headers['session-id'];
            //console.log('extract auth: session-id: ' + app.settings.sessionId);
        }        
        else {
            app.settings.sessionId = '';
            //console.log('extract auth: session-id: ' + app.settings.sessionId);
        }

        //if (headers['code-id']) {
        //    app.settings.codeId = headers['code-id'];
        //    console.log('extract auth: code-id: ' + app.settings.codeId);
        //}

        if (headers['code-type']) {
            app.settings.codeType = headers['code-type'];
            //console.log('extract auth: code-type: ' + app.settings.codeType);
        }
        // should we reset the isPreauhtorized state? it can happen if the previous session has expired
        let isPreauthorized = app.store.state.isPreauthorized;
        if (app.settings.sessionId == '') {
            isPreauthorized = false;
        }
        app.store.setState({ isPreauthorized: isPreauthorized, codeId: app.settings.codeId, codeType: app.settings.codeType, sesionId: app.settings.sessionId, lastTask: task, stateId: app.stateId++ });
        saveAppSettings(app); // the required code type should be persisted, otherwise it gets lost on page reload and UI is not rendered while server keeps expecting the code
    }
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
};


// called when user is valid
export const checkIfUserIsValid = (app) => {

    loadUserData(RequestType.UserIsValid);
    // if all is ok, the funcion 'userIsValid' will be called (see next)
}

// called when user is valid
export const userIsValid = (app) => {

    const auth = app.auth;
    //console.log('userIsValid : app.authenticated (before setting it to true): ', app.state.authenticated);

    // reset the token attempt counter (used to avoid loop if 401 is received
    // when token signature is not valid in the resources API that was called,
    // but the client/authorization API tokens and signature is correct. When 
    // the 401 is received and the app has refresh token - it keeps using the refresh
    // token, it keeps getting new tokens pair, repeats the task and resource API throws 401 again 
    // --> TODO: other loop breaker could be implemented if detecting this exact condition is possible:
    // maybe the resources API could send a message to indicate that the token signature is wrong?)
    auth.tokenAttempts = 0;
    // increase the token id counter: this id will be used when task is sent, so on 401 response we can check if we have newer tokens
    auth.tokenId++;
    // now that user is valid - reset the flag (this flag is used to not ot launch multiple token requests and rather wait for the current one to complete)
    auth.isWaitingForAuthorization = false;

    app.store.setState({ loginError: false }); // to reset the error used by login

    // set 'is authenticated' (which will re-render the App and its content)
    if (!app.state.authenticated) {
        // the 'isloadingCustomAppData' flag is set when 'isValid' response is received 
        // (just before calling 'isValid' function here).
        // It is set to 'true' if a custom organization code is returned with the
        // 'is valid' response, and the custom content needs to be loaded.
        // In this case, setting the 'authenticated' flag is delayed untill
        // the custom data is loaded, so it will jump from 'Login' to the 'Home'
        // screen when the custom CSS and images are ready, not before.
        if (!app.isloadingCustomAppData) {           

            setAuthenticated(app, true); // update the state only if it's not yet true, otherwise it will be re-rendering everything without need
        }
    }

    // TO DO: check if it's still in use?
    // execute callbacks if any
    //app.onAuthenticated.map(callback => callback());
    app.onAuthenticated = [];


   
    //if (isNewSession()) {
    if (true) {
        console.log('new session: last user: ', app.lastUser);
        // Case 1: new user is being logged-in
        // in case the app is starting, we want to load all user data
        //userManager.loadAllUserData(app);
        if (app.shouldLoadUserData) {
            app.shouldLoadUserData = false;
            loadAllUserData(app);
            //    var l = 0;
        }
    }
    else {
        console.log('re-authroizing an existing session: last user: ', app.lastUser);
        // Case 2: an existing session is being re-authorized?        
        // navigate back to the last page before login        
        if (app.history) { app.history.goBack() }
    }

    //function isNewSession() {

    //    // check the last user to tell if the new authorized user is the same as the last one        
    //    if (app.lastUser &&
    //        app.lastUser.U &&
    //        app.lastUser.U !== "" &&
    //        app.lastUser.U === app.user.U) {
    //        return false;
    //    }
        
    //    // else: this is a new session: reset the last user model (in case it was set before)
    //    app.lastUser = {};
    //    return true;              
    //}

    // relauch ponding tasks if any
    app.taskProcessor.relaunchPendingTasks(app);
    
}

//// called to add a callback on authenticated
//export const whenAuthenticated = (app, callback) => {

//    app.onAuthenticated.push(callback);

//}

// called when endpoint returned 401: access token is not accepted
export const notifyUnauthorizedTask = (app, task) => {

    const auth = app.auth;
    console.log('notifyUnauthorizedTask: user is not authenticated. auth.isWaitingForAuthorization: ', auth.isWaitingForAuthorization);

    // Scenario detected during race conditions tests:
    // do we have newer tokens? (they could had been obtained while waiting for this task to return with 401 result, bacuase of expired token used)
    if (task.tokenId < auth.tokenId) {
        // we have some newer tokens - relaunch the task:
        console.log('user is not authenticated. we have new tokens - relaunch the task: ', task);
        app.taskProcessor.push(task);
    }
    else { // we don't have newer tokens: mark the task for relaunching, then check if we have already requested new tokens and if not - request it.

        task.waitingForAuthorization = true;
        //postponePendingTasks(); // other pending tasks will fail as well, and we don't want each of them to call this 'notifyUnauthorizedTask' method

        // is the task not an authorization one and are we waiting for the authorization?
        if (!isAuthorizationTask(task) && auth.isWaitingForAuthorization) {
            console.log('user is not authenticated: waiting for tokens - do nothing ')
            // do nothing keep waiting, the 'task.waitingForAuthorization' is already true,
            //so it will be relaunched when the token operation is completed
        }
        else { // We are not waiting for tokens, so should we launch a new token request?
            // Do we have a refresh token?
            if (auth.tokens.refresh_token && auth.tokens.refresh_token != '' && auth.tokenAttempts < 2) { // token attemps is to avoid loop when tokens have wrong signature: they get signed by auth API with one key, and refresh tokens are condiered valid, but when the data API is using other encryption keys, it will throw 401 and the 'wrong' refresh token would be succesfully used again, and again
                //console.log('using refesh token: ' + auth.tokens.refresh_token);
                // send the refresh token, do not set the unauthenticated flag yet,
                // first wait to see if can get a new access token and then to repeat the operation

                // (get new token only if we haven't requested it already: when new requests are made
                // when other are paused and tokens are already requested but not yet received,
                // would fail with 401 and force the login: it's because the refresh token is deleted
                // in the moment of reqeusting new tokens, so the app sees that there are none after
                // the new request fails and the app would to the login! Note: keeping the refresh tokens
                // until the token request hasn't completed is not a solution: new token request would
                // be made with the same refresh token --> would they work or not, it's better to mark
                // a flag that we are waiting for new tokens already so the other requests wait.
                if (!auth.isWaitingForAuthorization) {
                    //console.log('user is not authenticated: not waiting for tokens - get tokens... ');
                    getToken(); // this method will use the refresh token to obtain a new access token
                }
            }
            else {
               
                // clean the user data, tokens and app state
                // (but keep the error property, which is used by the login component)
                const loginError = app.store.state.loginError;
// block disabled to develop a new flow: keep user context so he can re-authenticate again
// make sure to clean tokens and local storage session, so wrong tokens are not going to be reused
deleteSession(app); // before...
resetApp(app); // before...
saveAppSettings(app); // before missing...

                //signOut(app); // ...now

                // keep the user model to check later if the same user continues the session:
                // the app.user model will be overwritten during login!
                app.lastUser = app.user;

                app.store.state.loginError = loginError;
                // redirect to login
                setAuthenticated(app, false);
            }
        }
    }
    // show the alert
    //...


    //app.forceRender();
}

// called when 'user/valid' endpoint didn't return 'ok', 
// possibly after using expired access token that is not valid anymore,
// either a token loaded from local storage or one from current session that had just expired

export const userIsNotValid = (app, task) => {

    //console.log('user is not valid');
    // show the alert
    //...

    setAuthenticated(app, false);
    //app.forceRender();
}

export const setAuthenticated = (app, authenticated) => {

    //console.log('set authenticated: ' + authenticated);

    // start the inactivity timer (it's already initialized from the App constructor)
    app.idleTimer.start();

    // once we completed the authentication, whether with success or not,
    // we will set 'app.initializing = false', so the login view can be shown in case we are not
    // authenticated (app.state.initialized is used as a switch there to show anything or not):
    app.setState({ authenticated: authenticated, initializing: false });

    // TO DO: move this flag to the Login component state:
    // authenticated or not: set it to false, so login view can update its 'busy' state:
    if (app.locator.loginPage)
        app.locator.loginPage.setState({ authenticating: false });

}

export function isAuthorizationTask(task) {

    if (task.type == RequestType.UserIsValid ||
        task.type == RequestType.Preauthorize ||
        task.type == RequestType.AuthorizeWithCookie ||
        task.type == RequestType.AuthorizeWithUserNamePassword ||
        task.type == RequestType.GetTokenWithAuthorizationCode ||
        task.type == RequestType.GetTokenWithRefreshToken) {
        return true;
    }
    return false;
}    
export function onLoginError(app, task) {

    //let type = app.codeType;
    //if (type) {
    //    if (type !== '') {
    //        if (type.localeCompare('none')) {
                
    //        }
    //        else if (type.localeCompare('captcha')) {
    //            console.log('make a captcha call');
    //            //

    //        }
    //        else if (type.localeCompare('two-factor')) {

    //        }
    //        // set login state with the current code type
    //        app.store.setState({ codeType: type });            
    //    }
    //}
    app.store.setState({ loginError: true, });    
}  