import React, { Component } from 'react';
import { Form, Table } from 'react-bootstrap';

import {
    findObjectWithId,
    onReturnBack,    
    updateAppHistory
} from '../../managers/appManager'; 
import { goToDocumentInfoPage } from '../../managers/documentManager';
import { loadUserData, findUserById } from '../../managers/userManager';

import * as appManager from '../../managers/appManager';
// import * as documentManager from '../managers/documentManager';
import { Item, ItemColumn, ItemList, Radio, SmallButton } from '../../components/atoms';
import {
    Paginator2, SelectList,
    SearchFilter, DocumentTypePanel,
} from '../../components/organisms';
import { routes } from '../../common/routes';
import { formatDateString } from '../../common/format';
import { DateOptions, PageSizeOptions, RequestType, WarningOptions } from "../../common/constants";


export class Documents extends Component {
    displayName = Documents.name

    constructor(props) {
        super(props);

        //console.log('Documents.constructor');
        this.app = window.app;        

        this.filter = {
            // search options and params
            type: this.app.store.getOption({ section: 'documents', option: 'type' }),
            warningType: this.app.store.getOption({ section: 'documents', option: 'warningType' }),
            // Note: when the store is created the localized texts are not loaded yet: the text in DateOptions(app)[0] will be undefined!
            date: this.app.store.getOption({ section: 'documents', option: 'date' }),
            text: this.app.store.getOption({ section: 'documents', option: 'text' }),

            items: this.app.store.state.documents || [], // (initially it's better to keep it here as 'this.filter' (synchronously) instead of within state (asynchronously updated). 
            // pagination
            page: {
                index: 0,
                inputText: '1',
                //size: PageSizeOptions[0].id,
                size: this.app.store.getOption({ section: 'page', option: 'size' }),
                sizeModel: PageSizeOptions[0], // default page size option = 10 items per page           
                items: [], // page items ,
                showPageInput: true,
                showTotalPages: false,
                totalPages: 0
            }
        };

        this.state = {
            // synchronized with the global store
            documents: this.app.store.state.documents,
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,

            // component with search options (page number, type, name) and the filtered items
            filter: this.filter
        };

    }

    componentDidMount() {
        //console.log('Documents.componentDidMount');
        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the state:
        window.app.locator.documentsPage = this;
        this.app.store.subscribe({ property: 'documents', component: this });
        this.app.store.subscribe({ property: 'options', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });

        if (!this.app.store.state.documents) {
            this.filterItems(); // in Documents page the 'filterItems' will actually apply a filter params and load documents
        }
    }

    componentDidUpdate(prevProps, prevState) {

        //console.log('documents: componentDidUpdate');
        if (prevState.documents !== this.state.documents) {            
            //this.filterItems();
            this.filter.items = this.app.store.state.documents; // in document component we don't assign the items now, only reset them and start loading new dataset, so let's set it here

            this.fillPage();            
        }
        const configPageSize = this.app.store.getOption({ section: 'page', option: 'size' });
        if (configPageSize !== this.filter.page.size) {
            //console.log('Documents.componentDidUpdate: new page size: ', configPageSize);
            this.filter.page.size = configPageSize;
            this.fillPage();
        }
    }

    componentWillUnmount() {

        window.app.locator.documentsPage = null;
        this.app.store.unsubscribe({ property: 'documents', component: this });
        this.app.store.unsubscribe({ property: 'options', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });

        //policyManager.unselectPolicies();
        //policyManager.hideContextMenu();
    }

    //// load documents will convert into filterItems()
    //loadDocuments = (search, type = this.filter.type) => {

    //    //console.log('load documents...');
    //    // first reset the documents array:
    //    //this.app.documents = []; // -> change this pattern: to see if it improves UX:
    //    //do not remove currently rendered documents untill new ones are loaded,
    //    // only show the loading icon.

    //    // do not load all: check the type selected:
    //    //if (this.state.type = RequestType.DocumentsProtected) {
    //    //    loadUserData(RequestType.DocumentsProtected);
    //    //}
    //    //else if (this.state.type = RequestType.DocumentsAccessed) {
    //    //    loadUserData(RequestType.DocumentsAccessed);
    //    //}
    //    //else if (this.state.type = RequestType.DocumentsWarnings) {
    //    //    loadUserData(RequestType.DocumentsWarnings);
    //    //}
        
    //    let initialFilterState = {
    //        DateFilterId: this.filter.date,
    //        Text: this.filter.text,
    //        PageIndex: 0, PageSize: 10,
    //    };
    //    console.log('loadDocuments: filter: ', this.filter);
    //    console.log('loadDocuments: initialFilterState: ', initialFilterState);
    //    // cancel the pending document tasks to avoid race condition
    //    cancelPendingDocumentTasks();
    //    // load the selected document type
    //    loadUserData(type, { ...initialFilterState, ...search });
    //}

    filterItems = () => {
        //console.log('Documents.filterItems --> load user data -->')
        const filter = this.filter;
        // for documents we clear the current list with any call to filterItems: a new list dataset will be loaded next
        this.app.store.setState({ documents: null });
        // load documents
        loadUserData(filter.type, filter);
    };

    fillPage = () => {

        const { items, page } = this.filter;
        if (!items) return;
        //page.items = items
        //    .slice(
        //        (page.index * page.size),
        //        (page.index * page.size) + page.size,
        //);
        // for documents we use entire items set, as it's page-index-based
        page.items = items            
        this.setState({ filter: this.filter });
    };

    changePage = (model) => {
        //console.log('Documents.changePage --> filter items -->')
        const filter = this.filter;
        const { page } = filter;
        if (model) { page.index = model.index; }
        page.inputText = page.index + 1; // set the text for the input control 
        this.filterItems(); // we need to apply all filter settings and load the documents from server
        //this.fillPage(); // fill page when documents are downloaded
    };

    onDateFilterChange = (e, model) => {

        if (e) e.stopPropagation();
        const filter = this.filter;
        
        if (model.id !== filter.date) {

            filter.date = model.id;
            this.app.store.setOption({ section: 'documents', option: 'date', value: model.id }); // set the new configuration value

            // go to the first page of the new search result  ('filterItems' will be called automatically)
            this.changePage({ index: 0 });
        }
    }

    onWarningTypeChange = (e, model) => {

        if (e) e.stopPropagation();
        const filter = this.filter;
        if (model.id !== filter.warningType) {
            // set the new filter value
            filter.warningType = model.id;
            // set the new configuration value
            this.app.store.setOption({ section: 'documents', option: 'warningType', value: model.id });

            // go to the first page of the new search result  ('filterItems' will be called automatically)
            this.changePage({ index: 0 });
        }
    };

    onTextFilterChange = (value) => {

        const filter = this.filter;
        if (filter.text != value) {

            filter.text = value; // set the new filter value
            this.app.store.setOption({ section: 'documents', option: 'text', value: value }); // set the new configuration value

            // go to the first page of the new search result  ('filterItems' will be called automatically)
            this.changePage({ index: 0 });
        }
    };

    onDocumentTypeChange = (type) => {
        
        // cancel the pending document tasks to avoid race condition when different tasks results come at different time
        window.app.taskProcessor.cancelPendingDocumentTasks();   
        
        const filter = this.filter;               
        if (type !== filter.type) {
            // set the new filter value
            filter.type = type; 
            // set the new configuration value
            this.app.store.setOption({ section: 'documents', option: 'type', value: type }); 
            // go to the first page of the new search result  ('filterItems' will be called automatically)
            this.changePage({ index: 0 }); 
        }
    };

    //=====================================================================================
    // REDIRECTIONS


    //editPolicy = (e, component) => {

    //    ////console.log('edit policy: ');
    //    let policy = this.app.selectedPolicy;
    //    // link to the 'edit' page
    //    this.props.history.push(routes.policies.edit(policy.RightsPolicyId));

    //    e.stopPropagation();
    //};

    //=====================================================================================
    // RENDER

    renderDocumentsTable(documents) {

        let columnNames = []
        let columnClasses = []
        let columns = [];

        switch (this.filter.type) {

            case RequestType.DocumentsProtected:
                columnNames = [this.app.R.Document, this.app.R.ProtectionPolicy, this.app.R.Date]
                columnClasses = [
                    'xs-col-12 xs-border-bottom xs-no-border-right s-col-5',
                    'xs-col-6 s-col-4',
                    'xs-col-6 s-col-3'
                ]
                break

            case RequestType.DocumentsAccessed:
                columnNames = [this.app.R.Document, this.app.R.AccessedBy, this.app.R.ProtectionPolicy, this.app.R.Date]
                columnClasses = [
                    'xs-col-12 xs-border-bottom xs-no-border-right s-col-4',
                    'xs-col-12 xs-border-bottom xs-no-border-right s-col-3',
                    'xs-col-6 s-col-3',
                    'xs-col-6 s-col-2'
                ]
                break
            
            case RequestType.DocumentsWarnings:
                columnNames = [this.app.R.Document, this.app.R.User, this.app.R.Warning, this.app.R.Date]
                columnClasses = [
                    'xs-col-12 xs-border-bottom xs-no-border-right s-col-6 s-border-bottom m-col-4 ',
                    'xs-col-12 xs-border-bottom xs-no-border-right s-col-6 s-border-bottom s-no-border-right m-col-3',
                    'xs-col-6 s-col-6 m-col-3',
                    'xs-col-6 sm-col-6 m-col-2'
                ]
                break
        }

        return (
            <ItemList>
                <Item className='head policies disabled'>
                    {this.renderColumns(columnNames, columnClasses)}
                </Item>

                {!documents &&
                    (<Item key={'docs-loading-icon'} >
                        <ItemColumn key={'col-docs-loading-icon'} className={'item-col one-line'}>{this.app.R.Loading}</ItemColumn>
                    </Item>)
                }

                {documents &&
                    (documents.length > 0
                        ?
                        documents.map((document, i) => this.renderDocument(document, columnClasses, i))
                        :
                        (<Item className='disabled'>
                            <ItemColumn className='col-12 text-center'>{this.app.R.NoResults}</ItemColumn>
                        </Item>)
                    )
                }       
            </ItemList>
        );
    }

    // Ihor code: leave for future reference
    //renderRow(columns, type) {
    //    const Comp = type === 'head' ? 'th' : 'td';

    //    return (
    //        columns.map((column, i) => (
    //            <Comp
    //                key={`col-${columns}-${i}`}
    //                className={'item-col one-line' + ''}
    //            >
    //                {column}
    //            </Comp>
    //        ))
    //    )
    //}

    renderColumns(names, classes) {
        
        return (
            names.map((name, i) => (                
                <ItemColumn
                    key={`col-${name}-${i}`}
                    className={'item-col one-line ' + classes[i]}
                >
                    {name}
                </ItemColumn >                    
            ))
        )
    }

    getUserNameOfUserThatAccessedDocument(document) {

        let accessedBy = document.AccessedBy;
        // hack for tests:
        //accessedBy = null;

        if (!accessedBy || accessedBy === '') {
            // try to find the user name using his id
            let user = findUserById(this.app, document.UserId);
            //console.log('getUserNameOfUserThatAccessedDocument: user : ', user)
            if (user) {
                accessedBy = user.U;//UserName;
            }
            else {
                // user doesn't exist in the contact list, could be a removed user or user from another organization:
                // we should get his data from server
            }
        }
        return accessedBy;
    }

    renderDocument(document, columnClasses, index) {

        let columnNames = [];

        switch (this.filter.type) {
                     
            case RequestType.DocumentsProtected:
                columnNames = [document.Document, document.ProtectionName, formatDateString(document.ProtectionDate)]                
                break
            case RequestType.DocumentsAccessed: {
                let accessedBy = this.getUserNameOfUserThatAccessedDocument(document);
                columnNames = [document.Document, accessedBy, document.ProtectionName, formatDateString(document.AccessDate)]
                break
            }      
            case RequestType.DocumentsWarnings:
                columnNames = [document.DocumentName, document.UserFullName, appManager.formatWarning(document.WarningType), formatDateString(document.DateTime)]                
                break
        }

        return (            
            <Item
                key={'doc-tr' + index}                
                onClick={(e) => { goToDocumentInfoPage(e, document, null, this.filter) }}     
                selected={document.selected}
            >     
                {this.renderColumns(columnNames, columnClasses)}
            </Item>
        )
    }

    renderBackButton = () => {

        return (
            <SmallButton
                contentClass='icon-back'
                onClick={onReturnBack}
            />
        );
    }

    //-------------------------------------------------------------------------------------
    render() {

        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)
            return null;

        const filter = this.filter;
        const { documents } = this.state;
        const { date, type, warningType} = filter;        
        const app = window.app;

        updateAppHistory(app, this);

        // find the date model, using the selected date id (originally read from the store options)
        const dateOptions = DateOptions(app);
        const warningOptions = WarningOptions(app);

        const selectedDateOption = findObjectWithId(dateOptions, date);
        const selectedWarningOption = findObjectWithId(warningOptions, warningType);

        return (
            <div>
                <div>
                    {this.renderBackButton()}
                    <span className='label extra-space'></span>
                    <h3 className='label'>{this.app.R.Documents}</h3>
                </div>
                <DocumentTypePanel
                    documentType={type}
                    onChange={this.onDocumentTypeChange}
                />
                <div className='filters'>
                    <SelectList
                        items={dateOptions}
                        selectedItem={selectedDateOption}
                        onChange={this.onDateFilterChange}
                        variant="secondary"
                    />
                    &nbsp;&nbsp;
                    {(filter.type === RequestType.DocumentWarnings || filter.type === RequestType.DocumentsWarnings) && (
                        <SelectList
                            items={warningOptions}
                            selectedItem={selectedWarningOption}
                            onChange={this.onWarningTypeChange}
                            variant='secondary'
                        />
                    )}
                    <SearchFilter
                        app={app}
                        showSearchIcon
                        placeholder={app.R.Search}
                        value={filter.text}      
                        minCharacters={3}
                        onChange={this.onTextFilterChange}
                    />
                </div>
                
                {
                    this.renderDocumentsTable(this.state.documents)
                    //this.renderDocumentsTable(this.filter.page.items)
                }

                <Paginator2
                    filter={this.filter}
                    onChange={this.changePage}
                    onlyCurrentPageLoaded={true}
                />
                <br />

            </div>
        );
    }
}
