/*
  Used to import DayPickerInput. e.g. `import DayPickerInput from 'react-day-picker/DayPickerInput'`
*/

/* eslint-disable no-var */
/* eslint-env node */

var DayPickerInput = require('./lib/src/DayPickerInput');

module.exports = DayPickerInput;
