import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import { ItemList, Item, ItemHead, ItemColumn } from "../atoms";


export class TableGrid extends Component {

    render() {
        const { title, list } = this.props;
        if (!list) return null;
        return(
            <ItemList className='clear-left' striped bordered variant="dark">
                <Item className='head disabled' key={'key' + title}>
                    <ItemColumn>{title}</ItemColumn>
                </Item>
                    
                {list.map((item, i) => this.renderItem(item, i))}

            </ItemList>
        )
    }

    renderItem = (item, i) => {
        const { withoutColon } = this.props;

        const onClick = item.onClick ? item.onClick : null;
        const className = item.className ? ' ' + item.className : ''; // TO DO: this should be improved, classes for individual columns should be passed in props
        const itemClassName = item.onClick ? '' : 'disabled'; // set 'disabled' class for items that have no click callback

        return (
            <Item className={itemClassName} onClick={onClick} key={'key' + this.props.title + i}>
                <ItemColumn className={'xs-col-12 xs-border-bottom xs-no-border-right s-col-6' + className}>{item.title}{withoutColon ? `` : `:`}</ItemColumn>
                <ItemColumn className={'xs-col-12 s-col-6' + className}>{item.value}</ItemColumn>
            </Item>
        );
    }

}