import React, { Component } from 'react';

// For pseudo-preloading images:  we insert this container
// out of a screen once we have the css files downloaded.
// TO DO: For better preloading use javascript on <img> elements.

export class Preloader extends Component {

    constructor(props) {
        super(props);       
    }
    
    render() {
        return (
            <div className='out-of-screen'>

                <div className='icon-back' />
                <div className='icon-refresh' />
                <div className='icon-delete' />
                <div className='icon-fav-off' />
                <div className='icon-fav-on' />
                <div className='icon-fav-off-negative' />
                <div className='icon-fav-on-negative' />
                <div className='icon-edit' />
                <div className='icon-edit-negative' />
                <div className='icon-close' />
                <div className='icon-close-negative' />
                <div className='icon-checked' />
                <div className='icon-unchecked' />
                <div className='icon-add' />
                <div className='icon-add-negative' />
                <div className='icon-share-off' />
                <div className='icon-share-on' />
                <div className='icon-share-on-negative' />
                <div className='icon-search' />
                <div className='icon-checkbox-checked' />
                <div className='icon-checkbox-unchecked' />
                <div className='icon-invite' />
                <div className='icon-invite-negative' />
                <div className='icon-users' />
                <div className='icon-users-negative' />
                <div className='icon-next' />
                <div className='icon-prev' />

                <div className='icon-menu' />
                <div className='icon-home' />
                <div className='icon-protections' />
                <div className='icon-documents' />
                <div className='icon-options' />
                <div className='icon-user' />
                <div className='icon-search' />
                <div className='icon-users-big' />

                <div className='icon-box' />
                <div className='icon-onedrive' />
                <div className='icon-googledrive' />
                <div className='icon-sharepoint' />
                <div className='icon-folder' />
                <div className='icon-locked' />
                <div className='icon-unlocked' />
                <div className='icon-accept' />
                <div className='icon-add-users' />
                <div className='icon-document-info' />
                <div className='icon-custom-protection' />
                <div className='icon-unprotect' />

            </div>
        );
    }
}