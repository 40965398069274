
import { formatDate } from '../components/atoms/Calendar/lib/moment';
import {
    AccessPermissionType,
    AccessPermissionModels,
    ResultCode
} from "./constants";
import { CanEditPolicyValue } from '../managers/policyManager';

export const formatDateString = (dateString, dateOnly) => {

    let date = new Date(dateString);
    var offset = date.getTimezoneOffset(); // offset in minutes
    //console.log('formatDateString: offset: ', offset);
    date.setMinutes(date.getMinutes() - offset);
    let dString = formatDate(date, window.app.settings.language.dateTimeFormat);
    return dString;

    // old code:

    //if (!date) date = ''; // do not work with undefined
    //let day = '';
    //let time = '';
    //// format the date for better rendering
    //let parts = date.split('T');
    //if (parts.length > 0) {
    //    day = parts[0];
    //    // format the day so it has '/' in place of '-', like the one from Calendar
    //    let dayParts = day.split('-');
    //    if (dayParts.length > 2) {
    //        day = dayParts[0] + '/' + dayParts[1] + '/' + dayParts[2];
    //    }

    //    if (dateOnly || parts.length < 2) {
    //        date = day;
    //    }
    //    else  {
    //        time = parts[1];
    //        let timeParts = time.split('.');
    //        if (timeParts.length > 0) {
    //            time = timeParts[0];
    //        }
    //        date = day + ', ' + time;
    //    }        
    //}
    //return date;
}

export const formatPermissions = (accessPermissions, parentModel) => {

    // It will convert a policy's or document's 'permission.AccessPermission' 
    // (which is an integer with binary flags set on) to a list of permission models:
    // we will find a source permission model that corresponds to each bit 
    // that is turned-on, iterating over a list of source permission models.
    let sourcePermissionModels = AccessPermissionModels(window.app);
    let targetPermissionModels = []; // permissions to be returned, with the 'selected' or other properties set on/off
    
    sourcePermissionModels.map(sourceModel => {
        // clone the original permission
        var model = Object.assign({ enabled: true, selected: false }, sourceModel);        
        ////console.log('clone.id :' + clone.id);
        ////console.log('flags :' + flags);
        ////console.log('A & B :' + clone.id & flags);
        // set the 'selected' property
        if ((model.id & accessPermissions) == model.id) {
            model.selected = true;
        }

        model.parentModel = parentModel; // set the reference to the parent model we edit - to the entire permission model that belongs to the policy

        // enable/disable the options:
        //if full control - disable all other options but the full control
        if ((accessPermissions & AccessPermissionType.FullControl) == AccessPermissionType.FullControl) {
            ////console.log('renderPermissionOptions: Full Control on - disable other options');
            if (model.id == AccessPermissionType.FullControl) {
                ////console.log('m.id = ' + m.id + ': enabled ');
                model.enabled = true;
                model.transparent = false;
            } else {
                ////console.log('m.id = ' + m.id + ': disabled ');
                model.enabled = false;
                model.transparent = true;
            }
        }
        else { // enable all options except the 'View', which is always on
            ////console.log('renderPermissionOptions: enable all options but View');            
            if (model.id == AccessPermissionType.View) {
                ////console.log('m.id = ' + m.id + ': disabled ');
                model.enabled = false;
                model.transparent = true;
            } else {
                ////console.log('m.id = ' + m.id + ': enabled ');
                model.enabled = true;
                model.transparent = false;
            }
        }

        // push the cloned permission
        targetPermissionModels.push(model);
    });

    return targetPermissionModels;
}

export const convertSelectedPermissionsToText = (permissions) => {

    let output = '';
    let count = 0;
    let total = 0;
    let completed = false;
    // Option 1:
    // if 'full control' is selected: render only this one:
    permissions.map(p => {
        if (p.selected) {
            if (p.id == AccessPermissionType.FullControl) {                
                completed = true;
                output += p.label;                
            }
        }
    });
    if (completed) return output;

    // Option 2:
    // first count the selected ones (it's needed to know if we should add the ',' character or not after a permission text)
    permissions.map(p => {
        if (p.selected && p.id !== AccessPermissionType.AddUsers) {
            total++;
        }
    });
    // now format the output text
    permissions.map(p => {
        if (p.selected && p.id !== AccessPermissionType.AddUsers) {
            output += p.label;
            count++;
            if (count < total) { output += ' | ' }
        }
    });
    return output;
}

export const convertShareOptionsToText = (app, permission) => {

    let output = '';
    let isAuthor = permission.IsAuthor;
    let share = permission.IsVisible ? true : false;
    let addUsers = (permission.AccessPermissions & AccessPermissionType.AddUsers) === AccessPermissionType.AddUsers;
    let canEditPolicy = permission.CanEditPolicy === CanEditPolicyValue.True ? true : false;

    if (share || isAuthor) {        
        output += app.R.SharePolicy;   
        output += (addUsers || canEditPolicy || isAuthor) ? ' | ' : '';
    }

    if (addUsers || isAuthor) {
        output += app.R.AddUsers;
        output += canEditPolicy || isAuthor ? ' | ' : '';
    }

    if (canEditPolicy || isAuthor) {
        output += app.R.EditPolicy;        
    }

    return output;
}

export const convertPolicyNamesToText = (items) => {

    let output = '';
    let count = 0;
    let total = 0;
    // first count the selected ones (it's needed to know if we should add the ',' character or not after a permission text)
    items.map(i => { total++; return true});
    // now format the output text
    items.map(i => {        
        output += "'" + i.Name + "'" ;
        count++;
        if (count < total) { output += ', ' }
        return true;
    });
    return output;
}

// alternative function made before the 'formatPermissions', keep it for reference or for re-using
//export const getAccessPermission = (userPermission) => {

//    let app = window.app;
//    let accessPermissions = userPermission.accessPermissions;
//    let details = [];
//    //check all flags we use in the UI and populate the details array 
//    if (accessPermissions & AccessPermission.FullControl) {
//        details.push({ flag: AccessPermission.FullControl, info: app.R.FullControl });
//    }
//    if (accessPermissions & AccessPermission.View) {
//        details.push({ flag: AccessPermission.View, info: app.R.View });
//    }
//    if (accessPermissions & AccessPermission.Edit) {
//        details.push({ flag: AccessPermission.Edit, info: app.R.Edit });
//    }
//    if (accessPermissions & AccessPermission.Print) {
//        details.push({ flag: AccessPermission.Print, info: app.R.Print });
//    }
//    if (accessPermissions & AccessPermission.Extract) {
//        details.push({ flag: AccessPermission.Extract, info: app.R.Extract });
//    }
//    if (accessPermissions & AccessPermission.AddUsers) {
//        details.push({ flag: AccessPermission.AddUsers, info: app.R.AddUsers });
//    }
//    return details;
//}


export function getUserPermission(userName, policy) {

    let result = null;
    (policy.Users || []).filter(permission => {
        if (userName === permission.UserName) {
            result = permission
        }
    })
    return result;
}


export function getAvailableUserPermissions(userName, policiesList) {
    let accessList = [];

    policiesList.forEach(permission => {
        (permission.Users || []).filter(user => {
            if(userName === user.UserName) {
                accessList = [...accessList, user.AccessPermissions]
            }
        })
    })

    return accessList;
}


export function template(original, replacements) {

    let result = original;
    for (var key in replacements) {
        var value = replacements[key];
        result = result.replace(key, value);
    }
    return result;

}

export function getFullNameWithGuestLabel(app, user) {

    let fullNameToShow = user.F ? user.F : '';
    if (user.O != app.user.O) {
        // have to be a guest
        fullNameToShow += ' (' + app.R.Guest + ')';
    }
    return fullNameToShow;
}
