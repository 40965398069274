import React, { Component } from 'react';

import { Checkbox, Item, ItemColumn, SmallButton } from "../../../components/atoms";
//import { CheckboxList } from "../../../components/organisms";


export class LockSection extends Component {

    constructor(props) {
        super(props);

        this.app = window.app;
    }

    onDocumentLocked = (e) => {

        if (e) e.stopPropagation();
        if (e) e.preventDefault();
        const { onDocumentLocked } = this.props;        
        // callback to the parent component (document info page)
        this.props.onDocumentLocked()
    }

    render() {
        const { IsLocked } = this.props;        
        //console.log('LockSection: render: is locked: ' + IsLocked);       
        return (
            <div >     
                {IsLocked && (<span className='red'>{this.app.R.DocumentIsLocked}</span>)}
                <div className='clickable' onClick={this.onDocumentLocked}>
                    <h4 className='label'>{IsLocked ? this.app.R.Unlock : this.app.R.Lock}</h4>
                    <SmallButton
                        contentClass='icon-locked'
                        onClick={(e) => this.onDocumentLocked(e)}
                    />
                </div>        
                <p>{this.app.R.ChangesWillAffectThisDocumentAndCopies}</p>
            </div>
        )
    }
}