import {
    deleteSession,
    loadAllAppData,
    saveSession,
    setLanguage,
    setUserLanguage,

    saveAppSettings
} from './appManager';
import {
    userIsNotValid,
    userIsValid
} from './authManager';
//import * as appManager from './appManager';
//import { initializeSimplePolicy} from './policyManager';
import { launchTests } from './testManager';
import { api } from '../common/api';
import { RequestType, TaskState, TaskSubState, UserDataRequestTypes } from '../common/constants';
//import { setOption } from '../common/configs';

/* SP DESKTOP methods to implement:

IUserService/CheckIfValidUser --> <CheckIfValidUserResult>Ok</CheckIfValidUserResult>
IPolicyService/GetClassifierMetadataPoliciesForUser
IUserService/GetGroupsEmailOfUserById
IPolicyService/GetPoliciesForUser
IPolicyService/GetCustomGuidByOrganizationId
IUserService/GetUserConfig
IUserService/GetUsersOfOrganization

NEW POLICY:
IPolicyService/CreatePolicy
EDIT POLICY:
IPolicyService/UpdateWithUserPolicy
REMOVE POLICY:
IPolicyService/RemovePolicyList

DOCS (protegidos)
StatsService/GetDocumentsByProtectionDateInfoAndUserId
DOCS (accesos)
IStatsService/GetDocumentsByAccessDateInfoAndUserId
DOCS (alertas)
IStatsService/GetWarningsByUserId

*/

// We won't load the following user data automatically, it will be deferred until the user enters the specific path:
// - documents (the requests can take time) 
// - users (with the optimization executed on server - now we load them from the start)
// When we mount the component, we can check if the data is null and then start loading

var loadAllUserDataMode = false;

// this method loads user data, starting all requests at once
export const loadAllUserData = () => {

    loadAllUserDataMode = true;

    // reset the language state before loading the userr data (later in userDetails 
    // the user language will be loaded) (which will re-render the App and its content)
    window.app.store.setState({ userLanguageLoading: false });
    window.app.store.setState({ userLanguageLoaded: false }); // set this one to false, so the pages are not rendered until we get the user's language (in one of next steps)

    UserDataRequestTypes.map(item => {
        item.loaded = false; // reset the 'loaded' flag before loading it, later we will count them to see if all types were loaded
        loadUserData(item.type);
    });
}

//-------------------------------------------------------------------
const updateUserDataList = (app, task) => {

    let completed = true;
    UserDataRequestTypes.map(item => {
        // update the 'loaded' state:
        if (item.type === task.type) {
            item.state = TaskState.Completed;
        }
        // check if this data type is loaded
        if (item.state !== TaskState.Completed) completed = false;
    });
    if (completed) {
        //console.log('all user data is loaded!');
        loadAllUserDataMode = false; // turn it off, so when we make individual data calls they won't end up calling 'onAllUserDataLoaded' again
        //loadUserSettings();

        launchTests(app);
    }
}
//-------------------------------------------------------------------

export const loadUserData = (type, filter) => {
    let app = window.app;
   
    // prepare the 'task' object
    let task = {
        app: app,
        type: type,
        onComplete: onUserDataLoaded,
    };

    if (type == RequestType.UserIsValid) {

        //let formData = taskManager.convertTaskToFormData(task);
        task.ajax = { // create the request object for HTTP client
            url: api().user.isValid(),
        };
    }
    //else if (type == RequestType.UserClassifierMetadataPolicies) {

    //    let formData = taskManager.convertTaskToFormData(task);
    //    task.ajax = { // create the request object for HTTP client
    //        url: api().user.classifierpolicies(),
    //        data: formData
    //    };  
    //}
    else if (type == RequestType.UserConfiguration) {

        task.ajax = { // create the request object for HTTP client
            url: api().user.config(),
        };
    }
    else if (type == RequestType.UserDetails) {

        task.ajax = { // create the request object for HTTP client
            url: api().user.details(),
        };
    }
    else if (type == RequestType.UserGroups) {

        task.ajax = { // create the request object for HTTP client
            url: api().user.groups(),
        }; 
    }
    else if (type == RequestType.UserPolicies) {         

        task.ajax = { // create the request object for HTTP client
            url: api().user.policies(),
        };        
    }
    else if (type == RequestType.UserPolicy) {

        task.ajax = { // create the request object for HTTP client
            url: api().user.policy(),
            data: filter ? { TargetId: parseInt(filter.id) } : { } // filter.id is a path param and is a string type
        };
        //console.log('get user policy: filter: ', filter)
        //console.log('get user policy: task.ajax.data: ', task.ajax.data)
    }
    else if (type == RequestType.CustomPolicyGuid) {
        
        task.ajax = { // create the request object for HTTP client
            url: api().policy.customPolicyGuid(),
        };
    }
    else if (type == RequestType.OrganizationUsers) {

        task.ajax = { // create the request object for HTTP client
            url: api().organization.users(),
        };
    }
    
    // DOCUMENTS:
    //    DocumentsProtected: 61,
    //      DocumentsAccessed: 62,
    //          DocumentsWarnings: 63

    else if (type == RequestType.DocumentsProtected) {

        task.ajax = { // create the request object for HTTP client
            url: api().document.getProtected(),
        };
    }
    else if (type == RequestType.DocumentsAccessed) {

        task.ajax = { // create the request object for HTTP client
            url: api().document.accessed(),
        };
    }
    else if (type == RequestType.DocumentsWarnings) { // for all documents for the current user
   
        task.ajax = { // create the request object for HTTP client
            url: api().document.userWarnings(),
        };
    }
    else if (type == RequestType.DocumentWarnings) { // for selected document

        task.ajax = { // create the request object for HTTP client
            url: api().document.warnings(),
        };
    }
    else if (type == RequestType.DocumentTracking) { // for all documents/for selected document

        task.ajax = { // create the request object for HTTP client
            url: api().document.tracking(),
        };
    }

    // prepare the filter (only if 'ajax.data' is not yet set)
    if (filter) {
        if (!task.ajax.data) {
            task.ajax.data = {};
            // we need to convert a bigger filter model used in components to smaller server model
            let documentId = filter.documentId || 0;
            let warningType = filter.warningType || 0;
            let serverFilter = {
                WarningType: warningType,
                TargetId: documentId,
                DateFilterId: filter.date,
                Text: filter.text,
                PageIndex: filter.page.index,
                //PageSize: task.filter.page.size,
                PageSize: filter.page.size + 1, // get one more value so the 'next' button can be enabled/disabled according to the exceeding results
            }
            task.ajax.data.Filter = serverFilter;
        }

    }

    // push the task to be executed
    window.app.taskProcessor.push(task);
}

//-------------------------------------------------------------------
const showAlert = (data) => {

    let message = '';
    if (data.error.description) { message = data.error.description };
    // temp, only until good dialog with retry button is finished
    alert('Error: ' + message);
}
//-------------------------------------------------------------------
const onUserDataLoaded = (task) => {

    let app = window.app;
    let store = app.store;
    let locator = app.locator;
    let result = task.result;
    let type = task.type;

    //console.log('on User Data Loaded . type: ', task.typeName)

    // this call will load user preferences, if all is loaded:
    if (loadAllUserDataMode) {        
        updateUserDataList(app, task);
    }

    // set the information string used in some tasks lists
    //task.info = 'ok';

    // Important: this call is excluded from 'load all user data' and instead called 
    // from the authentication block: don't load any other user data before we know user that is valid:    
    if (type == RequestType.UserIsValid) {

        ////console.log('is user a valid user?');
        // ...
        // check if the user is valid: tokens could be still valid even if user was removed, so make sure he's ok

        //if (result !== 'ok') {
        //    userIsNotValid(app, task);
        //    // log error, show alert with error description
        //    //showAlert(data);
        //    return;
        //}

        // extend the response so organization code can be returned by the updated Data API in the following format:
        // result = {
        //    organization: {
        //        code
        //    },
        //    user: {

        //    }
        //}
        let shouldLoadCustomAppData = false;

        let resultObject = eval(result); // the result is a composed object (from updated Data API) or the simplified user model (from older Data API)

        let organization = null;
        let simpleUser = null;

        if (resultObject.User) { // this means that a composed object is received, with 'User' and 'Org' properties            
            simpleUser = resultObject.User;
            organization = resultObject.Org;
            if (organization) {                            
                //if (organization.Code !== '') {

                // Custom org code is persisting, so the app can load from the start with a custom content.
                // When a user from a different org signs in, his org's code (even if empty) will overwrite the old one.
                // This way users from orgs without customizations will see the 'global' content. 
                    if (app.settings.organizationCode !== organization.Code) {
                        app.settings.organizationCode = organization.Code;
                        saveAppSettings(app); // save this value here because it will be loaded later in 'loadAllAppData', this way we avoid having to fix the flow
                        shouldLoadCustomAppData = true;
                    }                        
                //} 
            }
        }
        else {
            simpleUser = resultObject; // only the simple user model is received (older Data API)
        }
        
        app.user.Id = simpleUser.Id;
        app.user.O = simpleUser.O;
        app.user.R = simpleUser.R;
        // the result has the current user.UserName (email): take it in case it's been updated
        if (app.user.U !== simpleUser.U) {
            //console.log('user email has been updated: ', result);
            // delete existing session and create a new one under the new user name
            deleteSession(app, app.user.U); // pass the old user name
            app.user.U = simpleUser.U; // set the new user name
            //save the session under the new name (no param needed, app.user will be used)
            saveSession(app);
        }

        // set the user name so it can be retrieved next time on start
        app.settings.lastUserName = simpleUser.U;
        app.settings.lastOrganizationId = simpleUser.O;
        if (shouldLoadCustomAppData) {
            // ...
            app.isloadingCustomAppData = true;
            loadAllAppData(app);
            userIsValid(app);
        }
        else {
            app.isloadingCustomAppData = false;
            userIsValid(app);
        }
    }
    //-----------------------------------------------------
    // DOCUMENTS: (loaded on demand, here only for tests)
    else if (type == RequestType.DocumentsProtected) {

        let documents = eval(result);
        //app.store.state.documents = items;
        //let currentState = app.store.state.documents;
        //let partialState = { items };
        //let newState = Object.assign({}, currentState, partialState);
        //store.setState({ documents: newState });
        //app.setComponentState(locator.documentsPage, { documents });  
        app.store.setState({ documents });
    }
    else if (type == RequestType.DocumentsAccessed) {

        let documents = eval(result);
        //let currentState = app.store.state.documents;
        //let partialState = { items };
        //let newState = Object.assign({}, currentState, partialState);
        //store.setState({ documents: newState });
        //app.setComponentState(locator.documentsPage, { items });  
        app.store.setState({ documents });
    }
    else if (type == RequestType.DocumentsWarnings) {

        let documents = eval(result);
        //app.store.state.documents = items;
        //let currentState = app.store.state.documents;
        //let partialState = { items };
        //let newState = Object.assign({}, currentState, partialState);
        //store.setState({ documents: newState });
        //app.setComponentState(locator.documentsPage, { items });
        app.store.setState({ documents });
        
    }
    else if (type == RequestType.DocumentWarnings || type == RequestType.DocumentTracking) { // events for selected document

        let documentEvents = eval(result);        
        app.store.setState({ documentEvents });

    }
    //-----------------------------------------------------
    else if (type == RequestType.UserConfiguration) {

        let userConfiguration = eval(result);
        // copy fields fron the received oject to the existing app.store.state.options.userConfiguration,
        // so we don't override the exiting UI params!
        //app.store.state.options.userConfiguration = userConfiguration;  NOT LIKE THIS  
        //for (var k in userConfiguration) app.store.state.options.userConfiguration[k] = userConfiguration[k];
        for (var node in userConfiguration) {
            let keyvalue = userConfiguration[node]
            let key = keyvalue.Key;
            let value = keyvalue.Value;
            if (value == 'true' || value == 'True' || value == '1')
                value = true;
            else value = false;
            ////console.log('set user configuration: keyvalue: ',  keyvalue)
            ////console.log('set user configuration: key: ' + key + ', value: ' + value)
            //app.store.state.options.userConfiguration[key] = value;

            // set option value for the given configuration key
            store.setOption({ key: key, value: value });
        }
        store.setOption({ key: 'UserConfigurationLoaded', value: true });
        // Update the state of the store so subscribers get notified.
        // Re-assign the reference to the same original object, which
        // is a Singleton and is already initialized, so the reference
        // shouldn't be changed.
        store.setState({ options: store.state.options });
    }

    else if (type == RequestType.UserDetails) {
        
        let userDetails = eval(result);        
        store.setState({ userDetails });
        // set the app.user properties (they could be incomplete - for external users, or outdated)
        if (app.user) {
            // the commented fields are currently returned from 'is valid' call which is always executed first,
            // only the FullName is missing there
            //app.user.Id = userDetails.UserId; // !!! the users we get from server use the HTTP-optimized-'UserSimple'-model with short property names!!!
            //app.user.R = userDetails.RolId;
            //app.user.O = userDetails.OrganizationId;
            app.user.F = userDetails.ShowName; // !!! property name is different in UserDetails
        }

        if (userDetails.Language === null || !userDetails.Language || userDetails.Language === '') {
            // language property is null: set the default language for this user
            const languageId = app.store.state.options.appSettings.defaultLanguage;

            // set the default language to the user details object
            userDetails.Language = languageId;

            // save the new user language in the DB
            app.store.state.userDetails.Language = languageId;
            saveUserDetails();
        }
        else {
            // trim the language code string - it can have spaces when loaded from DB
            userDetails.Language = userDetails.Language.trim();            
        }    
        // set the user language
        setUserLanguage({ id: userDetails.Language });
    }

    else if (type == RequestType.UserGroups) {

        let groups = eval(result);     
        store.setState({ groups });
        // convert the groups to user models and add them to the user list - DO NOT do it
        // - group users need to be returned from the UserService API, group emails received 
        // here are only used to relate the current user with groups where he is a member
        //groups.map(g => {
        //    let user = {
        //        UserId: 1,
        //        UserName: g,
        //        FullName: "group fullname",
        //        OrganizationId: 666,
        //        RolId: 666
        //    }
        //})
    }
    
    else if (type == RequestType.UserPolicies) {

        let policies = eval(result);
        // for tests: initialize the false protections first:
        //policies.map(policy => {
        //    initializeSimplePolicy(policy); // step needed to prepare the model for correct GUI function (deprecated: tool split from the policy, is sent as tool.policy to onFilesDrop)
        //});

        //store.setState({ policies: { items } }); v1 (removes deep properties!)
        //let currentState = app.store.state.policies;
        //let partialState = { items };
        //let newState = Object.assign({}, currentState, partialState);
        //store.setState({ policies: newState }); // v2 (keeps deeper properties)
        store.setState({ policies }); // v2: state has only one dimension now, so we don't have to worry about deeper properties

        // after loading the policies it's a good moment to load user settings, so policies favourite state will be set
        //loadUserSettings(); --> no, as policy fav flags are loaded from and DB with policies and saved to DB on change, and user language is loaded with user details

    }
    else if (type == RequestType.UserPolicy) {
        //console.log("on policy loaded.");
        let policyInformation = eval(result);
        if (policyInformation.Policy) {
            store.setState({ policy: policyInformation.Policy }); // v2: state has only one dimension now, so we don't have to worry about deeper properties
        }            
        else {
            //console.log('TODO: warning dialog and user options hould be offered here')
        }            
    }
    //else if (type == RequestType.UserClassifierMetadataPolicies) {
    //    ...
       
    //}
    
    else if (type == RequestType.CustomPolicyGuid) {

        // this is used for custom policies guid: it's the same for all custom policies applied by an organization.
        // it is stored in RightsPolicy table as "CustomPolicy" for an org
        //console.log('result: ' + result);
        //let customPolicyGuid = eval(result); // doesn't work with serialized guid: just use a "request.Result = guid" on API side
        let customPolicyGuid = result;
        store.setState({ customPolicyGuid });
        //console.log('custom policy guid: ' + customPolicyGuid);
        //console.log('store.state. custom policy guid: ' + store.state.customPolicyGuid);
        
    }
    else if (type == RequestType.OrganizationUsers) {

        let users = eval(result);
       
        // step 1: find current user on the list and store the reference as 'app.user'
        const filtered = users.filter(user => user.Id == app.user.Id);
        if (filtered && filtered.length > 0) {
            //console.log('onUserDataLoaded: setting new app.user: ', app.user, filtered[0])
            app.user = filtered[0]; // this is our user

            saveSession(app);
        };
        // step 2: remove the current user from the list so we don't see him
        // when rendering contacts (e.g.when adding users to permissions).
        // It's better for performance of search, otherwise we'd have
        // to look for him during search so he's excluded from results.
        users = users.filter(user => user.Id != app.user.Id);

        //let currentState = app.store.state.users;
        //let partialState = { items };        
        //let newState = Object.assign({}, currentState, partialState);
        //store.setState({ users: newState });
        store.setState({ users });

        //app.setComponentState(locator.contactsPage, { items });
        //app.setComponentState(locator.editPolicyPage, { users: store.state.users });
    }    
}
//-------------------------------------------------------------------
export const goToSendInvitation = (userNameList) => {
    const invitation = window.app.R.Invitation;

    sendInvitation(userNameList, invitation);
}

// params:
// 'users' is a list of user emails
// 'invitation' is a string with the invitation text, already formatted
// with the current user name (e.g. '{Peter} invited you to...')
export const sendInvitation = (component, users, invitation) => {

    let app = window.app;
    let type = RequestType.SendInvitation;
    let task = {        
        type: type,
        onComplete: (task) => onSendInvitationCompleted(task, component),
    };

    task.ajax = { // create the request object for HTTP client
        url: api().user.invitation(),
        data: {            
            TargetNames: users,
            TargetValue: invitation,
        }
    };

    window.app.taskProcessor.push(task);
}

export const onSendInvitationCompleted = (task, component) => {
    //console.log('onSendInvitationCompleted: task, component: ', task, component)
    let app = window.app;
    //task.info = 'ok';
    if (component) component.onSendInvitationCompleted();
    //  return to the previous screen
    if (app.history) { app.history.goBack() }
}

export const refresh = (e) => {
    e.stopPropagation();
    loadAllUserData();
}

export function saveUserConfiguration(key, value) {

    let app = window.app;
    let type = RequestType.SaveUserConfiguration;
    let task = {       
        type: type,
        onComplete: onSaveUserConfigurationCompleted,
    };

    task.ajax = { // create the request object for HTTP client
        url: api().user.saveConfiguration(),
        data: {        
            TargetName: key,
            TargetValue: value            
        }
    };

    window.app.taskProcessor.push(task);
}

export const onSaveUserConfigurationCompleted = (task) => {

    //task.info = 'ok';    
}

export function saveUserDetails() {

    let app = window.app;
    let task = {        
        type: RequestType.SaveUserDetails,
        onComplete: onSaveUserDetailsCompleted,
    };

    task.ajax = { // create the request object for HTTP client
        url: api().user.saveUserDetails(),
        data: {
            TargetValue: JSON.stringify(app.store.state.userDetails), //serialize user details here,
        }
    };

    window.app.taskProcessor.push(task);
}

export const onSaveUserDetailsCompleted = (task) => {

    //task.info = 'ok';
}
export const findUserById = (app, id) => {

    let users = app.store.state.users
    if (!users) return null;
    for (let i = 0; i < users.length; i++) {
        let user = users[i];
        if (user.Id === id) {
            return user;
        }
    }
    return null;
}
