import React, { Component, Fragment } from 'react';
import { Form } from "react-bootstrap";

import {
    addSelectedUsersToInvitiationsList,
    addSelectedUsersToPermissionsList,
    canSendInvitations,
    clickUserFromContactList,
    goToHomePage,
    goToInvitationsPage,
    onReturnBack,
    unselectItems,
    updateAppHistory
} from '../managers/appManager';
import * as appManager from '../managers/appManager';
import * as taskManager from '../managers/taskManager';
import * as userManager from '../managers/userManager';
import { Item, ItemColumn, ItemHead, ItemList, SmallButton } from '../components/atoms';
import { getFullNameWithGuestLabel, template } from '../common/format';
import {
    Modal, Paginator2,
    SearchFilter, SelectList,
    MultiSelect,
} from "../components/organisms";
import { PageSizeOptions, RequestType, UserTypeModels } from "../common/constants";


export class Contacts extends Component {
    displayName = Contacts.name;
    
    constructor(props) {
        //console.log('Contacts. constructor');
        super(props);
        //this.preparedListOfLanguages = {};

        this.app = window.app;
        
        this.filter = {             
            // search options and params
            type: this.app.store.getOption({ section: 'contacts', option: 'type' }),
            // Note: when the store is created the localized texts are not loaded yet: the text in DateOptions(app)[0] will be undefined!
            text: this.app.store.getOption({ section: 'contacts', option: 'text' }),

            items: this.app.store.state.users || [], // (initially it's better to keep it here as 'this.filter' (synchronously) instead of within state (asynchronously updated). 
            // pagination
            page: {
                index: 0,   
                inputText: '1',
                //size: PageSizeOptions[0].id,
                size: this.app.store.getOption({ section: 'page', option: 'size' }),
                //sizeModel: PageSizeOptions[0], // default page size option = 10 items per page           
                items: [], // page items ,
                showPageInput: true,
                showTotalPages: true,
                totalPages: 0        
            }
        };

        this.state = {
            // synchronized with the global store         
            users: this.app.store.state.users,// || [],
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,

            // component with search options (page number, type, name) and the filtered items
            filter: this.filter,

            showContextMenu: false,
            showDeleteButton: false,
            showEditButton: false, // edit here is actually the invite operation 

            //modalInvitations: this.app.invitations,
            //selectedLang: this.app.store.state.options.appSettings.languages[0],
            //invitationTemplate: this.preparedListOfLanguages[this.app.store.state.options.appSettings.languages[0].id],
            filter: this.filter
        };
    }

    componentDidMount() {

        // for browser refresh:
        if (this.props.addUsersToPermissionsMode) {
            if (!this.app.store.state.permissionCopy) {
                // this component won't work after browser refresh: if those object are not defined: abort and return to home screen                
                goToHomePage();
                return;
            }
        }

        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'items' state (or other):
        this.app.locator.contactsPage = this;
        this.app.store.subscribe({ property: 'users', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });

        unselectItems(window.app.store.state.users); // reset the selections

        if(this.app.store.state.users) {    
        
            this.filterItems();
            this.fillPage();
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.users !== this.state.users) {
            this.filterItems();
            this.fillPage();
        }
    }

    componentWillUnmount() {

        window.app.locator.contactsPage = null;
        this.app.store.unsubscribe({ property: 'users', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
        unselectItems(window.app.store.state.users);
    }


    filterItems = () => {

        const filter = this.filter;

        filter.items = this.app.store.state.users;
        if (!filter.items) return;

        if (this.props.addUsersToPermissionsMode) {
            // exclude the users that are already on the selected users list
            if (this.app.store.state.permissionUsers && this.app.store.state.permissionUsers.length) {
                // pass 1. mark the selected users as excluded:
                this.app.store.state.permissionUsers.map(user => user.excluded = true);
                // pass 2. keep only the users that are not excluded:
                filter.items = filter.items.filter(i => !i.excluded);
            }
        }
        // step 1 (quicker): policy type filter (my policies/shared with me)
        //filter.items = filter.items
        //    .filter(user => { return(this.filterUserType(user, filter.userType.id)) });
        //    //console.log('filter.items after filtering user type: ');
        //    //console.log(filter.items);

        // step 2 (slower): text filter

        //if (filter.text !== '') {
        //    try {
        //        filter.items = filter.items
        //            .filter(item => `${item.U}${item.F}`.toLocaleLowerCase()
        //                .search(filter.text.toLocaleLowerCase()) > -1)
        //    }
        //    catch {
        //        // wrong search string: mark the input field in red?
        //    }     
        //}

        if (filter.text !== '') {
            try {
                filter.items = filter.items
                    .filter(item => ('' + item.U + item.F).toLocaleLowerCase()
                        .search(filter.text.toLocaleLowerCase()) > -1)
            }
            catch {
                // wrong search string: mark the input field in red?
            }
        }
        if (!filter.items) { filter.items = [] }; // for security, to avoid processing and rendering a null list later
        this.setState({ filter: this.filter });
    };

    fillPage = () => {

        const { items, page } = this.filter;
        if (!items) return;
        page.items = items
            .slice(
                (page.index * page.size),
                (page.index * page.size) + page.size,
            );
        this.setState({ filter: this.filter });
    };

    changePage = (model) => {

        const filter = this.filter;
        const { page } = filter;
        if (model) { page.index = model.index; }
        page.inputText = page.index + 1; // set the text for the input control 
        unselectItems(this.state.policies); // we don't keep selected items from other pages for this component        
        this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter
        this.fillPage();
    };    

    onSelectUserType = (type) => {

        const filter = this.filter;
        if (type !== filter.type) {
            // set the new filter value
            filter.type = type;
            // set the new configuration value
            this.app.store.setOption({ section: 'contacts', option: 'type', value: type });
            
            this.filterItems(); // apply the new filter to the items collection
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }
    }

    onTextFilterChange = (value) => {

        const filter = this.filter;
        if (filter.text != value) {
            filter.text = value; // set the new filter value
            this.app.store.setOption({ section: 'contacts', option: 'text', value: value }); // set the new configuration value

            this.filterItems(); // apply the new filter to the items collection            
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }        
    };
    
    filterUserType = (user, type) => {

        if (!user) return false;

        if (type == 1 && user.O == this.app.user.O) {
            return true;
        }
        if (type == 2 && user.O != this.app.user.O) {                    
            return true
        }           
        return false;
    }

    renderUsers = (app, users) => {
        //console.log('Contacts: render users: ' + users);
        //if (users) console.log('users count: ' + users.length);
        if (!users) return;

        return (

            <ItemList>
                <Item className='head disabled'>
                    <ItemColumn className='xs-col-12 xs-border-bottom xs-no-border-right s-col-6'>{this.app.R.Name}</ItemColumn>
                    <ItemColumn className='xs-col-12 s-col-6'>{app.R.Email}</ItemColumn>
                </Item>

                {users.length
                    ? users.map((user, i) => {
                        
                        return (
                            <Item
                                key={`user ${i}`}
                                model={user}
                                onClick={(e) => clickUserFromContactList(e, null, this)}
                            >
                                <ItemColumn className='xs-col-12 xs-border-bottom xs-no-border-right s-col-6'>{getFullNameWithGuestLabel(app, user)}</ItemColumn>
                                <ItemColumn className='xs-col-12 s-col-6'>{user.U}</ItemColumn>
                            </Item>
                        )
                    })
                    : (<Item className='disabled'>
                        <ItemColumn className='col-12 text-center'>{app.R.NoItems}</ItemColumn>
                    </Item>)}

            </ItemList>
        )
    }

    renderBackButton = () => {        
        let callback = this.onReturnBack;
        if (this.props.addUsersToPermissionsMode)
            callback = this.onOk;
        return (
            <SmallButton
                contentClass='icon-back'
                onClick={callback}
            />
        );
    }

    renderOkButton = (showLabel) => {
        let label = showLabel ?
            <h3 className=' label '>{this.app.R.Ok}</h3>
            :
            <h3 className=' label inline-hide-for-small'>{this.app.R.Ok}</h3>

        return (
            <div className='align-right inline clickable' onClick={(e) => { this.onOk(e, this) }}>
                {label}
                <SmallButton contentClass='icon-accept' />
            </div >
        );
    }

    // for cancelling changes (not used now)
    onReturnBack = (e) => {
        if (e) e.stopPropagation();
        //if (this.props.invitationMode) {
        //    // we do nothing, users will be unselected on 'componentWillUnmount'
        //}
        //else if (this.props.addUsersToPermissionsMode) {
        //    // in this mode we collect the selected users and pass them to the permission we are editing
        //    addSelectedUsersToList();
        //    // now unselect them before going back to permission editor
        //    unselectItems(window.app.store.state.users);
        //}
        onReturnBack();
    }
    // for applying changes (current mode: back button only that applies changes)
    onOk = (e) => {

        if (e) e.stopPropagation();
        this.addSelectedUsersToList();        
        onReturnBack();
    }

    onGoToInvitationsPage = (e) => {

        if (e) e.stopPropagation();
        this.addSelectedUsersToList();
        this.setState({ showContextMenu: false });
        goToInvitationsPage();
    }
    addSelectedUsersToList() {

        const { state } = this.app.store;
        if (this.props.invitationMode) {
            // add selected users to the invitations list before going to the invitation page
            addSelectedUsersToInvitiationsList(state);
                     
        }
        else if (this.props.addUsersToPermissionsMode) {
            // in this mode we add selected users and pass them to the permission we are editing
            addSelectedUsersToPermissionsList(state);
        }
        // now unselect the users before moving out of this page
        unselectItems(state.users);  
    }

    // When rendered from context menu the policy will be null, 
    // in which case it will be assigned later in policy manager
    renderDeleteButton = (user) => {

        if (this.state.showDeleteButton) {
            return (
                <div className='context-menu-button delete'>
                    <SmallButton contentClass={'context icon-delete'} />
                </div>
            );
        }
        else {// render it, but disabled
            return (
                <div className='context-menu-button delete'>
                    <SmallButton className='disabled' contentClass={'context icon-delete'} />
                </div>
            );
        }        
    }

    renderEditButton = (user) => {

        if (this.state.showEditButton) {
            return (
                <div className='context-menu-button edit'>
                    <SmallButton contentClass={'context icon-invite-negative'} onClick={(e) => { this.onGoToInvitationsPage(e) }} />
                </div>
            );
        }
        else {// render it, but disabled
            return (
                <div className='context-menu-button edit'>
                    <SmallButton className='disabled' contentClass={'context icon-invite-negative'} />
                </div>
            );
        }          
    }

    render() {

        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)
            return null;

        const app = this.app;
        const filter = this.filter;
        const { page } = filter;
        //console.log('Contacts.render. filter.text: ', filter.text);
        updateAppHistory(app, this);


        let contextMenu = '';
        if (this.state.showContextMenu) {
            var divStyle = {
                display: 'block',
                position: 'absolute',
                left: this.app.clientX,
                top: this.app.clientY
            };
            //contextMenu = <div style={divStyle}><StaggeredMenu2  /></div>;
            contextMenu = <div className='over' style={divStyle}>
                {this.renderDeleteButton(null)}
                {this.renderEditButton(null)}
            </div>;
        }

        const canSendInvitationsBool = canSendInvitations(app);
        return (
            <Fragment>
                <div>
                    {this.props.invitationMode ? (
                        <Fragment>                            
                            <div className="form-groupX">  
                                <div>
                                    {this.renderBackButton()}
                                    <span className='label extra-space'></span>
                                    <h3 className='label'>{this.app.R.Contacts}</h3>
                                </div>                                
                            </div>
                            {
                                canSendInvitationsBool
                                &&
                                <div className='filters'>
                                    <div className='label-and-button label clickable' onClick={this.onGoToInvitationsPage}>
                                        <h4 className='label'>{this.app.R.SendInvitation}</h4>
                                        <SmallButton contentClass='icon-invite' />                                        
                                    </div>
                                    <SearchFilter
                                        app={app}
                                        showSearchIcon
                                        placeholder={app.R.Search}
                                        value={filter.text}
                                        minCharacters={3}
                                        onChange={this.onTextFilterChange}
                                    />
                                </div>
                            }
                            {
                                !canSendInvitationsBool
                                &&
                                <div className='filters'>                                    
                                    <SearchFilter
                                        app={app}
                                        showSearchIcon     
                                        placeholder={app.R.Search}
                                        value={filter.text}
                                        minCharacters={3}
                                        onChange={this.onTextFilterChange}
                                    />
                                </div>
                            }
                        </Fragment>
                    ) : null}
                    {this.props.addUsersToPermissionsMode ? (
                        <Fragment>
                            <div>
                                {this.renderBackButton()}
                                <span className='label extra-space'></span>
                                <h3 className='label'>{this.app.R.SelectUsers}</h3>                            
                                <br />
                                <span className='long-tip'>{this.app.R.AddUsersInfo}</span>
                            </div>
                            <div className='filters'>
                                <SearchFilter
                                    app={app}
                                    showSearchIcon
                                    placeholder={app.R.Search}  
                                    value={filter.text}      
                                    minCharacters={3}
                                    onChange={this.onTextFilterChange}
                                />
                            </div>
                        </Fragment>
                    ) : null}
                </div>
                
                {this.renderUsers(app, page.items)}

                <Paginator2
                    filter={this.filter}
                    onChange={this.changePage}
                />
          
                <br className='clear-left' /><br /><br />

                {
                    //this.props.addUsersToPermissionsMode && this.renderOkButton()
                }
                {contextMenu}
            </Fragment>
        );    
    }
}
