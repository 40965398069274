import React, { Component } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

import { goToHomePage, updateAppHistory } from '../../managers/appManager';
import {
    isDocumentOwner,
    loadDocumentInfo,
    loadDocumentWarnings,
    lockDocument
} from '../../managers/documentManager';
import {
    canLockDocument,
    canAddUsers,
    canEditPolicy,
    createNewPermission,
    policyClickedInDocumentInfo,
    restorePolicy
} from '../../managers/policyManager';

import {
    prepareFileTaskForLaunch,
    pushFileTask
} from '../../managers/fileManager';

import { SmallButton, Loader, ItemHead, ItemList } from '../../components/atoms';
import { CheckboxList, SelectList, TableGrid } from '../../components/organisms';
import {
    formatDateString, template, formatPermissions,
    getUserPermission,
} from "../../common/format";

import {
    AccessPermissionModels,
    AccessPermissionType,
    DateOptions,
    RequestType,
    WarningOptions,
} from "../../common/constants";
import {
    EditPolicySection,
    GeneralSection,
    LockSection,
    PermissionsSection,
    RestorePolicySection,
    UnprotectSection,
    TrackingSection,
    WarningsSection,
} from "./components";


export class DocumentInfo extends Component {

    constructor(props) {

        super(props);

        this.app = window.app;

        this.optionsSection = this.props.inSelectedDocumentMode ? 'document' : 'documents';

        this.state = {
            // synchronized with the global store
            customPolicyGuid: this.app.store.state.customPolicyGuid,
            documentInfo: this.app.store.state.documentInfo, 
            documentInfoPermissions: this.app.store.state.documentInfoPermissions, 
            documentInfoPolicy: this.app.store.state.documentInfoPolicy,
            documentTracking: this.app.store.state.documentTracking,
            documentWarning: this.app.store.state.documentWarning, // this one is an extra waring info from the last page (doc. list), we won't synch with that one
            documentWarnings: this.app.store.state.documentWarnings,
            policies: this.app.store.state.policies,
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,            
            users: this.app.store.state.users,
            groups: this.app.store.state.groups,

            showRestorePolicy: false,
            showRestorePolicyError: false
        }
    }

    componentDidMount() {

        //console.log('componentDidMount');
        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'items' state (or other):
        window.app.locator.documentInfoPage = this;
        this.app.store.subscribe({ property: 'customPolicyGuid', component: this });
        this.app.store.subscribe({ property: 'documentInfo', component: this });
        this.app.store.subscribe({ property: 'documentInfoPermissions', component: this });
        this.app.store.subscribe({ property: 'documentInfoPolicy', component: this });
        this.app.store.subscribe({ property: 'documentTracking', component: this });
        this.app.store.subscribe({ property: 'documentWarnings', component: this });
        this.app.store.subscribe({ property: 'policies', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });
        this.app.store.subscribe({ property: 'users', component: this });
        this.app.store.subscribe({ property: 'groups', component: this });

        ////const { history } = this.props;
        ////if (this.app.state.authenticated) history.push(routes.home());
        //const { match: { params } } = this.props;
        //const docId = params.id;
        //const docGuid = this.app.store.state.documentGuid;
        //if (!this.app.store.state.documentInfo) {
        //    //console.log('docId: ' + docId + ', docGuid: ' + docGuid)
        //    if (docId == 0 && !docGuid) {
        //        //console.log('return to home screen...')
        //        // most likely we refreshed browser: return to Home screen
        //        goToHomePage();
        //        return; 
        //    }
        //    loadDocumentInfo(this.app, null, { DocumentId: docId, DocumentGuid: docGuid }); // the loading endpoint only needs DocumentId or DocumentGuid
        //}
    }
    
    //===============================================================
    componentWillUnmount() {

        window.app.locator.documentInfoPage = null;
        this.app.store.unsubscribe({ property: 'customPolicyGuid', component: this });
        this.app.store.unsubscribe({ property: 'documentInfo', component: this });
        this.app.store.unsubscribe({ property: 'documentInfoPermissions', component: this });
        this.app.store.unsubscribe({ property: 'documentInfoPolicy', component: this });
        this.app.store.unsubscribe({ property: 'documentTracking', component: this });
        this.app.store.unsubscribe({ property: 'documentWarnings', component: this });
        this.app.store.unsubscribe({ property: 'policies', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
        this.app.store.unsubscribe({ property: 'users', component: this });
        this.app.store.unsubscribe({ property: 'groups', component: this });
    }
    //===============================================================

    // RESTORE POLICY
    closeRestorePolicy = () => {
        this.setState({
            showRestorePolicy: false
        });
    }

    showRestorePolicy = () => {
        this.setState({
            showRestorePolicy: true
        });
    }

    closeRestorePolicyError = () => {
        this.setState({
            showRestorePolicyError: false
        });
    }

    showRestorePolicyError = () => {
        this.setState({
            showRestorePolicyError: true,
            showRestorePolicy: false // close the first dialog before opening the error dialog!
        });
    }

    onRestorePolicyError = () => {
        //console.log('component.onRestorePolicyError')
        this.showRestorePolicyError();
    }

    onRestorePolicyCompleted = () => {
        //console.log('component.onRestorePolicyCompleted')
        this.closeRestorePolicy();
    }

    renderRestorePolicy = () => {

        if (!this.state.showRestorePolicy) return;

        let app = window.app;
        let info = app.R.RestorePolicyInfo;
        const policy = this.state.documentInfo.Policy;
        if (policy) {
            // replace the placeholder
            let replacements = {
                '{n}': policy.Name
            };
            info = template(info, replacements);
        }

        return (
            <Modal show={true} onHide={this.closeRestorePolicy}>
                <Modal.Header closeButton>
                    <Modal.Title>{app.R.RestorePolicy}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{info}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeRestorePolicy}>
                        {app.R.Cancel}
                    </Button>
                    <Button variant="primary" onClick={(e) => restorePolicy(e, this, policy, this.state.documentInfo)}>
                        {app.R.RestorePolicy}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderRestorePolicyError = () => {

        if (!this.state.showRestorePolicyError) return;

        let app = window.app;
        let info = app.R.RestorePolicyError;
        const policy = this.state.documentInfo.Policy;        
        if (policy) {
            // replace the placeholder
            let replacements = {
                '{n}': policy.Name
            };
            info = template(info, replacements);
        }

        return (
            <Modal show={true} onHide={this.closeRestorePolicyError}>
                <Modal.Header closeButton>
                    <Modal.Title>{app.R.RestorePolicy}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{info}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeRestorePolicyError}>
                        {app.R.Close}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    returnBack = (e) => {
        if (e) e.stopPropagation();

        if (this.app.history) { this.app.history.goBack() }
    }

    //changeDateFilter = (value) => {
    //    const { match: { params } } = this.props;

    //    loadDocumentWarnings(this.app, { DocumentId: params.id }, value)
    //}

    //changeTypeFilter = (value) => {
    //    const { match: { params } } = this.props;

    //    loadDocumentWarnings(this.app, { DocumentId: params.id }, value)
    //}

    onDocumentLocked = () => {
        //console.log('onDocumentLocked');
        lockDocument(this.app, this.state.documentInfo.DocumentDetails);
    }

    onDocumentUnprotected = () => {
        
        const task = this.getTask();
        //console.log('onDocumentUnprotected', task);
        if (this.hasFile(task)) {
            // reset the task and launch it again            
            //task.type = RequestType.Unprotect;
            //task.tool = null; // reset the old 'doc info' tool reference
            //onFileDrop(this.app, task);
            //this.resetTask();

            // rather create a new task so the original one is kept and can be accessed/unprotected again
            const newTask = {
                file: task.file,
                type: RequestType.Unprotect
            }
            prepareFileTaskForLaunch(this.app, newTask);
            pushFileTask(newTask);
            
            //this.returnBack();
        }
        
        //lockDocument(this.app, documentDetails);
    }

    getTask = () => {
        return this.app.store.state.documentInfoTask;
    }

    resetTask = () => {        
        this.app.store.state.documentInfoTask = null;
        this.setState({ documentInfoTask: null });
    }

    hasFile = (task) => {
        const result = (task && task.file) ? true : false;
        return result;
    }

    checkIfCanEditPolicy = (permission) => {
        return permission !== null && permission !== undefined &&
            permission.CanEditPolicy !== null && permission.CanEditPolicy === true;
    }

    //onEditPolicy = (e) => {
    //    if (e) e.stopPropagation();
    //    console.log('onEditPolicy');
    //    const documentDetails = this.state.documentInfo.DocumentDetails;
    //    //documentManager.lockDocument(this.app, documentDetails);
    //    policyClickedInDocumentInfo(e, this, policy, policyRemoved)
    //}

    render() {
        //console.log('DocumentInfo.render')
        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)
            return null;

        const app = this.app;

        updateAppHistory(app, this);

        //if (this.state.documentInfo && !this.state.documentInfo.DocumentDetails) {
        //    // doc info is missing!
        //    console.log('DocumentInfo.render: 1: doc info is missing!')
        //    return (
        //        <div>
        //            <div>
        //                <SmallButton contentClass='icon-back' onClick={this.returnBack} />
        //                <span className='label extra-space'></span>
        //                <h3 className='label'>{`${this.app.R.DocumentInformation}`}</h3>
        //            </div>
        //            <h4>{this.app.R.DocumentInformationNotFound}</h4>
        //        </div>
        //    );
        //}
        //else if (!this.state.customPolicyGuid ||
        //    !this.state.documentInfo ||
        //    //!this.state.documentTracking ||
        //    //!this.state.documentWarnings ||
        //    !this.state.policies ||
        //    !this.state.users ||
        //    !this.state.groups ||
        //    !this.state.documentInfoPermissions ||
        //    !this.state.documentInfoPolicy) {
        //    console.log('DocumentInfo.render: 2')
        //    console.log('this.state.customPolicyGuid : ', this.state.customPolicyGuid)
        //    console.log('this.state.documentInfo : ', this.state.documentInfo)
        //    console.log('this.state.policies : ', this.state.policies)
        //    console.log('this.state.users : ', this.state.users)
        //    console.log('this.state.groups : ', this.state.groups)
        //    console.log('this.state.documentInfoPermissions : ', this.state.documentInfoPermissions)
        //    console.log('this.state.documentInfoPolicy : ', this.state.documentInfoPolicy)
        //    return (
        //        <div>
        //            <div>
        //                <SmallButton contentClass='icon-back' onClick={this.returnBack} />
        //                <span className='label extra-space'></span>
        //                <h3 className='label'>{`${this.app.R.DocumentInformation}`}</h3>
        //            </div>
        //            <h4>{this.app.R.Loading}</h4>
        //        </div>
        //    );
        //}
        if ((this.state.documentInfo && !this.state.documentInfo.DocumentDetails) ||
            //!this.state.customPolicyGuid ||
            !this.state.documentInfo //||
            //!this.state.policies ||
            //!this.state.users ||
            //!this.state.groups ||
            //!this.state.documentInfoPolicy ||
            //!this.state.documentInfoPermissions
        ) {
            //console.log('DocumentInfo.render: 2')
            //console.log('this.state.customPolicyGuid : ', this.state.customPolicyGuid)
            //console.log('this.state.documentInfo : ', this.state.documentInfo)
            //console.log('this.state.policies : ', this.state.policies)
            //console.log('this.state.users : ', this.state.users)
            //console.log('this.state.groups : ', this.state.groups)
            //console.log('this.state.documentInfoPermissions : ', this.state.documentInfoPermissions)
            //console.log('this.state.documentInfoPolicy : ', this.state.documentInfoPolicy)
            return null;
        }

        //console.log('DocumentInfo.render: 3');
        /*
        { DocumentDetails: { DocId: 10, DocName: "claves.txt", FileTypeId: 16, OwnerId: 2, Owner: "user1", � }, � }
        DocumentDetails: { DocId: 10, DocName: "claves.txt", FileTypeId: 16, OwnerId: 2, Owner: "user1", � }
        AccessDetails: { Date: "2020-04-16T12:20:53.583", AccessedBy: "user1 <user1@lab.com>" }
        UserPermission: { AccessPermissions: 1, IsVisible: false, IsGuest: false, UserIdFather: null, IsAuthor: false, � }
        Policy: { RightsPolicyId: 5, Guid: "889fba71-5f70-4950-a25b-e7b0dad422fd", Users: [, �], � }
        IsUserBlocked: false
        IsDocumentOwner: true
        IsPolicyAuthor: true
        IsPolicyNonPersistent: false
        IsPolicyDeleted: false
        PolicyName: "For User 2"
        */
        const { documentInfo } = this.state;
        const documentDetails = documentInfo.DocumentDetails;
        let accessDetails = documentInfo.AccessDetails;

        const policy = documentInfo.Policy ? documentInfo.Policy : {};
        //-------------------------------------------------------------------------------------
        // PERMISSIONS
        const permission = documentInfo.UserPermission; // the access permissions model loaded from server
        //
        const isUserBlocked = documentInfo.IsUserBlocked;
        // the document owner is the user that protected the document: he is registered in document details
        const isDocumentOwner = (documentInfo.IsDocumentOwner) && (!isUserBlocked); //isDocumentOwner(app, documentDetails);

        // the policy owner is the user who created the policy (permission.IsAuthor = true)
        const isPolicyOwner = permission.IsAuthor;

        // to have full control in a policy: 
        // 1. user can be given a full control permission in policy, 
        // 2. he can be the policy author,
        // 3. or the owner of the custom document protection (the user that protected 
        // this document with the custom protection authored ad-hoc by himself)
        // --> the last one is sorted in 'onUserPermisssionsLoaded' function
        const hasFullControl = ((permission.AccessPermissions & AccessPermissionType.FullControl) === AccessPermissionType.FullControl) && (!isUserBlocked);

        const hasPermissions = (permission.AccessPermissions > 0) && (!isUserBlocked) ? true : false;

        // TODO: check the Desktop logic for 'can lock'!
        const canLockDocument = isDocumentOwner || isPolicyOwner || (permission.IsVisible && hasFullControl);

        const isLocked = documentDetails.IsLocked;
        //
        const hasFile = this.hasFile(this.getTask());//(app.store.state.documentInfoTask && app.store.state.documentInfoTask.file) ? true : false;
        //
        const canUnprotectDocument = (isDocumentOwner || isPolicyOwner || hasFullControl) && hasFile && !isLocked; 
        console.log('canUnprotectDocument: ', canUnprotectDocument);

        // CAN ADD USERS: being document owner is not valid here: the owner is the one that
        // protected the document, but user could be owner e.g. if policy with only VIEW/EDIT rights
        // was shared with him, so he could protect files, but not edit the shared policy:
        //const canAddUsers = ((permission.AccessPermissions & AccessPermissionType.AddUsers) == AccessPermissionType.AddUsers) && (!isLocked) && (!isUserBlocked);
        //const canAddUsers = checkIfAddUsersIsSetInPermission(permission); // --> new helper method
        const canAddUsers = false;

        // CAN EDIT POLICY: being document owner is not valid here: the owner is the one that
        // protected the document, but user could be owner e.g. if policy with only VIEW/EDIT rights
        // was shared with him, so he could protect files, but not edit the shared policy.
        // So we need to have a full control IN THE POLICY (or be the policy or the custom
        // protection owner, which translates to having a full control permission)
        //
        //const canEditPolicy = hasFullControl && !documentInfo.IsPolicyNonPersistent;
        const canEditPolicy = (isPolicyOwner || this.checkIfCanEditPolicy(permission)) && !documentInfo.IsPolicyNonPersistent; // allow to edit a policy even if the document is locked: policy and document are independent
        console.log('isPolicyOwner: ', canEditPolicy);
        console.log('this.checkIfCanEditPolicy(permission): ', this.checkIfCanEditPolicy(permission));
        console.log('(permission): ', permission);
        console.log('documentInfo.IsPolicyNonPersistent: ', documentInfo.IsPolicyNonPersistent);
        console.log('canEditPolicy: ', canEditPolicy);

        const isPolicyDeleted = documentInfo.IsPolicyDeleted;
        //
        //const canRestorePolicy = isPolicyDeleted && hasFullControl;
        const canRestorePolicy = isPolicyDeleted && isPolicyOwner;
       
        // do not render the policy details (expiration, oofline, watermark) when there is no details
        // (which applies both for removed policies and for non-persistent custom protections), or when user has no rights
        const canSeePolicyDetails = ((!isPolicyDeleted && !documentInfo.IsPolicyNonPersistent) && hasPermissions) ? true : false;

        // do not render the access details if user is not the owner or has no full control
        if (!isDocumentOwner && !hasFullControl) accessDetails = null;

        //console.log('render: canEditPolicy: ' + canEditPolicy);
        //console.log('render: canAddUsers: ' + canAddUsers);

        //const allPermissionFlags = getAvailableUserPermissions(this.app.user.UserName, this.app.store.state.policies);
        //const formattedPermissions = formatPermissions(allPermissionFlags);
        let formattedPermissions = [];
        let allowOfflineText = '';

        const noPermissionsText = hasPermissions ?
            null
            :
            documentInfo.IsPolicyNonPersistent ?
                app.R.OperationNotSupported
                :
                app.R.NoPermissionsToViewDocInfo;

        if (hasPermissions) {
            // we need to convert the number (representing the permission flags)
            // to a list of models, and then we will select/unselect the corresponding models. 
            formattedPermissions = formatPermissions(permission.AccessPermissions, permission);
            formattedPermissions.map(p => (p.transparent = false));
            //console.log('render document info: formattedPermissions: ', formattedPermissions);
        }
        
        //-------------------------------------------------------------------------------------
        // MODEL TO BE RENDERED BY CHILD COMPONENTS
        const currDocumentInfo = {
            general: {
                details: [
                    { title: this.app.R.ProtectionDate, value: formatDateString(documentDetails.ProtectionDate) },
                    { title: this.app.R.ProtectedBy, value: documentDetails.Owner }, // TODO: get a fullname + username if the owner is in our contacts?
                ],
                access: [
                    { title: this.app.R.AccessedBy, value: accessDetails && accessDetails.AccessedBy },
                    { title: this.app.R.Date, value: accessDetails && formatDateString(accessDetails.Date) },
                ]
            },
            //permissions: formattedPermissions.map(p => ({...p, selected: true})),
            permissions: formattedPermissions,
            tracking: {
                list: null // null will be used as a marker to show 'loading...' text, after loading is finished it will be []
            },
            warnings: {
                list: null // null will be used as a marker to show 'loading...' text, after loading is finished it will be []
            },
        };

        //-------------------------------------------------------------------------------------
        // POLICY

        // format the policy name
        let policyName = documentInfo.Policy ? documentInfo.Policy.Name : documentInfo.PolicyName;
        //
        if (documentInfo.IsPolicyNonPersistent) {
            policyName = this.app.R.CustomProtection;
        } 
        if (isPolicyDeleted) {
            policyName = policyName + ' (' + this.app.R.DeletedPolicy + ')';
        }          

        // push the policy name to the UI list
        if (policy && (canEditPolicy || canRestorePolicy))// TO DO: add support for ADD USERS right to 'policy edit' || canAddUsers)            
            currDocumentInfo.general.details.push({ title: this.app.R.Protection, value: policyName, className: 'text-highlight', onClick: (e) => policyClickedInDocumentInfo(e, app, this, documentInfo) });
        else
            currDocumentInfo.general.details.push({ title: this.app.R.Protection, value: policyName, className: '', onClick: null });

        // push additional policy details to the UI list
        if (policy && canSeePolicyDetails) {
            let replacements = { '{0}': policy.OfflineAccessDays };
            let offlineDaysFormatted = this.app.R.Yes + '. ' + template(this.app.R.OfflineAccessAllowed, replacements);
            allowOfflineText = policy.IsOfflineAccessActive ? offlineDaysFormatted : this.app.R.No;            
                
            currDocumentInfo.general.details.push({ title: this.app.R.ExpirationDate, value: policy.IsContentExpirationActive ? formatDateString(policy.ContentExpirationEndDate, true) : this.app.R.NotDefined });
            currDocumentInfo.general.details.push({ title: this.app.R.AllowOfflineAccess, value: allowOfflineText });
            currDocumentInfo.general.details.push({ title: this.app.R.WatermarkForPDF, value: policy.Watermark ? this.app.R.Yes : this.app.R.No });
        }        

        //console.log('Document Info. render . currDocumentInfo: ', currDocumentInfo);
        return (
            <div>                
                <PermissionsSection
                    hasPermissions={hasPermissions}
                    noPermissionsText={noPermissionsText}
                    permissions={currDocumentInfo.permissions}
                />
                <br />

                {canUnprotectDocument && <UnprotectSection
                    onClicked={this.onDocumentUnprotected}
                />}

                {canLockDocument && <LockSection
                    IsLocked={documentDetails.IsLocked}
                    onDocumentLocked={this.onDocumentLocked}
                />}

                {(!isPolicyDeleted && (canEditPolicy || canAddUsers)) && <EditPolicySection
                    canAddUsers={canAddUsers}
                    canEditPolicy={canEditPolicy}
                    onEditPolicy={(e) => policyClickedInDocumentInfo(e, app, this, documentInfo)}
                    policy={policy}
                />}

                {(canRestorePolicy) && <RestorePolicySection                    
                    onClicked={(e) => policyClickedInDocumentInfo(e, app, this, documentInfo)}
                />}

                <GeneralSection
                    accessDetails={accessDetails}
                    documentName={documentDetails.DocName} /* TO DO: use the current file name, not the DB name !!! */
                    general={currDocumentInfo.general}
                />

                {this.renderRestorePolicy()}
                {this.renderRestorePolicyError()}
            </div>
        )
    }
}
