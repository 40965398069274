import React, { Component } from 'react';
//import Dropzone from 'react-dropzone'; //https://react-dropzone.js.org/
//import { Glyphicon } from 'react-bootstrap';
import {
    onGoogleProviderClicked,
    getGoogleDriveFolderItems, // for development only
    createExternalFileForGoogleDriveDocument // for development only
} from '../managers/storage/googleDrive';

import {
    canCreateProtections,
    isProtector,
    updateAppHistory
} from '../managers/appManager';

import {
    getLocalDocumentInfo,
    onCancelFileSelection,
    onFilesDrop,
    onProviderClicked
} from '../managers/fileManager'; // TO DO: import only used functions here 

import {    
    canEditPolicy,
    editPolicy,
    getPolicyNameAndUserNamesForSearch,
    getPolicyUserNamesForSearch,
    filterPolicyType,    
    isSimplePolicyExpired,
    sortPolicies,
    toggleFavourite
} from '../managers/policyManager';
import * as taskManager from '../managers/taskManager';
import { refresh } from '../managers/userManager';
//import { Login } from './Login';
import { SmallButton, StorageView, LoadingIcon } from '../components/atoms';
import Dropzone from 'react-dropzone'
import {
    SelectList, Paginator2,
    SearchFilter, RadioButtonsList,
} from "../components/organisms";
import { ExternalStorageConstants, ProtectionType, RequestType } from '../common/constants';
import { routes } from '../common/routes';
import { PageSizeOptions, HomePolicyTypeModels, UserRole } from '../common/constants';
import GooglePicker from '../components/GooglePicker';


export class Home extends Component {

    displayName = Home.name

    constructor(props) {

        super(props);
        
        this.app = window.app;
        //console.log('Home: constructor. store.state.policies:', this.app.store.state.policies);
        this.filter = {
            // search options and params
            policyType: this.app.store.getOption({ section: 'homeProtections', option: 'type' }),
            //policySubType: selectedPolicyFilter || policySubTypes(this.app)[0], (like ALL, MOST USED, UNUSED)
            text: this.app.store.getOption({ section: 'homeProtections', option: 'text' }),

            items: this.app.store.state.policies || [], // (initially it's better to keep it here in 'this.filter' (synchronously) instead of within state (asynchronously updated). 

            // pagination
            page: {
                //index: 0,
                //inputText: '1',
                //size: PageSizeOptions[0].id,
                //sizeModel: PageSizeOptions[0], // default page size option = 10 items per page
                items: [], // page items ,
                //showPageInput: true,
                //showTotalPages: true,
                //totalPages: 0
            }
        };

        //this.filter2 = {
        //    // search options and params
        //    policyType: this.app.store.getOption({ section: 'protections', option: 'type' }),
        //    //policySubType: selectedPolicyFilter || policySubTypes(this.app)[0], (like ALL, MOST USED, UNUSED)
        //    text: this.app.store.getOption({ section: 'protections', option: 'text' }),
        //    items: this.app.store.state.policies || [], // (initially it's better to keep it here as 'this.filter' (synchronously) instead of within state (asynchronously updated). 
        //    // pagination
        //    page: {
        //        index: 0,
        //        inputText: '1',
        //        size: PageSizeOptions[0].id,
        //        sizeModel: PageSizeOptions[0], // default page size option = 10 items per page           
        //        items: [], // page items,
        //        showPageInput: true,
        //        showTotalPages: true,
        //        totalPages: 0
        //    }
        //};

        this.state = {
            // synchronized with the global store
            //selectedDocuments: this.app.store.state.selectedDocuments,
            policies: this.app.store.state.policies,
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,

            // local state properties
            //selectedDocuments: null,
            //shouldRenderDocs: false,
            // component with search options (page number, type, name) and the filtered items
            filter: this.filter
        };       
    }

    componentDidMount() {

        //console.log('Home: componentWillMount');
        window.app.locator.homePage = this;

        //this.app.store.subscribe({ property: 'selectedDocuments', component: this });
        this.app.store.subscribe({ property: 'groups', component: this });
        this.app.store.subscribe({ property: 'policies', component: this });                
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });
        this.app.store.subscribe({ property: 'users', component: this });

        if (this.app.store.state.policies) {
            this.filterItems();
            this.fillPage();
        }
        //-----------------------------------------------------------------------------------
        //// DEV HOOK
        //let folderId = '1FxcXaxmhEqbaJPc0XESiFKfL4KhVqF9c';

        //// option 1: shortcut to the google folder method
        ////getGoogleDriveFolderItems(this.app, task);

        //// option 2: fake a real flow
        //// prepare the 'tool' object to be used with this operation
        //const tool = {
        //    action: 'ProtectExternal', policy: {} };

        //let doc = {
        //    name: 'super fake name 2020.docx',
        //    id: folderId,
        //    mimeType: ExternalStorageConstants.GOOGLE_DRIVE_FOLDER
        //};
        //let provider = {
        //    id: 'googledrive'
        //};

        //// Prepare the model used in client-server communication.
        //// This model will be deserialized on server using the ExternalFile class.                
        //let externalFile = createExternalFileForGoogleDriveDocument(this.app, doc); // defined in googleDrive.js file
        
        //onFilesDrop(this.app,
        //        /* acceptedFiles */[],
        //        /* rejectedFiles */[],
        //        /* tool */ tool,
        //        /* externalFiles*/[externalFile],
        //    provider
        //);
        //-----------------------------------------------------------------------------------

    }

    componentDidUpdate(prevProps, prevState) {
        //console.log('Home: componentDidUpdate. store.state.policies:', this.app.store.state.policies);
        //if (this.filter.items !== this.app.store.state.policies) {    
        if (prevState.policies !== this.state.policies) { 
            //console.log('Home: componentDidUpdate: filter items')
            this.filterItems();
            this.fillPage();
        }
    }

    componentWillUnmount() {

        window.app.locator.homePage = null;
        //this.app.store.unsubscribe({ property: 'selectedDocuments', component: this });
        this.app.store.unsubscribe({ property: 'groups', component: this });
        this.app.store.unsubscribe({ property: 'policies', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
        this.app.store.unsubscribe({ property: 'users', component: this });
    }

    filterItems0 = () => {
        
        const filter = this.filter;        
        filter.items = this.app.store.state.policies;
        if (!filter.items) return;
        //console.log('Home: filterItems. store.state.policies:', this.app.store.state.policies);
        //console.log('Home: filterItems. filter.items:', filter.items);
        //// step 1 (quicker - place it first): policy type filter (my policies/shared with me)
        //filter.items = filter.items
        //    .filter(policy => { return (this.filterPolicyType(this.app, policy, filter.policyType.id)) })

        //// step 2 (slower - place it second): text filter
        //if (filter.text !== '') {
        //    try {
        //        filter.items = filter.items
        //            .filter(policy => `${policy.Name}${this.getPolicyUserNamesForSearch(this.app, policy)}`.toLocaleLowerCase()
        //                .search(filter.text.toLocaleLowerCase()) > -1)
        //    }
        //    catch {
        //        // wrong search string: mark the input field in red?
        //    }
        //}

        // sort the policies: alphabetically and by favourite option (favs go first) 
        sortPolicies(filter.items);
        
        this.setState({ filter: this.filter });
    };

    filterItems = () => {
        //console.log('policies : filter items... store.state.policies: '); console.log(this.app.store.state.policies);
        ////console.log('policies : filter items... filter: '); //console.log(this.filter);
        const filter = this.filter;        
        filter.items = this.app.store.state.policies;
        if (!filter.items) return;

        // step 1 (quicker - place it first): policy type filter (my policies/shared with me)
        filter.items = filter.items
            .filter(policy => { return (filterPolicyType(this.app, policy, filter.policyType)) });
        //console.log('filter.items after filtering policy type: ');
        //console.log(filter.items);

        // step 2 (slower - place it second): text filter
        if (filter.text !== '') {
            try {
                //filter.items = filter.items
                //    .filter(policy => `${policy.Name}${getPolicyUserNamesForSearch(this.app, policy)}`.toLocaleLowerCase()
                //        .search(filter.text.toLocaleLowerCase()) > -1)
                filter.items = filter.items
                    .filter(policy => getPolicyNameAndUserNamesForSearch(this.app, policy).toLocaleLowerCase()
                        .search(filter.text.toLocaleLowerCase()) > -1)
            }
            catch {
                // wrong search string: mark the input field in red?
            }
        }
        // sort the policies: alphabetically and by favourite option (favs go first) 
        sortPolicies(filter.items);

        if (!filter.items) { filter.items = [] }; // for security, to not to process and render a null list later
        this.setState({ filter: this.filter });
    };

    fillPage = () => {

        const { items, page } = this.filter;
        if (!items) return;
        // do not limit the page size for protections in Home component
        page.items = items;
        //page.items = items
        //    .slice(
        //        (page.index * page.size),
        //        (page.index * page.size) + page.size,
        //    );
        this.setState({ filter: this.filter });
    };

    changePage = (model) => {

        //const filter = this.filter;
        //const { page } = filter;
        //if (model) { page.index = model.index; }
        //page.inputText = page.index + 1; // set the text for the input control 
        ////unselectItems(this.state.policies); // we don't keep selected items from other pages for this component        
        ////this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter
        this.fillPage();
    };

    // EVENTS =================================================
    onSelectPolicyType = (model) => {

        const filter = this.filter;
        if (model.id !== filter.policyType) {

            filter.policyType = model.id; // set the new filter value
            this.app.store.setOption({ section: 'homeProtections', option: 'type', value: model.id }); // set the new configuration value

            this.filterItems(); // apply the new filter to the items collection
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }
    }

    onTextFilterChange = (value) => {

        const filter = this.filter;
        if (filter.text != value) {

            filter.text = value; // set the new filter value
            this.app.store.setOption({ section: 'homeProtections', option: 'text', value: value }); // set the new configuration value

            this.filterItems(); // apply the new filter to the items collection            
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }
    };
    // EVENTS : END =================================================

    renderStorageProviders(app) {

        //return;
        let providers = app.providers;
        return (
            providers.map(provider =>
                this.renderStorageProvider(app, provider)
            )
        );
    }

    //googleDrivePickerCallback(app, provider, data, google) {

    //    console.log('on Google Drive change:', data)
    //    var url = 'nothing';
    //    let docs = null;
    //    let shouldRenderDocs = false;
    //    app.storageProvider = provider;
    //    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
            
    //        let docs = data[google.picker.Response.DOCUMENTS];
    //        if (docs && docs.length > 0) {                
    //            docs.map(doc => {
    //                doc.css_visible = true; // add a visibility flag that will be reset on close, when hide transition is completed 
    //                console.log('document ' + doc.name + ': ', doc)
    //                console.log('document url: ', doc[google.picker.Document.URL])
    //            })
    //            app.store.setState({ selectedDocuments: docs }); // manage the global store state?
    //            window.app.history.push(routes.documents.selected());
    //        }            
    //    }       
        
    //}

    //onAuthFailed(app, provider, data) {
    //    console.log('on auth failed:', data)
    //}

    //renderStorageProvider0(app, provider) {

    //    //console.log('renderStorageProvider 1:', provider)
    //    if (provider.enabled !== true) {
    //        return '';
    //    }
    //    const languageCode = app.settings.language.id ? app.settings.language.id : app.store.state.options.appSettings.defaultLanguage; 
    //    //const languageCode = 'fr'; // test
    //    //console.log('renderStorageProvider 2: languageCode: ', languageCode);

    //    if (provider.id === 'googledrive') {
    //        const className = 'button box icon ' + provider.class;
    //        return (                
    //            <GooglePicker clientId={provider.clientId}
    //                developerKey={provider.developerKey}
    //                scope={provider.scope}
    //                onChange={data => this.onGoogleDriveChange(app, provider, data)}
    //                onAuthFailed={data => this.onAuthFailed(app, provider, data)}
    //                multiselect={true}
    //                navHidden={true}
    //                authImmediate={false}
    //                viewId={'FOLDERS'}
    //                createPicker={(google, response) => {
    //                    const googleViewId = google.picker.ViewId.DOCS;//FOLDERS;
    //                    const docsView = new google.picker.DocsView(googleViewId)
    //                        .setIncludeFolders(true)
    //                        .setParent('root') // looks like it does the same as 'setOwnedByMe' below(?): TO DO: check docs
    //                        //.setOwnedByMe(true) 
    //                        // do not set mime-types if need to show all types
    //                        //.setMimeTypes(['image/png', 'image/jpeg', 'image/jpg'])//'application/vnd.google-apps.folder')
    //                        .setSelectFolderEnabled(true);

    //                    if (!app.googlePicker) {

    //                        const picker = new window.google.picker.PickerBuilder()
    //                            .addView(docsView)
    //                            //.setOAuthToken(accessToken)
    //                            .setOAuthToken(response.access_token)
    //                            .setDeveloperKey(provider.developerKey)
    //                            //.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
    //                            .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
    //                            .setLocale(languageCode)
    //                            // Set the preferred dialog size. The dialog is auto-centered. It has a minimum size of (566,350) and a maximum size of (1051,650).
    //                            // https://developers.google.com/picker/docs/reference#Picker
    //                            .setSize(1051, 650) // --> set the maxmium size allowed: it will stretch down in width on small screens, and height will be the 650 (scrollable)
    //                            .setCallback((data) => this.googleDrivePickerCallback(app, provider, data, google)
    //                        );
    //                        app.googlePicker = picker;
    //                    }


    //                    app.googlePicker.build().setVisible(true);
    //                    // store the oAuthToken
    //                    //app.auth.tokens.googleDriveAccessToken = accessToken;                        
    //                    app.auth.tokens.googleDriveAccessToken = response.access_token;
    //                    console.log('GoogleDrive auth response: ', response);
                                                
    //                }}
    //                // visual aspect
    //                key={provider.id}
    //                className={className}
    //            >        
                    
    //            </GooglePicker>
                    
    //        );
    //    }

    //    return (           
    //        <button
    //            key={provider.id}
    //            className={'button box icon ' + provider.class}
    //            onClick={(e) => { fileManager.onProviderClicked(e, app, provider); this.setState({ lastAction: 'provider clicked' }); }} />
    //    );
    //}

    renderStorageProvider(app, provider) {

        //return null;

        //console.log('renderStorageProvider 1:', provider)
        if (provider.enabled !== true) {
            return '';
        }
        const languageCode = app.settings.language.id ? app.settings.language.id : app.store.state.options.appSettings.defaultLanguage;
        //const languageCode = 'fr'; // test
        //console.log('renderStorageProvider 2: languageCode: ', languageCode);

        if (provider.id === 'googledrive') {

            return (
                <button
                    key={provider.id}
                    className={'button box icon ' + provider.class}
                    onClick={(e) => { onGoogleProviderClicked(e, app, provider); this.setState({ lastAction: 'provider clicked' }); }} />
            );

            //const className = 'button box icon ' + provider.class;
            //return (
            //    <GooglePicker clientId={provider.clientId}
            //        developerKey={provider.developerKey}
            //        scope={provider.scope}
            //        onChange={data => this.onGoogleDriveChange(app, provider, data)}
            //        onAuthFailed={data => this.onAuthFailed(app, provider, data)}
            //        multiselect={true}
            //        navHidden={true}
            //        authImmediate={false}
            //        viewId={'FOLDERS'}
            //        createPicker={(google, response) => {
            //            const googleViewId = google.picker.ViewId.DOCS;//FOLDERS;
            //            const docsView = new google.picker.DocsView(googleViewId)
            //                .setIncludeFolders(true)
            //                .setParent('root') // looks like it does the same as 'setOwnedByMe' below(?): TO DO: check docs
            //                //.setOwnedByMe(true) 
            //                // do not set mime-types if need to show all types
            //                //.setMimeTypes(['image/png', 'image/jpeg', 'image/jpg'])//'application/vnd.google-apps.folder')
            //                .setSelectFolderEnabled(true);

            //            if (!app.googlePicker) {

            //                const picker = new window.google.picker.PickerBuilder()
            //                    .addView(docsView)
            //                    //.setOAuthToken(accessToken)
            //                    .setOAuthToken(response.access_token)
            //                    .setDeveloperKey(provider.developerKey)
            //                    //.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
            //                    .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
            //                    .setLocale(languageCode)
            //                    // Set the preferred dialog size. The dialog is auto-centered. It has a minimum size of (566,350) and a maximum size of (1051,650).
            //                    // https://developers.google.com/picker/docs/reference#Picker
            //                    .setSize(1051, 650) // --> set the maxmium size allowed: it will stretch down in width on small screens, and height will be the 650 (scrollable)
            //                    .setCallback((data) => this.googleDrivePickerCallback(app, provider, data, google)
            //                    );
            //                app.googlePicker = picker;
            //            }


            //            app.googlePicker.build().setVisible(true);
            //            // store the oAuthToken
            //            //app.auth.tokens.googleDriveAccessToken = accessToken;                        
            //            app.auth.tokens.googleDriveAccessToken = response.access_token;
            //            console.log('GoogleDrive auth response: ', response);

            //        }}
            //        // visual aspect
            //        key={provider.id}
            //        className={className}
            //    >

            //    </GooglePicker>

            //);
        }

        return (
            <button
                key={provider.id}
                className={'button box icon ' + provider.class}
                onClick={(e) => { onProviderClicked(e, app, provider); this.setState({ lastAction: 'provider clicked' }); }} />
        );
    }

    //renderGooglePicker(app) {
    //    return ( 
    //<div className="container">
    //<GooglePicker clientId={CLIENT_ID}
    //    developerKey={DEVELOPER_KEY}
    //    scope={SCOPE}
    //    onChange={data => console.log('on change:', data)}
    //    onAuthFailed={data => console.log('on auth failed:', data)}
    //    multiselect={true}
    //    navHidden={true}
    //    authImmediate={false}
    //    mimeTypes={['image/png', 'image/jpeg', 'image/jpg']}
    //    viewId={'DOCS'}>
    //    <span>Click me!</span>
    //    <div className="google"></div>
    //</GooglePicker>
    //<br />
    //<hr />
    //<br />
    //<GooglePicker clientId={CLIENT_ID}
    //    developerKey={DEVELOPER_KEY}
    //    scope={SCOPE}
    //    onChange={data => console.log('on change:', data)}
    //    onAuthFailed={data => console.log('on auth failed:', data)}
    //    multiselect={true}
    //    navHidden={true}
    //    authImmediate={false}
    //    viewId={'FOLDERS'}
    //    createPicker={(google, oauthToken) => {
    //        const googleViewId = google.picker.ViewId.FOLDERS;
    //        const docsView = new google.picker.DocsView(googleViewId)
    //            .setIncludeFolders(true)
    //            .setMimeTypes('application/vnd.google-apps.folder')
    //            .setSelectFolderEnabled(true);

    //        const picker = new window.google.picker.PickerBuilder()
    //            .addView(docsView)
    //            .setOAuthToken(oauthToken)
    //            .setDeveloperKey(DEVELOPER_KEY)
    //            .setCallback(() => {
    //                console.log('Custom picker is ready!');
    //            });

    //        picker.build().setVisible(true);
    //    }}
    //>        
    //</GooglePicker>

    //        </div>);
    //}

    renderTools = (app) => {

        let tools = app.store.state.options.appSettings.tools;
                    
        return (
            tools.map((tool, index) => 
                <div key={'tool-' + index}>{this.renderTool(tool)}</div>                    
            )
        );
    }
    
    renderTool = (tool) => {
        
        let app = this.app;

        let toolClass = tool.class;
        tool.name = app.R[tool.label];
        tool.info = '';
        tool.disabled = false;
        if (tool.label == "ToolFavProtection") {

            if (app.superFavPolicy !== null) {
                tool.policy = app.superFavPolicy;
                tool.name = tool.policy.Name;
                //tool.info = app.R.DragFileTip;
            }
            else {
                toolClass += ' disabled';
                tool.disabled = true;
                tool.policy = null;
                tool.name = app.R.ChoseFavProtection;
                tool.info = app.R.ChoseFavProtectionTip;
            }
        }
        
        toolClass = 'button box icon ' + toolClass;
        const toolClassActive = toolClass + ' active';
        
        return (        
            <Dropzone                
                className={toolClass}
                activeClassName={toolClassActive}
                onDrop={(acceptedFiles, rejectedFiles) => onFilesDrop(this.app, acceptedFiles, rejectedFiles, tool)}
                onFileDialogCancel={onCancelFileSelection}
                disabled={tool.disabled}
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps()}
                        className={toolClass}
                    >
                        <input {...getInputProps()} />
                        <div className='name'>{tool.name}</div>
                        <div className='info'>{tool.info}</div>
                    </div>
                )}
            </Dropzone> 
        );
    }

    renderProtectionTools = (app) => {

        if (!isProtector(app)) return null;

        let items = this.filter.page.items;
        if (!items) {
            // this could mean that we haven't loaded the policies yet:
            // should put this component in loading state and then load them
            // ...
            return null;
        }            
        
        return (
            items.map(policy =>
                this.renderProtectionTool(app, policy)
            )
        );        
    }

    renderProtectionTool = (app, policy) => {

        const isExpired = isSimplePolicyExpired(policy);
        const isExpiredClass = isExpired ? ' expired' : ''
        const dropzoneClass = 'button box policy ' + (policy.class ? policy.class : '') + isExpiredClass;
        const dropzoneClassActive = dropzoneClass + ' active';
        const policyName = isExpired ? policy.Name + ' (' + app.R.Expired + ')' : policy.Name;

        const tool = {
            action: RequestType.Protect,
            id: policy.Id, // check if it's necesary to set the id (now the id param is used by UI buttons, keep it like this until it gets changed)
            selected: false,
            class: '',
            policy: policy
        };
        

        return (            
            <Dropzone
                key={'policy-' + policy.Id}
                className={dropzoneClass}
                activeClassName={dropzoneClassActive}
                onDrop={(acceptedFiles, rejectedFiles) => onFilesDrop(this.app, acceptedFiles, rejectedFiles, tool)}
                onFileDialogCancel={onCancelFileSelection}
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps()}
                        className={dropzoneClass}
                    >
                        <input {...getInputProps()} />
                        <div className='name'>{policyName}</div>
                        { this.renderFavButton(app, policy) }
                        { this.renderEditButton(app, policy) }
                    </div>
                )}
            </Dropzone>            
        );
    }

    renderFavButton = (app, policy) => {

        // choose the fav icon class
        let favClass = ' icon-fav-off';
        if (policy.Fav) { favClass = ' icon-fav-on'; }
        
        return (
            <div className='policy-box-button fav'>
                <div className='button small' onClick={(e) => { toggleFavourite(e, policy); this.filterItems(); this.fillPage(); }}>
                    <div className={'fav growing icon' + favClass}></div>
                </div>
            </div>            
        );        
    }

    renderEditButton = (app, policy) => {
        // RULES
        // to edit a policy: user needs to be author, have "full control" or "add users" permission
        //if (!this.app.store.state.options.userConfiguration.CanCreateProtections) return null;
        //if (this.app.user.RolId != UserRole.Protector) return null;
        //var permission = getUserPermission(this.app.user, policy);
        if (canEditPolicy(app.user, policy)) {
            return (
                <div className='policy-box-button edit'>
                    <div className='button small' onClick={(e) => { editPolicy(e, app, /* full policy */ null, /* simple policy */ policy, RequestType.None, /* is new policy */ false) }}>
                        <div className='growing icon icon-edit'></div>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }

    renderStorageViews = (app) => {
        return;
        //let views = [];
        //let index = 0;
        //app.providers.map(provider => {            
        //    provider.views.map(view => {                
        //        view.index = index;
        //        views.push(view);   
        //        index++;
        //    });
        //})        
        //return (
        //    views.map(view =>                
        //        <StorageView key={view.index} view={view} controller={fileManager} />   
        //    )
        //)                
    }

    renderEmptyButtonFill = () => {
        return (
            <div className='button small'>
                <div className='icon empty'></div>
            </div>
        );
    }

    renderAddPolicyButton = (app) => {

        if (!canCreateProtections(app)) return null;
        if (!isProtector(app)) return null;

        // on click we will call 'policyManager.editPolicy', passing a null policy there,
        // so the EditPolicy component will be called with id=0, and it will detect it 
        // to create a new policy model.
        return (
            <SmallButton
                contentClass='icon-add'
                onClick={(e) => { editPolicy(e, app, /* full policy */ null, /* simple policy */ null, RequestType.None, /* is new policy */ true) }}
            />
        );
    }

    goToPolicies = (e) => {

        e.stopPropagation();
        let app = window.app;
        if (app.history) { app.history.push(routes.policies.list());}
    }
    //--------------------------------------------------------------------------------------------

    render() {

        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)
            return null;

        const app = this.app;
        const filter = this.filter;
        
        updateAppHistory(app, this);

        // !!! In React you CAN'T do this:
        //return (
        //  views.map(view => { ...} !!! the {} are not allowed as the immediate return character,
        // only elements like this: return ( <element></element> ) are allowed as return object.
        // The {} can go inside of those <elements>.
        // And you CAN'T return an array of adjacent elements, they need to be wrapped in one element like <div>

        //<StaggeredMenu />
        //<h3>Mouse coordinates: {this.state.x} {this.state.y}</h3>

        //    <h3>Storage</h3>                    
        //            { this.renderStorageProviders(app) }
        //<hr />

        //{ this.renderStorageViews(app) }

        // all these pages follow a common pattern: move control of the loading state
        // to the only component that knows if any data is loading: the task manager!
        //if (!taskManager.isDataTypeLoaded([
        //    appManager.RequestType.UserIsValid,
        //    appManager.RequestType.UserConfiguration,
        //    appManager.RequestType.UserGroups,
        //    appManager.RequestType.UserPolicies,
        //    appManager.RequestType.CustomPolicyGuid
        //])) {            
        //    appManager.setLoadingIcon(true);
        //}
        //else {
        //    appManager.setLoadingIcon(false);
        //}

        //    <div>{app.R.DragFile}</div>

        const renderStorage = false;
        //console.log('rendering Home...');
        return (
            <div>
                <div>                   
                    {
                    //<div className='long-tip-home'>{app.R.DragFileOverPolicy}</div>
                    }
                    {(renderStorage && (
                        <div key='storage-row-1'>
                            <h3>Storage</h3>
                            <div className='button small absolute-top-right'>
                                <div className='button growing policy icon icon-refresh' onClick={(e) => { refresh(e) }} />
                            </div>
                        </div>
                    ))}
                    {(renderStorage && (
                        <div key='storage-row-2'>
                            {this.renderStorageProviders(app)}
                            {this.renderStorageViews(app)}
                        </div>
                    ))}
                    {(renderStorage && (
                        <hr className='clear-left' /> 
                    ) && (
                            <h3>{app.R.Tools}</h3>
                        ))}

                    {isProtector(app) && (
                        <div>
                            <div>
                                <h3 className='label clickable' onClick={this.goToPolicies}>{app.R.Protections}</h3>
                                {this.renderAddPolicyButton(app)}
                            </div>
                            <div className='filters'>
                                {<RadioButtonsList
                                    className='inline'
                                    items={HomePolicyTypeModels(app)}
                                    onChange={this.onSelectPolicyType}
                                    selectedItemId={filter.policyType}
                                />}
                                {
                                    // SearchFilter - minCharacters:
                                    // the JavaScript search filter can work from 2 characters, but we
                                    // set 3 so it behaves the same as the Documents SQL search script
                                }
                                <SearchFilter
                                    app={app}
                                    showSearchIcon
                                    placeholder={app.R.Search}
                                    value={filter.text}
                                    minCharacters={3}
                                    onChange={this.onTextFilterChange}
                                />
                            </div>
                        </div>
                    )}

                    <div className='clear-left'>                        

                        <div key='tools' className='float-left'>                            
                            {
                                //<div>
                                ////app.R.Tools                                
                                //<h3 className='label'>{}</h3>
                                //{this.renderEmptyButtonFill()}
                                //</div>
                            }                        
                            {this.renderTools(app)}
                        </div>
                        
                        <div key='policies'>
                            {
                                //<div>
                                //    <h3 className='label clickable' onClick={this.goToPolicies}>{app.R.Protections}</h3>
                                //    {this.renderAddPolicyButton()}
                                //</div>
                            }
                            {this.renderStorageProviders(app)}
                            {this.renderProtectionTools(app)}
                            
                        </div>
                    </div>                    
                </div>
            </div>
        );
    }
}

//<div >
//    <hr />
//    <p>Welcome to your new single-page application, built with:</p>
//    <ul>
//        <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
//        <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
//        <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
//    </ul>
//    <p>To help you get started, we've also set up:</p>
//    <ul>
//        <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
//        <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
//        <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
//    </ul>
//    <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
//</div>