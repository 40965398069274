import React, { Component } from 'react';
import { Button, Dropdown, Modal, Table } from 'react-bootstrap';

import { sortDocuments } from '../../managers/documentManager';
import {
    canCreateProtections,
    clickPolicy,
    findSelectedItems,
    goToHomePage,
    isProtector,
    onReturnBack,
    unselectItems,
    updateAppHistory
} from '../../managers/appManager';
import { findUserById } from '../../managers/userManager';
import {
    canEditPolicy,
    canDeletePolicy,
    editPolicy,
    filterPolicyType,
    getAuthor,    
    getNamesOfUsersThatHavePolicyShared,
    getPolicyNameAndUserNamesForSearch,
    getPolicyUserNamesForSearch,
    getUserPermissionModels,    
    isAuthorInPolicy,
    isPolicySharedAndNotAuthor,
    isSimplePolicyExpired,
    onRemovePolicyConfirmed,
    removePolicy,
    toggleFavourite
} from '../../managers/policyManager';
import { removeItemFromDisplayList } from '../../managers/appManager';
import {
    Item, ItemColumn, ItemHead, ItemList,
    SmallButton, LoadingIcon
} from '../../components/atoms';
import {
    SelectList, Paginator2,
    SearchFilter, RadioButtonsList,
} from "../../components/organisms";
import { routes } from "../../common/routes";
import { convertPolicyNamesToText, template } from '../../common/format';
import { PageSizeOptions, PolicySubTypeModels, PolicyType, PolicyTypeModels, RequestType, UserRole } from "../../common/constants";


export class SelectedDocuments extends Component {
    constructor(props) {
        super(props);

        this.app = window.app;

        this.filter = {
            // search options and params
            policyType: this.app.store.getOption({ section: 'protections', option: 'type' }),
            //policySubType: selectedPolicyFilter || policySubTypes(this.app)[0], (like ALL, MOST USED, UNUSED)
            text: this.app.store.getOption({ section: 'protections', option: 'text' }),            
            items: this.app.store.state.selectedDocuments || [], // (initially it's better to keep it here as 'this.filter' (synchronously) instead of within state (asynchronously updated). 
            // pagination
            page: {
                index: 0,
                inputText: '1',
                //size: PageSizeOptions[0].id,
                size: this.app.store.getOption({ section: 'page', option: 'size' }),
                //sizeModel: PageSizeOptions[0], // default page size option = 10 items per page           
                items: [], // page items,
                showPageInput: true,
                showTotalPages: true,
                totalPages: 0
            }
        };

        this.state = {
            // synchronized with the global store
            selectedDocuments: this.app.store.state.selectedDocuments || [],
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,

            // component with search properties (page number, type, name) and the filtered items
            filter: this.filter,

            showRemovePolicyModal: false,
            showRemovePolicyErrorModal: false,
            showContextMenu: false,
            showDeleteButton: false,
            showEditButton: false
        };
    }

    componentDidMount() {

        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'items' state (or other):
        //window.app.locator.policiesPage = this;
        this.app.store.subscribe({ property: 'selectedDocuments', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });

        //unselectItems(this.app.store.state.selectedDocuments); // reset the selections

        if (this.app.store.state.selectedDocuments) {

            this.filterItems();
            this.fillPage();
        }
        else {           
            // this component won't work after browser refresh: if those object are not defined: abort and return to home screen                
            goToHomePage();
            return;            
        }
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (prevState.selectedDocuments !== this.state.selectedDocuments) {
            //if (this.app.store.state.policies) {

                this.filterItems();
                this.fillPage();
            //}
            //this.loadData({
            //    items: this.state.policies,
            //    PolicySubType: this.state.policySubType,
            //    PolicyType: this.state.policyType,
            //    TextFilter: this.state.text,
            //    PageIndex: this.state.top,
            //    PageSize: this.state.pageSize,
            //});
        }
    }

    componentWillUnmount() {
        //window.app.locator.policiesPage = null;
        this.app.store.unsubscribe({ property: 'selectedDocuments', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
        //unselectItems(this.app.store.state.selectedDocuments);        
    }

    filterItems = () => {
        //console.log('selectedDocuments : filter items... store.state.selectedDocuments: '); console.log(this.app.store.state.selectedDocuments);
        ////console.log('selectedDocuments : filter items... filter: '); //console.log(this.filter);
        const filter = this.filter;
        //console.log('filterItems')
        filter.items = this.app.store.state.selectedDocuments;
        if (!filter.items) return;
        // step 1 (quicker - place it first): policy type filter (my policies/shared with me)
        //filter.items = filter.items
        //    .filter(selectedDocument => { return (filterPolicyType(this.app, selectedDocument, filter.policyType)) });
            
        // step 2 (slower - place it second): text filter
        if (filter.text !== '') {
            try {
                //filter.items = filter.items
                //    .filter(policy => `${policy.Name}${getPolicyUserNamesForSearch(this.app, policy)}`.toLocaleLowerCase()
                //        .search(filter.text.toLocaleLowerCase()) > -1)
                filter.items = filter.items
                    //.filter(selectedDocument => getPolicyNameAndUserNamesForSearch(this.app, policy).toLocaleLowerCase()
                    .filter(selectedDocument => selectedDocument.name.toLocaleLowerCase()
                        .search(filter.text.toLocaleLowerCase()) > -1)
            }
            catch {
                // wrong search string: mark the input field in red?
            }     
        }
        // sort the policies: alphabetically and by favourite option (favs go first) 
        sortDocuments(filter.items);

        if (!filter.items) { filter.items = [] }; // for security, to not to process and render null list later
        this.setState({ filter: this.filter });
    };

    fillPage = () => {

        const { items, page } = this.filter;
        if (!items) return;
        page.items = items
            .slice(
                (page.index * page.size),
                (page.index * page.size) + page.size,
            );
        this.setState({ filter: this.filter });
    };

    changePage = (model) => {

        const filter = this.filter;
        const { page } = filter;
        if (model) { page.index = model.index; }
        page.inputText = page.index + 1; // set the text for the input control 
        unselectItems(this.state.selectedDocuments); // we don't keep selected items from other pages for this component        
        this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter
        //this.state.showContextMenu = false; // IT DIDN'T WORK: see if this will avoid introducing some additional 'set state' related calls that break context menu sometimes?
        this.fillPage();
    };

    onSelectDocumentType = (model) => {

        
        //const filter = this.filter;
        //if (model.id !== filter.policyType) {

        //    filter.policyType = model.id; // set the new filter value
        //    this.app.store.setOption({ section: 'protections', option: 'type', value: model.id }); // set the new configuration value

        //    this.filterItems(); // apply the new filter to the items collection
        //    this.changePage({ index: 0 }); // go to the first page of the new search result  
        //}
    }

    onTextFilterChange = (value) => {

        const filter = this.filter;
        if (filter.text != value) {

            filter.text = value; // set the new filter value
            //this.app.store.setOption({ section: 'protections', option: 'text', value: value }); // set the new configuration value

            this.filterItems(); // apply the new filter to the items collection            
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }        
    };

    changePageSize = (e, model) => {

        if (e) e.stopPropagation();
        const { page } = this.filter;
        if (page.size !== model.id) {
            page.sizeModel = model;
            page.index = 0;
            page.size = model.id;
        }
        this.changePage();
    }

    //onSelectPolicyFilterChange = (e, filter) => {
    //    e.stopPropagation();

    //    function sortFunc(a, b) {
    //        if (filter.id === 'ALL') {
    //            return b.Counter - a.Counter;
    //        }
    //        if (filter.id === 'MOST_USED') {
    //            return b.Counter - a.Counter;
    //        }
    //        if (filter.id === 'UNUSED') {
    //            return a.Counter - b.Counter;
    //        }
    //    }

    //    if (filter.id !== this.state.policySubType.id) {
    //        this.loadData({
    //            items: this.state.selectedDocuments.sort(sortFunc),
    //            PolicyFilter: filter,
    //            PolicyType: this.state.policyType,
    //            TextFilter: this.state.text,
    //            PageIndex: this.state.top,
    //            PageSize: this.state.pageSize,
    //        })
    //    }
    //}




    renderItems(app, items) {

        //if (!canCreateProtections(app)) return null;
        //if (!isProtector(app)) return null;

        //console.log('renderProtectionList: ', items)
        return (            
            <ItemList>
                {
                    //<Item className='head policies disabled'>
                    //    <ItemColumn className='xs-col-2 s-col-2 m-col-1'></ItemColumn>
                    //    <ItemColumn className='xs-col-10 xs-border-bottom xs-no-border-right s-col-5'>{this.app.R.ProtectionPolicy}</ItemColumn>
                    //    <ItemColumn className='xs-col-2 s-hidden'></ItemColumn>
                    //    <div className='absolute fav-button'><div className='button small disabled'><div className='growing icon icon-fav-off'></div></div></div>
                    //    <ItemColumn className='xs-col-4 s-col-2'>{this.app.R.Documents}</ItemColumn>
                    //</Item>
                }                

                {!items &&
                    (<Item key={'docs-loading-icon'} >
                        <ItemColumn key={'col-docs-loading-icon'} className={'item-col one-line'}>{this.app.R.Loading}</ItemColumn>
                    </Item>)
                }

                {items &&
                    (items.length > 0
                        ?
                        items.map((item, i) => this.renderItem(item, i))
                        :
                        (<Item className='disabled document'>
                            <ItemColumn className='col-12 text-center'>{this.app.R.NoItems}</ItemColumn>
                        </Item>)
                    )
                }       

            </ItemList>
        );
    }

    renderItem(item, i) {

        let css_visibility = '';
        if (item.css_visible) { // this state and class will control animation
            css_visibility = ' visible';
        }
        else {
            css_visibility = ' hidden';
        }
        let onlick = null;
        let className = 'document disabled document' + css_visibility;

        return (
            <Item
                key={'seldoc-' + i}
                onClick={onlick}
                selected={item.selected}
                className={className}
            >                                
                <ItemColumn className='xs-col-12 xs-border-bottom xs-no-border-right'>{item.name}
                    <div className='document-button close'>
                        <div className='button small' onClick={(e) =>
                        {
                            removeItemFromDisplayList(e, this.state.selectedDocuments, item, this.onRemoveItem);
                            this.setState({ selectedDocuments: this.state.selectedDocuments });
                                                         
                        }}>
                            <div className='button growing icon icon-close-negative'></div>
                        </div>
                    </div>     
                </ItemColumn>                         
            </Item>
        )
    }

    onRemoveItem = (items) => {

        //this.setState({ selectedDocuments: items });
        this.app.store.setState({ selectedDocuments: items });
        console.log('items after removing: ', items)
        if (items && items.length === 0) {
            goToHomePage();            
        }   
    }

    renderFavButton = (policy) => {

        let favClass = 'icon-fav-off-negative';
        if (policy.Fav) { favClass = 'icon-fav-on-negative'; }      
        return (
            <SmallButton contentClass={'fav ' + favClass} onClick={(e) => { toggleFavourite(e, policy); this.filterItems(); this.fillPage(); }} />
        );
    };

    renderEditButton = (policy) => {

        // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
        // render the context menu buttons we use that model to check if we can edit/delete
        if (!policy)
            policy = this.app.store.state.policy
        if (policy) {
            if (!canEditPolicy(this.app.user, policy))
                return null;
        }

        if (this.state.showEditButton) {
            return (
                <div className='context-menu-button edit'>
                    <SmallButton contentClass={'context icon-edit-2'} onClick={(e) => editPolicy(e, this.app, /* full policy */ null, /* simple policy */ null, RequestType.None, /* is new policy */ false)} />
                </div>
            );
        }
        if (!this.state.showEditButton) { // render it, but disabled
            return (
                <div className='context-menu-button edit'>
                    <SmallButton className='disabled' contentClass={'context icon-edit-2'} />
                </div>
            );
        }
        else {
            return ('');
        }
    };

    // When rendered from context menu the policy will be null, 
    // in which case it will be assigned later in policy manager
    renderDeleteButton = (policy) => {
        
        // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
        // render the context menu buttons we use that model to check if we can edit/delete
        if (!policy)
            policy = this.app.store.state.policy

        //if (policy) {
        //    if (!canDeletePolicy(this.app.user, policy))
        //        return null;
        //}        

        if (this.state.showDeleteButton) {
            return (
                <div className='context-menu-button delete'>
                    <SmallButton contentClass={'context icon-delete'} onClick={(e) => { removePolicy(e, policy, this) }} />
                </div>
            );
        }
        else { // render it, but disabled
            return (
                <div className='context-menu-button delete'>
                    <SmallButton className='disabled' contentClass={'context icon-delete'} />
                </div>
            );            
        }
    }


    //===============================================================
    // REMOVE 
    closeRemovePolicyModal = () => {
        this.setState({    
            showRemovePolicyModal: false,
            filter: this.filter
        });
    }

    showRemovePolicyModal = (policy) => {
        // when clicking on a inner item button: will always pass and set a reference
        // to that particular policy, otherwise, when the context menu was used, we could
        // have multiple selecetd items, so we will ignore the 'currentItem'
        this.app.store.state.policy = policy; 
        this.setState({
            showRemovePolicyModal: true            
        });
    }

    closeRemovePolicyErrorModal = () => {
        this.setState({
            showRemovePolicyErrorModal: false
        });
    }

    showRemovePolicyErrorModal = () => {
        this.setState({
            showRemovePolicyErrorModal: true
        });
    }

    onRemovePolicyError = (task) => {
        // could be we are removing multiple policies: check if all have been removed?
        // ...
        this.showRemovePolicyErrorModal(task);
    }

    onRemovePolicyCompleted = (task) => {
        // could be we are removing multiple policies: check if all have been removed?
        // ...
        this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter

        this.closeRemovePolicyModal();
    }
    
    //=========================================================================
    // REMOVE POLICY

    renderRemovePolicyModal = () => {

        if (!this.state.showRemovePolicyModal) return null;

        let app = window.app;
        let title = '';
        let info = '';
        let items;
        // Option A: (not implemented now)
        // we have clicked the inner item button, and the policy reference is passed here 
        // Option B: (current mode)
        // we have clicked the context menu button, and we have to find a reference to the 
        // currently selected policies (there could be many, and we will delete all of them):
        //let policy = this.app.store.state.policy !!! potentially buggy: the store.state.policy can be set from other places as well
        //if (policy) {
        //    // Option A: looks like we called the 'delete' from inner item button:
        //    // delete only this policy
        //    items = [];
        //    items.push(policy);
        //}
        //else { // Option B: called from context menu, delete all selected policies:            
            items = findSelectedItems(this.state.selectedDocuments);  // this allows for multiselection
        //}
        
        const length = items.length;
        if (length === 1) {
            title = app.R.RemovePolicy;
            info = app.R.RemovePolicyInfo;
            if (items[0]) {
                // replace the placeholder
                let replacements = {
                    '{n}': items[0].Name
                };
                info = template(info, replacements);
            }            
        }
        else if (length > 1) {
            title = app.R.RemovePolicies;
            info = app.R.RemovePoliciesInfo;
            let names = convertPolicyNamesToText(items);
            // replace the placeholder            
            let replacements = {
                '{n}': names
            };
            info = template(info, replacements);
        }

        return (
            <Modal show={true} onHide={this.closeRemovePolicyModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='labelZ lines-3'>
                        {info}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeRemovePolicyModal}>
                        {app.R.Cancel}
                    </Button>
                    <Button variant="primary" onClick={(e) => onRemovePolicyConfirmed(e, items, this)}>
                        {title}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderRemovePolicyErrorModal = () => {

        let app = window.app;
        let info = app.R.RemovePolicyError;
        if (app.store.state.policy) {
            // replace the placeholder
            let replacements = {
                '{n}': app.store.state.policy.name
            };
            info = template(info, replacements);
        }

        return (
            <Modal show={this.state.showRemovePolicyErrorModal} onHide={this.closeRemovePolicyErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{app.R.RemovePolicy}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{info}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeRemovePolicyErrorModal}>
                        {app.R.Close}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderAddPolicyButton = () => {

        //console.log('renderAddPolicyButton : user configuration: ', this.app.store.state.options.userConfiguration)
        if (!canCreateProtections(this.app)) return null;
        if (!isProtector(this.app)) return null;

        // on click we will call 'policyManager.editPolicy', passing a null policy there,
        // so the EditPolicy component will be called with id=0, and it will detect it 
        // to create a new policy model.
        return (
            <SmallButton
                contentClass='icon-add'
                onClick={(e) => { editPolicy(e, this.app, /* full policy */ null, /* simple policy */ null, RequestType.None, /* is new policy */ true) }}
            />
        );
    }

    renderBackButton = () => {

        return (
            <SmallButton
                contentClass='icon-back'
                onClick={onReturnBack}
            />
        );
    }

    onProtect = (e) => {
        if (e) e.stopPropagation();      
        console.log('onProtect...');    
        this.app.history.push(routes.policies.select());
    }

    onUnprotect = (e) => {
        if (e) e.stopPropagation();     
        console.log('onUnprotect...');
        //this.app.history.push(routes.policies.select());
    }

    onDocumentInfo = (e) => {
        if (e) e.stopPropagation();
        console.log('onDocumentInfo...')
        // callback to the parent component (document info page)
        //this.props.onEditPolicy();
    }
    //=========================================================================
    // MAIN RENDER

    render() {
        //console.log('Policies.render()...')
        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)  
            return null;        

        const app = this.app;
        const filter = this.filter;
        const { page } = filter;

        updateAppHistory(app, this);

        let contextMenu = '';
        //if (this.state.showContextMenu) {
        //    var divStyle = {
        //        display: 'block',
        //        position: 'absolute',
        //        left: this.app.clientX,
        //        top: this.app.clientY
        //    };
        //    //contextMenu = <div style={divStyle}><StaggeredMenu2  /></div>;
        //    contextMenu = <div className='over' style={divStyle}>
        //        {this.renderDeleteButton(null)}
        //        {this.renderEditButton(null)}
        //                  </div>;
        //}

        //<Button variant='primary' type='submit' className='login-buttonX'>
        //    {app.R.Protect}
        //</Button>
        //<Button variant='primary' type='submit' className='login-buttonX'>
        //    {app.R.Unprotect}
        //</Button>
        //<Button variant='primary' type='submit' className='login-buttonX'>
        //    {app.R.DocumentInformation}
        //</Button>

        let showPolicyTypeFilter = false; // used to hide the combo from demo until the filter code is finished
        let showPageSizeSelector = false;
        let buttonClass = 'label-and-button float-left margin-right-2rem';
        let infoButtonEnabled = this.state.selectedDocuments.length == 1 ? true : false;
        let infoButtonEnabledClass = infoButtonEnabled ? ' clickable' : ' disabled transparent';
        let infoButtonOnClick = infoButtonEnabled ? (e) => this.onDocumentInfo(e) : null;
        return (
            <div>
                
                <div className='extra-margin-bottom'>
                    {this.renderBackButton()}
                    <span className='label extra-space'></span>
                    <h3 className='label'>{this.app.R.SelectedDocuments}</h3>                    
                </div>

                <div className='clear-left margin-bottom-05rem'>

                    <div className={buttonClass}>
                    <div className='clickable' onClick={this.onProtect}>
                        <h4 className='label'>{this.app.R.Protect}</h4>
                        <SmallButton
                            className='label-and-button'
                            contentClass='icon-locked'
                            onClick={(e) => this.onProtect(e)}
                        />
                    </div>   
                    </div>

                    <div className={buttonClass}>
                    <div className='clickable' onClick={this.onUnprotect}>
                        <h4 className='label'>{this.app.R.Unprotect}</h4>
                        <SmallButton
                            className='label-and-button'
                            contentClass='icon-locked'
                            onClick={this.onUnprotect}
                        />
                    </div> 
                    </div>                    
                
                    <div className={ buttonClass + infoButtonEnabledClass}>
                        <div onClick={infoButtonOnClick}>
                        <h4 className='label'>{this.app.R.Info}</h4>
                        <SmallButton
                            className={infoButtonEnabledClass}
                            contentClass='icon-edit-negative'
                            onClick={infoButtonOnClick}
                        />
                    </div> 
                    </div>
                    <div className='clear-left'></div>
                </div>               
                
                {
                    //(
                    //<div className='filters'>

                    //    {showPolicyTypeFilter && <RadioButtonsList
                    //        className='inline'
                    //        items={PolicyTypeModels(this.app)}
                    //        onChange={this.onSelectDocumentType}
                    //        selectedItemId={filter.policyType}
                    //    />}

                    //    {
                    //        //<div className="pagination">
                    //        //    <Dropdown className=''>
                    //        //        <Dropdown.Toggle variant="secondary">
                    //        //            {skip}
                    //        //        </Dropdown.Toggle>

                    //        //        <Dropdown.Menu>
                    //        //            {this.pageSizeList.map((option, i) =>
                    //        //                (<Dropdown.Item
                    //        //                    key={`page-size-${i}`}
                    //        //                    onClick={(e) => this.onPageSizeListChange(e, option.id)}
                    //        //                >
                    //        //                    {option.label}
                    //        //                </Dropdown.Item>)
                    //        //            )}
                    //        //        </Dropdown.Menu>
                    //        //    </Dropdown>
                    //        //</div>
                    //    }

                    //    {
                    //        // SearchFilter - minCharacters:
                    //        // the JavaScript search filter can work from 2 characters, but we
                    //        // set 3 so it behaves the same as the Documents SQL search script
                    //    }
                    //    <SearchFilter
                    //        app={app}
                    //        showSearchIcon
                    //        placeholder={app.R.Search}
                    //        value={filter.text}
                    //        minCharacters={3}
                    //        onChange={this.onTextFilterChange}
                    //    />
                    //</div>
                    //)
                }

                {this.renderItems(app, page.items)}

                    {
                        // remove the ALL /MOST USED /UNUSED combo for now, later add sorting trigger to the column head
                        //<SelectList
                        //    items={policySubTypes(this.app)}
                        //    selectedItem={policySubType}
                        //    onChange={this.onSelectPolicyFilterChange}
                        ///>
                    }                    

                {isProtector(app) && (

                    <Paginator2                    
                        filter={this.filter}                    
                        onChange={this.changePage}
                    />
                )}
                    <br />
                
                    {showPageSizeSelector && (
                        <SelectList
                            title={app.R.ResultsPerPage}
                            variant="secondary"
                            items={page.sizeModels}
                            selectedItem={page.sizeModel}
                            onChange={this.changePageSize}
                        />                    
                    )}
                
                
                {this.renderRemovePolicyModal()}
                {this.renderRemovePolicyErrorModal()}   
                <br />
                <br />
                {contextMenu}
            </div>
        );
    }
}
