import React, { Component } from 'react';

import { TableGrid } from "../../../components/organisms";


export class GeneralSection extends Component {
    constructor(props) {
        super(props);

        this.app = window.app;
    }

    render() {
        const {
            documentName,
            general: { details, access },
            accessDetails,
        } = this.props;
        // <h4>{this.app.R.General}</h4>
        return (
            <div className='tab-section clear-left'>                
                <TableGrid
                    title={this.app.R.ProtectionDetails}
                    list={details}
                />
                {accessDetails && (
                    <TableGrid
                        title={this.app.R.DateOfLastAccess}
                        list={access}
                    />
                )}
            </div>
        )
    }
}