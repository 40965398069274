import { createOptions } from '../common/configs';
import { RequestType } from '../common/constants';



/**
 * This is the Store module.
 * It has a state which keeps all data collections we get from server
 * It has functions to subscribe for state updates, unscubscribe,
 * and to set the state
 */
export class Store {

    constructor(app) {
        this.app = app;
        this.state = null;
        this.subscribers = [];
        // initialize the state
        this.initialize();
    }

    initialize() {
        //console.log('Store.initialize');
        // old code: refactor the 'selected items' refs to the new store model!
        //app.users = null; // organization users sent from the server
        //app.policies = null; // the policy list sent from the server
        //app.store.state.policy = null; // currently selected policy
        //app.selectedUserPermissions = null; // currently selected user permissions for the selected policy
        //app.superFavPolicy = null;
        //app.lastSuperFavPolicy = null;
        // keep the existing app settings when we reset the store for a new user!
        let existingAppSettings = null;
        if (this.state && this.state.options && this.state.options.appSettings) {            
            existingAppSettings = this.state.options.appSettings;
            //console.log('Store.initialize: existing app settings: ', existingAppSettings);
        }            

        this.state = {

            customPolicyGuid: null, // used for custom protections - the same for all custom protections applied by an organization

            delayedTasks: null, // task list used for the support of multiple files in 'Custom Protection' and 'Add' Users operations, which require 'Edit Policy' form to be completed first
            delayedTaskType: RequestType.None,

            documentEvents: null, // for selected 'Document' list
            documents: null, // for all user 'Documents' list
            document: null,
            documentGuid: null,
            documentId: 0,
            documentInfo: null,
            documentInfoPermissions: null,
            documentInfoPolicy: null,
            documentInfoTask: null,
            documentTracking: null,
            documentWarning: null,
            documentWarnings: null,

            groups: null,

            invitationUsers: null, // auxiliar list, e.g. for permission edition
            isPreauthorized: false,

            lastTask: null, // helper, to be passed to componentes through state, currently used in Login component so it refreshes its code information if 'codeType' stays the same and state would not get rendered 
            loginError: false, // used for login errors, when task.error is returned, the login is reset and constructed again, it needs to know the error has to be shown

            options: createOptions(),

            originalTask: null,
            
            policies: null,
            //    currentItem: null, // for current policy
            //    currentItemCopy: null, // for policy edition,
            //    currentSubItem: null, // for current permission
            //currentSubItemCopy: null // for permission edition
            policy: null, // for current policy
            policyCopy: null, // for policy edition,
            permission: null, // for current permission
            permissionCopy: null, // for permission edition
            permissionUsers: null, // users list for permission edition

            codeId: '',
            codeType: '', // extra code required for login: empty (or 'none'), 'captcha', 'two-factor': affects the login UI and is sent to server with credentials

            selectedDocuments: null, // originally for docs selected in Google Picker, possibly for other providers later
            sessionId: '',
            stateId: 0, // fake counter to force state rendering fro some componentes, until better implementation is made for them tha allows to provide changed object to the comeponent state to force render

            tasks: null, // the task list

            userDetails: null,
            userLanguageLoaded: false,
            userLanguageLoading: false,

            users: null,// organization users and guests sent from the server        
        };
        // restore the app settings:
        if (existingAppSettings) {
            this.state.options.appSettings = existingAppSettings;
            //console.log('store.initialize: restored existing app settings.');
        }
            
    }
    // options (like the user configuration above) is an object with different properties - 'sections', 
    // which are also objects. Each section property is an 'option' object, 
    // which follows a pattern
    findOption(props) {

        let options = this.state.options;        
        let option = null; // result: option to be found

        if (props.section) { // 'props.section' is actually a section name, provided in a shorter form as a call parameter
            let section = options[props.section];
            if (section) {                
                option = this.findOptionInSection(section, props);                
            }
        }
        else {
            option = this.findOptionInAllSections(props);
        } 
        return option;
    }

    findOptionInAllSections(props) {

        let options = this.state.options;

        for (var sectionName in options) {
            let section = options[sectionName]; // options are divided into sections
            let option = this.findOptionInSection(section, props);
            if (option)
                return option;
        }        
        return null;
    }

    findOptionInSection(section, props) {

        for (var optionName in section) {
            let option = section[optionName];
            if (props.key) {
                // check 'key' property
                if (option.key) {
                    if (option.key === props.key) {
                        return option;
                    };
                }
            }
            if (props.option) { // 'props.option' is actually an option name, provided in a shorter form as a call parameter
                // check property (model) name
                if (optionName === props.option)
                    return option;
            }            
        }
        return null;
    }
    // exposes an interface to set the option value actually, not the option
    // object, as the option object is used internally only, from outside code
    // we only set/get the internal value property by calling these methods.
    setOption(props) {

        let option = this.findOption(props);
        if (option) {
            option.value = props.value;
            if (props.key) {
                //console.log('set option for key <' + props.key + '> : ' + props.value);
            }                
            else if (props.name) {
                //console.log('set option <' + props.name + '> : ' + props.value);
            }                
        }
        else {
            //console.log('cannot find the option with key <' + props.key + '> ');
        }            
    }

    // exposes an interface to get the option value actually, not the option
    // object, as the option object is used internally only, from outside code
    // we only set/get the internal value property by calling these methods.
    getOption(props) {

        let value = null; // result 
        let option = this.findOption(props);

        if (option) {
            value = option.value;
            if (props.key) {
                //console.log('get option for key <' + props.key + '> : ' + value);
            }
            else if (props.name) {
                //console.log('get option <' + props.name + '> : ' + value);
            }
        }
        else {
            //console.log('cannot find the option with key <' + props.key + '> ');
        }           

        return value;
    }

    // a selective method of loading options (e.g. from local storage) 
    //that will keep the existing options model and only set new values
    setOptions(sourceOptions) {

        let targetOptions = this.state.options;
        //if (option)
        for (var sectionName in sourceOptions) { // options are divided into sections

            if (sectionName === 'appSettings')
                continue;

            let sourceSection = sourceOptions[sectionName]; 
            let targetSection = targetOptions[sectionName]; 

            if (sourceSection && targetSection) { // copy the stored section if the target section still exists (could be deprectated in development)
                for (var optionName in sourceSection) { // sections contain individual options, each one represented by a key
                    let sourceOption = sourceSection[optionName];
                    // find the existing option to update its value only
                    let targetOption = this.findOptionInSection(targetSection, { option: optionName }); // 'props.option' is actually an option name, provided in a shorter form as a call parameter
                    if (sourceOption && targetOption) { 
                        // set the target option value
                        //console.log('store.SetOptions: option: [' + sectionName + '].[' + optionName + '], sourceOption, targetOption: ', sourceOption, targetOption);
                        targetOption.value = sourceOption.value;
                        
                    }
                }
            }            
        }   
    }

    /**
     * Store manager sets the references to components in a locator, using
     * subscribe/unscubscribe pattern, so later the components can be updated
     * when the store data is updated.
    * @param {string} key The property we want to subscribe for.
    * @return {ReactComponent} the component whose state will be updated.
    */
    subscribe(subscriber) {

        /** subscription is and object like:
         * {key, component (for 'set state'), callback}
        */

        this.subscribers.push(subscriber);
    };

    // unsubscribe:
    // removes references to an object from locator, by setting the reference to null.
    // Thw way it's done avoids possible overwriting of a reference to other object,
    //  e.g. in some kind of race condition, compared to a direct assignment, like:
    // window.app.locator.editPolicyPage = null;

    unsubscribe(subscriber) {

        this.subscribers = this.subscribers.filter(s =>
            s.property != subscriber.property ||
            s.component != subscriber.component ||
            s.callback != subscriber.callback
        );
    };

    setState(partialState) {

        if (partialState != null) {
            this.state = Object.assign({}, this.state, partialState);
        }

        // before we used this code, based on app.locator:
        //app.setComponentState(locator.contactsPage, { items });
        //app.setComponentState(locator.editPolicyPage, { users: store.state.users });

        // look for subscribers 
        ////console.log('store.setState: look for subscribers...');
        let _subscribers = [];
        for (var property in partialState) {
            const match = this.subscribers.filter(s => s.property == property);
            ////console.log('store.setState: match: ', match)
            if (match) match.map(s => _subscribers.push(s));
        }
        // set state and make notification callbacks
        _subscribers.map(s => {
            if (s.component) {
                // set component state:   
                //console.log('store: set subscriber state:', s, partialState);
                s.component.setState(partialState);                
            }
            if (s.callback) {
                // make the callback
                //console.log('store: make subscriber onComplete:', s, partialState);
                s.callback(partialState);
            }
            return true;
        });        
    };
}