import React, { Component, Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { ProtectionType, RequestType } from './common/constants';
import { getServerFromUrl, initializeIdleTimer, loadAllAppData } from './managers/appManager';
import { Auth } from './managers/Auth';
import { Store } from './managers/Store';
import { TaskProcessor } from './managers/TaskProcessor';
import { Home } from './pages/Home';
import { Tasks } from './pages/Tasks';
import { Policies } from './pages/Policies/Policies';
import { EditPolicy } from './pages/Policies/EditPolicy';
import { Permissions } from './pages/Policies/Permissions';
import { Contacts } from './pages/Contacts';
import { Invitations } from './pages/Invitations';
import { Documents } from './pages/Documents/Documents';
import { Document } from './pages/Documents/Document';
import { SelectedDocuments } from './pages/Documents/SelectedDocuments'
import { Options } from './pages/Options';
import { Identity } from './pages/Identity';
import { Login } from './pages/Login';
import { PageNotFound404 } from "./pages/PageNotFoung404";

import { Layout } from './components/layouts/Layout';
import {Preloader, Loader } from './components/atoms';
import * as configs from './common/configs';
import { IdleTimer } from './components/IdleTimer';
import { Test } from './components/atoms';
import { PrivateRoute } from './pages/Auth/PrivateRoute';
import { routes } from './common/routes';
import { template } from './common/format';


export class App extends Component {

    constructor(props) {
        super(props);

        // store it in the global scope to allow universal access from other pages
        window.app = this;

        //this.path = '../'; // USE FOR ROUTER / DEVELOPMENT !!!

        //this.path = '/app/'; // USE FOR ROUTER / PRODUCTION !!!
        this.path = ''; // USE FOR HASH ROUTER !!!

        // 1. The additional 'app' path is needed for publishing this as an IIS application under 'app' path:
        //    the script-to-content path relation is changed in the compiled code during the production build.
        // 2. The opening '/' character is needed, otherwise the build produces a double 'app' path: '.../app/app/content/...' !

        // static switch that enables processing external folders on client instead of doing it on server
        this.PROCESS_EXTERNAL_FOLDERS_ON_CLIENT = true;

        this.useFakeIdentity = false; // turn it on to use a pre-generated tokens

        // Tests will run with the current user, can be seen on the '/test' path.
        // If there's no user, we will have to authenticate and the tests will run.
        // Each test is actually a task, so it is located on the 'tasks' list.
        // When we get authenticated and 'appManager.userIsValid' method is called,
        // then tests are started if 'test.mode' is bigger than 0.
        this.test = {
            // test mode switch:
            // 0: no tests,
            // 1: additional endpoints tests
            // 2: heavy load tests
            // 3. concurrent tests to simulate random UI clicks on paths and filters
            // 4. concurrent protection tests to simulate multiple protection requests and see how File Server is behaving
            mode: 0,
            policy: null // a policy object created to test create/edit/remove/recover policy endpoints
        }

        // counter increased along the 'app.state.stateId' to force rendering
        this.stateId = 0;

        // The R object (based on word "Resources") will hold the localized strings. A JSON object
        // with the current language texts is loaded from a global and then a custom folder.
        this.R = {};

        // the json object with invitations in all languages
        this.invitations = {};

        // prefs will be loaded at application start, for now it will support storing language code
        // prefs will be saved/loaded (with 'load/saveSettings' methods)
        this.settings = {
            organizationCode: '',
            language: null,
            lastOrganizationId: 0,
            lastUserName: '',
            rememberMe: false,            
            // auth session properties: returned from AuthorizationApi and used for login: session has counters to avoid more than maximum count of error logins
            sessionId: '',
            codeId: '', // for captcha or two-facor, is persistent so after page reload the captcha gets loaded and shown
            codeType: ''
        }

        this.locator = { // component locator

            contactsPage: null,// reference to the current 'Contacts' component (set later from component constructor)
            invitationsPage: null,
            documentsPage: null, // the same as above for the rest of references here,
            documentInfoPage: null,
            editPolicyPage: null,
            permissionsPage: null,
            homePage: null,
            loader: null,
            loginPage: null,
            optionsPage: null,
            policiesPage: null,     
            tasks: null
        }
        // the data store, to encapsulate the data and to replace direct refs like 'app.policies' or 'app.policy'
        this.store = new Store(this);

        // the task processor: the document requests will be put on queue here and then processed one by one
        this.taskProcessor = new TaskProcessor(this);

        this.items = null; // a changing reference to a list that we are currently workin on, e.g. policies or users
        this.users = null; // organization users sent from the server
        this.policies = null;
        
        this.selectedItem = null; // generic pointer to a selected item (policy, user, document: all those share lot of code)

        this.selectedPolicy = null; // currently selected policy
        this.selectedUserPermissions = null; // currently selected user permissions for the selected policy
        this.superFavPolicy = null;
        this.lastSuperFavPolicy = null;

        this.documentInfo = null; // document information and last access details obtained from server when we click on a document

        // the list of storage provider objects: one per storage provider, it will
        // keep a reference to the provider and to the root node, like:
        // storage = { provider: provider, root: root};
        this.providers = []; // storage providers

        // the list of storage views: there can be several storage views
        // per storage object, so we can easily move files around:
        // storageView = { storage: storage, docked: true };
        //this.storageViews = [];

        // STATE FLAGS:
        // initially 'authenticated' is true, so login won't be rendered:
        // first we load the persistent data and call 'IsValidUser': if not valid,
        // then we set 'authenticated' to false and the login is rendered
        this.state = {

            authenticated: false,
            initializing: true,
            showError: false, // should render the error modal?
            errorObject: null, // object with fixed error content (title, message1, message2) for some errors, e.g. used when dragging more than 1 file for 'doc info' or 'add users'
            errorTask: null // when error is detected, the task will be assigned here so we can use its information in the error modal            
        };

        this.isLoadingCustomAppData = false; // a flag accessed from code, placed here, and not in the state above, because state is not synchronous

        this.onAuthenticated = []; // optional callbacks when user gets authenticated, so components can start loading their data
        this.history = null; // we will assign the history object from Router, so it can be used from everywhere

        // 'userDataLoaded' is a switch set to false before we call 'load all app data' for the first time, so later,
        // when 'all app data is loaded', it will call the 'load all user data' method.
        // Afterwards, we will load app data selectively, e.g. the languages, so when it's loaded
        // it won't start loading other data automatically (we will only set app state or re-render the screen).
        this.shouldLoadUserData = true;


        this.user = {}; // the user.config object will be loaded after the user has signed in: maybe we should move it to another object, like user or identity

        this.lastUser = {}; // the last user is set when session is kicked by 401 to the login: we keep the existing user, so after login we can check if it's the same user and keep going with his session
        
        this.auth = new Auth(this); 
        //this.codeType = ''; //this one is not reset in 'initialize' method! (kept outside of 'auth'  obejct because the 'auth' that contains tokens gets saved/loaded on restart)

        // number of file tasks being currently processed
        this.fileTaskCount = 0;

        this.idleTimer = initializeIdleTimer(this);

        // auxiliar properties, probably many of them will disappear after cleaning and refactoring:

        this.leftColumn = null;
        this.rightColumn = null;
        this.showContextMenu = false;
        this.clientX = 0;
        this.clientY = 0;
        this.contextMenuX = 0;
        this.contextMenuY = 0;
        this.spinnerX = 0;
        this.spinnerY = 0;

        // currently selected external storage provider        
        this.storageProvider = null;

        // hook to google picker
        this.googlePicker = null;
        this.docsView = null;        
        this.auth2 = null;
        this.scriptLoadingStarted = false;

    }
   
    //-------------------------------------------------------------------
    // this method will receive a reference to a component instance for 
    // which a new state will be set, and an object with new state,
    // following the Component.setState({}); pattern.

    // Before we can use it: we need to store references somewhere, and 
    // in the current architecture the best option is the app component.
    // 2. Then we can call this function from the modules, so a component
    // will have its state updated and it  will trigger the rendering.

    setComponentState = (component, state) => {

        //console.log('app. set component state: component: ', component);
        //console.log('app. set component state: state: ', state);
        
        if (component) {
            component.setState(state);
        }
    }

    //getFakeIdentity = () => {

    //    this.user = { U: 'adspadmin@lab.com' };
    //    // PILOT
    //    this.tokens.access_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImZESHR1c0Z3TnRBd0cwejB2RzA2V2MyNkVZVSJ9.eyJ1bmlxdWVfbmFtZSI6ImFkc3BhZG1pbkBsYWIuY29tIiwidXJuOm9hdXRoOnNjb3BlIjoiIiwic2VhbHBhdGg6b3JnOmlkIjoiMiIsInNlYWxwYXRoOm9yZzpuYW1lIjoiTWFpbk9yZyIsInNlYWxwYXRoOnJvbGU6aWQiOiIyIiwic2VhbHBhdGg6dXNlcjppZCI6IjEiLCJ1cG4iOiJhZHNwYWRtaW5AbGFiLmNvbSIsImF1dGhfdGltZSI6IjIwMTktMDQtMDhUMTI6MTU6NDYuNDIwWiIsImF1dGhtZXRob2QiOiJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YWM6Y2xhc3NlczpQYXNzd29yZFByb3RlY3RlZFRyYW5zcG9ydCIsInZlciI6IjEuMCIsImFwcGlkIjoiRUU2RjhBMzYtRkIzNC00QUY0LUExQjMtNURGMEYxOUNEMkIyIiwiaWF0IjoiMTU1NDcyNTc0NiIsImlzcyI6Imh0dHBzOi8vcGlsb3QubGFiLmNvbS9hZGZzL3NlcnZpY2VzL3RydXN0IiwiYXVkIjoibWljcm9zb2Z0OmlkZW50aXR5c2VydmVyOmFwaS5ybXMucmVzdC5jb20iLCJleHAiOjE1NTQ3MjY5NDYsIm5iZiI6MTU1NDcyNTc0Nn0.msQMQYu7oupdiULykV4hkuhn2KDUK9jeu_MWSCD7Ll1wruIAXQbAy2qPfsajvt8yI-E7rQt1iqcXh61cQzqvWLhuhjtm2HhDSYQl-RCCiiDzGWOUefIRhXErONl9g48w-IP7lzFCZ6KjM3P0d30DSEmpWrPFgZeTlpw-m7PnQeGkhLt7v1ICF3NGgIhirG-oN3N4F1mYZIFwsh5UY5VYIYphTy30nPphvQ1hFpKmOY_BB4o-Ig1D_Zb-b297JQOaX5kCkjLNvFjww-rIwl09or1AHjXuAvZ3NGngJ7HZ-PU3k_VJyO6546Sy4ti1j5xyLzL9Ah7x7GCPjNcKULSxig';
    //    // DC-1
    //    //this.tokens.access_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImZESHR1c0Z3TnRBd0cwejB2RzA2V2MyNkVZVSJ9.eyJ1bmlxdWVfbmFtZSI6ImFkc3BhZG1pbkBsYWIuY29tIiwidXJuOm9hdXRoOnNjb3BlIjoiIiwic2VhbHBhdGg6b3JnOmlkIjoiMyIsInNlYWxwYXRoOm9yZzpuYW1lIjoiTWFpbiIsInNlYWxwYXRoOnJvbGU6aWQiOiIyIiwic2VhbHBhdGg6dXNlcjppZCI6IjIiLCJ1cG4iOiJhZHNwYWRtaW5AbGFiLmNvbSIsImF1dGhfdGltZSI6IjIwMTktMDQtMDNUMjA6MzA6MjQuMTI4WiIsImF1dGhtZXRob2QiOiJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YWM6Y2xhc3NlczpQYXNzd29yZFByb3RlY3RlZFRyYW5zcG9ydCIsInZlciI6IjEuMCIsImFwcGlkIjoiRUU2RjhBMzYtRkIzNC00QUY0LUExQjMtNURGMEYxOUNEMkIyIiwiaWF0IjoiMTU1NDMyMzQyNCIsImlzcyI6Imh0dHBzOi8vZG9tYWluLmNvbS9hZGZzL3NlcnZpY2VzL3RydXN0IiwiYXVkIjoibWljcm9zb2Z0OmlkZW50aXR5c2VydmVyOmFwaS5ybXMucmVzdC5jb20iLCJleHAiOjI3NTQzMjM0MjQsIm5iZiI6MTU1NDMyMzQyNH0.bG4KCl9KCvWZCN5UHHqqKSxGVtqP923NPjqBA3yosypKzkGTZqGQXpiBb0wM3J7hUMen39VPbHaxFJdzDE63q-_e0gy6nWPuwEraax1kPeXW_VGHh-CmknUrIfrEzLNZlZ1kFvtBige0UG1iQr-j9QVUFpgrF3uprnOdaUsj4DV1MzS46KSUsYg6pMbKU6JkHqqYtgWfe3-N8ZSDeBwobS4_DQUhb20XnCJEH6CwT8w-WeNacZJM4s2O3EnWJc6FatWqCJKis-1fbHdpUdWRBGrHEqWoatp0AovoiVTp57gfDd02ZkOLSgEJXeVwWSriVHftFQ3WHVuuZDCmUoSvnw';
    //}

    componentDidMount() {

        this.store.initialize();
        
        // STEP A:
        // We need to load the initial application data, both the default(factory) and custom files:
        // 1. css files,
        // 2. configuration of the application,
        // 3. localization files (the texts used in the app) in a selected language.

        loadAllAppData(this);

        // Later, STEP B:
        // then, from 'loadAllUserData' method, once the user is logged-in and 'is valid = true': load the user configuration and other data,
        // like policies, favourite policy, users...

    };  

    //=========================================================================
    // RENDER ERROR MODAL

    // TODO: this has a state change! but it's called on render! 
    // --> cannot change state from render function
    renderError = () => {
        
        if (!this.state.showError) return null;

        const obj = this.state.errorObject;
        const task = this.state.errorTask;

        if (!task && !obj) return null;
        //console.log('ERROR TASK:', task)
        // cannot update state from RENDER! move it to dialog EVENTS: when user clicks on any button, that's the right moment 
        //if (task.state === TaskState.Completed) {
        //    this.setState({                
        //        errorTask: null
        //    });
        //    return null;
        //}

        const app = this;
        
        // TO DO: correctly format the error information (task.error data or task.info data):
        //let info = task.info
        // or event better: insert some user-friendly text:
        //let info = app.R.NetworkErrorInfo        
        //let url = 'URL: ' + task.ajax.url;        
        let message1 = '';
        let message2 = '';
        let hasMessage2 = false;
        let title = '';
        let shouldRenderRetryButton = false;
        if (obj) {
            message1 = obj.message1;
            if (obj.message2) {
                hasMessage2 = true;
                message2 = obj.message2;
            }
            title = obj.title;
        }
        else if (task) {
            let server = '';
            if (task.ajax) {
                server = getServerFromUrl(task.ajax.url);
                message1 = template(app.R.Timeout, { '{0}': server });
            }
            title = app.R.ConnectionError;
            message2 = app.R.TryAgainLater;
            hasMessage2 = true;
            shouldRenderRetryButton = true;
        }
        
        
        return (
            <Modal show={true} onHide={this.closeError}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message1}
                    {hasMessage2 && <br />}
                    {hasMessage2 && message2}
                </Modal.Body>
                <Modal.Footer>      
                    {!shouldRenderRetryButton &&
                        <Button variant="secondary" onClick={this.closeError}>
                            {app.R.Ok}
                        </Button>
                    }
                    {shouldRenderRetryButton &&
                        <Button variant="secondary" onClick={this.closeError}>
                            {app.R.Cancel}
                        </Button>
                    }
                    {shouldRenderRetryButton &&
                        <Button variant="primary" onClick={(e) => this.onRetryConfirmed(e)}>
                            {app.R.Retry}
                        </Button>
                    }
                    
                </Modal.Footer>
            </Modal>
        );
    }

    // CLOSE ERROR MODAL
    closeError = () => {
        this.setState({
            showError: false,
            errorObject: null,
            errorTask: null
        });
    }
    
    onRetryConfirmed = (e) => {

        let task = this.state.errorTask
        task.waitingForAuthorization = false // reset it in case it was true, so it won't get relaunched automatically (we will launch it here)
        this.setState({
            showError: false,
            errorObject: null,
            errorTask: null
        });
        // TO DO: retry needs to be perfected to work with queuing, ow only one taks (the errorTask)
        // but we need to restart the queue, as there could be more tasks affected by the same error
        // (e.g. 500 status code), not only the task referenced here
        this.taskProcessor.retryFailedTasks();
        //this.taskProcessor.push(task);
    }

    render() {

        //console.log('App.render');

        const { authenticated, initializing } = this.state;

        // this.state.initializing = true when the app is loading the appsettings.json, custom.appsettings.json, and css files:
        // at this point we don't have custom css loaded and we don't know what how to render things, so keep
        // the screen as clean as possible. If we use a loading icon, most likely it will look different then after
        // the css gets loaded so there will be a visual jump:
        //
        // option 1: set some styles at index.html, the problem is that custom styles could be completely different --> 
        // the solution would be to use 'index' code that loads additional json configuration or styles with a custom logo
        // (the bear minimum) - if we have a custom URL path parameter that points to an org, we would load this startup
        // content from the org folder.
        //
        // option 2: use a different marker at this point, like a small text in the corner: 'loading configuration'...
        if (initializing) return (
            <div>                
                <Loader />
                { this.renderError() }
            </div>
        );

        // at start 'this.state.initializing' is true, it's used to not to render the login component yet,
        // as we try to get the user tokens from local storage and then we will call /user/isvalid' to make sure
        // that the user is ok. These steps take a little time, enough to have the login flash and disappear
        // shortly after if user is valid, so we don't show login while the auth is being initialized:

        //if (initializing) return (
        //    <div>
        //        <Preloader />
        //        <Loader />
        //    </div>
        //);

        // tasks page, WIP
       //<PrivateRoute exact authed={authenticated} path='/tasks' component={(props) => <Tasks app={this} history={props.history} />} />

        return (
            <Layout app={this}>
                <Preloader />
                <Switch>
                    <Route exact path='/login' component={(props) => <Login app={this} history={props.history} />} />
                    <Route exact path='/test' component={(props) => <Test history={props.history} />} />
                    <PrivateRoute exact authed={authenticated} path='/' component={(props) => <Home app={this} history={props.history} />} />
                    <PrivateRoute exact authed={authenticated} path='/protections' component={(props) => <Policies history={props.history} app={this} selectionMode={false} />} />
                    <PrivateRoute exact authed={authenticated} path='/protection/:id/edit' component={(props) => <EditPolicy history={props.history} match={props.match} app={this} />} />
                    <PrivateRoute exact authed={authenticated} path='/protections/select' component={(props) => <Policies history={props.history} match={props.match} app={this} selectionMode={true} />} />
                    <PrivateRoute exact authed={authenticated} path='/permissions' component={(props) => <Permissions history={props.history} match={props.match} app={this} />} />
                    <PrivateRoute exact authed={authenticated} path='/documents' component={(props) => <Documents app={this} history={props.history} />} />                    
                    <PrivateRoute exact authed={authenticated} path='/document/:id' component={Document} />
                    <PrivateRoute exact authed={authenticated} path='/documents/selected' component={(props) => <SelectedDocuments app={this} history={props.history} />} />
                    <PrivateRoute exact authed={authenticated} path='/contacts' component={(props) => <Contacts app={this} history={props.history} invitationMode={true} />} />
                    <PrivateRoute exact authed={authenticated} path='/addusers' component={(props) => <Contacts app={this} history={props.history} addUsersToPermissionsMode={true} />} />
                    <PrivateRoute exact authed={authenticated} path='/invitations' component={(props) => <Invitations app={this} history={props.history} invitationMode={true} />} />
                    <PrivateRoute exact authed={authenticated} path='/options' component={(props) => <Options app={this} history={props.history} />} />
                    <PrivateRoute exact authed={authenticated} path='/identity' component={(props) => <Identity app={this} history={props.history} />} />
                    
                    <Route exact={true} component={(props) => <PageNotFound404 history={props.history} />} />
                </Switch>
                {this.renderError()}
            </Layout>
        )
    }
}
