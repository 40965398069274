import React, { Component } from 'react';

import { Checkbox, SmallButton } from "../../../components/atoms";
//import { CheckboxList } from "../../../components/organisms";


export class EditPolicySection extends Component {

    constructor(props) {
        super(props);

        this.app = window.app;
    }

    onEditPolicy = (e) => {
        if (e) e.stopPropagation();
        //console.log('EditPolicySection : onEditPolicy');
        // callback to the parent component (document info page)
        this.props.onEditPolicy();
    }

    render() {
        const { canAddUsers, canEditPolicy, onEditPolicy, policy } = this.props;        
        //console.log('EditPolicySection: render: canEditPolicy: ' + canEditPolicy);
        if (!canAddUsers && !canEditPolicy)
            return null;

        return (
            <div >                
                <div className='clickable' onClick={(e) => this.onEditPolicy(e)}>
                    <h4 className='label'>{canEditPolicy ? this.app.R.EditPermissions : this.app.R.AddUsers}</h4>
                    <SmallButton
                        contentClass='icon-edit-negative'
                        onClick={(e) => this.onEditPolicy(e)}
                    />
                </div>        
                <p>{this.app.R.ChangesWillAffectAllDocuments}</p>                
            </div>
        )
    }
}