import React, { Component } from 'react';
import { Dropdown, Form, Input, Select } from 'react-bootstrap';

import { SmallButton } from "../atoms";
import { SearchFilter } from "./SearchFilter";
import { validateIsEmail } from '../../common/validation';


export class MultiSelect extends Component {
    constructor(props) {
        //console.log('MultiSelect : constructor');
        super(props);

        this.app = window.app;

        this.searching = true; 
        this.noResultsPosition = 0;
        this.changePosition = 0;
        this.prevChangePosition = 0;
        this.minLength = 3;

        this.clickedItem = null;

        this.filter = {            
            items: [],
            lastTextLength: 0,
            prevText: '',
            text: '',
            error: '',
            page: {
                items: [] // page items are for rendering purpose
            },
            showList: false
        }

        this.state = {
            filter: this.filter          
        }        
    }

    changeValue = (e) => {

        e.stopPropagation();
        this.filter.text = e.target.value; // store the value from input field 

        this.updateSearch();
    }

    updateSearch() {

        const filter = this.filter;
        filter.error = ''; // reset the email validation error when user types sth, in case the error was set before when validating incorrect email
        
        // what happens if the user types a new character at position n?
        // 1. if n is the last character and we are searching: keep searching
        // 2. if n is the last character and before we stopped searching (before at some
        // point there was no result and we had set the 'noResultsPosition' value to that
        // position and the 'searching' flag to false):
        // 2.1 if n < noResultsPosition : reset items and start searching
        // 2.2 else: do nothing (keep 'searching' = false)

        // 3. if n is not the last character:
        // 3.1 if n < noResultsPosition && we are not searching: keep like that
        // 3.2 if n < noResultsPosition && we are not searching: reset items and start searching
        // (could be user has made a change in text (like changing a character mistyped at the start)
        // and search now will produce results)
        // 3.3 if n < (text length - 1) and we are searching: reset items and keep searching
        // (e.g. user has changed a character in the middle, so we need to reset the items we search,
        // because the last item ssubset, which was narrowed down, is not valid anymore)


        // get the position of the first character typed that is different: it doesn't have to be 
        // the last character in the string, as user could go back and type sth in the middle: so we should 
        // compare the last string to the new one and detect the position of the first character changed.
        this.changePosition = -1;
        
        for (let i = 0; i < filter.text.length; i++) {
            if (filter.prevText.length > i) {
                if (filter.text.charAt(i) != filter.prevText.charAt(i)) {
                    // this character is different:
                    this.changePosition = i;
                    break;
                }
            }
            else { // we got further than the last text length:
                this.changePosition = i;
                break;
            }
        }

        // store the current text so next time we can compare it again:
        filter.prevText = filter.text;

        // logic switch:
        if (this.changePosition < filter.text.length - 1) { // not the last character, we typed sth in the middle
            if (this.searching) {
                // we need to reset items 
                filter.items = [];
            }
            else { // we are not searching
                if (this.changePosition < this.noResultsPosition) {
                    filter.items = [];
                    this.searching = true;
                }
            }
        }
        else { // the last character has been typed
            if (!this.searching) {
                if (this.changePosition < this.noResultsPosition) {
                    this.searching = true;
                    filter.items = [];
                }
            }
        }

        if (filter.text.length >= this.minLength) {

            if (this.searching) {
                //console.log('MultiSelect. this.searching: ', this.searching);
                //let regexp = new RegExp(e.target.value, 'gi');
                //
                // _selectedItems = items
                //     .filter(item => (regexp.test(item.UserName)))
                //     .slice(0, 1000);

                // originally search in the items injected with props...
                let items = this.props.items;
                // ... but when we already have some previous search results, use them to narrow down the number of items the algorithm will traverse
                // NOTE: this could work bad with search limited to 1000 results in the first run, as later, with more characters,
                // the theoretically correct results that were not in the original 1000 list could be just missing! 
                // So don't limit the number of search items, only limit a size of the secondary list that will be rendered (in page.items)
                if (filter.items && filter.items.length > 0) {
                    items = filter.items;
                }
                ////console.log('this.props.items after: '); //console.log(this.props.items);
                ////console.log('items: '); //console.log(items);
                ////console.log('filter.items: '); //console.log(filter.items);
                try {
                    filter.items = items
                        .filter(item => item.U.toLocaleLowerCase()
                            .search(filter.text.toLocaleLowerCase()) > -1)
                }
                catch {
                    // wrong search string: mark the input field in red?
                }
                filter.page.items = filter.items.slice(0, 100); // page items are for rendering purpose
                ////console.log('searching: filter.items.length: ' + filter.items.length);
                // when there's no results, set the 'searching' flag  to false and store the current text length 
                // as 'noResultsPosition', so when we start delete characters, or we change a character BEFORE
                // this breakpoint position, we can switch the 'searching' flag back when we get below this treshold
                if (!filter.items || filter.items.length == 0) {
                    this.searching = false;
                    this.noResultsPosition = filter.text.length;
                    ////console.log('disable searching: noResultsPosition: ' + this.noResultsPosition);
                }
            }
            else {
                ////console.log('searching disabled: noResultsPosition: ' + this.noResultsPosition);
                // reset items, so next time we will search again in the full list
                filter.items = [];
            }
            filter.showList = true; // make the selector list visible again
        }
        else {
            filter.showList = false; // hide the selector if less characters
        }
        
        this.setState({
            filter: this.filter
        });
    }

    keyPress = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            this.selectValue(e)
        }
    }

    selectValueFromInput = () => {
        const e = {
            isCustom: true,
            target: {
                value: this.filter.text
            }}

        this.selectValue(e);
    }

    selectValue = (e, item) => {

        if (e && !e.isCustom) e.stopPropagation();
        //console.log('MultiSelect : selectValue');
        const filter = this.filter;
        const { onChange } = this.props;
        const error = validateIsEmail(e.target.value) ? '' : 'Email is invalid';

        if (item) {
            filter.text = '';
            filter.error = '';            
            onChange(e, item);
        }
        else {
            if (error) {                
                filter.error = error;
            }
            else {
                filter.error = '';
                filter.text = '';
                const item = { U: e.target.value, F: '', IsGuest: true, UserIdFather: this.app.user.Id, selected: false, excluded: true }
                onChange(e, item);
            }
        }        
        // reset the selector list
        filter.showList = false;
        filter.items = [];
        filter.page.items = [];
        // set the updated state
        this.setState({ filter });
    };

    onMouseDown = (e, item) => {

        // a trick with 'onmousedown' to keep reference to the item 'clicked' in the multiselector list
        // (otherwise the input 'onblur' will get executed and list will be hidden before item 'onclick' is captured).
        // then, onblur will call 'selectValue(e, this.clickedItem)' and item will be selected
        this.clickedItem = item;
    }

    onBlur = (e) => {

        const filter = this.filter;
        filter.showList = false;
        if (this.clickedItem) {
            this.selectValue(e, this.clickedItem)
            this.clickedItem = null;
        }
        this.setState({ filter });
    }

    onFocus = (e) => {

        this.clickedItem = null; // extra step to reset the reference that gets set on item's mouse down event (just in case)
        const filter = this.filter;
        //if (this.shouldResetSearchItems)
            this.updateSearch();

        //if (filter.text.length >= this.minLength) {
        //    filter.showList = true;
        //    this.setState({ filter });
        //}
    }
    
    render() {
        
        const filter = this.filter;
        
        return (
            <div className='multi-select'>
                
                <div className='filters' onBlur={this.onBlur}
                        onFocus={this.onFocus}>
                    <div className='filter search multi' >
                        
                        {/*<div className={error ? `text-error` : ``}>
                            <span>{error}</span>
                        </div>*/}
                        <Form.Control
                            className={filter.error ? `text-input error` : `text-input`}
                            type="email"
                            value={filter.text}
                            placeholder={this.app.R.SearchOrAdd}
                            onChange={this.changeValue}
                            onKeyPress={this.keyPress}
                            
                        >
                        </Form.Control>
                        {(filter.page.items.length > 0 && filter.showList) ? (
                            <div className="multi-select-list mod">
                                {filter.page.items.map((item, key) => (
                                    <div
                                        key={`multi-select-${key}`}
                                        className="multi-select-item"
                                        onMouseDown={(e) => this.onMouseDown(e, item)}
                                        onClick={(e) => this.selectValue(e, item)}
                                    >
                                        <span>{item.U}</span>
                                    </div>
                                ))}
                            </div>
                        ) : ((filter.text.length >= this.minLength && filter.showList) && (
                            <div className="multi-select-list mod">
                                <div className="multi-select-item">
                                    <span>{this.app.R.NoItemsFound}</span>
                                </div>
                            </div>
                        ))}
                        <div className='search-button' onClick={this.selectValueFromInput} >
                            <SmallButton contentClass='icon-add' />
                        </div>
                    </div>
                    
                </div>


            </div>
        );
    }
}

//{
//    selectedItems.length ? (
//        <div className="multi-select-list">
//            {selectedItems.map((item, key) => (
//                <div
//                    key={`multi-select-${key}`}
//                    className="multi-select-item"
//                    onClick={(e) => this.selectValue(e, item)}
//                >
//                    <span>{item.U}</span>
//                </div>
//            ))}
//        </div>
//    ) : null
//}
