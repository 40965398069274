
export const api = (config = window.app.store.state.options.appSettings) => ({
    auth: {
        login: () => `${config.Api.AuthorizationServerUrl + config.Api.AuthorizationServerApiPath}/account/login2`,
        preauthorize: () => `${config.Api.AuthorizationServerUrl + config.Api.AuthorizationServerApiPath}/oauth2/preauthorize`,
        authorize: () => `${config.Api.AuthorizationServerUrl + config.Api.AuthorizationServerApiPath}/oauth2/authorize2`,
        token: () => `${config.Api.AuthorizationServerUrl + config.Api.AuthorizationServerApiPath}/oauth2/token`,
        captcha: () => `${config.Api.AuthorizationServerUrl + config.Api.AuthorizationServerApiPath}/oauth2/getcaptcharest`
    },
    user: {
        isValid: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/isvalid/`,
        config: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/config/`,
        details: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/details/`,
        groups: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/groups/`,
        policies: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/policies/`, // user policies have Favourite + Counter properties returned
        policy: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/policy/`,// user policy has Favourite + Counter properties returned
        classifierpolicies: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/classifierpolicies/`,
        invitation: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/invitation/`,
        saveConfiguration: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/saveconfiguration/`,
        saveUserDetails: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/saveuserdetails/`,
    },
    organization: {        
        users: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/organization/users/`
    },
    document: {
        getProtected: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/document/protected/`,
        accessed: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/document/accessed/`,
        userWarnings: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/document/userwarnings/`,
        info: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/document/info/`,
        lock: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/document/lock/`,
        tracking: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/document/tracking/`,
        warnings: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/document/warnings/`,
    },
    policy: {
        getPolicy: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/policy/`, // for a general use policies, e.g. from admin, without Favourite + Counter properties returned
        editPolicy: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/policy/edit/`,
        removedPolicy: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/policy/removed/`,
        list: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/policy/list/`,
        remove: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/policy/remove/`,
        restore: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/policy/recover/`,
        customPolicyGuid: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/custom/policy/guid/`,
        setFavouritePolicy: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/policy/setfavourite/`,    
        updatePermissions: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/policy/updatepermissions/`,    
        userPermissions: () => `${config.Api.DataServerUrl + config.Api.DataServerApiPath}/api/user/permissions/`,        
    },
    file: {
        protect: () => `${config.Api.FileServerUrl + config.Api.FileServerApiPath}/api/processtream/`,
        protectExternal: () => `${config.Api.FileServerUrl + config.Api.FileServerApiPath}/api/process/external/`, // not used for now
        testProtection: () => `${config.Api.FileServerUrl + config.Api.FileServerApiPath}/testprotection/`,
        unprotect: () => `${config.Api.FileServerUrl + config.Api.FileServerApiPath}/api/processtream/`,        
        unprotectExternal: () => `${config.Api.FileServerUrl + config.Api.FileServerApiPath}/api/process/external/`, // not used for now
        download: () => `${config.Api.FileServerUrl + config.Api.FileServerApiPath}/api/download/`,
        isauthorized: () => `${config.Api.FileServerUrl + config.Api.FileServerApiPath}/api/isauthorized/`,
        notifyUpdatedPolicy: () => `${config.Api.FileServerUrl + config.Api.FileServerApiPath}/api/notifyupdatedpolicy/`
    }
});