import React, { Component } from 'react';
import { RadioButtonsList } from "./RadioButtonsList";
import { RequestType } from "../../common/constants";


export class DocumentTypePanel extends Component {
    constructor(props) {
        super(props);

        this.app = window.app;

        this.items = null;
        if (this.props.inSelectedDocumentMode)
            this.items = [
                { id: RequestType.DocumentInfo, label: this.app.R.DocumentGeneral },
                { id: RequestType.DocumentTracking, label: this.app.R.DocumentAccessed },
                { id: RequestType.DocumentWarnings, label: this.app.R.DocumentWarnings }
                               
            ]
        else
            this.items = [
            { id: RequestType.DocumentsProtected, label: this.app.R.DocumentProtected },
            { id: RequestType.DocumentsAccessed, label: this.app.R.DocumentAccessed },
            { id: RequestType.DocumentsWarnings, label: this.app.R.DocumentWarnings }
        ]
    }

    onChange = (item) => { 

        if (item.id !== this.props.documentType) {
            this.props.onChange(item.id);
        }
    }

    render() {

        return (
            <RadioButtonsList
                items={this.items}
                onChange={this.onChange}
                selectedItemId={this.props.documentType}
            />
        );
    }
}