import React, { Component } from 'react';

import {
    findObjectWithId,
    onReturnBack,
    resetAppSession,
    setLanguage,
    saveSession,
    updateAppHistory
} from '../managers/appManager';

import { saveUserConfiguration, saveUserDetails } from '../managers/userManager'; 
import { RadioButtonsList, SelectList } from '../components/organisms';
import { Checkbox, Radio, SmallButton } from '../components/atoms';
import { signOut } from '../managers/authManager';
import { PageSizeOptions } from '../common/constants';
import { Button } from 'react-bootstrap';

export class Identity extends Component {
    displayName = Identity.name;

    constructor(props) {
        super(props);
        this.app = window.app;
        this.autoSendInvitationOption = { type: 'autosend', error: false, selected: false };
        const languageId = (this.app.settings.language && this.app.settings.language.id) ? this.app.settings.language.id : 'en';
        this.state = {
            // synchronized with the global store
            options: this.app.store.state.options,
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,
            // local state property
            languageId: languageId      
        };

    }

    componentDidMount() {

        this.app.locator.optionsPage = this;
        this.app.store.subscribe({ property: 'options', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });
    }

    componentWillUnmount() {

        this.app.locator.optionsPage = null;
        this.app.store.unsubscribe({ property: 'options', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
    }

    //static setLanguage(code) {        
    //    window.sealpath.loadResources(code);
        
    //    //this.setState({
    //      //currentCount: this.state.currentCount + 1
    //    //});
    //}

    selectLanguage = (item) => {

        const languageId = item.id;

        this.setState({
            languageId: languageId,
        })
        // First (UI)
        // set language (id: languageId) and force loading (true)
        setLanguage(languageId, true);

        // Second:
        // save the new user language in the DB
        this.app.store.state.userDetails.Language = languageId;
        saveUserDetails();
    }

    onOptionChange = (e, component) => {

        if (component.props.model) {
            const model = component.props.model;
            if (model.type === 'autosend') {
                this.app.store.setOption({ key: 'AutoSendInvitation', value: model.selected });
                // TO DO: the following call should be moved to store.setOption: when option is persistent and has a key: push it to the server -->
                saveUserConfiguration('AutoSendInvitation', model.selected); // TO DO: this can be hacked on client: server validation is needed when user sends invitations!
            }           
        }
    }

    onPageSizeChange = (e, model) => {

        if (e) e.stopPropagation();
        // get the current configuration value
        const configPageSizeType = this.app.store.getOption({ section: 'page', option: 'size' });
        if (configPageSizeType !== model.id) {
            // reset the loaded documents, which do not download automatically when we re-render the Documents page, they stay there untill filter is changed by user
            this.app.store.state.documents = null;
            this.app.store.state.documentEvents = null;
            // set the new configuration value
            this.app.store.setOption({ section: 'page', option: 'size', value: model.id });            
            // TO DO: the following call should be moved to store.setOption: when option is persistent and has a key: push it to the server -->
            //saveUserConfiguration('WebProtector.WebClient.PageSize', model.selected); --> this key has no support!

            this.app.store.setState({ options: this.app.store.state.options });
            saveSession(this.app); // save the options
        }
    }

    renderBackButton = () => {

        return (
            <SmallButton
                contentClass='icon-back'
                onClick={onReturnBack}
            />
        );
    }

    renderAutoSendInvitationOption() {
        // render this option only if user have permission to send invitations
        // Add  information about this feature:
        // Invitations are sent only if:
        // newUser.OrganizationId == Organization.UNREGISTERED (-1)

        // if new user "Is Ad Ldap User"
        //  Register and send welcome message to internal user or to external-internal user.
        //  It is done even when the "Autosend" invitation is not enabled.
        //  The internal user are registered automatically, no matter if the "Autosend" invitation is enabled or not.

        const model = this.autoSendInvitationOption;      
        model.selected = this.app.store.getOption({ key: 'AutoSendInvitation' });
        //console.log('renderAutoSendInvitationOption: ', model.selected);
        return (
            <div>
                <h4>{this.app.R.Invitations}</h4>
                <p className='medium-width-line'>{this.app.R.AutoSendInvitationsInfo}</p>
                <Checkbox
                    enabled={true}
                    key={'opt-' + 2}
                    label={this.app.R.AutoSendInvitations}
                    className='filterX radioX'
                    model={model}
                    onChange={this.onOptionChange}
                />                
            </div>
        )        
    }

    renderPageSizeOption() {

        // get the configuration value
        const pageSizeOptions = PageSizeOptions;
        const selectedPageSizeType = this.app.store.getOption({ section: 'page', option: 'size' });
        const option = findObjectWithId(pageSizeOptions, selectedPageSizeType);

        return (
            <div>
                <h4>{this.app.R.ResultsPerPageTitle}</h4>
                <br />
                <SelectList
                    title={this.app.R.ResultsPerPage}
                    variant="secondary"
                    items={pageSizeOptions}
                    selectedItem={option}
                    onChange={this.onPageSizeChange}
                    />                
            </div>
        );
    }

    render() {

        const app = window.app;
        const store = app.store;

        updateAppHistory(app, this);

        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)
            return null;

        if (!store.getOption({ key: 'UserConfigurationLoaded' }))
            return null;


        //let languages = app.store.state.options.appSettings.languages.map(item => ({
        //    ...item,
        //    id: item.code,
        //    label: item.label,
        //}));
        const languages = app.store.state.options.appSettings.languages;
        // button aligned to the right
        //<Button variant='primary' className='login-button align-right inline clickable' onClick={(e) => { signOut(app) }}>
        //    {app.R.SignOut}
        //</Button>

        return (
            <div>
                <div className='label'>
                    {this.renderBackButton()}
                    <span className='label extra-space'></span>
                    <h3 className='label'>{app.user.U}</h3>
                </div>                
                <br /><br />
                
                <div className='margin-left'>
                    <Button variant='primary' className='clickable' onClick={(e) => { resetAppSession(app); signOut(app) }}>
                        {app.R.SignOut}
                    </Button>

                    <br /><br />
                    <h3 className='label'>{this.app.R.UserOptions}</h3>
                    <h4>{this.app.R.Language}</h4>
                    <RadioButtonsList
                        selectedItemId={this.state.languageId}
                        items={languages} onChange={this.selectLanguage} />
                    <br />
                    {this.renderAutoSendInvitationOption()}
                    <br />
                    {this.renderPageSizeOption()}
                </div>
            </div>
        );
    }
}
