import { convertProtectionTaskParamsToFormData, getKeyByValue } from './taskManager';
import { addUsersToPolicy, editPolicy, isSimplePolicyExpired } from './policyManager';
import { onGetLocalDocumentInfoCompleted } from './documentManager'; 
import { api } from '../common/api';
import { ResultCode, RequestType, TaskState, TaskSubState } from '../common/constants';
import { reAuth } from '../components/GooglePicker';

//--------------------------------------------------------------------------------------------
// the tool is a UI tool component with an action property defined (like 'protect' or 'unprotect')
export const onFilesDrop = (app, acceptedFiles, rejectedFiles, tool, externalFiles, provider) => {

    console.log('onFilesDrop. accepted files: ', acceptedFiles);
    console.log('onFilesDrop. external files: ', externalFiles);
    const state = app.store.state;

    state.delayedTasks = []; // reset the task list used for the support of multiple files in 'Custom Protection' and 'Add' Users operations

    //-------------------------------------------------------------------------------------
    // do not put any limits on how many files can be pushed
    acceptedFiles.map(file => {        
        var task = { file, tool };
        //var task = { file: file, app: app, type: type, tool: tool};        
        prepareFileTaskForLaunch(app, task);  
        pushFileTask(task);
        return true;
    });
    //-------------------------------------------------------------------------------------
    // do not put any limits on how many files can be pushed
    if (externalFiles) {
        externalFiles.map(externalFile => {
            var task = { provider, externalFile, tool };
            //var task = { file: file, app: app, type: type, tool: tool};        
            prepareFileTaskForLaunch(app, task);
            pushFileTask(task);
            return true;
        });
    }
    //-------------------------------------------------------------------------------------

    // in case of 'CustomProtection' and 'AddUsers' the launch of tasks is delayed until the 'Edit Policy' form is completed    
    if (state.delayedTasks.length > 0) {
        // get the first task to see what type is it
        const task = state.delayedTasks[0];
        if (task.type === RequestType.CustomProtection) {            
            editPolicy(null, app, /* full policy */ null, /* simple policy */ null, RequestType.CustomProtection, /* is new policy */ false); // we don't have an event and a policy, only the task/tasks of 'CustomProtection' type
        }
        else if (task.type === RequestType.AddUsers) {            
            if (state.delayedTasks.length > 1) {
                // TO DO: maximum 1 file for the Add Users: show an alert!
                const errorObject = {
                    title: app.R.AddUsers,
                    message1: app.R.OnlyOneDocAllowed
                };
                app.setState({ showError: true, errorTask: null, errorObject });
            }
            else {
                addUsersToPolicy(null, null, RequestType.AddUsers, false); // we don't have an event and a policy (yet), only the task/tasks of 'AddUsers' type
            }            
        }
        else if (task.type === RequestType.DocumentInfo) {
            if (state.delayedTasks.length > 1) {
                // TO DO: maximum 1 file for the Document Info: show an alert!
                const errorObject = {
                    title: app.R.GetDocumentInformation,
                    message1: app.R.OnlyOneDocAllowed
                };
                app.setState({ showError: true, errorTask: null, errorObject });
            }
            else {
                // push the task so it gets added to the notification UI
                window.app.taskProcessor.push(task);
                // go to the local doc info method
                getLocalDocumentInfo(task);
            }
        }
    }
}


export const convertToolTypeToRequestType = (task) => {

    console.log('convertStringTypeToRequestType')

    // the tool is a UI tool component with a type like 'protect' or 'unprotect'
    let tool = task.tool;
    //console.log('convertStringTypeToRequestType: tool', tool);
    //if (tool) console.log('convertStringTypeToRequestType: tool.policy', tool.policy);

    // convert the 'tool.action' (which is a string defined for tools in the appsettings.json configuration) to the RequestType (which is an integer used internally)
    if (tool) {
        if (tool.action) {
            if (tool.action === "Protect") {
                task.type = RequestType.Protect;
            }
            if (tool.action === "ProtectExternal") {
                task.type = RequestType.ProtectExternal;
            }
            else if (tool.action === "Unprotect") {
                task.type = RequestType.Unprotect;
            }
            else if (tool.action === "UnprotectExternal") {
                task.type = RequestType.UnprotectExternal;
            }
            else if (tool.action === "DocumentInfo") {
                task.type = RequestType.DocumentInfo;
            }
            else if (tool.action === "DocumentInfoExternal") {
                task.type = RequestType.DocumentInfoExternal;
            }
            else if (tool.action === "CustomProtection") {
                task.type = RequestType.CustomProtection;
            }
            else if (tool.action === "CustomProtectionExternal") {
                task.type = RequestType.CustomProtectionExternal;
            }
            else if (tool.action === "AddUsers") {
                task.type = RequestType.AddUsers;
            }
            else {
                task.type = tool.action; // this covers all other cases, when actionType already is equal to RequestType.X (an integer value)
            }
        }
    }
}



export const prepareFileTaskForLaunch = (app, task) => {

    //console.log('initializeFileTask');

    convertToolTypeToRequestType(task);

    // TO DO: convert options to a class
    // create a default options object for this task
    var options = {
        AutoFileDownload: false,
        DeleteFileAfterDownload: false,
        Operation: 'None'
    };
    task.options = options;

    task.followed = true; // will follow this task on the UI notification list (actually it means it will render it and update its UI state)
    task.state = TaskState.None; // ???  .completed = false;
    task.substate = TaskSubState.None; // ???  .completed = false;
    // when task is 'enqueued', it will not be launched to server immediately, instead it will be launched after other enqueued tasks. 
    task.enqueued = true;

    // the file name is used by UI
    task.fileName = task.file ?
        task.file.name
        :
        task.externalFile ?
            task.externalFile.GoogleDriveFile ?
                task.externalFile.GoogleDriveFile.name
                :
                ''
            :
            '';
    
    // FOR FILE INFO:
    if (task.type === RequestType.DocumentInfo) {
        // skip the 'uploading' state and set the 'processing' one directly, as we gonna process it locally
        task.substate = TaskSubState.DocumentProcessing;
        // do not push the 'isauthorized' call: set the task UI and jump to the callback directly,
        //task.title = app.R.DocumentInfo + ': ' + task.file.name; // for the UI
        //task.info = app.R.GettingDocumentInfo; // for the UI             
    }
    // FOR ADD USERS:
    //else if (task.type == RequestType.AddUsers) {
    //    // skip the 'uploading' state and set the 'processing' one directly, as we gonna process it locally
    //    task.state = TaskState.DocumentProcessing;
    //    // do not push the 'isauthorized' call: set the task UI and jump to the callback directly,
    //    task.title = app.R.AddUsers + ': ' + task.file.name; // for the UI
    //    task.info = app.R.GettingDocumentInfoXXXX; // for the UI    
    //}
    // FOR OTHER TYPES OF FILE PROCESSING:
    else {
        // the rest of the file requests are processed on the remote file server:
        // asign the callback for the initial check to the 'isauthorized' endpoint, before
        // we send a file (to avoid sending files when token is not valid anymore).
        task.onComplete = onIsAuthorizedCheckCompleted;

        // set the special bool, later used for updating the 'task.state' and UI
        //task.isAuthorizing = true;

        // assign type name before creating FormData so we can pass this param to file streaming endpoint (there's only one for protect and unprotect so the type is needed to be specified in the request) 
        task.options.Operation = getKeyByValue(RequestType, task.type); // convert type id (int) to type name (string) (e.g. 'Protect', 'Unprotect', 'Download')        
        task.options.DeleteFileAfterDownload = app.store.state.options.appSettings.Server.DeleteFileAfterDownload; // set the auto file downoload flag according to the client configuration
        task.options.AutoFileDownload = app.store.state.options.appSettings.Server.AutoFileDownload; // set the auto file downoload flag according to the client configuration

        //console.log('initializeFileTask: task', task);

        task.formData = convertProtectionTaskParamsToFormData(task); // to send the file AND additional fields in the body we use the FormData object because it can accept a file as one of fields
        
        task.ajax = { // create the request data object
            url: api().file.isauthorized(),
            data: null // do not assign form data yet, make a token validaty check before uploading (otherwise a file would be sent to the server, and we don't want to do it yet)
        };
    }
}

// 'pushFileTask' function either:
// 1. adds the task to delayed tasks(tasks that frst need a result of other operation or request)
// 2. or pushes the task to the task processor list 
export const pushFileTask = (task) => {

    //-------------------------------------------------------------------------------------
    // code variant with a max number of files (not used now)
    //var maxFileTasks = 5; // get it from options
    //var optionsMaxFileTasks = app.store.state.options.appSettings.Local.MaxFileTasks;
    //if (optionsMaxFileTasks) {
    //    maxFileTasks = optionsMaxFileTasks;
    //}
    //let shouldShowFilesLimitWarning = false;
    //acceptedFiles.map(file => {
    //    if (app.fileTaskCount < maxFileTasks) {
    //        var task = { file, options, tool };
    //        //var task = { file: file, app: app, type: type, tool: tool};        
    //        initializeFileTask(app, task);  
    //        pushFileTask(task);
    //        app.fileTaskCount++;
    //    }
    //    else {
    //        shouldShowFilesLimitWarning = true;
    //    }
    //});
    //if (shouldShowFilesLimitWarning) {
    //    // TO DO:
    //    // show warning dialog
    //}

    console.log('pushFileTask: task: ', task);

    // Exception for the 'custom protection' and 'add users': do not launch the task yet - we need to go to the policy editor first
    // The 'DocumentInfo' is added to the list so we can verify that there's no  
    // more than 1 file dragged, to show an alert instead of pushing the task
    if (task.type === RequestType.CustomProtection
        || task.type === RequestType.CustomProtectionExternal
        || task.type === RequestType.AddUsers
        || task.type === RequestType.DocumentInfo) {
        //task.ajax.url = api().file.protect();
        //task.title = app.R.Protect + ': ' + task.file.name; // for the UI
        //task.info = app.R.Protecting; // for the UI
        // go to custom policy edition page
        //policyManager.selectProtection(task);

        // do not call edit policy yet: initialize and recollect all tasks, then launch it from the parent method ('onFilesDrop') :
        //editPolicy(null, null, task); // we don't have an event and a policy, only the task. later we will continue with it when custom policy is defined
        window.app.store.state.delayedTasks.push(task);
    }
    else {
        window.app.taskProcessor.push(task);
    }
}

export const onIsAuthorizedCheckCompleted = (task) => {

    // set the bool back to false
    //task.isAuthorizing = false;

    // reset this flag before relaunching the second phase request
    task.state = TaskState.None; //??? completed = false;
    task.substate = TaskSubState.None; //??? completed = false;
    
    const app = window.app;
    const auth = app.auth;
    const policy = task.tool ? (task.tool.policy ? task.tool.policy : null) : null;

    // get the server flags from the response: when true, they will have a higher priority and will
    // overwrite the local settings, so the task UI can behave correctly when file processing result is received
    if (task.result.autoFileDownload)
        app.store.state.options.appSettings.Server.AutoFileDownload = true;
    if (task.result.deleteFileAfterDownload)
        app.store.state.options.appSettings.Server.DeleteFileAfterDownload = true;

    //console.log('onIsAuthorizedCheckCompleted. task.result.autoFileDownload = ' + task.result.autoFileDownload + ', task.result.deleteFileAfterDownload = ' + task.result.deleteFileAfterDownload);
    // we got here because the user is authorized: reset the token attempts counter (used to avoid entering
    // a loop if we receive 401 when tokens have wrong signature - if the client has the refresh token, it would keep pushing the refresh token forever)
    // TODO :find other way to detect it and control the behavior? (as long as there is sth more than just 401)
    // maybe after the first exchange, when the response is NOT OK, delete the existing refresh token? 
    auth.tokenAttempts = 0;

    // now we can assign the form data to the ajax request payload so the file will be sent
    task.ajax.data = task.formData;

    // asign the callbacks for file processing
    task.onComplete = onFileProcessed;
    task.onError = onFileProcessingError;

    // set the endpoint
    if (task.type === RequestType.Protect || task.type === RequestType.CustomProtection) {  
        
        task.ajax.url = api().file.protect();
    }
    else if (task.type === RequestType.ProtectExternal || task.type === RequestType.CustomProtectionExternal) {
        
        task.ajax.url = api().file.protectExternal();
    }
    else if (task.type === RequestType.Unprotect) {
        
        task.ajax.url = api().file.unprotect();
    }
    else if (task.type === RequestType.UnprotectExternal) {
        
        task.ajax.url = api().file.unprotectExternal();
    }
    else if (task.type === RequestType.TestProtection) {

        task.ajax.url = api().file.testProtection(); 
    }


    // set the task UI and state
    if (task.type === RequestType.Protect || task.type === RequestType.ProtectExternal) {

        if (!isSimplePolicyExpired(policy)) { // --> TO DO: isExpired always returns false - the simple model does not contain date! add expiration date support to the simple models for improved UI
            //console.log('onIsAuthorizedCheckCompleted: isSimplePolicyExpired = false. task: ', task);
            //task.ajax.url = api().file.protect();
            //task.title = app.R.Protect + ': ' + task.file.name; // for the UI
            //task.info = app.R.Protecting; // for the UI
        }
        else {
            //console.log('onIsAuthorizedCheckCompleted: isSimplePolicyExpired = true. task: ', task);
            // we want the task status to be rendered in red and with the result code for expired policy            
            //task.title = app.R.Protect + ': ' + task.file.name; // for the UI
            task.hasError = true; 
            task.error = { error_code: -7, error_description: "ErrorExpirationDatePolicyNotValid" };

            // reset the state so the subscribers (Tasks.js) can re-render the tasks 
            app.store.setState({ tasks: app.taskProcessor.tasks });
            return;
        }
    }
    else if (task.type === RequestType.CustomProtection || task.type === RequestType.CustomProtectionExternal) {

        //task.ajax.url = api().file.protect();
        //task.title = app.R.Protect + ': ' + task.file.name; // for the UI
        //task.info = app.R.Protecting; // for the UI 

        task.ajax.data = convertProtectionTaskParamsToFormData(task);
    }
    else if (task.type === RequestType.TestProtection) {

        //task.ajax.url = api().file.testprotection();
        //task.title = app.R.Protect + ': ' + task.file.name; // for the UI
        //task.info = app.R.Protecting; // for the UI 
    }
    else if (task.type === RequestType.Unprotect) { // it was dropped over 'Unprotect' tool

        //task.ajax.url = api().file.unprotect();
        //task.title = app.R.Unprotect + ': ' + task.file.name; // for the UI
        //task.info = app.R.Unprotecting; // for the UI
    }
    //console.log('onIsAuthorizedCheckCompleted: pushing task: ', task);

    // launch the task using the 'immediatelly' flag so it doesn't have to wait for a new turn
    // (the flag is added especially for the authorization "pre-request")
    window.app.taskProcessor.push(task, /* immediately */ true);

    // new 
}

function onFileProcessingError(task) {

    const app = window.app;

    console.log('onFileProcessingError...', task);
    if (task.type === RequestType.ProtectExternal || task.type === RequestType.CustomProtectionExternal || task.type === RequestType.UnprotectExternal) {

        if (task.error && task.error_code) {
            console.log('failed task result: ', task.error);

            // check if is error type is the provider 401 code (needs re-auth)
            if (task.error_code === ResultCode.GoogleDriveAuthNeeded) {
                console.log('error_code == ResultCode.GoogleDriveAuthNeeded !');
            }
        }
        // run it always! (during development)
        let provider = task.provider;
        if (provider && provider.id === 'googledrive') {
            reAuth(response => {
                if (response.access_token) {
                    //this.createPicker(response.access_token)
                    //this.createPicker(response)
                    app.auth.tokens.googleDriveAccessToken = response.access_token;
                    console.log('GoogleDrive auth response: ', response);
                    console.log('access token: ', response.access_token);

                    if (task.externalFile) {
                        task.externalFile.AccessToken = response.access_token;

                    }
                    //task.title = app.R.Protect + ': ' + task.file.name; // for the UI
                    task.info = app.R.Protecting; // for the UI 

                    task.ajax.data = convertProtectionTaskParamsToFormData(task);

                    // re-launch the task again
                    //pushTask(task2); //--> this can enter a loop if server continues to respond with error

                } else {
                    // onAuthFailed(response);
                    console.log('google auth failed: ', response);
                    // pop-up could be blocked!
                    // TODO: should inform about it?

                }
            },
                provider.clientId,
                provider.scope,
                false
            );
        }
    }
}

export const onFileProcessed = (task) => {

    let app = window.app;

    // decrease the number of file tasks being currently processed
    app.fileTaskCount--;

    // call the processor so it removes this task from queue and starts processing next file
    //app.taskProcessor.complete(task);

    let result = task.result;
    let nextCall = null;
    const autoFileDownload = app.store.state.options.appSettings.Server.AutoFileDownload;
    const autoFileDeleteFromServer = app.store.state.options.appSettings.Server.DeleteFileAfterDownload;
    // has error? // I need to decide finally if error goes into data object or instead of data
    // --> in case it goes instead, it would be: 'result.error'
    if (task.hasError) {
        if (task.status === 401) {
            // this is an auth error: we should wait for token can be refreshed, otherwise the login will be shown
            // show 'authorizing' text on the task notification
            // ...
        }
        else {
            // this is other kind of error: 
            //format some user friendly text on the task notification:
            // ...
            // 
            app.taskProcessor.onTaskError(task);
        }
    }
    else {    

        let fileName = '';
        if (result) fileName = result.fileName; // initial value, change it if we had received a filestream to name from "content-disposition" header

        if (autoFileDownload && (task.type === RequestType.Protect || task.type === RequestType.CustomProtection || task.type === RequestType.Unprotect)) {
            // we do not wait for user click in this mode: the server directly sends back the processed file
            ////console.log(result);
            //console.log(' *** *** *** task.file: ', task.file);

            //function unicodeUnEscape(string) {
            //    return string.replace(/%u([\dA-Z]{4})|%([\dA-Z]{2})/g, function (_, m1, m2) {
            //        return String.fromCharCode(parseInt("0x" + (m1 || m2)));
            //    })
            //}

            // get rid of numbers from "txt (1)(2)" kind of extensions
            function cleanVersionNumbers(string) {

                let parts = string.split('(');
                if (parts && parts.length > 0) {
                    //console.log('cleanVersionNumbers. string before: ' + string);
                    string = parts[0];
                    string = string.trim();
                    //console.log('cleanVersionNumbers. string after: ' + string);
                    return string;
                }
                else {
                    string = string.trim();
                    return string;
                }
            }

            let extensionA = '';
            let extensionB = ''; 

            if (task.response && task.response.headers) {
                let disposition = task.response.headers["content-disposition"];
                if (disposition) {
                    let parts = disposition.split(";");
                    if (parts) {
                        let found = false;
                        // first run: try to find the the encoded UTF-8 filename                        
                        let searchText = "filename*=UTF-8''";
                        for (let i = 0; i < parts.length; i++) {
                            let part = parts[i];
                            part = part.trim();

                            // When API returens the body with result { fileName = ""}, the fileName keeps all the pictograms.                            
                            // But when it returns the header "filename=*''.....', it has some different value or different
                            // format encoded(?), and the texx doesn't convet back to the original one.
                            // Several tests were done, without results. The closest to the goal is returning the encoded string
                            //  from the API, but it doesn't decode back to not encoded version at all! (maybe there is a format error?)

                            // Observation: In API: the filename=*UTF-8 is created FROM THE regular filename it inserts, not from the original file! 
                            // Maybe we can get the filename from the downloaded blob? 
                            //console.log('retrieved part: ' + part);
                            //if (part.startsWith(searchText)) {
                            //    fileName = part.slice(searchText.length);
                            //    // ALL FAIL!!! (is there a problem with the source text so it generates an error or sth?)
                            //    console.log('decodeURIComponent: ' + decodeURIComponent(fileName));
                            //    console.log('decodeURI: ' + decodeURI(fileName));
                            //    //console.log('unicodeUnEscape: ' + unicodeUnEscape(fileName));
                            //    console.log('unescape: ' + unescape(fileName));
                            //    //fileName = decodeURIComponent(fileName); // gets close, e.g. it converts japanese chars or so, but not pictograms!
                            //    //fileName = decodeURI(fileName);
                            //    //fileName = unicodeUnEscape(fileName);
                            //    fileName = fileName.replace('"', '');
                            //    fileName = fileName.replace('"', '');
                            //    console.log('fileName: ' + fileName);
                            //    found = true;
                            //    break;
                            //}

                            // Given the encoding/decoding issues, a hack is implemented: the original file's name is 
                            // used and only its extensions are modified, by using the returned filename extensions to tell 
                            // if it was protected (extension possibly added) or unprotected (extension possibly removed)
                            if (part.startsWith(searchText)) {
                                let returnedFileName = part.slice(searchText.length);
                                // find the extension
                                let partsA = returnedFileName.split("."); // returned filename
                                let partsB = task.file.name.split("."); // original filename
                                //console.log('partsA: ' + partsA);
                                //console.log('partsB: ' + partsB);
                                if (partsA && partsB) {
                                    extensionA = partsA[partsA.length - 1];
                                    extensionB = partsB[partsB.length - 1];
                                    extensionA = cleanVersionNumbers(extensionA);
                                    extensionB = cleanVersionNumbers(extensionB);
                                    //console.log('extensionA: ', extensionA);
                                    //console.log('extensionB: ', extensionB);

                                    if (extensionA.localeCompare(extensionB) == 0) {                                        
                                        // they have the same extension, keep the original filename
                                        fileName = task.file.name;
                                    }                                
                                    else {                                             
                                        // the extensions are not the same, maybe the .slpth extension is added to the processed file (after protecting).
                                        // check if the [last-1] extension of processed file is the same as the last extension of the original file                                    
                                        if (partsA.length > 1 && partsB.length > 1) {
                                            extensionA = partsA[partsA.length - 2];
                                            extensionB = partsB[partsB.length - 1];
                                            extensionA = cleanVersionNumbers(extensionA);
                                            extensionB = cleanVersionNumbers(extensionB);
                                            //console.log('extensionA: ', extensionA);
                                            //console.log('extensionB: ', extensionB);                                       
                                        
                                            if (extensionA.localeCompare(extensionB) == 0) {  
                                                
                                                fileName = task.file.name + "." + partsA[partsA.length - 1];                                                
                                            }
                                            else {                                                
                                                extensionA = partsA[partsA.length - 1];
                                                extensionB = partsB[partsB.length - 2];
                                                extensionA = cleanVersionNumbers(extensionA);
                                                extensionB = cleanVersionNumbers(extensionB);
                                                //console.log('extensionA: ', extensionA);
                                                //console.log('extensionB: ', extensionB);
                                                
                                                if (extensionA.localeCompare(extensionB) == 0) { 
                                                    // remove the last extension from the original filename:
                                                    // sum up all partsB but the last one, cleaning the last-1, so the new extension doesn't have numbers                                                
                                                    partsB[partsB.length - 2] = cleanVersionNumbers(partsB[partsB.length - 2]);
                                                    fileName = '';
                                                    for (let j = 0; j < partsB.length - 1; j++) {
                                                        fileName += partsB[j];
                                                        if (j < partsB.length - 2)
                                                            fileName += '.';
                                                    }                                                
                                                }
                                            }     
                                        }
                                    }
                                }
                                if (!fileName) fileName = 'unknown';  
                                fileName = fileName.replace('"', '');
                                fileName = fileName.replace('"', '');
                                //console.log('fileName: ' + fileName);
                                found = true;
                                break;
                            }
                        }
                        
                        if (!found) {
                            // second run: try to find the plane text filename (not the encoded UTF-8 one, in case the encoded one doesn't exists)
                            let searchText = "filename=";
                            for (let i = 0; i < parts.length; i++) {
                                let part = parts[i];
                                part = part.trim();                                
                                //console.log('retrieved part: ' + part);
                                if (part.startsWith(searchText)) {
                                    fileName = part.slice(searchText.length);                                    
                                    fileName = fileName.replace('"', '');
                                    fileName = fileName.replace('"', '');
                                    //console.log('fileName: ' + fileName);
                                    break;
                                }                                
                            }
                        }                        
                    }

                    if (!fileName) fileName = 'unknown';                    
                    fileName = fileName.trim();
                    parts = fileName.split('\\');
                    //parts = fileName.split('/');
                    if (parts.length) {
                        fileName = parts[parts.length - 1];
                    }
                    //fileName = '\"' + fileName + '\"'; // add this so files are not saved with underscores!
                    //let filenameRegex = /filename[^; \n]*=(UTF -\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/;
                    ////var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    //var matches = filenameRegex.exec(disposition);
                    //if (matches != null && matches[1]) {
                    //    fileName = matches[1].replace(/['"]/g, '');
                    //}
                }
            }
            //console.log('fileName: ' + fileName);
            task.fileName = fileName;
            processDownloadedBlob(result, fileName);
            //task.info = app.R.Completed; // we don't allow to do it again (these endpoint delete files automatically after downloading)
            task.clickable = false;
        }

        if (task.type === RequestType.ProtectExternal ||
            task.type === RequestType.UnprotectExternal ||
            task.type === RequestType.CustomProtectionExternal) {

            // only when not folder type
            if (task.externalFile && !task.externalFile.IsFolder) {
                // set the filename that later will be used for UI rendering 
                //(the file extension could have changed on processing)             
                task.fileName = result.fileName;
            }
        }
        if (task.type === RequestType.Protect) {

            // prepare the next call
            task.filePath = result.filePath;
            task.fileName = result.fileName;
            if (!autoFileDownload) nextCall = downloadFile;
        }
        if (task.type === RequestType.CustomProtection) {

            // prepare the next call
            task.filePath = result.filePath;
            task.fileName = result.fileName;
            if (!autoFileDownload) nextCall = downloadFile;
        }
        else if (task.type === RequestType.Unprotect) {

            // prepare the next call
            task.filePath = result.filePath;
            task.fileName = result.fileName;
            if (!autoFileDownload) nextCall = downloadFile;
        }        
        else if (task.type === RequestType.Download) {

            //console.log(result);
            processDownloadedBlob(result, task.fileName);
            if (autoFileDeleteFromServer) {
                //task.info = app.R.Downloaded; // we don't allow to download it again (the file on server has been deleted)
                task.substate = TaskSubState.Downloaded; // we don't allow to download it again (the file on server has been deleted)
                task.clickable = false;
            }
            else {
                //task.info = app.R.ClickToDownloadAgain; // we allow to do it again (if we decide otherwise: close this task here!)
                task.substate = TaskSubState.ClickToDownloadAgain;
                task.clickable = true;
            }
        }
    }

    // add-on: update policy counter after protection:
    //updatePolicyCounter(task); --> updating the policy counter is the responsability of File Server.
    // what do we have to do now, is to refresh the policy model, to get the updated counter,
    //or to increase the local counter manually! (only if the operation went ok)

    if (nextCall) nextCall(task);
    
}
// Blob processing
// source: https://github.com/kennethjiang/js-file-download/blob/master/file-download.js

function processDownloadedBlob (data, filename, mime, bom) {

    var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

    // new code that uses modified FileSaver.js
    var blobURL = window.URL.createObjectURL(blob);
    saveAs(blobURL, filename);
    window.URL.revokeObjectURL(blobURL);

    //if (typeof window.navigator.msSaveBlob !== 'undefined') {
    //    // IE workaround for "HTML7007: One or more blob URLs were
    //    // revoked by closing the blob for which they were created.
    //    // These URLs will no longer resolve as the data backing
    //    // the URL has been freed."
    //    window.navigator.msSaveBlob(blob, filename);
    //}    
    //else {
    //    var blobURL = window.URL.createObjectURL(blob);
    //    var tempLink = document.createElement('a');
    //    tempLink.style.display = 'none';
    //    tempLink.href = blobURL;
    //    tempLink.setAttribute('download', filename);

    //    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    //    // target if the browser does not support the HTML5 download attribute.
    //    // This allows you to download files in desktop safari if pop up blocking 
    //    // is enabled.
    //    if (typeof tempLink.download === 'undefined') {
    //        tempLink.setAttribute('target', '_blank');
    //    }
    //    //console.log('tempLink: ');
    //    //console.log(tempLink);
    //    document.body.appendChild(tempLink);
    //    tempLink.click();
    //    document.body.removeChild(tempLink);
    //    window.URL.revokeObjectURL(blobURL);
    //}
}

// maneja el evento drop de informaci�n de archivo
export function getLocalDocumentInfo(task) {
    
    const file = task.file;    

    var reader = new FileReader();    
    reader.onloadend = function (e) {

        var licenseInfo = findLicenseInfo(e.target.result);
        console.log("licenseInfo", licenseInfo);

        if (licenseInfo.guid && licenseInfo.guid !== '') {

            //task.type = RequestType.DocumentInfo; // set new type so we can launch the task and get the doc info from server
            //task.file = null; // we don't want to send the file to server: reset the file so it won't be sent 
            onGetLocalDocumentInfoCompleted(null, task, licenseInfo, null);
            // si se encuantra guid se consulta la informaci�n por ajax
            //$.get(
            //    BASE_URL + 'Document/GetId/' + guid,
            //    {},
            //    function (data) {

            //        if (data.success)
            //            // si se encuantra el GUID registrado, va al detalle del documento
            //            document.location.href = BASE_URL + "Document/Details/" + data.id;
            //        else
            //            // si no se encuentra registrado el GUID, se avisa al usuario
            //            jqAlert(format(R.ElDocConGuidXNoHaSidoProtegido, [data.guid]));

            //    }
            //);
        }
        else {
            console.log('protection info not found. document is not protected!');
            // si no hay GUID se alerta al usuario
            //jqAlert(R.ElDocNoContieneGuid);
            // TO DO : assign the 'not protected' ResultCode and push the task to show notification?            
            notifyDocumentIsNotProtected(task);            
        }        
    };
    // se lanza la lectura del fichero
    reader.readAsText(file, 'utf-8');
}

function findLicenseInfo(fileString) {

    var documentInfo = {
        isGuidFound: false,
        guid: [],
        licenseString: '',
        licenseStringBase64: '',
    };

    //console.log('FileReader.onloadend');

    // a policy GUID can be found in this node:
    //<DESCRIPTOR><OBJECT><ID type="MS-GUID">{C7729FBF - 511F-4C1E-A8E8-E71D8BAC18C7}</ID><NAME>LCID 1033:NAME New protection:DESCRIPTION  ;
    //</NAME></OBJECT></DESCRIPTOR>

    // a document GUID and some extra info, like OrgId (SkuID) can be found here:
    //<WORK>
    //    <OBJECT type=".txt">
    //        <ID type="MS-GUID">{7aa9132c-171e-4dc1-91bd-e0cbc4642853}</ID>
    //        <NAME>errro IO - dir not empty - Copy.txt</NAME>
    //    </OBJECT>
    //    <METADATA>
    //        <OWNER><OBJECT><ID type="Unspecified" /><NAME>fileserver@lab.com</NAME></OBJECT></OWNER>
    //        <SKU type="SkuID">0001-0-00000000</SKU>
    //    </METADATA>
    //</WORK>

    //// OWNER
    //// the owner can be found here (format is the same for the old PL and the new MISPC PL):
    //// <OWNER><OBJECT><ID type="Unspecified" /><NAME>jonathan.teran@sealpath.com</NAME></OBJECT></OWNER>
    //var r1 = /(?<=<OWNER><OBJECT><ID type=".[^\"]*" \/><NAME>).{1,256}(?=<\/NAME>)/i;
    //var ownerSearch = r1.exec(this.result);
    //var owner = null;
    //if (ownerSearch && ownerSearch.length > 0) {
    //    owner = ownerSearch[0];
    //}
    //console.log("OWNER: ", owner);
    documentInfo.licenseString = findPublishingLicense(fileString);
    documentInfo.licenseStringBase64 = convertUtf8TextToBase64(documentInfo.licenseString);
    documentInfo.guid = findDocumentGuidInPublishingLicense(documentInfo.licenseString);
    if (documentInfo.guid && documentInfo.guid !== '') {
        documentInfo.isGuidFound = true;
    }

    //if (!documentInfo.guid || documentInfo.guid.length === 0) {
        return documentInfo;
    //}
    
    
    //var OrgId = null;
    //// busca con regEx un SKU en el XML para obtener la organizaci�n due�a
    ////<SKU type="SkuID">0008-0-00000002</SKU>
    //// new MSIPC format:
    ////<AUTHENTICATEDDATA id="APPSPECIFIC" name="SkuId">0001-0-00000003</AUTHENTICATEDDATA>

    //var r2 = /<SKU type="SkuID">([0-9]{4}-[0-9]{1}-[0-9]{8})<\/SKU>/i;

    //var SKID = r2.exec(this.result);

    //if (!SKID || SKID.length === 0) {
    //    //if (isPdf) {
    //    // pdf format (supposed to be, new tests needed)
    //    r2 = /SkuId\(([0-9]{4}-[0-9]{1}-[0-9]{8})\)/i;
    //    //}
    //    SKID = r2.exec(this.result);
    //}

    //if (!SKID || SKID.length === 0) {
    //    // any id query: works ok -->
    //    //r2 = /<AUTHENTICATEDDATA id=".[^\"]*" name="SkuId">([0-9]{4}-[0-9]{1}-[0-9]{8})<\/AUTHENTICATEDDATA>/i;
    //    // APPSPECIFIC id only (more specific query): works ok -->
    //    r2 = /<AUTHENTICATEDDATA id="APPSPECIFIC" name="SkuId">([0-9]{4}-[0-9]{1}-[0-9]{8})<\/AUTHENTICATEDDATA>/i;
    //    // simplified query: works ok -->
    //    //r2 = /name="SkuId">([0-9]{4}-[0-9]{1}-[0-9]{8})/i;
    //    SKID = r2.exec(this.result);
    //}

    //if (!SKID || SKID.length === 0) {
    //    // TO DO : assign the 'error reading document info' ResultCode and push the task to show notification?
    //    //return;                
    //}
    //else if (SKID && SKID.length > 0) {
    //    var stringSplit = SKID[1].split("-");
    //    if (stringSplit.length === 3) {
    //        OrgId = stringSplit[2];
    //    }
    //}
    ////console.log('getLocalDocumentInfo . SKID: ', SKID);
    //console.log('OrgId: ' + OrgId);
    //// TO DO: find a good logic for custom protections when in local-doc-info mode
    //// ERROR?: guid is a document guid, not a policy guid?
    ////if (guid !== app.store.state.customPolicyGuid) {

    ////    //task.file = null; // we don't want to send the file to server: reset the file so it won't be sent 
    ////    // this is a custom protection: get info from server, later parse it for the correct UI output                
    ////    //app.taskProcessor.push(task);
    ////    //return;
    ////}
    ////else {
    ////    //console.log('getLocalDocumentInfo . not a custom protection');
    ////}

    //if (organizationId !== OrgId) {
    //    //jqAlert(R.ElDocOtraOrganizacion);
    //    // TO DO: and if user have rights, even if the doc is from another org?
    //    // 1. if user is the owner - accept it and render the info
    //    // 2. user could be a guest and have that org's policies shared - 
    //    //but this we cannot tell unless we obtain the predefined policy - 
    //    //it won't be available for the custom policies
    //    // TO DO:  check it
    //    //return;
    //}
}

function findPublishingLicense(documentString) {

    if (documentString === undefined || documentString === null) {
        return '';
    }
    const startTag = '<XrML'; // it is more safe to search for <XrML version...> node (the second node in the PL text),
    //than to search for the opening < xml version...> node(the first node in the PL text)
    //(dpendening on type of file, <xml> node could be present in another place in the document)    
    const endTag = '</XrML>';

    const startIndex = documentString.indexOf(startTag);
    const endIndex = documentString.lastIndexOf(endTag);
    
    if (startIndex === -1 || endIndex === -1 || endIndex < startIndex) {
        return ''; // License not found or invalid format
    }    
    const licenseString = documentString.substring(startIndex, endIndex + endTag.length);
    return licenseString;
}

function convertUtf8TextToBase64(text) {

    if (text === undefined || text === null) {
        return '';
    }        
    // Convert the extracted license string to bytes, so they can be encoded
    const bytes = new Uint8Array(text.length);
    for (let i = 0; i < text.length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    // Encode the bytes as Base64
    const base64Encoded = btoa(String.fromCharCode.apply(null, bytes));
    return base64Encoded;
}

function findDocumentGuidInPublishingLicense(text) {

    if (!text || text === '') {
        return '';
    }
    //console.log('getLocalDocumentInfo . on read end');

    // a policy GUID can be found in this node:
    //<DESCRIPTOR><OBJECT><ID type="MS-GUID">{C7729FBF - 511F-4C1E-A8E8-E71D8BAC18C7}</ID><NAME>LCID 1033:NAME New protection:DESCRIPTION  ;
    //</NAME></OBJECT></DESCRIPTOR>

    // a document GUID and some extra info, like OrgId (SkuID) can be found here:
    //<WORK>
    //    <OBJECT type=".txt">
    //        <ID type="MS-GUID">{7aa9132c-171e-4dc1-91bd-e0cbc4642853}</ID>
    //        <NAME>errro IO - dir not empty - Copy.txt</NAME>
    //    </OBJECT>
    //    <METADATA>
    //        <OWNER><OBJECT><ID type="Unspecified" /><NAME>fileserver@lab.com</NAME></OBJECT></OWNER>
    //        <SKU type="SkuID">0001-0-00000000</SKU>
    //    </METADATA>
    //</WORK>

    //// OWNER
    //// the owner can be found here (format is the same for the old PL and the new MISPC PL):
    //// <OWNER><OBJECT><ID type="Unspecified" /><NAME>jonathan.teran@sealpath.com</NAME></OBJECT></OWNER>
    //var r1 = /(?<=<OWNER><OBJECT><ID type=".[^\"]*" \/><NAME>).{1,256}(?=<\/NAME>)/i;
    //var ownerSearch = r1.exec(this.result);        
    //var owner = null;
    //if (ownerSearch && ownerSearch.length > 0) {
    //    owner = ownerSearch[0];
    //}
    //console.log("OWNER: ", owner);

    // 1. first format for regedit query (highest priority):
    // the new 2.1 format: first we search for OBJECT without any type (otherwise the CLC-type OBJECT is returned first, as it is placed before the good one!)
    //  - the original search was returning the ID of this element from MSIPC PL:
    // search: /<WORK><OBJECT type=".[^\"]*"><ID type="MS[-]{0,1}GUID">\{([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\}<\/ID>/i;
    // returns: <WORK><OBJECT type="Client-Licensor-Certificate"><ID type="MS-GUID">{93a24d95-9659-471e-b980-f812582e96ce}</ID></OBJECT>
    // the current ID that must be returned instead: <WORK><OBJECT><ID type="MS-GUID">{a9034a31-e2f6-4c9f-ba58-c99d89bd7045}</ID></OBJECT>
    var r = /<WORK><OBJECT><ID type="MS[-]{0,1}GUID">\{([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\}<\/ID>/i;
    var guid = r.exec(text);

    // 2. original pdf check
    if (!guid || guid.length === 0) {
        //Para dar soporte a PDF
        r = /DocumentId\({([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\}\)/i;
        guid = r.exec(text);
    }
    // 3. original check (before MSIPC, the first WORK/OBJECT with doc ID node had the "type" attribute, so it must be included, or both searches could be unified by making this attribute optional)
    if (!guid || guid.length === 0) {
        // Original format: with any OBJECT type
        r = /<WORK><OBJECT type=".[^\"]*"><ID type="MS[-]{0,1}GUID">\{([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\}<\/ID>/i;
        guid = r.exec(text);
    }

    if (guid && guid.length > 0) {
         return guid[1]; // we must take the second element of the regex result
    }
    return '';
}

//--------------------------------------------------------------------------------------------

const notifyDocumentIsNotProtected = (task) => {
    //console.log('notifyDocumentIsNotProtected', task);
    // format the error object and assign the result code, then call the 'onComplete' so it will take care of the rest
    task.hasError = true;
    task.error = {};
    task.error.error_code = ResultCode.FileIsNotProtected;
    window.app.taskProcessor.onComplete(task);
}

//--------------------------------------------------------------------------------------------
export const downloadFile = (task) => {

    let app = window.app;

    ////console.log('task: on file download. file path: ' + task.filePath);
    // modify the task and execute it again as a different type:
    // reset the file, otherwise it will be sent again:
    task.file = null;
    task.previousType = task.type;    // store it as it will be important to know it when formatting task UI texts, to tell if the Download was set after Protect or Unprotect
    task.type = RequestType.Download;    
    task.onComplete = onFileProcessed;
   
    task.options.DeleteFileAfterDownload = app.store.state.options.appSettings.Server.DeleteFileAfterDownload; 
    task.options.AutoFileDownload = app.store.state.options.appSettings.Server.AutoFileDownload;

    let formData = convertProtectionTaskParamsToFormData(task);
    task.ajax = {
        url: api().file.download(),        
        data: formData
    };

    //if (app.store.state.options.appSettings.Server.AutoFileDownload) { !!! now the auto download mode doesn't call the 'donwload' task - the file gets downloaded directly from 'protect'/'unprotect' endpoints
    //    task.info = app.R.Downloading;
    //    app.taskProcessor.push(task);
    //}
    //else {
        // enable the 'download-on-click' for the task
        // ...
        //task.info = app.R.ClickToDownload;
        task.substate = TaskSubState.ClickToDownload;
        task.clickable = true;
    //}
    //console.log('file manager: download file: force render');
    //app.forceRender();

    ////console.log('app.stateId: ' + app.stateId);
}

////--------------------------------------------------------------------------------------------
//const onNodesDrop = (tool, nodes) => {

//    nodes.map(node => {      
//        var task = { node: node, type: tool.action, tool: tool };
//        window.app.taskProcessor.push(task); 
//    });
//}

export const onFileNodeProtected = (task, result) => {

}


export const onCancelFileSelection = () => {

    ////console.log('drop cancelled');
}

export const onFilesLoaded = (task, result) => {

    let view = task.view;
    let provider = task.provider;
    let folder = task.folder;

    let nodes = result.files;

    // order nodes by folders (they go first) and files
    let folders = [];
    let files = [];

    nodes.map(node => {
        node.parent = folder; // set the parent property for each node (it's not returned from server for transfer efficiency)
        node.provider = provider;
        if (node.type === 'folder') {
            node.nodes = [];
            folders.push(node); // add it to the folders
        }
        else {
            files.push(node); // add it to the files
        }
        return true;
    });
    // sort the nodes
    sortItemsByName(folders);
    sortItemsByName(files);

    // add child nodes to the folder node
    folder.nodes = [];
    // concat doesn't work, check it and meanwhile use .map
    //folder.nodes.concat(folders); 
    //folder.nodes.concat(files);
    folders.map(fo => folder.nodes.push(fo));
    files.map(fi => folder.nodes.push(fi));

    // set the flags
    folder.loading = false;
    folder.loaded = true;

    //this.logNode(folder);
    ////console.log('after loadFiles: task.view: ' + view + ', task.provider: ' + provider + ', task.folder: ' + folder);

    // inform the view that new files have been loaded, so it can hide its loading icon --> 
    // it is unnecesarry now, we will call the higher component setState so the child component gets updated
    view.component.onLoadingComplete();
}

//const loadFiles_NotUsedAnymore_ActionIsUsed = (view, provider, folder) => {

//    ////console.log('load ' + provider.name + ' files for the folder ' + folder.name + ', id: ' + folder.id);

//    folder.loading = true;

//    //view.onLoadingStart();

//    if (provider.id === 'local') {
//        // load files from device

//        ////console.log('loading local files...');
//        //..
//        //this.files = [];
//        //this.setState({ files: this.files, loading: false });
//    }
//    else {
//        // load files from server

//        fetch('api/files/' + provider.id + '/' + folder.id)
//            .then(response => response.json())
//            .then(result => {

//                //if (taskManager.hasError(result)) {
//                //    //view.onLoadingError(result);
//                //    return;
//                //}

//                //if (!taskManager.hasData(result)) {
//                //    //view.onLoadingError(result);
//                //    return;
//                //}

//                let nodes = result.files;

//                // order nodes by folders (they go first) and files
//                let folders = [];
//                let files = [];

//                nodes.map(node => {
//                    node.parent = folder; // set the parent property for each node (it's not returned from server for transfer efficiency)
//                    node.provider = provider;
//                    if (node.type === 'folder') {
//                        node.nodes = [];
//                        folders.push(node); // add it to the folders
//                    }
//                    else {
//                        files.push(node); // add it to the files
//                    }
//                });
//                // sort the nodes
//                sortItemsByName(folders);
//                sortItemsByName(files);

//                // add child nodes to the folder node
//                folder.nodes = [];
//                // concat doesn't work, check it and meanwhile use .map
//                //folder.nodes.concat(folders); 
//                //folder.nodes.concat(files);
//                folders.map(fo => folder.nodes.push(fo));
//                files.map(fi => folder.nodes.push(fi));

//                // set the flags
//                folder.loading = false;
//                folder.loaded = true;

//                //this.logNode(folder);
//                ////console.log('after loadFiles: task.view: ' + view + ', task.provider: ' + provider + ', task.folder: ' + folder);

//                // inform the view that new files have been loaded, so it can hide its loading icon --> 
//                // it is unnecesarry now, we will call the higher component setState so the child component gets updated
//                view.component.onLoadingComplete();

//                // re-render
//                //this.setState({ lastAction: 'folder loaded', loading: false });
//            });
//    }
//}

//-----------------------------------------------------------------------------------------------

// sorting:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort

const sortItemsByName = (items) => {

    if (items.length < 2) {
        return;
    }
    //items.sort(function (a, b) {
    //    return a.localeCompare(b);
    //});
    items.sort(function (a, b) {
        var a2 = a.name.toUpperCase(); // ignore upper and lowercase
        var b2 = b.name.toUpperCase(); // ignore upper and lowercase
        return a2.localeCompare(b2);
    });
}

//--------------------------------------------------------------------------------------------

// ACTION RESULT CALLBACKS:

export const onFolderLoaded = (task, result) => {

}
//--------------------------------------------------------------------------------------------


// https://stackoverflow.com/questions/2897619/using-html5-javascript-to-generate-and-save-a-file

//function downloadDynamicFile(content, filename, contentType) {
//    if (!contentType) contentType = 'application/octet-stream';
//    var a = document.createElement('a');
//    var blob = new Blob([content], { 'type': contentType });
//    a.href = window.URL.createObjectURL(blob);
//    a.download = filename;
//    a.click();
//}

//--------------------------------------------------------------------------------------------

export const onFileNodeUnprotected = (task, result) => {

}
//--------------------------------------------------------------------------------------------

export const onFileNodeInfo = (task, result) => {

}

//--------------------------------------------------------------------------------------------

//const upload = (file, providerId) => {

//    fetch('api/upload/files', {
//        headers: { "Content-Type": file.type },
//        body: file
//    }
//    ).then(
//        response => response.json()
//    ).then(
//        //result => logger.logObject(result, "result")
//        //fetch('api/upload/files/', { method: 'POST', body: file }).then(
//        //     response => response.json() // if the response is a JSON object
//        //).then(
//        //    success => ////console.log(success) // Handle the success response object
//        //).catch(
//        //    error => ////console.log(error) // Handle the error response object
//    );
//};

//const uploadJson = (file, providerId) => {

//    fetch('api/upload/json', {
//        method: 'POST',
//        //headers: { "Content-Type": "application/json" },
//        body: { name: 'my input message' }
//    }
//    ).then(
//        response => response.json()
//    ).then(
//        //result => logger.logObject(result, "result")
//        //fetch('api/upload/files/', { method: 'POST', body: file }).then(
//        //     response => response.json() // if the response is a JSON object
//        //).then(
//        //    success => ////console.log(success) // Handle the success response object
//        //).catch(
//        //    error => ////console.log(error) // Handle the error response object
//    );
//};


//// (! deprecated) Synchronous loading method
//function loadJsonFile(file_url) {

//    ////console.log("load JSON file: " + file_url);
//    var http = new XMLHttpRequest();
//    http.open('GET', file_url, false);
//    http.send('');
//    //http.onreadystatechange = function () {
//    if (http.readyState == 4 && http.status > 100 && http.status < 400) {
//        //if (this.readyState == 4 && this.status > 100 && this.status < 400) {
//        var json = JSON.parse(http.responseText);
//        return json;
//    }
//    else {
//        return false;
//    }
//    //};      
//}

//https://github.com/axios/axios/issues/2049

//const formDataToBuffer = (formData) => {
//    //console.log('formDataToBuffer: form data: ', formData);
//    let dataBuffer = new Buffer(0);
//    let boundary = '--WebKitFormBoundaryyEmKNDsBKjB7QEqu';//formData.getBoundary();
//    for (let i = 0, len = formData._streams.length; i < len; i++) {

//        if (typeof formData._streams[i] !== 'function') {

//            dataBuffer = this.bufferWrite(dataBuffer, formData._streams[i]);

//            // The item have 2 more "-" in the boundary. No clue why
//            // rfc7578 specifies (4.1): "The boundary is supplied as a "boundary"
//            //    parameter to the multipart/form-data type.  As noted in Section 5.1
//            //    of [RFC2046], the boundary delimiter MUST NOT appear inside any of
//            //    the encapsulated parts, and it is often necessary to enclose the
//            //    "boundary" parameter values in quotes in the Content-Type header
//            //    field."
//            // This means, that we can use the boundary as unique value, indicating that
//            // we do NOT need to add a break (\r\n). These are added by data-form package.
//            //
//            // As seen in this example (https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST#Example)
//            // the boundary is preceded by 2x "-". If thus --Boundary exists, do not add the break.
//            if (typeof formData._streams[i] !== 'string' || formData._streams[i].substring(2, boundary.length + 2) !== boundary) {
//                dataBuffer = this.bufferWrite(dataBuffer, "\r\n");
//            }
//        }
//    }

//    // Close the request
//    dataBuffer = this.bufferWrite(dataBuffer, '--' + boundary + '--');

//    return dataBuffer;
//}

//// Below function appends the data to the Buffer.
//const bufferWrite = (buffer, data) => {

//    let addBuffer;
//    if (typeof data === 'string') {
//        addBuffer = Buffer.from(data);
//    }
//    else if (typeof data === 'object' && Buffer.isBuffer(data)) {
//        addBuffer = data;
//    }

//    return Buffer.concat([buffer, addBuffer]);
//}


/*
* FileSaver.js
* A saveAs() FileSaver implementation.
*
* By Eli Grey, http://eligrey.com
*
* License : https://github.com/eligrey/FileSaver.js/blob/master/LICENSE.md (MIT)
* source  : http://purl.eligrey.com/github/FileSaver.js
*/

// The one and only way of getting global scope in all environments
// https://stackoverflow.com/q/3277182/1008999
//var _global = typeof window === 'object' && window.window === window
//    ? window : typeof self === 'object' && self.self === self
//        ? self : typeof global === 'object' && global.global === global
//            ? global
//            : this

function bom(blob, opts) {
    if (typeof opts === 'undefined') opts = { autoBom: false }
    else if (typeof opts !== 'object') {
        console.warn('Deprecated: Expected third argument to be a object')
        opts = { autoBom: !opts }
    }

    // prepend BOM for UTF-8 XML and text/* types (including HTML)
    // note: your browser will automatically convert UTF-16 U+FEFF to EF BB BF
    if (opts.autoBom && /^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(blob.type)) {
        return new Blob([String.fromCharCode(0xFEFF), blob], { type: blob.type })
    }
    return blob
}

function download(url, name, opts) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.onload = function () {
        saveAs(xhr.response, name, opts)
    }
    xhr.onerror = function () {
        console.error('could not download file')
    }
    xhr.send()
}

function corsEnabled(url) {
    var xhr = new XMLHttpRequest()
    // use sync to avoid popup blocker
    xhr.open('HEAD', url, false)
    try {
        xhr.send()
    } catch (e) { }
    return xhr.status >= 200 && xhr.status <= 299
}

// `a.click()` doesn't work for all browsers (#465)
function click(node) {
    try {
        node.dispatchEvent(new MouseEvent('click'))
    } catch (e) {
        var evt = document.createEvent('MouseEvents')
        evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
            20, false, false, false, false, 0, null)
        node.dispatchEvent(evt)
    }
}

var saveAs = //window.saveAs || (
    
    // probably in some web worker
    (typeof window !== 'object')
        ? function saveAs() { /* noop */ }

        // Use download attribute first if possible (#193 Lumia mobile)
        : 'download' in HTMLAnchorElement.prototype
            ? function saveAs(blob, name, opts) {
                var URL = window.URL || window.webkitURL
                var a = document.createElement('a')
                name = name || blob.name || 'download'

                a.download = name
                a.rel = 'noopener' // tabnabbing

                // TODO: detect chrome extensions & packaged apps
                // a.target = '_blank'

                if (typeof blob === 'string') {
                    // Support regular links
                    a.href = blob
                    if (a.origin !== window.location.origin) {
                        corsEnabled(a.href)
                            ? download(blob, name, opts)
                            : click(a, a.target = '_blank')
                    } else {
                        click(a)
                    }
                } else {
                    // Support blobs
                    a.href = URL.createObjectURL(blob)
                    setTimeout(function () { URL.revokeObjectURL(a.href) }, 4E4) // 40s
                    setTimeout(function () { click(a) }, 0)
                }
            }

            // Use msSaveOrOpenBlob as a second approach
            : 'msSaveOrOpenBlob' in navigator
                ? function saveAs(blob, name, opts) {
                    name = name || blob.name || 'download'

                    if (typeof blob === 'string') {
                        if (corsEnabled(blob)) {
                            download(blob, name, opts)
                        } else {
                            var a = document.createElement('a')
                            a.href = blob
                            a.target = '_blank'
                            setTimeout(function () { click(a) })
                        }
                    } else {
                        navigator.msSaveOrOpenBlob(bom(blob, opts), name)
                    }
                }

                // Fallback to using FileReader and a popup
                : function saveAs(blob, name, opts, popup) {
                    // Open a popup immediately do go around popup blocker
                    // Mostly only available on user interaction and the fileReader is async so...
                    popup = popup || window.open('', '_blank')
                    if (popup) {
                        popup.document.title =
                            popup.document.body.innerText = 'downloading...'
                    }

                    if (typeof blob === 'string') return download(blob, name, opts)

                    var force = blob.type === 'application/octet-stream'
                    var isSafari = /constructor/i.test(window.HTMLElement) || window.safari
                    var isChromeIOS = /CriOS\/[\d]+/.test(navigator.userAgent)

                    if ((isChromeIOS || (force && isSafari)) && typeof FileReader !== 'undefined') {
                        // Safari doesn't allow downloading of blob URLs
                        var reader = new FileReader()
                        reader.onloadend = function () {
                            var url = reader.result
                            url = isChromeIOS ? url : url.replace(/^data:[^;]*;/, 'data:attachment/file;')
                            if (popup) popup.location.href = url
                            else window.location = url
                            popup = null // reverse-tabnabbing #460
                        }
                        reader.readAsDataURL(blob)
                    } else {
                        var URL = window.URL || window.webkitURL
                        var url = URL.createObjectURL(blob)
                        if (popup) popup.location = url
                        else window.location.href = url
                        popup = null // reverse-tabnabbing #460
                        setTimeout(function () { URL.revokeObjectURL(url) }, 4E4) // 40s
                    }
                }
//)

//window.saveAs = saveAs.saveAs = saveAs

//if (typeof module !== 'undefined') {
//    module.exports = saveAs;
//}

//--------------------------------------------------------------------------------------------
export const onProviderClicked = (e, app, provider) => {

    console.log('provider clicked: ' + provider.name);

    if (provider.id === 'googledrive') {
        //
    }
    else {
        // create the storage view (a container for the files)
        let view = createStorageView(provider);
    }

    //if (!provider.root.loaded) {
    //    this.loadFiles2(view, provider, provider.root);
    //}
    //this.setState({ lastAction: 'provider clicked', providers: app.providers, loading: false });
}

const createStorageView = (provider) => {
    // make a new storage view object
    let view = { provider: provider, docked: true, currentFolder: provider.root };
    // store it on the global list
    provider.views.push(view);
    ////console.log('createStorageView. Provider ' + provider.name + '.views: ' + provider.views);
    return view;
}

export const loadFolder = (view, provider, folder) => {

    // comment the 'loadFiles' and push a new task instead, folowing the pattern, 
    // so in the task processor we could produce a log, add a monitoring event,
    // add a task to the cue, or produce a UI component with information about the task.
    //fileManager.loadFiles(view, provider, folder);
    let task = { type: 'load-folder', view: view, provider: provider, folder: folder };
    window.app.taskProcessor.push(task);
}

////--------------------------------------------------------------------------------------------
//const onToolClicked = (e, id) => {
//    ////console.log('tool clicked. id: ' + id);
//}

//const onProtectionClicked = (e, id) => {
//    ////console.log('policy clicked. id: ' + id);
//}


//export const onProviderClicked = (e, app, provider) => {

//    ////console.log('provider clicked: ' + provider.name);
//    // create the storage view (a container for the files)
//    let view = createStorageView(provider);

//    //if (!provider.root.loaded) {
//    //    this.loadFiles2(view, provider, provider.root);
//    //}
//    //this.setState({ lastAction: 'provider clicked', providers: app.providers, loading: false });
//}

//const createStorageView = (provider) => {
//    // make a new storage view object
//    let view = { provider: provider, docked: true, currentFolder: provider.root };
//    // store it on the global list
//    provider.views.push(view);
//    ////console.log('createStorageView. Provider ' + provider.name + '.views: ' + provider.views);
//    return view;
//}

// good pattern to reuse:
//let storage = window.sealpath.storages.find(function (element) {
//    return element.provider.id === provider.id;
//});

//------------------------------------------------------------------------------------

export const onNodeClicked = (node, view) => {

    if (node.type === 'folder') {
        onFolderClicked(node, view);
    }
    else {
        onFileClicked(node, view);
    }
};

const onFileClicked = (file, view) => {

    ////console.log('file clicked: ' + file.name);

}

const onFolderClicked = (folder, view) => {

    ////console.log('folder clicked: ' + folder.name);// + ', view: ' + view);//', view.provider: ' + view.provider);
    view.currentFolder = folder;
    view.component.setState({ lastAction: 'open folder' });
    //this.loadFiles2(view, view.provider, folder);
}

