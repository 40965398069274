import React, { Component, Fragment } from "react";
import { onReturnBack } from '../managers/appManager';
import { SmallButton } from "../components/atoms";
import { routes } from "../common/routes";


export class PageNotFound404 extends Component {

    renderBackButton = () => {

        return (
            <SmallButton
                contentClass='icon-back'
                onClick={onReturnBack}
            />
        );
    }

    render() {

        return (
            <Fragment>
                <div>
                    {this.renderBackButton()}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h3>Page not found 404</h3>
                </div>
            </Fragment>
        )
    }
}