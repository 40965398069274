import React, { Component, Fragment } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
//import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';

import { routes } from '../../../common/routes'; 
import { UserRole } from '../../../common/constants'; 
//import { signOut } from '../../../managers/authManager';


export class NavMenuComp extends Component {

    displayName = NavMenu.name;

    constructor(props) {

        super(props);
        this.app = window.app;
        this.state = {
            visible: false,
            //user: this.app.store.state.user,
            users: this.app.store.state.users
        };

        this.app = window.app;
    }


    componentDidMount() {

        this.app.store.subscribe({ property: 'users', component: this });
    }

    componentWillUnmount() {

        this.app.store.unsubscribe({ property: 'users', component: this });
    }

    renderNav = () => {

        const user = this.app.user;
        // we don't need links if there is no user
        if (!user)
            return null;

        let routeConfig = [];
        // protector in organization
        if (user.O > 1 && user.R && user.R === UserRole.Protector) {
            routeConfig = [
                { title: this.app.R.Home, route: routes.home(), icon: 'icon-home' },
                //{ title: this.app.R.Tasks, route: routes.tasks(), icon: 'icon-documents' },
                { title: this.app.R.Protections, route: routes.policies.list(), icon: 'icon-protections' },
                { title: this.app.R.Documents, route: routes.documents.list(), icon: 'icon-documents' },
                { title: this.app.R.Contacts, route: routes.contacts(), icon: 'icon-users-big' },
                { title: this.app.user.U, route: routes.identity(), icon: 'icon-user' },
            ];
        }
        // consumer in organization
        else if (user.O > 1 && user.R && user.R === UserRole.Consumer) {
            routeConfig = [
                { title: this.app.R.Home, route: routes.home(), icon: 'icon-home' },
                //{ title: this.app.R.Tasks, route: routes.tasks(), icon: 'icon-documents' },
                { title: this.app.R.Documents, route: routes.documents.list(), icon: 'icon-documents' },
                { title: this.app.R.Contacts, route: routes.contacts(), icon: 'icon-users-big' },
                { title: this.app.user.U, route: routes.identity(), icon: 'icon-user' },
            ];
        }
        // external user, not internal in any organization
        // Note: we don't obtain the model of current user in a dedicated call: we get his details when we load
        // the organization users. For user not internal in any organization this list would be empty.
        // In this situation the current user model could only have the UserName property (either loaded
        // from an old session or from a new login) - the 'RolId' could be missing because if organization
        // users cannot be loaded, the current user's full model (including RolId property or OrganizationId)
        // cannot be retrieved from one of the 'org users' model): in this situation we would only have
        // the UserName from an old session or from a new login.
        else {
            routeConfig = [
                { title: this.app.R.Home, route: routes.home(), icon: 'icon-home' },
                { title: this.app.R.Documents, route: routes.documents.list(), icon: 'icon-documents' },
                { title: this.app.user.U, route: routes.identity(), icon: 'icon-user' },
            ];
        }

        return (
            <div className='navigation'>
                {routeConfig.map((menuItem, i) => (
                    <LinkContainer exact to={menuItem.route} key={i}>
                        <Nav.Link href={menuItem.route} active={false} onClick={this.hideNav}>
                            <div className={`icon ${menuItem.icon}`}></div>
                            <div className='label'>{menuItem.title}</div>
                        </Nav.Link>
                    </LinkContainer>
                ))}
                
            </div>
        )
    };
                //<Nav.Item>
                //    <Nav.Link active={false} onClick={this.logOff}>
                //        <div className='icon icon-user'></div>
                //        <div className='label'>{this.app.R.SignOut + ' (' + this.app.user.U + ')'}</div>
                //    </Nav.Link>
                //</Nav.Item>

    hideNav = (e) => {
        e.stopPropagation();
        this.setState({ visible: false });
    }

    //logOff = (e) => {
    //    const { history } = this.props;

    //    this.hideNav(e);        
    //    signOut(this.app);
    //}
    
    toggleNavVisibility = (e) => {

        e.stopPropagation();

        //this.app.visible = !this.app.visible;
        this.setState((prevState, props) => ({
            visible: !prevState.visible
        }));
    };

    render() {
        if (this.props.history) {
            this.app.history = this.props.history;
        }

        return (
            <Fragment>
                <div>
                    <LinkContainer exact to={routes.home()}>
                        <Nav.Link href={routes.home()} active={false} className='button logo' onClick={this.hideNav} >
                            <div className='icon icon-logo' />
                        </Nav.Link>
                    </LinkContainer>
                    {this.app.state.authenticated && (
                        <div className='button toggle-nav' onClick={this.toggleNavVisibility}>
                            <div className='icon icon-menu'></div>
                        </div>)
                    }
                </div>
                {this.app.state.authenticated && (
                    <div className={this.state.visible ? 'visible' : 'visible-on-large'}>
                        {this.renderNav()}
                    </div>)
                }
            </Fragment>
        );
    };
}

export const NavMenu = withRouter(NavMenuComp)