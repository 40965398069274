import React, { Component } from 'react';

export class ItemColumn extends Component {

    constructor(props) {
        super(props);       
    }

    render() {

        const containerClass = this.props.className ? 'column ' + this.props.className : 'column';
        if (this.props.onClick) {
            return (
                <span onClick={this.props.onClick} className={'column ' + this.props.className}>
                    {this.props.children}
                </span>
            );
        }
        return (
            <span className={containerClass}>
                {this.props.children}
            </span>
        );

    }
}
    