import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';

import { DateOptions } from '../../common/constants';


export class SelectList extends Component {
    constructor(props) {
        super(props);

        this.app = window.app;
    }

    render() {
        const { onChange, selectedItem, items, variant } = this.props;
        const title = this.props.title ? this.props.title : '';
        //console.log('SelectList: items: ', items)
        //console.log('SelectList: selectedItem: ', selectedItem)
        return (            
            <Dropdown className='filter date' >
                <Dropdown.Toggle variant={variant ? variant : "primary"}>
                    {selectedItem.label + title}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {items.map((option, i) =>
                        <Dropdown.Item
                            key={`date-option-${i}`}
                            onClick={(e) => onChange(e, option)}
                        >
                            {option.label}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );      
    }
}
