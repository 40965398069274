import React from 'react';
import PropTypes from 'prop-types';
import loadScript from 'load-script';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

let scriptLoadingStarted = false;

export function reAuth(callback, client_id, scope, authImmediate) {
    console.log('re-auth google...');

    //onApiLoad(callback, client_id, scope, authImmediate); // --> hack
    //return;

    window.gapi.auth.authorize({
        client_id: client_id,
        scope: scope,
        immediate: authImmediate
    },
        callback
    );
}

export function openPickerUsingToken(callback, client_id, scope, authImmediate) {
    console.log('open picker using token...');
    var accessToken = "ya29.a0AfH6SMA04dg1lSo0fgNAGqIr8OzGGKwFwi96oTEOTMFqY9z5oB9GdoWE_BGhxjnG7O9vCs0bKCYy4JYwwTnSAjB6_zUbiQ9EhLR-09lvZ8AUuoVKldpjnYX5IvCsQHB9oQPZsmvsJHghF97T62IZxHH18oZdj2hk9zShjauIgpF1EruT";

    //onApiLoad(callback, client_id, scope, authImmediate); // --> hack
    //return;

    window.gapi.auth.authorize({
        client_id: client_id,
        scope: scope,
        immediate: authImmediate
    },
        callback
    );
}

//function onAuthApiLoad() {
//    window.gapi.auth2.init({
//        client_id: clientId
//    }).then(function (n) {
//        n.signIn({
//            scope: scope
//        }).then(function (n) {
//            handleAuthResult(n.getAuthResponse())
//        })
//    })
//}
//var picker = null;
//var clientId = null;
//var scope = null;
//var developerKey = null;
//var token = null;

//function handleAuthResult(n) {
//    n && !n.error && (token = n.access_token, createPicker())
//}
//function onApiLoad() {
//    null === picker && null !== developerKey && null !== clientId && null !== scope
//        && (window.gapi.load("picker", null), window.gapi.load("auth2", onAuthApiLoad))
//}

export default class GooglePicker extends React.Component {

    static propTypes = {
        children: PropTypes.node,
        clientId: PropTypes.string.isRequired,
        developerKey: PropTypes.string,
        scope: PropTypes.array,
        viewId: PropTypes.string,
        authImmediate: PropTypes.bool,
        origin: PropTypes.string,
        onChange: PropTypes.func,
        onAuthenticate: PropTypes.func,
        onAuthFailed: PropTypes.func,
        createPicker: PropTypes.func,
        multiselect: PropTypes.bool,
        navHidden: PropTypes.bool,
        disabled: PropTypes.bool
  };

  static defaultProps = {
    onChange: () => {},
    onAuthenticate: () => {},
    onAuthFailed: () => {},
    //scope:['https://www.googleapis.com/auth/drive.readonly'],
    scope: ['https://www.googleapis.com/auth/drive'],
    viewId: 'DOCS',
    authImmediate: false,
    multiselect: false,
    navHidden: false,
    disabled: false
  };

  constructor(props) {
    super(props);

    this.onApiLoad = this.onApiLoad.bind(this);
    this.onChoose = this.onChoose.bind(this);
  }

  componentDidMount() {
    if(this.isGoogleReady()) {
      // google api is already exists
      // init immediately
      this.onApiLoad();
    } else if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, this.onApiLoad)
    } else {
      // is loading
    }
  }

  isGoogleReady() {
    return !!window.gapi;
  }

  isGoogleAuthReady() {
    return !!window.gapi.auth;
  }

  isGooglePickerReady() {
    return !!window.google.picker;
  }

  onApiLoad() {
    window.gapi.load('auth');
    window.gapi.load('picker');
  }

  doAuth(callback) {
    window.gapi.auth.authorize({
        client_id: this.props.clientId,
        scope: this.props.scope,
        immediate: this.props.authImmediate
      },
      callback
    );
  }

    onChoose(e) {
        console.log('GooglePicker: onChoose');
        if(e) e.preventDefault();
        if (e) e.stopPropagation();
        if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady() || this.props.disabled) {
          return null;
        }

        const token = window.gapi.auth.getToken();
        const oauthToken = token && token.access_token;
        const refreshToken = '';//token && token.refresh_token;
            console.log('Google token: ', token);
        if (oauthToken) {
          this.createPicker(oauthToken, refreshToken);
        } else {
          this.doAuth(response => {
            if (response.access_token) {
              //this.createPicker(response.access_token)
              this.createPicker(response)
            } else {
              this.props.onAuthFailed(response);
            }
          });
    }
  }

  //createPicker(oauthToken, refreshToken) {
  createPicker(response) {
    var oauthToken = response.access_token; 

    //this.props.onAuthenticate(oauthToken);
    this.props.onAuthenticate(response);

    if(this.props.createPicker){
      //return this.props.createPicker(google, oauthToken)
        return this.props.createPicker(window.google, response)
    }

    //const googleViewId = google.picker.ViewId[this.props.viewId];
    const googleViewId = window.google.picker.ViewId[this.props.viewId];
    const view = new window.google.picker.View(googleViewId);

    if (this.props.mimeTypes) {
      view.setMimeTypes(this.props.mimeTypes.join(','))
    }
    if (this.props.query) {
      view.setQuery(this.props.query)
    }

    if (!view) {
      throw new Error('Can\'t find view by viewId');
    }

    const picker = new window.google.picker.PickerBuilder()
                             .addView(view)
                             .setOAuthToken(oauthToken)
                             .setDeveloperKey(this.props.developerKey)
                             .setCallback(this.props.onChange);

    if (this.props.origin) {
      picker.setOrigin(this.props.origin);
    }

    if (this.props.navHidden) {
      picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
    }

    if (this.props.multiselect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
    }

    picker.build()
          .setVisible(true);
  }

    render() {   
      return (
          <div onClick={e => this.onChoose(e)} className={this.props.className ? this.props.className : ''}>
            {
              this.props.children ?
                this.props.children : ''            
            }
        </div>
    );
      //this.props.children ?
      //    this.props.children : <button>Open google chooser</button>
  }
}
