import React, { Component } from 'react';

export class Item extends Component {

    constructor(props) {
        super(props);       
    }

    // now we have an internal method to manage the internal state, which
    // will make a callback to a function passed in props to render function.
    onInternalClick = (e) => {
        ////console.log('item: on internal click: model: ', this.props.model);
        e.preventDefault();

        this.selected = this.selected ? false : true; // this one sets us an immediately available value
        this.setState({ selected: this.selected }); // this will make it re-render
        if (this.props.model) {
            if (this.props.modelParam) {
                this.props.model[this.props.modelParam] = this.selected;
            }
            else {
                this.props.model.selected = this.selected;
            }
        }
        // callback
        if (this.props.onClick) {
            this.props.onClick(e, this.props.model, this);
        }
    }

    render() {

        // if we have a model - it should provide the 'selected' value, otherwise use props value
        this.selected = this.props.model ? this.props.model.selected : this.props.selected;
        // set the class for selected state
        const selectedClass = this.selected ? 'selected' : '';

        const containerClass = this.props.className ? ('item ' + selectedClass + ' ' + this.props.className) : ('item ' + selectedClass);

        if (this.props.onClick) {
            return (
                <div onClick={this.onInternalClick} className={containerClass}>
                    {this.props.children}
                </div>
            );
        }

        return (
            <div className={containerClass}>
                {this.props.children}
            </div>
        );

    }
}
    