export const LocalStorage = {
    set(property, value) {
        window.localStorage.setItem(property, JSON.stringify(value));
    },

    get(property) {
        return localStorage.getItem(property) ? JSON.parse(localStorage.getItem(property)) : null;
    },

    remove(property) {
        window.localStorage.removeItem(property);
    },

    clear() {
        window.localStorage.clear();
    },
};
