import React, { Component } from 'react';

import * as appManager from '../managers/appManager';
import { saveUserConfiguration, saveUserDetails } from '../managers/userManager'; 
import { RadioButtonsList } from '../components/organisms';
import { Checkbox, Radio, SmallButton } from '../components/atoms';

export class Options extends Component {
    displayName = Options.name;

    constructor(props) {
        super(props);
        this.app = window.app;
        this.autoSendInvitationOption = { type: 'autosend', error: false, selected: false };
        const languageId = (this.app.settings.language && this.app.settings.language.id) ? this.app.settings.language.id : 'en';
        this.state = {
            languageId: languageId
            //userLanguageLoaded: this.app.store.state.userLanguageLoaded
        };

    }

    componentDidMount() {

        this.app.locator.optionsPage = this;
        //this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });
    }

    componentWillUnmount() {

        this.app.locator.optionsPage = null;
        //this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
    }

    //static setLanguage(code) {        
    //    window.sealpath.loadResources(code);
        
    //    //this.setState({
    //      //currentCount: this.state.currentCount + 1
    //    //});
    //}

    selectLanguage = (item) => {

        const languageId = item.id;

        this.setState({
            languageId: languageId,
        })
        // First (UI)
        // set language (id: languageId) and force loading (true)
        appManager.setLanguage(languageId, true);

        // Second:
        // save the new user language on the server
        this.app.store.state.userDetails.Language = languageId;
        saveUserDetails();
    }

    onOptionChange = (e, component) => {

        if (component.props.model) {
            const model = component.props.model;
            if (model.type === 'autosend') {
                this.app.store.setOption({ key: 'AutoSendInvitation', value: model.selected });
                // TO DO: the following call should be moved to store.setOption: when option is persistent and has a key: push it to the server -->
                saveUserConfiguration('AutoSendInvitation', model.selected); // TO DO: this can be hacked on client: server validation is needed when user sends invitations!
            }
        }
    }

    renderBackButton = () => {

        return (
            <SmallButton
                contentClass='icon-back'
                onClick={appManager.onReturnBack}
            />
        );
    }

    renderAutoSendInvitationOption() {
        // render this option only if user have permission to send invitations
        // Add  information about this feature:
        // Invitations are sent only if:
        // newUser.OrganizationId == Organization.UNREGISTERED (-1)

        // if new user "Is Ad Ldap User"
        //  Register and send welcome message to internal user or to external-internal user.
        //  It is done even when the "Autosend" invitation is not enabled.
        //  The internal user are registered automatically, no matter if the "Autosend" invitation is enabled or not.

        const model = this.autoSendInvitationOption;      
        model.selected = this.app.store.getOption({ key: 'AutoSendInvitation' });

        return (
            <div>
                <h4>{this.app.R.Invitations}</h4>
                <p className='medium-width-line'>{this.app.R.AutoSendInvitationsInfo}</p>
                <Checkbox
                    enabled={true}
                    key={'opt-' + 2}
                    label={this.app.R.AutoSendInvitations}
                    className='filterX radioX'
                    model={model}
                    onChange={this.onOptionChange}
                />                
            </div>
        )        
    }

    render() {
        
        //if (!this.state.userLanguageLoaded) { // wait for the user prefs loaded and language
        //    return null;
        //}

        //console.log('options.render: this.props.app = ', this.app.R)
        if (this.props.history) { this.app.history = this.props.history; }

        //let languages = this.app.store.state.options.appSettings.languages.map(item => ({
        //    ...item,
        //    id: item.code,
        //    label: item.label,
        //}));
        let languages = this.app.store.state.options.appSettings.languages;

        return (
            <div>
                <div>
                    {this.renderBackButton()}
                    <span className='label extra-space'></span>
                    <h3 className='label'>{this.app.R.Options}</h3>
                </div>
                <div className='margin-left'>
                    <br /><br />
                    <h4>{this.app.R.Language}</h4>
                    <RadioButtonsList
                        selectedItemId={this.state.languageId}
                        items={languages} onChange={this.selectLanguage} />
                    <br />
                    {this.renderAutoSendInvitationOption()}
                </div>
            </div>
        );
    }
}
