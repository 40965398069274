import React, { Component } from 'react';
import { SmallButton } from './SmallButton';

export class Checkbox extends Component {

    constructor(props) {
        //console.log('Checkbox constructor')
        super(props);
        this.state = {
            selected: false
        };
        this.selected = false; // I need an accurate value here so it can be immediately used from external callback, this.state.checked will be used only to trigger for rendering
        this.onChange = null;
        this.type = '';
    }

    // now we have an internal method to manage the internal state, which
    // will make a callback to a function passed in props to render function.
    onInternalChange = (e) => {

        e.preventDefault();

        this.selected = this.selected ? false : true; // this one sets us an immediately available value
        this.setState({ selected: this.selected }); // this will make it re-render
        if (this.props.model) {
            if (this.props.modelParam) {
                this.props.model[this.props.modelParam] = this.selected;
            }
            else {
                this.props.model.selected = this.selected;
            }
        }        
        // callback
        if (this.props.onChange) {
            this.props.onChange(e, this);
        }
    }

    render() {

        //console.log('Checkbox . render...');
        // if we have a model - it should provide the 'selected' value, otherwise use props value
        this.selected = this.props.model ? this.props.model.selected : this.props.selected;
        // the main class: 
        let mainClass = this.props.className ? 'radio ' + this.props.className : 'radio';        
        // the checked / unchecked class
        let checkedClass = this.selected ? 'icon-checkbox-checked' : 'icon-checkbox-unchecked';
        // the button class
        let buttonClass = '';
        // the label class
        let labelClass = this.props.labelClass ? 'label ' + this.props.labelClass : 'label';
        // the 'enabled' property:
        if (!this.props.enabled) {
            mainClass += ' disabled';
            labelClass += ' disabled';
            buttonClass += ' disabled';            
        }
        // the 'transparent' property:
        if (this.props.transparent) {
            mainClass += ' transparent';            
        }
        return (
            <div className={mainClass} onClick={this.props.enabled ? this.onInternalChange : null}>
                <SmallButton className={buttonClass} contentClass={checkedClass} />
                <div className={labelClass}>{this.props.label}</div>                
            </div>
        );
    }
}
    