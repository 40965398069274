import * as userManager from './userManager';
import { prepareFileTaskForLaunch, pushFileTask } from './fileManager';
import { RequestType } from '../common/constants';
import { api } from '../common/api';

//-------------------------------------------------------------------
export const launchTests = (app) => { 
    // now the user is authenticated and user data (like users needed for adding policy test) is loaded, we sould have an access token,
    // so it's the moment to run the tests
    if (app.test.mode == 1) { runAdditionalTests() }
    else if (app.test.mode == 2) { runHeavyLoadTests() }
    //else if (app.test.mode == 3) { runConcurrentTests() }
    else if (app.test.mode == 4) { runConcurrentProtectionTests() }
}

export const runAdditionalTests = () => {
    //console.log('runAdditionalTests ->');
    let app = window.app;

    // 1. create a new test policy
    // 2. edit and update test policy
    // 3. remove test policy
    addTestPolicy(); // step 1: it will continue through callbacks...    
}

export const runHeavyLoadTests = () => {
    //console.log('runHeavyLoadTests ->');
    let app = window.app;
    let i;
    let count = 10;
    for (i = 0; i < count; i++) {
        userManager.loadAllUserData();
    }
}

export const runConcurrentProtectionTests = () => {

    //console.log('runConcurrentProtectionTests ->');
    let app = window.app;
    //addTestPolicy(); // step 1: it will continue through callbacks... 
    sendConurrentProtectionRequests(null)
}

export const sendConurrentProtectionRequests = (policy) => {

    if (!policy) {
        policy = { Guid: "6eff214c-23ea-40b9-91ab-1e0cc6db61c3" }
    }
    let app = window.app;
    let i;
    let count = 5;
    for (i = 0; i < count; i++) {
        let file = { name: 'empty file.exx' };
        let task = { file, app, tool: policy, type: RequestType.TestProtection };        
        prepareFileTaskForLaunch(app, task);
        pushFileTask(task);
    }
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}
export const addTestPolicy = () => {

    ////console.log('add test policy: ');
    let app = window.app;
    //let policy = {
    //    guid: '', // assigned by the server
    //    name: app.R.NewPolicy, // placeholder name
    //    rightsPolicyId: 0, // assigned by the server
    //    users: [], // user permissions
    //    organizationId: 0, // assigned by the server
    //};

    // We keep the test policy reference in tha pp object, so we can reuse it next time we run tests
    // (could be that we had to create a new test policy with a different GUID, so we want to keep using it)
    let policy = app.test.policy;
    if (!policy) {

        let user1 = null;
        let user2 = null;
        let users = [];
        if (app.user)
            user1 = app.user;
        if (app.store.state.users.length > 0) {
            app.store.state.users.map(user => {                
                user2 = user;                
            })
        }
        if (user1) {
            users.push({ "AccessPermissions": 1, "IsVisible": false, "IsGuest": false, "UserIdFather": null, "IsAuthor": true, "UserId": user1.Id, "UserName": user1.U, "OrganizationId": user1.O, "RolId": user1.R })
        }
        if (user2) {
            users.push({ "AccessPermissions": 2, "IsVisible": true, "IsGuest": false, "UserIdFather": null, "IsAuthor": false, "UserId": user2.Id, "UserName": user2.U, "OrganizationId": user2.O, "RolId": user2.R })           
        }
        if (!user1 && !user2) {
            window.alert('in addTestPolicy: cannot add permissions: users are missing!');
            return;
        }
        policy = {
            RightsPolicyId: 0,//-1,
            // GUID:
            // Server API breaks if there's no Guid in the policy:
            // use empty guid or generate a new one with "uuidv4()"?,
            // 1. For tests better use a const Guid so during tests we will only manipulate 
            // one policy on the server instead of creating a new one with every run.
            //Guid: "4797561c-76f7-47d2-8f2e-e882e4ffe3fa",
            Guid: "4797561c-76f7-47d2-8f2e-e882e4ffe3fa",
       
            // 2. For normal policy creation: send an empty guid: '00000000-0000-0000-0000-000000000000'
            //Guid: '00000000-0000-0000-0000-000000000000', 

            // -> we need to add the current user and not unknown users that possibly are not on a server:
            //check if we can set current user(the only user we "know") as the author, anytime...
            // how to do it: 
            // take users list: find the current user there: get his OrgId, UserId, UserRol -> insert it
            // if we need another permission: get another user from the users list and add it in the same way

            Users: users,
            DateCreated: "2018-11-29T13:26:00Z",
            DateUpdated: "2019-04-10T17:42:00Z",
            IsPublished: false,
            OrganizationId: app.user.O,
            Name: "Automatic Test Protection", // this name is required to avoid making new DB policies: if the existing policy with this name exists, it will be updated instead
            Description: "for tests",
            IsOfflineAccessActive: false,
            OfflineAccessDays: 5,
            IsContentExpirationActive: false,
            ContentExpirationInitDate: null,
            ContentExpirationEndDate: null,
            IsCorporative: false,
            Version: 12,
            Counter: 1,
            IsFavourite: false,
            ShareType: 0,
            Watermark: false,
            IsCustomProtection: false,
            MobileOfficeEnabled: false,
            GenericProtection: 0
        };
    }

    // add user permissions
    //let permissions = makeUserPermissions(1, false, false, null, true, 1, 'adspadmin@lab.com', 2, 2);
    //policy.Users.push(permissions);
    //permissions = makeUserPermissions(1, false, false, null, true, 1, 'adspadmin@lab.com', 2, 2);
    //policy.Users.push(permissions);
    //permissions = makeUserPermissions(1, false, false, null, true, 1, 'adspadmin@lab.com', 2, 2);
    //policy.Users.push(permissions);
    //permissions = makeUserPermissions(1, false, false, null, true, 1, 'adspadmin@lab.com', 2, 2);
    //policy.Users.push(permissions);
    // now prepare the request
    let type = RequestType.NewPolicy;
    let task = {                     
        type: type,
        onComplete: onAddTestPolicyCompleted,
    };

    task.ajax = { // create the request object for HTTP client
        url: api().policy.editPolicy(),
        data: {
            Policy: policy, // the policy will be added to ajax data
        }
    };

    window.app.taskProcessor.push(task);
}

export const onAddTestPolicyCompleted = (task) => {

    let app = window.app;
    let policy = task.policy;
    let result = task.result;
    let type = task.type;    
    // A policy id should be returned, and a guid. 
    // The guid could be differnet then the test guid:
    // in case that a not-test policy with the test GUID already exists, 
    // we have created a new test policy with a different GUID.
    // Apply the new parameters from the response to the test policy, so we keep 
    // using this test policy with these parameters in the following tests:
    let policyId = result.policyId;
    let policyGuid = result.policyGuid;
    if (policyId) { policy.RightsPolicyId = policyId }
    if (policyGuid) { policy.Guid = policyGuid }
    app.test.policy = policy;   // store it to reuse it in later test calls

    //task.info = 'ok';
    if (app.test.mode == 4) { // concurrent protections mode: now use the test policy to protect the files
        sendConurrentProtectionRequests(policy);
    }
    else {
        // 2. edit and update test policy
        editTestPolicy(policy);
    }    
}

export const editTestPolicy = (policy) => {

    let app = window.app;
    let type = RequestType.EditPolicy;
    let task = {        
        type: type,
        onComplete: onEditTestPolicyCompleted,
    };

    task.ajax = { // create the request object for HTTP client       
        url: api().policy.editPolicy(),
        data: {
            Policy: policy,
        }
    };

    window.app.taskProcessor.push(task);
}

export const onEditTestPolicyCompleted = (task) => {

    let app = window.app;
    //task.info = 'ok';
    // 3. remove test policy
    removeTestPolicy(app.test.policy);
}

export const removeTestPolicy = (policy) => {

    let app = window.app;
    app.store.state.policy = policy;

    let type = RequestType.RemovePolicy;
    let task = {        
        type: type,
        onComplete: onRemoveTestPolicyCompleted,
    };

    task.ajax = { // create the request object for HTTP client
        url: api().policy.remove(),
        data: {
            TargetGuid: policy.Guid,
        }
    };
    window.app.taskProcessor.push(task);
}

export const onRemoveTestPolicyCompleted = (task) => {

    let app = window.app;
    //task.info = 'ok';
    // 3. restore test policy
    restoreTestPolicy(app.test.policy);
}

export const restoreTestPolicy = (policy) => {

    let app = window.app;
    app.store.state.policy = policy;

    let type = RequestType.RestorePolicy;
    let task = {        
        type: type,
        onComplete: onRestoreTestPolicyCompleted,
    };

    task.ajax = { // create the request object for HTTP client
        url: api().policy.restore(),
        data: {
            Policy: policy, // for recovering we send the whole policy
        }
    };

    window.app.taskProcessor.push(task);
}

export const onRestoreTestPolicyCompleted = (task) => {
    // After recovery the RightsPolicyId will be different then before removal - 
    // the client will have to update it:
    task.policy.RightsPolicyId = task.result.RightsPolicyId;
    //task.info = 'ok';
    // more tests now?
    sendTestInvitation();
}

export const makeUserPermissions = (accessPermissions, isVisible, isGuest, userIdFather, isAuthor, userId, userName, organizationId, rollId) => {

    ////console.log('get user permissions: ');
    let app = window.app;
    let userPermissions = {
        AccessPermissions: accessPermissions,
        IsVisible: isVisible,
        IsGuest: isGuest,
        UserIdFather: userIdFather,
        IsAuthor: isAuthor,
        UserId: userId,
        UserName: userName,
        OrganizationId: organizationId,
        RolId: rollId
    };
    return userPermissions;
}
// params:
// 'users' is a list of user emails
// 'invitation' is a string with the invitation text, already formatted
// with the current user name (e.g. '{Peter} invited you to...')
export const sendTestInvitation = (users, invitation) => {

    let app = window.app;
    let type = RequestType.SendInvitation;
    let task = {        
        type: type,
        onComplete: onSendTestInvitationCompleted,
    };

    task.ajax = { // create the request object for HTTP client        
        url: api().policy.invitation(),
        data: {
            TargetNames: users,
        }
    };
    
    window.app.taskProcessor.push(task);
}

export const onSendTestInvitationCompleted = (task) => {

    let app = window.app;
    //task.info = 'ok';
}
