import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import {    
    clickUserFromPermissionList,    
    getUserNameAndFullName,
    goToAddUsersToPermissionsPage,
    goToHomePage,
    onReturnBack,    
    selectPermissionUserFromMultiSelector,
    setExcludedForItems,
    unselectItems
} from '../../managers/appManager';

//import DatePicker, { registerLocale } from 'react-datepicker';
//import es from 'date-fns/locale/es';


import { Contacts } from '../Contacts';

import {
    Calendar, Checkbox, SmallButton,
    Item, ItemColumn, ItemHead,
    ItemList, MultiSelectWithSubList, Radio,
} from '../../components/atoms';
import { MultiSelect, Paginator, Paginator2, SearchFilter, SelectList } from '../../components/organisms';

import { CanEditPolicyValue } from '../../managers/policyManager';
import * as policyManager from '../../managers/policyManager';
import { AccessPermissionType, accessPermissionModels, PageSizeOptions, RequestType } from '../../common/constants';
import {    
    convertSelectedPermissionsToText,
    formatDateString,
    formatPermissions, 
    getFullNameWithGuestLabel,
    template
} from '../../common/format';
//import * as Store from '../../managers/Store';
//import { formatDate, parseDate } from '../../components/atoms/Calendar/lib/moment';
//registerLocale('es', es)


export class Permissions extends Component {

    constructor(props) {

        super(props);
        ////console.log('edit permissions . constructor');
        this.app = window.app;

        this.policy = null;
        this.permission = null;
        
        this.filter = {
            // search options and params
            text: '',            
            items: this.app.store.state.permissionUsers || [], // (initially it's better to keep it here as 'this.filter' (synchronously) instead of within state (asynchronously updated). 
            // pagination
            page: {
                index: 0,    
                inputText: '1',
                //size: PageSizeOptions[0].id,
                size: this.app.store.getOption({ section: 'page', option: 'size' }),
                //sizeModel: PageSizeOptions[0], // default page size option = 10 items per page           
                items: [], // page items ,
                showPageInput: true,
                showTotalPages: true,
                totalPages: 0        
            }
        };

        this.state = {
            policies: this.app.store.state.policies,
            permissionUsers: this.app.store.state.permissionUsers || [],
            users: this.app.store.state.users,
            showContactMenu: false,
            showContextMenu: false,
            showDeleteButton: false,
            showEditButton: false,
            showAddPermissionMenu: false,
            showEditPermissionMenu: false,            
            showEditPolicyError: false,
            startDate: new Date(),
            selectedDay: undefined,
            permissions: [],
            itemsPerPage: [],
            top: 0,
            skip: 10,
            text: '',
            pageSize: PageSizeOptions[0].id,
            pageSizeModel: PageSizeOptions[0], // default page size option = 10 items per page

            filter: this.filter
        };

        this.options = {
            accessTypeModels: null,
            share: null,
        }
    }
    //===============================================================
    componentDidMount() {

        //console.log('edit permissions: componentWillMount');
        this.app.locator.permissionsPage = this;
        this.app.store.subscribe({ property: 'policies', component: this });
        this.app.store.subscribe({ property: 'users', component: this });
        this.app.store.subscribe({ property: 'permissionUsers', component: this });

        if (!this.permission) {
            this.initializePermissionForEdition();
            // this component won't work after browser refresh: if those object are not defined: abort and return to home screen
            if (!this.permission) { 
                goToHomePage(); 
                return; 
            }            
        }
        // un-exclude all users first:
        setExcludedForItems(this.app.store.state.users, false);
        // exclude the ones that are already on the selected list
        setExcludedForItems(this.app.store.state.permissionUsers, true);

        this.filterItems();
        this.fillPage();
    }

    //componentDidMount() {
    //    //console.log('edit permissions: componentDidMount');
    //    //const policy = this.getPolicy(this.state.policies, this.props.match.params.id);
    //    //const policy = this.app.store.state.policyCopy;
    //    //this.loadData({
    //    //    permissions: policy && policy.Users || [],
    //    //    PageIndex: 0,
    //    //    PageSize: this.state.skip,
    //    //})
    //}

    componentDidUpdate(prevProps, prevState) {

        //console.log('edit permissions: componentDidUpdate');
        if (prevState.permissionUsers !== this.state.permissionUsers) {            
            this.filterItems();
            this.fillPage();
        }
    }
    
    componentWillUnmount() {

        //console.log('edit permissions: componentWillUnmount');
        this.app.locator.permissionsPage = null;
        this.app.store.unsubscribe({ property: 'policies', component: this });
        this.app.store.unsubscribe({ property: 'users', component: this });
        this.app.store.unsubscribe({ property: 'permissionUsers', component: this });

    }

    //===============================================================

    filterItems = () => {
        ////console.log('policies : filter items... store.state.policies: '); //console.log(this.app.store.state.policies);
        ////console.log('policies : filter items... filter: '); //console.log(this.filter);
        const filter = this.filter;
        if (!this.state.permissionUsers) return;
        filter.items = this.state.permissionUsers;
        if (!filter.items) return;

        // step 1 (quicker): policy type filter (my policies/shared with me)
        //filter.items = filter.items
        //            .filter(policy => { return(this.filterPolicyType(this.app, policy, filter.policyType.id)) });
        //    //console.log('filter.items after filtering policy type: ');
        //    //console.log(filter.items);

        // step 2 (slower): text filter for permissions search
        if (filter.text !== '') {
            filter.items = filter.items
                //.filter(item => `${item.UserName}${item.FullName}`.toLocaleLowerCase() // a user model variant
                //.filter(item => `${item.UserName}`.toLocaleLowerCase() // this is a simple search variant fpr permission model
                .filter(item => getUserNameAndFullName(item).toLocaleLowerCase() // this is a less expensive variant that uses a user model to search in full name too
                .search(filter.text.toLocaleLowerCase()) > -1)
        }
        if (!filter.items) { filter.items = [] }; // for security, to not to process and render null list later
        this.setState({ filter: this.filter });
    };

    fillPage = () => {

        const { items, page } = this.filter;
        if (!items) return;
        page.items = items
            .slice(
                (page.index * page.size),
                (page.index * page.size) + page.size,
            );
        this.setState({ filter: this.filter });
    };

    //loadData = (search) => {
    //    const itemsPerPage = search.permissions
    //        .slice(
    //            (search.PageIndex * search.PageSize),
    //            (search.PageIndex * search.PageSize) + search.PageSize,
    //        );

    //    this.setState({
    //        permissions: search.permissions,
    //        itemsPerPage: itemsPerPage,
    //        top: search.PageIndex,
    //        skip: search.PageSize,
    //    })
    //};

    //changePagination = ({ PageIndex, PageSize }) => {
    //    this.loadData({
    //        permissions: this.state.permissions,
    //        PageIndex,
    //        PageSize,
    //    })
    //}

    //===============================================================

    onShareOptionChange1 = (e, component) => {

        if (e) e.stopPropagation();

        const model = component.props.model;
        if (model) {
            const permission = model.permission;
            ////console.log('onShareOptionChange: before: ' + permission.IsVisible);
            //console.log(model);
            if (!permission.IsAuthor) { // the owner permission is disabled from UI action
                permission.IsVisible = model.selected; // toggle the 'Share With' option
                if (!permission.IsVisible) {                    
                    permission.CanEditPolicy = CanEditPolicyValue.False; // must disable the "CanEditPolicy" if "IsVisible" is false!
                }
            }
            ////console.log('onShareOptionChange: after: ' + permission.IsVisible);
            this.setState({});
        }
    }
    onShareOptionChange2 = (e, component) => {

        if (e) e.stopPropagation();

        const model = component.props.model;
        if (model) {
            const permission = model.permission;
            ////console.log('onShareOptionChange: before: ' + permission.IsVisible);
            //console.log("model", model);           
            if (!permission.IsAuthor) { // the owner permission is disabled from UI action
                if (model.selected) {
                    // set the bit:
                    permission.AccessPermissions = permission.AccessPermissions |= AccessPermissionType.AddUsers;                    
                }
                else {
                    //clear the bit:
                    permission.AccessPermissions = permission.AccessPermissions &= ~AccessPermissionType.AddUsers;
                }        
            }
            ////console.log('onShareOptionChange: after: ' + permission.IsVisible);
            this.setState({});
        }
    }
    onShareOptionChange3 = (e, component) => {

        if (e) e.stopPropagation();

        const model = component.props.model;
        if (model) {
            const permission = model.permission;
            //console.log("model", model);
            if (!permission.IsAuthor) { // the owner permission is disabled from UI action
                permission.CanEditPolicy = model.selected ? CanEditPolicyValue.True : CanEditPolicyValue.False; // toggle the 'Can Edit Policy' option

                if (model.selected) {
                    permission.IsVisible = true;// must set "IsVisible" if "CanEditPolicy" is true!
                }
            }            
            this.setState({});
        }
    }

    selectMultiselectorItem = (e, item) => {

        selectPermissionUserFromMultiSelector(e, item);
        this.filterItems();
        this.fillPage();
    }

    //===============================================================
    // ADD CONTACT SELECTION MENU
    closeContactMenu = () => {
        this.setState({ showContactMenu: false});
    }

    showContactMenu = () => {
        this.setState({ showContactMenu: true });
    }

    // EDIT PERMISSIONS
    closeEditPermissionMenu = () => {
        this.setState({ showEditPermissionMenu: false });
    }

    showEditPermissionMenu = () => {
        this.setState({ showEditPermissionMenu: true });
        unselectItems(window.app.store.state.users); // reset the selections before we start selecting contacts
    }

    // ERROR ALERT
    closeEditPolicyError = () => {
        this.setState({ showEditPolicyError: false });
    }

    showEditPolicyError = () => {
        this.setState({ showEditPolicyError: true });
    }
    //===============================================================
    // CALLBACKS

    onEditPolicyError = () => {
        this.showEditPolicyError();
    }

    onEditPolicyCompleted = () => {
        //this.closeEditPolicyModal();
        // return back to the previous page
    }

    onPermissionsRemoved = () => {
        // set new 'permissions' state
        //const policy = this.getPolicy(this.state.policies, this.props.match.params.id);
        const policy = this.app.store.state.policyCopy;
        //this.loadData({
        //    permissions: policy && policy.Users || [],
        //    PageIndex: 0,
        //    PageSize: this.state.skip,
        //})
    }

    //-----------------------------------------------------------------------

    onOptionChange = (e, component) => {

        if (e) e.stopPropagation();
        ////console.log('edit permissions . on Option Change: control selected:' + component.selected);

        if (component.props.model) {
            const model = component.props.model;
            ////console.log('edit permissions: model: ', model);
            if (model.type === 'permission') {
                // get the flag indicator and .selected value set the parent model's AccessPermission value
                const permission = model.parentModel;
                const mask = model.id; // the model.id is the flag for the given access permission
                if (model.selected) {
                    // set that bit:
                    permission.AccessPermissions |= mask;                    
                }
                else {
                    // clear that bit:
                    permission.AccessPermissions &= ~mask;
                }                
                this.setState({ policies: this.state.policies });
            }            
        }
        // check the controls and apply the current options to the policy or the permissions
        // ...
    }

    onTextChange = (e, model) => {

        if (e) e.preventDefault();
        if (e) e.stopPropagation();
        ////console.log('edit policy . on Text Change: control selected:' + control.selected);

        //console.log(model);

        //if (control.props.model) {
        //    //console.log(control.props.model)
        //}

        // check the controls and apply the current options to the policy or the permissions
        // ...
        if (e.target) {

            const name = e.target.name;
            const value = e.target.value;
            ////console.log('onTextChange: value: ' + value);
            ////console.log('onTextChange : name: ' + name);

            if (name === 'name') { // the control for policy name has changed
                this.policy.Name = value;
            }
            else if (name === 'description') { // policy description has changed
                this.policy.Description = value;
            }
            else if (name === 'offline_days') { // offline access days

                this.offlineAccessDaysText = ''; // default text to output
                if (isNaN(value)) {
                    this.policy.OfflineAccessDays = 0;
                }
                else {
                    let days = parseInt(value);
                    this.offlineAccessDaysText = '' + days;
                    this.policy.OfflineAccessDays = days;
                }
            }

            this.setState({ policies: this.app.store.state.policies });
        }

    }

    onPolicyOptionChanged = (e) => {

        // check the controls and apply the current options to the policy or the permissions
        // ...

    }

    onSavePolicy = (e) => {
        ////console.log('on save policy');
        if (e) e.stopPropagation();

    }

    onTextFilterChange = (value) => {

        const filter = this.filter;
        if (filter.text != value) {
            filter.text = value; // set the new filter value
            this.filterItems(); // apply the new filter to the items collection            
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }
    };

    changePage = (model) => {

        const filter = this.filter;
        const { page } = filter;
        if (model) { page.index = model.index; }
        page.inputText = page.index + 1; // set the text for the input control 
        unselectItems(this.state.permissionUsers); // we don't keep selected items from other pages for this component        
        this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter
        this.fillPage();
    };

    //changePage = ({ PageIndex, PageSize }) => {
    //    //console.log('change page: ' + PageIndex, ', size: ', PageSize);
    //    this.loadData({
    //        items: this.state.policies,
    //        PolicyFilter: this.state.selectedPolicyFilter,
    //        PolicyType: this.state.selectedPolicyType,
    //        TextFilter: this.state.text,
    //        PageIndex,
    //        PageSize,
    //    })
    //};

    changePageSize = (e, option) => {
        if (e) e.stopPropagation();
        const { pageSize, top } = this.state;
        // TO DO: when we change the page size, the 'top' (page index) is actually wrong
        // we should start from the first page, or we should recalculate the 'top' 
        // so it starts from the last "first" item(the one that was first on page)
        if (pageSize !== option.id) {
            this.setState({
                top: 0,
                pageSizeModel: option, // used to render the drop down menu
                pageSize: option.id
            })
        }
        this.changePage({ PageIndex: top, PageSize: option.id });
    }


    //==========================    AUXILIAR FUNCTIONS    =============================


    createPolicy = () => {
        let policy = {
            rightsPolicyId: 0,
            name: '',
            description: ''
        };
        return policy;
    }

    getPolicy = (policies, id) => {

        let result = null;
        if (policies) {
            policies.map(policy => {
                // 'id' comes from url path and it is a string: convert 'policy.rightsPolicyId' to string before comparing
                if ('' + policy.RightsPolicyId === id) {
                    result = policy;
                }
            });
        }
        return result;
    }

    onSubListButtonClick = () => {
        ////console.log('on sub list click...')
        this.showContactMenu();
    }
    //=================================================================================
    // RENDERS

    renderUserList = (permission) => {

        // this will set a 'excluded' flag on already selected users to remove them from MultiSelector
        setExcludedForItems(this.filter.items, true);

        return (
            <div className='section'>
                <div className='filters'>
                    <h4 className='label'>{this.app.R.SelectUsers}</h4>
                    <SmallButton
                        contentClass='icon-users-negative'
                        onClick={(e) => goToAddUsersToPermissionsPage(e)}
                    />
                </div>
                <br />
                <span className='long-tip'>{this.app.R.SelectUsersLong2}</span>

                <div>
                    <MultiSelect                        
                        items={this.state.users.filter(user => !user.excluded)}
                        onChange={(e, item) => this.selectMultiselectorItem(e, item)}                        
                        label={this.app.R.SelectUsersLong}
                    />
                </div>

                <div>
                    {this.renderUsers(this.filter.page.items)}
                   
                    <Paginator2
                        filter={this.filter}
                        onChange={this.changePage}
                    />
                </div>

                <br /><br />
            </div>
        );        
    }

    renderUsers = (users) => {
        if (!users) return;

        return (

            <ItemList>
                <Item className='head disabled'>
                    <ItemColumn className='xs-col-12 xs-border-bottom xs-no-border-right s-col-6'>{this.app.R.Name}</ItemColumn>
                    <ItemColumn className='xs-col-12 s-col-6'>{this.app.R.Email}</ItemColumn>
                </Item>

                {users.length
                    ? users.map((user, i) => (
                        <Item
                            key={'user ' + i}
                            model={user}
                            onClick={(e) => clickUserFromPermissionList(e, null, this)}
                        >
                            <ItemColumn className='xs-col-12 xs-border-bottom xs-no-border-right s-col-6'>{getFullNameWithGuestLabel(this.app, user)}</ItemColumn>
                            <ItemColumn className='xs-col-12 s-col-6'>{user.U}</ItemColumn>
                        </Item>
                    ))
                    : (<Item className='disabled'>
                        <ItemColumn className='col-12 text-center'>{this.app.R.NoItems}</ItemColumn>
                    </Item>)}

            </ItemList>
        )
    }

    //renderColumns(columns) {
    //    return (
    //        columns.map((column, i) => (
    //            <td key={`col-${column}-${i}`}
    //                className={`item-col one-line ${i && `hidden-sm`}`}
    //            >
    //                {column}
    //            </td>
    //        ))
    //    )
    //}
    //================================================================================

    renderShareOption = (permission) => {        
        
        if (!permission) return null;
        const model1 = { permission, selected: permission.IsVisible };
        const model2 = { permission, selected: (permission.AccessPermissions & AccessPermissionType.AddUsers) === AccessPermissionType.AddUsers };
        const model3 = { permission, selected: permission.CanEditPolicy === CanEditPolicyValue.True };
        this.options.share = model1;
        let sharedButton = '';
        ////console.log('render share option: ' + this.options.share.selected);
        //<br />{ this.app.R.ShareInfo } info is discarded now
        //sharedButton =             
        //<div className='inline'>
        //    <div className={'share-button-pos'}>
        //        <div className={'icon icon-share-on'}></div>
        //    </div>
        //</div > 

        return (
            <div className='section'>
                <h4 className='inline'>{this.app.R.ShareOptions}</h4>     
                {sharedButton}
                 
                <br />
                <Checkbox
                    enabled={true}
                    key={'share-checkbox-' + permission.id}
                    label={this.app.R.SharePolicyOption}
                    checkboxClass='filter radio'
                    model={model1}
                    onChange={this.onShareOptionChange1}
                />
                <br />
                <Checkbox
                    enabled={true}
                    key={'share-addusers-checkbox-' + permission.id}
                    label={this.app.R.SharePolicyOption_AddUsers}
                    checkboxClass='filter radio'
                    model={model2}
                    onChange={this.onShareOptionChange2}
                />
                <br />
                <Checkbox
                    enabled={true}
                    key={'share-editpolicy-checkbox-' + permission.id}
                    label={this.app.R.SharePolicyOption_EditPolicy}
                    checkboxClass='filter radio'
                    model={model3}
                    onChange={this.onShareOptionChange3}
                />
                <br /><br />
            </div>
        );
    }

    renderPermissionOptions = (permission) => {

        // var flags = 1 + 2 + 4 + 32 + /*256 + */4096; // for mockups

        // we need to convert the number (representing the permission flags)
        // to a list of models, and then we will select/unselect the corresponding models. 
        const models = formatPermissions(permission.AccessPermissions, permission);
       
        return (
            models.map(m =>
                <Checkbox
                    enabled={m.enabled}
                    transparent={m.transparent}
                    key={'perm-' + m.id}
                    label={m.label}
                    checkboxClass='filter radio'
                    model={m}
                    onChange={this.onOptionChange}
                />
            )            
        );
    }

    //==========================    BUTTONS    =============================

    renderOkButton = (showLabel) => {
        let label = showLabel ?
            <h3 className=' label '>{this.app.R.Ok}</h3>
            :
            <h3 className=' label inline-hide-for-small'>{this.app.R.Ok}</h3>

        return (
            <div className='align-right inline clickable' onClick={(e) => { this.onOk(e, this) }}>
                {label}
                <SmallButton contentClass='icon-accept'/>
            </div >
        );
    }

    //renderEditButton = (permission) => {

    //    if (this.state.showEditButton) {
    //        return (
    //            <div className='context-menu-button edit'>
    //                <SmallButton contentClass={'context icon-edit-2'} onClick={(e) => policyManager.editPermission(e, permission, this)} />
    //            </div>
    //        );
    //    }
    //    else { // render it, but disabled
    //        return (
    //            <div className='context-menu-button edit'>
    //                <SmallButton className='disabled' contentClass={'context icon-edit-2'} />
    //            </div>
    //        );
    //    }        
    //};

    renderEditButton = (permission) => {

        // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
        // render the context menu buttons we use that model to check if we can edit/delete
        //if (!policy)
        //    policy = this.app.store.state.policy
        //if (policy) {
        //    if (!canEditPolicy(this.app.user, policy))
        //        return null;
        //}

        if (this.state.showEditButton) {
            return (
                <div className='context-menu-button edit'>
                    <SmallButton contentClass={'context icon-edit-2'} />
                </div>
            );
        }
        else { // render it, but disabled
            return (
                <div className='context-menu-button edit'>
                    <SmallButton className='disabled' contentClass={'context icon-edit-2'} />
                </div>
            );
        }

    };
    // A. When rendered as an internal item button, the 'permission' will be assigned.
    // B. When rendered from context menu the permission will be null, in this case
    // a list of selected permissions will be assigned later in the policy manager
    //renderDeleteButton = (permission) => {

    //    if (this.state.showDeleteButton) {
    //        return (
    //            <div className='context-menu-button delete'>
    //                <SmallButton contentClass={'context icon-delete'} onClick={(e) => { policyManager.removePermissions(e, permission, this) }} />
    //            </div>
    //        );
    //    }
    //    else {
    //        return ('');
    //    }
    //}

    renderDeleteButton = (user) => {

        // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
        // render the context menu buttons we use that model to check if we can edit/delete
        //if (!policy)
        //    policy = this.app.store.state.policy
        //if (policy) {
        //    if (!canDeletePolicy(this.app.user, policy))
        //        return null;
        //}

        if (this.state.showDeleteButton) {
            return (
                <div className='context-menu-button delete'>
                    <SmallButton contentClass={'context icon-delete'} onClick={(e) => { this.removeUser(e, user, this) }} />
                </div>
            );
        }
        else {// render it, but disabled
            return (
                <div className='context-menu-button delete'>
                    <SmallButton className='disabled' contentClass={'context icon-delete'} />
                </div>
            );
        }
        //return ('');        
    }

    removeUser = (e, user, component) => {

        //console.log('remove selected permission user. user: ', user)
        if (user) {
            // delete the user
            //...
        }
        else {
            // find all the selected users and delete all of them
            let users = this.app.store.state.permissionUsers;
            if (users) {
                users.map(user => {
                    if (user.selected) user.excluded = false;
                    return true;
                }) // reset the 'excluded' flag for the users that are going to be deleted from the list, so they are enabled to be shown again in the search field
                users = users.filter(user => !user.selected);
                this.app.store.setState({ permissionUsers: users });
                this.setState({ showContextMenu: false });
            }
        }
    }
    //-----------------------------------------------------------------------
    renderBackButton = () => {

        // 'back 'button as confirmation (for immediate but destructive editing)
        return (
            <SmallButton
                contentClass='icon-back'
                onClick={(e) => this.onOk(e)}
            />
        );
        // 'close' button (for cancelling and dropping changes)
        //return (            
        //    <SmallButton                
        //        contentClass='icon-close-negative'
        //        onClick={(e) => this.onReturnBack(e)}
        //    />            
        //);
    }

    // for applying the changes
    onOk = (e) => {

        if (e) e.stopPropagation();
        // apply the necessary permission changes to the policy we are editing
        policyManager.applyNewPermissions(e, this, this.permission);
        //go back to policy editor
        onReturnBack(e);
    }

    // for cancelling the changes
    onReturnBack = (e) => {

        if (e) e.stopPropagation();
        // cancel the changes: reset selections and lists
        // ...
        //go back to policy editor
        onReturnBack(e);
    }

    initializePermissionForEdition() {

        // assign a local reference so we can access it easily later from form's event functions
        this.policy = this.app.store.state.policyCopy;
        this.permission = this.app.store.state.permissionCopy;
    }
    
    getTitleBar(permission) {

        // render titles and user selection only depending on if there is a user or not
        let title1 = permission.UserId ? this.app.R.EditUserPermissions : this.app.R.NewPermissions
        let title2 = ''
        //title1 = ''; // set it for UI tests
        //title2 = <h3 className='label ellipsis'>{'username@lab.com'}</h3>; // set it for UI tests
        if (permission.UserId) {
            title2 = permission.UserName
            //// replace the placeholder
            //let replacements = {
            //    '{n}': permission.UserName
            //};
            //title2 = template(title2, replacements);
            title2 = <h3 className='label ellipsis'>{title2}</h3>
        }

        // mode 1: cancel + apply buttons
        //let titleBar =
        //    <div className='extra-margin-bottom'>
        //        {this.renderBackButton()}
        //        <span className='label extra-space'></span>
        //        <h3 className='label'>{title1}</h3>                
        //        {this.renderOkButton(false)}
        //        {title2}
        //    </div>

        // mode 2: go back button only
        let titleBar =
            <div className='extra-margin-bottom'>
                {this.renderBackButton()}
                <span className='label extra-space'></span>
                <h3 className='label'>{title1}</h3>
                {title2}
            </div>

        return titleBar;
    }

    //=========================================================================
    // RENDER PERMISSIONS/POLICY MENUS

    // 1. ADD PERMISSIONS: we can add permissions for many users at once
    // we will render:
    // - the 'share with' checkbox and info
    // - the permissions checboxes
    // - users filter list (the only difference with EDIT PERMISSION)

    // 2. EDIT PERMISSIONS: we can edit permissions for many users at once
    // we will render:
    // - the 'share with' checkbox and info
    // - the permissions checboxes

    render = () => {

        ////console.log('edit permissions: render');
        ////console.log('this.permission: ' + this.permission);

        // this component won't work after browser refresh: if those object are not defined: abort and return to home screen
        if (!this.permission) {
            this.initializePermissionForEdition();
            if (!this.permission) {
                //    goToHomePage(); 
                return null;
            }
        }

        const permission = this.permission;

        let titleBar = this.getTitleBar(permission);

        const userSelection = permission.UserId ? '' : this.renderUserList(permission);

        //const confirmButtonText = permission.UserId ? this.app.R.Ok : this.app.R.Ok; //this.app.R.AddPermissions;

        ////console.log('permissions render: this.state.showContextMenu: ' + this.state.showContextMenu);
        // render the context menu
        let contextMenu = '';
        if (this.state.showContextMenu) {
            var divStyle = {
                display: 'block',
                position: 'absolute',
                left: this.app.clientX,
                top: this.app.clientY
            };
            //contextMenu = <div style={divStyle}><StaggeredMenu2  /></div>;
            contextMenu = <div className='over' style={divStyle}>
                {this.renderDeleteButton()}
                {this.renderEditButton()}
            </div>;
        }

        let shouldRenderShareOption = true; // default: true, 
        const storeState = this.app.store.state;
        if (storeState.policy) {
            if (storeState.policy.IsCustomProtection) {
                // do not render 'share' option for custom protections
                shouldRenderShareOption = false;
            }
        }

        return (
            <div>
                
                {titleBar}

                {userSelection}

                {contextMenu}

                <div className='section last'>
                    <h4>{this.app.R.AccessPermissions}</h4>
                    <div className='clear-left'>{this.app.R.AccessPermissionsInfo}</div>
                    {this.renderPermissionOptions(permission)}
                </div>
                <br />

                {shouldRenderShareOption && this.renderShareOption(permission)}
                
                {
                    //this.renderBackButton()
                    //this.renderOkButton(true)
                }                
                <br className='clear-left' /><br /><br />
                {contextMenu}
            </div>
        );           
    }
}
