import React, { Component } from 'react';
import * as appManager from '../../managers/appManager'
import * as taskManager from '../../managers/taskManager'
import Spinner from 'react-bootstrap/Spinner';
import { TaskState, TaskSubState } from '../../common/constants'

export class Test extends Component {
        
    constructor(props) {

        super(props);
        this.state = { tasks: null };
    }

    componentDidMount = () => {

        ////console.log('Tests.componentDidMount');
        // STEP A: 
        // We need to load the initial application data, both the default(factory) and the custom files: 
        // 1. css files, 
        // 2. configuration of the application,
        // 3. localization files (the texts used in tha app) in a given language.
        //appManager.loadAllAppData();
    }
    
    //--------------------------------------------------------------------------------------------
    renderTests(app) {

        //return;       
        return (
            app.taskProcessor.tasks.map(task =>
                this.renderTask(app, task)
            )
        );
    }  
    //--------------------------------------------------------------------------------------------

    renderTask(app, task) {       

        let typeName = task.typeName + ' : '; 
        let key = '' + task.type + '-' + task.id;
        //console.log('render task: task.id: ' + task.id + ', task.key: ' + key)
        return (
            <li key={ key } className='test-title'>
                { task.typeName + ' (' + task.type + ') : ' }
                { this.renderState(app, task) }
            </li>
        ); 
    }  
    //--------------------------------------------------------------------------------------------

    renderState(app, task) {

        if (task.state === TaskState.Completed) {
            return (                  
                    <span className=''>{task.info}</span>
            )
        }
        else {
            return (                    
                <span className=''>{task.info + ' '}<Spinner animation="border" size="sm" /></span>
                )                
        }
    }
    //-------------------------------------------------------------------------------------

    render() {
        ////console.log('Test.render ... ');

        let app = window.app;
        let title = app.R.Test;
        //<div className='tasks over anim-1'>
        //    <h3>{title}</h3>
        //    {this.renderTests()}
        //</div>
        return (
            <div>
                <h3>{title}</h3>
                <ul>{this.renderTests(app)}</ul>                
            </div>
        );
    }
}
