import React, { Component } from 'react';
import { Button, Dropdown, Modal, Table } from 'react-bootstrap';

import {
    canCreateProtections,
    clickPolicy,
    clickPolicyInSelectionMode,
    countSelectedItems,
    findSelectedItems,
    isProtector,
    onReturnBack,
    unselectItems,
    updateAppHistory,

    goToHomePage
} from '../../managers/appManager';
import { findUserById } from '../../managers/userManager';
import {
    canEditPolicy,
    canDeletePolicy,
    editPolicy,
    filterPolicyType,
    getAuthor,
    getNamesOfUsersThatHavePolicyShared,
    getPolicyNameAndUserNamesForSearch,
    getPolicyUserNamesForSearch,
    getUserPermissionModels,
    isAuthorInPolicy,
    isPolicySharedAndNotAuthor,
    isSimplePolicyExpired,
    onRemovePolicyConfirmed,
    protectSelectedDocuments,
    removePolicy,
    sortPolicies,
    toggleFavourite
} from '../../managers/policyManager';
import * as taskManager from '../../managers/taskManager';
import {
    Item, ItemColumn, ItemHead, ItemList,
    SmallButton, LoadingIcon
} from '../../components/atoms';
import {
    SelectList, Paginator2,
    SearchFilter, RadioButtonsList,
} from "../../components/organisms";
import { routes } from "../../common/routes";
import { convertPolicyNamesToText, template } from '../../common/format';
import { PageSizeOptions, PolicySubTypeModels, PolicyType, PolicyTypeModels, RequestType, UserRole } from "../../common/constants";


export class Policies extends Component {
    constructor(props) {
        super(props);

        this.app = window.app;

        this.filter = {
            // search options and params
            policyType: this.app.store.getOption({ section: 'protections', option: 'type' }),
            //policySubType: selectedPolicyFilter || policySubTypes(this.app)[0], (like ALL, MOST USED, UNUSED)
            text: this.app.store.getOption({ section: 'protections', option: 'text' }),            
            items: this.app.store.state.policies || [], // (initially it's better to keep it here as 'this.filter' (synchronously) instead of within state (asynchronously updated). 
            // pagination
            page: {
                index: 0,
                inputText: '1',
                //size: PageSizeOptions[0].id,
                size: this.app.store.getOption({ section: 'page', option: 'size' }),
                //sizeModel: PageSizeOptions[0], // default page size option = 10 items per page           
                items: [], // page items,
                showPageInput: true,
                showTotalPages: true,
                totalPages: 0
            }
        };

        this.state = {
            // synchronized with the global store
            policies: this.app.store.state.policies || [],
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,

            // component with search properties (page number, type, name) and the filtered items
            filter: this.filter,

            showRemovePolicyModal: false,
            showRemovePolicyErrorModal: false,
            showContextMenu: false,
            showDeleteButton: false,
            showEditButton: false
        };
    }

    componentDidMount() {

        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'items' state (or other):
        window.app.locator.policiesPage = this;
        this.app.store.subscribe({ property: 'policies', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });

        unselectItems(window.app.store.state.policies); // reset the selections

        if (this.app.store.state.policies) {

            this.filterItems();
            this.fillPage();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (prevState.policies !== this.state.policies) {
            //if (this.app.store.state.policies) {

                this.filterItems();
                this.fillPage();
            //}
            //this.loadData({
            //    items: this.state.policies,
            //    PolicySubType: this.state.policySubType,
            //    PolicyType: this.state.policyType,
            //    TextFilter: this.state.text,
            //    PageIndex: this.state.top,
            //    PageSize: this.state.pageSize,
            //});
        }
    }

    componentWillUnmount() {
        window.app.locator.policiesPage = null;
        this.app.store.unsubscribe({ property: 'policies', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
        unselectItems();        
    }

    filterItems = () => {
        //console.log('policies : filter items... store.state.policies: '); console.log(this.app.store.state.policies);
        ////console.log('policies : filter items... filter: '); //console.log(this.filter);
        const filter = this.filter;
        //console.log('filterItems')
        filter.items = this.app.store.state.policies;
        if (!filter.items) return;
        // step 1 (quicker - place it first): policy type filter (my policies/shared with me)
        filter.items = filter.items
                    .filter(policy => { return(filterPolicyType(this.app, policy, filter.policyType)) });
            //console.log('filter.items after filtering policy type: ');
            //console.log(filter.items);

        // step 2 (slower - place it second): text filter
        if (filter.text !== '') {
            try {
                //filter.items = filter.items
                //    .filter(policy => `${policy.Name}${getPolicyUserNamesForSearch(this.app, policy)}`.toLocaleLowerCase()
                //        .search(filter.text.toLocaleLowerCase()) > -1)
                filter.items = filter.items
                    .filter(policy => getPolicyNameAndUserNamesForSearch(this.app, policy).toLocaleLowerCase()
                        .search(filter.text.toLocaleLowerCase()) > -1)
            }
            catch {
                // wrong search string: mark the input field in red?
            }     
        }
        // sort the policies: alphabetically and by favourite option (favs go first) 
        sortPolicies(filter.items);

        if (!filter.items) { filter.items = [] }; // for security, to not to process and render null list later
        this.setState({ filter: this.filter });
    };

    fillPage = () => {

        const { items, page } = this.filter;
        if (!items) return;
        page.items = items
            .slice(
                (page.index * page.size),
                (page.index * page.size) + page.size,
            );
        this.setState({ filter: this.filter });
    };

    changePage = (model) => {

        const filter = this.filter;
        const { page } = filter;
        if (model) { page.index = model.index; }
        page.inputText = page.index + 1; // set the text for the input control 
        unselectItems(this.state.policies); // we don't keep selected items from other pages for this component        
        this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter
        //this.state.showContextMenu = false; // IT DIDN'T WORK: see if this will avoid introducing some additional 'set state' related calls that break context menu sometimes?
        this.fillPage();
    };

    onSelectPolicyType = (model) => {

        
        const filter = this.filter;
        if (model.id !== filter.policyType) {

            filter.policyType = model.id; // set the new filter value
            this.app.store.setOption({ section: 'protections', option: 'type', value: model.id }); // set the new configuration value

            this.filterItems(); // apply the new filter to the items collection
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }
    }

    onTextFilterChange = (value) => {

        const filter = this.filter;
        if (filter.text != value) {

            filter.text = value; // set the new filter value
            this.app.store.setOption({ section: 'protections', option: 'text', value: value }); // set the new configuration value

            this.filterItems(); // apply the new filter to the items collection            
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }        
    };

    changePageSize = (e, model) => {

        if (e) e.stopPropagation();
        const { page } = this.filter;
        if (page.size !== model.id) {
            page.sizeModel = model;
            page.index = 0;
            page.size = model.id;
        }
        this.changePage();
    }

    //onSelectPolicyFilterChange = (e, filter) => {
    //    e.stopPropagation();

    //    function sortFunc(a, b) {
    //        if (filter.id === 'ALL') {
    //            return b.Counter - a.Counter;
    //        }
    //        if (filter.id === 'MOST_USED') {
    //            return b.Counter - a.Counter;
    //        }
    //        if (filter.id === 'UNUSED') {
    //            return a.Counter - b.Counter;
    //        }
    //    }

    //    if (filter.id !== this.state.policySubType.id) {
    //        this.loadData({
    //            items: this.state.policies.sort(sortFunc),
    //            PolicyFilter: filter,
    //            PolicyType: this.state.policyType,
    //            TextFilter: this.state.text,
    //            PageIndex: this.state.top,
    //            PageSize: this.state.pageSize,
    //        })
    //    }
    //}




    renderProtectionList(app, items) {

        if (!canCreateProtections(app)) return null;
        if (!isProtector(app)) return null;

        //console.log('renderProtectionList: ', items)
        return (            
            <ItemList>
                <Item className='head policies disabled'>
                    <ItemColumn className='xs-col-2 s-col-2 m-col-1'></ItemColumn>
                    <ItemColumn className='xs-col-10 xs-border-bottom xs-no-border-right s-col-5'>{this.app.R.ProtectionPolicy}</ItemColumn>
                    <ItemColumn className='xs-col-2 s-hidden'></ItemColumn>
                    {this.filter.policyType === PolicyType.Own &&
                        (<ItemColumn className='xs-col-6 s-col-3 m-col-4'>{this.app.R.SharedWith}</ItemColumn>)}
                    {this.filter.policyType === PolicyType.Shared &&
                        (<ItemColumn className='xs-col-6 s-col-3 m-col-4'>{this.app.R.SharedBy}</ItemColumn>)}
                    <div className='absolute fav-button'><div className='button small disabled'><div className='growing icon icon-fav-off'></div></div></div>
                    <ItemColumn className='xs-col-4 s-col-2'>{this.app.R.Documents}</ItemColumn>
                </Item>

                {!items &&
                    (<Item key={'docs-loading-icon'} >
                        <ItemColumn key={'col-docs-loading-icon'} className={'item-col one-line'}>{this.app.R.Loading}</ItemColumn>
                    </Item>)
                }

                {items &&
                    (items.length > 0
                        ?
                        items.map(policy => this.renderPolicy(policy))
                        :
                        (<Item className='disabled'>
                            <ItemColumn className='col-12 text-center'>{this.app.R.NoItems}</ItemColumn>
                        </Item>)
                    )
                }       

            </ItemList>
        );
    }

    renderPolicy(policy) {

        const isExpired = isSimplePolicyExpired(policy);        
        const policyName = isExpired ? policy.Name + ' (' + this.app.R.Expired + ')' : policy.Name;

        let onlick = null
        let className = ''

        if (this.props.selectionMode) {
            onlick = (e) => { clickPolicyInSelectionMode(e, policy, this) }
        }
        else {
            if (canEditPolicy(this.app.user, policy) || canDeletePolicy(this.app.user, policy)) {
                onlick = (e) => { clickPolicy(e, policy, this) }
            }
            else {
                // we don't want a highlight for a policy we can't edit or delete
                className = 'disabled'
                // set it to false in case it was selected
                policy.selected = false
            }
        }        
        
        return (
            <Item
                key={'pol-' + policy.Id}
                onClick={onlick}
                selected={policy.selected}
                className={className}
            >                
                <ItemColumn className='xs-col-2 s-col-2 m-col-1'></ItemColumn>
                <ItemColumn className='xs-col-10 xs-border-bottom xs-no-border-right s-col-5'>{policyName}</ItemColumn>
                <ItemColumn className='xs-col-2 s-hidden'></ItemColumn>
                {this.filter.policyType === PolicyType.Own &&
                    (<ItemColumn className='xs-col-6 s-col-3 m-col-4'>{getNamesOfUsersThatHavePolicyShared(this.app, policy.Users)}</ItemColumn>)}
                {this.filter.policyType === PolicyType.Shared &&
                    (<ItemColumn className='xs-col-6 s-col-3 m-col-4'>{getAuthor(this.app, policy)}</ItemColumn>)}          
                <div className='absolute fav-button'>{this.renderFavButton(policy)}</div>
                <ItemColumn className='xs-col-4 s-col-2'>{policy.Count}</ItemColumn>                
            </Item>
        )
    }

    renderFavButton = (policy) => {

        let favClass = 'icon-fav-off-negative';
        if (policy.Fav) { favClass = 'icon-fav-on-negative'; }      
        return (
            <SmallButton contentClass={'fav ' + favClass} onClick={(e) => { toggleFavourite(e, policy); this.filterItems(); this.fillPage(); }} />
        );
    };

    renderEditButton = (policy) => {

        // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
        // render the context menu buttons we use that model to check if we can edit/delete
        if (!policy)
            policy = this.app.store.state.policy
        if (policy) {
            if (!canEditPolicy(this.app.user, policy))
                return null;
        }

        if (this.state.showEditButton) {
            return (
                <div className='context-menu-button edit'>
                    <SmallButton contentClass={'context icon-edit-2'} onClick={(e) => editPolicy(e, this.app, /* full policy */ null, /* simple policy */ null, RequestType.None, /* is new policy */ false)} />
                </div>
            );
        }
        if (!this.state.showEditButton) { // render it, but disabled
            return (
                <div className='context-menu-button edit'>
                    <SmallButton className='disabled' contentClass={'context icon-edit-2'} />
                </div>
            );
        }
        else {
            return ('');
        }
    };

    // When rendered from context menu the policy will be null, 
    // in which case it will be assigned later in policy manager
    renderDeleteButton = (policy) => {
        
        // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
        // render the context menu buttons we use that model to check if we can edit/delete
        if (!policy)
            policy = this.app.store.state.policy

        //if (policy) {
        //    if (!canDeletePolicy(this.app.user, policy))
        //        return null;
        //}        

        if (this.state.showDeleteButton) {
            return (
                <div className='context-menu-button delete'>
                    <SmallButton contentClass={'context icon-delete'} onClick={(e) => { removePolicy(e, policy, this) }} />
                </div>
            );
        }
        else { // render it, but disabled
            return (
                <div className='context-menu-button delete'>
                    <SmallButton className='disabled' contentClass={'context icon-delete'} />
                </div>
            );            
        }
    }


    //===============================================================
    // REMOVE 
    closeRemovePolicyModal = () => {
        this.setState({    
            showRemovePolicyModal: false,
            filter: this.filter
        });
    }

    showRemovePolicyModal = (policy) => {
        // when clicking on a inner item button: will always pass and set a reference
        // to that particular policy, otherwise, when the context menu was used, we could
        // have multiple selecetd items, so we will ignore the 'currentItem'
        this.app.store.state.policy = policy; 
        this.setState({
            showRemovePolicyModal: true            
        });
    }

    closeRemovePolicyErrorModal = () => {
        this.setState({
            showRemovePolicyErrorModal: false
        });
    }

    showRemovePolicyErrorModal = () => {
        this.setState({
            showRemovePolicyErrorModal: true
        });
    }

    onRemovePolicyError = (task) => {
        // could be we are removing multiple policies: check if all have been removed?
        // ...
        this.showRemovePolicyErrorModal(task);
    }

    onRemovePolicyCompleted = (task) => {
        // could be we are removing multiple policies: check if all have been removed?
        // ...
        this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter

        this.closeRemovePolicyModal();
    }
    
    //=========================================================================
    // REMOVE POLICY

    renderRemovePolicyModal = () => {

        if (!this.state.showRemovePolicyModal) return null;

        let app = window.app;
        let title = '';
        let info = '';
        let items;
        // Option A: (not implemented now)
        // we have clicked the inner item button, and the policy reference is passed here 
        // Option B: (current mode)
        // we have clicked the context menu button, and we have to find a reference to the 
        // currently selected policies (there could be many, and we will delete all of them):
        //let policy = this.app.store.state.policy !!! potentially buggy: the store.state.policy can be set from other places as well
        //if (policy) {
        //    // Option A: looks like we called the 'delete' from inner item button:
        //    // delete only this policy
        //    items = [];
        //    items.push(policy);
        //}
        //else { // Option B: called from context menu, delete all selected policies:            
            items = findSelectedItems(this.state.policies);  // this allows for multiselection
        //}
        
        const length = items.length;
        if (length === 1) {
            title = app.R.RemovePolicy;
            info = app.R.RemovePolicyInfo;
            if (items[0]) {
                // replace the placeholder
                let replacements = {
                    '{n}': items[0].Name
                };
                info = template(info, replacements);
            }            
        }
        else if (length > 1) {
            title = app.R.RemovePolicies;
            info = app.R.RemovePoliciesInfo;
            let names = convertPolicyNamesToText(items);
            // replace the placeholder            
            let replacements = {
                '{n}': names
            };
            info = template(info, replacements);
        }

        return (
            <Modal show={true} onHide={this.closeRemovePolicyModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='labelZ lines-3'>
                        {info}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeRemovePolicyModal}>
                        {app.R.Cancel}
                    </Button>
                    <Button variant="primary" onClick={(e) => onRemovePolicyConfirmed(e, items, this)}>
                        {title}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderRemovePolicyErrorModal = () => {

        let app = window.app;
        let info = app.R.RemovePolicyError;
        if (app.store.state.policy) {
            // replace the placeholder
            let replacements = {
                '{n}': app.store.state.policy.name
            };
            info = template(info, replacements);
        }

        return (
            <Modal show={this.state.showRemovePolicyErrorModal} onHide={this.closeRemovePolicyErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{app.R.RemovePolicy}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{info}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeRemovePolicyErrorModal}>
                        {app.R.Close}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderAddPolicyButton = () => {

        //console.log('renderAddPolicyButton : user configuration: ', this.app.store.state.options.userConfiguration)
        if (!canCreateProtections(this.app)) return null;
        if (!isProtector(this.app)) return null;

        // on click we will call 'policyManager.editPolicy', passing a null policy there,
        // so the EditPolicy component will be called with id=0, and it will detect it 
        // to create a new policy model.
        return (
            <SmallButton
                contentClass='icon-add'
                onClick={(e) => { editPolicy(e, this.app, /* full policy */ null, /* simple policy */ null, RequestType.None, /* is new policy */ true) }}
            />
        );
    }

    renderBackButton = () => {

        return (
            <SmallButton
                contentClass='icon-back'
                onClick={onReturnBack}
            />
        );
    }

    renderOkButton = (alwaysShowLabel) => {

        //let labelText = this.app.R.Accept;
        let labelText = this.app.R.Protect;
        
        //const delayedTaskType = this.app.store.state.delayedTaskType;
        //if (delayedTaskType === RequestType.CustomProtection) {
        //    // for custom protection
        //    labelText = this.app.R.Accept;
        //}

        let label = alwaysShowLabel ?
            // always show label
            <h3 className=' label '>{labelText}</h3>
            :
            // show label only when screen is not small
            <h3 className=' label inline-hide-for-small'>{labelText}</h3>

        const itemsCount = countSelectedItems(this.app.store.state.policies);
        const disabledClass = itemsCount > 0 ? ' clickable' : ' disabled transparent'
        return (
            <div className={'label-and-button align-right inline' + disabledClass} onClick={(e) => { protectSelectedDocuments(e, this) }}>
                {label}
                <SmallButton className={disabledClass} contentClass='icon-accept' label={this.app.R.Accept} />
            </div >
        );
        //return (
        //    <div className='align-right inline clickable inline-hide-for-small' onClick={(e) => { onEditPolicyConfirmed(e, this) }}>
        //    <Button variant='primary' type='submitX' className='login-button'>
        //        {labelText}
        //        </Button>
        //    </div >
        //);
    }

    renderTitleBar = (app, params) => {

        //let title = this.getTitle(app, params);
        //let title = this.props.selectionMode ?
        //    this.app.R.SelectProtection
        //    :
        //    this.app.R.Protections;
        let title = this.app.R.Protections;
        // we need a policy model (to be rendered) and users (so permissions can be defined)
        // is the policy and users loaded? if not, show the 'loading' text or icon:
        if (!app.store.state.policies || !app.store.state.users || !app.store.state.groups) {
            return (
                <div>
                    {this.renderBackButton()}
                    <span className='label extra-space'></span>
                    <h3 className='label'>{title + ': ' + app.R.Loading}</h3>
                </div>
            );
        }

        let titleBar =
            <div className='extra-margin-bottom'>
                {this.renderBackButton()}
                <span className='label extra-space'></span>
                <h3 className='label'>{title}</h3>
                {this.renderAddPolicyButton()}
                {this.props.selectionMode && this.renderOkButton(false)}
                {this.props.selectionMode && (
                    <div><br /><span className='long-tip'>{this.app.R.SelectPolicyTip}</span></div>
                )}
            </div>
           
        return titleBar;
    }

    //=========================================================================
    // MAIN RENDER

    render() {
        //console.log('Policies.render()...')
        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)  
            return null;        

        const app = this.app;
        const filter = this.filter;
        const { page } = filter;

        updateAppHistory(app, this);

        // TO DO: how to detect if this component was in the selector state?
        // possibly by using a different route?
        // ...
        // if policy list is in the "selector� mode for protecting the external files, and there are 
        // no selected files, then return to home (this page could be reloaded and context data lost).
        //if (!app.store.state.selectedDocuments || app.store.state.selectedDocuments.length === 0) {
        //    goToHomePage();
        //    return;
        //}

        let contextMenu = '';
        if (this.state.showContextMenu) {
            var divStyle = {
                display: 'block',
                position: 'absolute',
                left: this.app.clientX,
                top: this.app.clientY
            };
            //contextMenu = <div style={divStyle}><StaggeredMenu2  /></div>;
            contextMenu = <div className='over' style={divStyle}>
                {this.renderDeleteButton(null)}
                {this.renderEditButton(null)}
                          </div>;
        }

        let showPolicyTypeFilter = true; // used to hide the combo from demo until the filter code is finished
        let showPageSizeSelector = false;
        return (
            <div>
                {this.renderTitleBar(app)}
                

                {isProtector(app) && (
                    <div className='filters'>

                        {showPolicyTypeFilter && <RadioButtonsList
                            className='inline'
                            items={PolicyTypeModels(this.app)}
                            onChange={this.onSelectPolicyType}
                            selectedItemId={filter.policyType}
                        />}

                        {
                            //<div className="pagination">
                            //    <Dropdown className=''>
                            //        <Dropdown.Toggle variant="secondary">
                            //            {skip}
                            //        </Dropdown.Toggle>

                            //        <Dropdown.Menu>
                            //            {this.pageSizeList.map((option, i) =>
                            //                (<Dropdown.Item
                            //                    key={`page-size-${i}`}
                            //                    onClick={(e) => this.onPageSizeListChange(e, option.id)}
                            //                >
                            //                    {option.label}
                            //                </Dropdown.Item>)
                            //            )}
                            //        </Dropdown.Menu>
                            //    </Dropdown>
                            //</div>
                        }

                        {
                            // SearchFilter - minCharacters:
                            // the JavaScript search filter can work from 2 characters, but we
                            // set 3 so it behaves the same as the Documents SQL search script
                        }
                        <SearchFilter
                            app={app}
                            showSearchIcon
                            placeholder={app.R.Search}
                            value={filter.text}
                            minCharacters={3}
                            onChange={this.onTextFilterChange}
                        />
                    </div>
                )}

                {this.renderProtectionList(app, page.items)}

                    {
                        // remove the ALL /MOST USED /UNUSED combo for now, later add sorting trigger to the column head
                        //<SelectList
                        //    items={policySubTypes(this.app)}
                        //    selectedItem={policySubType}
                        //    onChange={this.onSelectPolicyFilterChange}
                        ///>
                    }                    

                {isProtector(app) && (

                    <Paginator2                    
                        filter={this.filter}                    
                        onChange={this.changePage}
                    />
                )}
                    <br />
                
                    {showPageSizeSelector && (
                        <SelectList
                            title={app.R.ResultsPerPage}
                            variant="secondary"
                            items={page.sizeModels}
                            selectedItem={page.sizeModel}
                            onChange={this.changePageSize}
                        />                    
                    )}
                
                
                {this.renderRemovePolicyModal()}
                {this.renderRemovePolicyErrorModal()}   
                {this.props.selectionMode && this.renderOkButton(/* alwaysShowLabel */ true)}
                <br className='clear-left' /><br /><br />               
                {contextMenu}
            </div>
        );
    }
}
