import React, { Component, Fragment } from 'react';
import { Form } from "react-bootstrap";

import {
    clickUserFromInvitationsList,
    findObjectWithId,
    onReturnBack,
    selectInvitiationUserFromMultiSelector,
    setExcludedForItems,
    unselectItems,
    updateAppHistory
} from '../managers/appManager';
import * as appManager from '../managers/appManager';
import * as taskManager from '../managers/taskManager';
import { sendInvitation } from '../managers/userManager';
import { Item, ItemColumn, ItemHead, ItemList, SmallButton } from '../components/atoms';
import { template } from '../common/format';
import {
    Modal, Paginator2,
    SearchFilter, SelectList,
    MultiSelect,
} from "../components/organisms";
import { DateOptions, PageSizeOptions } from "../common/constants";
import { getUserFullNameAndUserName } from '../managers/policyManager';


export class Invitations extends Component {

    displayName = Invitations.name;
    
    constructor(props) {
        super(props);
        this.preparedListOfLanguages = {};

        this.app = window.app;
        this.app.store.state.options.appSettings.invitationLanguages.forEach(lang => {
            this.preparedListOfLanguages[lang.id] =
                template(this.app.invitations[lang.id], { '{0}': this.getFullNameOrUserName() });
            this.preparedListOfLanguages[lang.id] =
                template(this.preparedListOfLanguages[lang.id], { '{0}': this.getFullNameOrUserName() });
        })

        this.filter = {
            // search options and params
            text: '',

            items: this.app.store.state.invitationUsers || [], // (initially it's better to keep it here as 'this.filter' (synchronously) instead of within state (asynchronously updated). 
            // pagination
            page: {
                index: 0,
                inputText: '1',
                //size: PageSizeOptions[0].id,
                size: this.app.store.getOption({ section: 'page', option: 'size' }),
                //sizeModel: PageSizeOptions[0], // default page size option = 10 items per page           
                items: [], // page items ,
                showPageInput: true,
                showTotalPages: true,
                totalPages: 0
            }
        };

        let languageId = this.app.store.getOption({ section: 'invitations', option: 'language' });
        if (!languageId || languageId === '') { 
            // check if we have the current application language set
            if (this.app.settings.language && this.app.settings.language.id) {
                languageId = this.app.settings.language.id;                
            }
            else { // get the default languauge of the application
                languageId = this.app.store.options.appSettings.defaultLanguage;
            }
        }
            
        this.state = {
            // synchronized with the global store
            users: this.app.store.state.users || [],
            invitationUsers: this.app.store.state.invitationUsers || [],
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,

            // component with search options (page number, type, name) and the filtered items
            filter: this.filter,

            showContextMenu: false,
            showDeleteButton: false,
            showEditButton: false,

            modalInvitations: this.app.invitations,
            //selectedLang: this.app.store.state.options.appSettings.languages[0],
            languageId: languageId,
            invitationTemplate: this.preparedListOfLanguages[languageId]
        };        

        // un-exclude all users first:
        setExcludedForItems(this.app.store.state.users, false);
        // exclude the ones that are already on the selected list
        setExcludedForItems(this.app.store.state.invitationUsers, true);

    }
    //===============================================================
    getFullNameOrUserName() {

        const name = this.app.user.F;//FullName;
        if (name && name != '')
            return name;
        else
            return this.app.user.U;//UserName;        
    }


    //componentWillMount() {

    //    // un-exclude all users first:
    //    setExcludedForItems(this.app.store.state.users, false);
    //    // exclude the ones that are already on the selected list
    //    setExcludedForItems(this.app.store.state.invitationUsers, true);
    //}


    componentDidMount() {

        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'items' state (or other):
        window.app.locator.invitationsPage = this;
        this.app.store.subscribe({ property: 'users', component: this });
        this.app.store.subscribe({ property: 'invitationUsers', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });

        if(this.state.users) {
            this.filterItems();
            this.fillPage();
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if ((prevState.users !== this.state.users) ||
            (prevState.invitationUsers !== this.state.invitationUsers)) {
            this.filterItems();
            this.fillPage();
        }
    }

    componentWillUnmount() {

        window.app.locator.invitationsPage = null;
        this.app.store.unsubscribe({ property: 'users', component: this });
        this.app.store.unsubscribe({ property: 'invitationUsers', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
    }
    //===============================================================

    filterItems = () => {
        ////console.log('policies : filter items... store.state.policies: '); //console.log(this.app.store.state.policies);
        ////console.log('policies : filter items... filter: '); //console.log(this.filter);
        const filter = this.filter;
        if (!this.state.invitationUsers) return;
        filter.items = this.state.invitationUsers;
        if (!filter.items) return;

        // step 1 (quicker): policy type filter (my policies/shared with me)
        //filter.items = filter.items
        //            .filter(policy => { return(this.filterPolicyType(this.app, policy, filter.policyType.id)) });
        //    //console.log('filter.items after filtering policy type: ');
        //    //console.log(filter.items);

        // step 2 (slower): text filter for polices search
        //if (filter.text !== '') {
        //    filter.items = filter.items
        //        .filter(policy => `${policy.Name}${this.getPolicyUserNamesForSearch(this.app, policy)}`.toLocaleLowerCase()
        //            .search(filter.text.toLocaleLowerCase()) > -1)
        //}

        // step 2 (slower): text filter for permissions search
        //if (filter.text !== '') {
        //    filter.items = filter.items
        //        //.filter(item => `${item.UserName}${item.FullName}`.toLocaleLowerCase() // a user model variant
        //        //.filter(item => `${item.UserName}`.toLocaleLowerCase() // this is a simple search variant fpr permission model
        //        .filter(item => getUserNameAndFullName(item).toLocaleLowerCase() // this is a more expensive variant that finds a user model to search in full name too
        //            .search(filter.text.toLocaleLowerCase()) > -1)
        //}
        if (!filter.items) { filter.items = [] }; // for security, to not to process and render null list later
        //this.setState({ filter: this.filter });
    };

    fillPage = () => {

        const { items, page } = this.filter;
        if (!items) return;
        page.items = items
            .slice(
                (page.index * page.size),
                (page.index * page.size) + page.size,
            );
        this.setState({ filter: this.filter });
    };

    changePage = (model) => {

        const filter = this.filter;
        const { page } = filter;
        if (model) { page.index = model.index; }
        page.inputText = page.index + 1; // set the text for the input control 
        //unselectItems(this.state.policies); // we don't keep selected items from other pages for this component        
        this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter
        this.fillPage();
    };


    changeTextFilter = (value) => {
        let items = this.app.store.state.users;

        if(value !== '') {
            items = this.app.store.state.users
                .filter(item => getUserFullNameAndUserName(item).toLocaleLowerCase()
                .search(value.toLocaleLowerCase()) > -1)
        }
        //`${item.UserName}${item.FullName}`
        this.loadData({
            items: items,
            TextFilter: value,
            PageIndex: 0,
            PageSize: this.state.skip,
        })
    }

    //closeModal = () => {
    //    const { users } = this.state;

    //    const remArraItems = users.filter(i => !i.excluded);

    //    this.setState({

    //        users: remArraItems,
    //    })

    //    unselectItems(this.state.users);
    //};

    selectMultiselectorItem = (e, item) => {

        selectInvitiationUserFromMultiSelector(e, item);
        this.filterItems();
        this.fillPage();
    }

    onChangeLanguage = (e, option) => {

        //console.log('onChangeLanguage: option:', option);
        this.app.store.setOption({ section: 'invitations', option: 'language', value: option.id });
        this.setState({
            languageId: option.id,
            invitationTemplate: this.preparedListOfLanguages[option.id]
        })
    }

    changePreparedListOfLanguages = (e) => {
        this.setState({
            invitationTemplate: e.target.value
        })
    }

    sendInvitationFromContextMenu(e) {
        // get the selected users and send the invitation only for them?
        //this.sendInvitation(e)
    }
    
    sendInvitation = (e) => {

        if (e) e.stopPropagation();

        const { invitationTemplate } = this.state;
        const { items } = this.filter;

        //const _selectedUserNames = users.filter(i => i.selected).map(i => i.UserName);
        const selectedUserNames = items.map(i => i.U);//UserName);

        if (selectedUserNames.length) {
            sendInvitation(this, selectedUserNames, invitationTemplate);
            items.map(user => user.excluded = false); // restore the original 'excluded' state so the user will show up in search control
        }

        unselectItems(this.state.users);

    }

    onSendInvitationCompleted = () => {
        //console.log('component.onSendInvitationCompleted: this.filter.items: ', this.filter.items)
        // reset the selected users
        this.app.store.state.invitationUsers = []; // no need to call the set state
        //this.filter.items = [];
        //this.setState({ filter: this.filter });
    }

    removeUser = (e, user, component) => {

        //console.log('remove selected users. user: ', user)
        if (user) {
            // delete the user
            // ...
        }
        else {
            // find all the selected users and delete all of them
            let users = this.app.store.state.invitationUsers; 
            if (users) {
                users.map(user => { if (user.selected) user.excluded = false }) // reset the 'excluded' flag for the users that are going to be deleted from the list, so they are enabled to be shown again in the search field
                users = users.filter(user => !user.selected);
                this.app.store.setState({ invitationUsers: users });
                this.setState({ showContextMenu: false }); 
            }                
        }          
    }
    //=======================================================================
    // RENDER

    renderEditButton = (policy) => {

        if (this.state.showEditButton) {
            return (
                <div className='context-menu-button edit'>
                    <SmallButton contentClass={'context icon-invite-negative'} onClick={(e) => { this.sendInvitationFromContextMenu(e) }} />
                </div>
            );
        }
        else { // render it, but disabled
            return (
                <div className='context-menu-button edit'>
                    <SmallButton className='disabled' contentClass={'context icon-invite-negative'} />
                </div>
            );
        }        
    };

    // When rendered from context menu the policy will be null, 
    // in which case it will be assigned later in policy manager
    renderDeleteButton = (user) => {

        // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
        // render the context menu buttons we use that model to check if we can edit/delete
        //if (!policy)
        //    policy = this.app.store.state.policy
        //if (policy) {
        //    if (!canDeletePolicy(this.app.user, policy))
        //        return null;
        //}

        if (this.state.showDeleteButton) {
            return (
                <div className='context-menu-button delete'>
                    <SmallButton contentClass={'context icon-delete'} onClick={(e) => { this.removeUser(e, user, this) }} />
                </div>
            );
        }
        else {// render it, but disabled
            return (
                <div className='context-menu-button delete'>
                    <SmallButton className='disabled' contentClass={'context icon-delete'} />
                </div>
            );
        }
        //return ('');        
    }

    renderUsers = (users) => {
        if (!users) return;

        return (

            <ItemList>
                {/*<Item className='head disabled'>
                    <ItemColumn className='col-12 sm-col-6'>{this.app.R.Name}</ItemColumn>
                    <ItemColumn className='col-12 sm-col-6'>{this.app.R.Email}</ItemColumn>
                </Item>*/}

                {users.length
                    ? users.map((user, i) => (                        
                        <Item
                            key={'user ' + i}                            
                            model={user}
                            onClick={(e) => clickUserFromInvitationsList(e, null, this)}
                        >
                            <ItemColumn className='xs-col-12 xs-border-bottom xs-no-border-right s-col-6'>{user.F}</ItemColumn>
                            <ItemColumn className='xs-col-12 s-col-6'>{user.U}</ItemColumn>
                        </Item>
                    ))
                    : (<Item className='disabled'>
                        <ItemColumn className='col-12 text-center'>{this.app.R.NoItems}</ItemColumn>
                    </Item>)}

            </ItemList>
        )
    }

    //renderColumns(columns) {
    //    return (
    //        columns.map((column, i) => (
    //            <td key={`col-${column}-${i}`}
    //                className={`item-col one-line ${i && `hidden-sm`}`}
    //            >
    //                {column}
    //            </td>
    //        ))
    //    )
    //}


    renderBackButton = () => {

        return (
            <SmallButton
                contentClass='icon-back'
                onClick={onReturnBack}
            />
        );
    }

    renderOkButton = () => {
         
        return (
            <div className='label-and-button align-right inline margin-right clickable' onClick={(e) => { this.sendInvitation(e) }}>
                <h4 className=' label'>{this.app.R.Send}</h4>
                <SmallButton contentClass='icon-accept' />
            </div >
        );
    }

    render() {
        //console.log('Invitations.render : showContextMenu: ', this.state.showContextMenu)
        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)
            return null;

        const app = window.app;
        const store = app.store;

        updateAppHistory(app, this);

        if (!store.getOption({ key: 'SendInvitationPermission' })) onReturnBack(); // TO DO: this can be hacked on client: server validation is needed when user sends invitations!

        const filter = this.filter;
        const { page } = filter;
        const { users } = this.app.store.state;
        const {            
            languageId,
            invitationTemplate,
        } = this.state;

        const { invitationMode, permissionMode, history } = this.props;

        if (history) app.history = history;

        // GOOD SYNTAX EXAMPLE FOR CLONING OBJECTS AND ADDING NEW PROPERTIES:
        //const languages = this.app.store.state.options.appSettings.invitationLanguages.map(lang => ({ ...lang, id: lang.code }))	
        const languages = this.app.store.state.options.appSettings.invitationLanguages;
        const selectedLanguage = findObjectWithId(languages, languageId);

        //console.log('invitation languages: ', languages);
        if (!users) return (
            <div>
                {this.renderBackButton()}
                <span className='label extra-space'></span>
                <h3 className='label'>{this.app.R.SendInvitation}</h3>                
            </div>
        );

        let contextMenu = '';
        if (this.state.showContextMenu) {
            var divStyle = {
                display: 'block',
                position: 'absolute',
                left: this.app.clientX,
                top: this.app.clientY
            };
            //contextMenu = <div style={divStyle}><StaggeredMenu2  /></div>;
            contextMenu = <div className='over' style={divStyle}>
                {this.renderDeleteButton(null)}
                {this.renderEditButton(null)}
            </div>;
        }

        return (
            <Fragment>
                <div>
                    {this.renderBackButton()}
                    <span className='label extra-space'></span>
                    <h3 className='label'>{this.app.R.SendInvitation}</h3>      
                    {this.renderOkButton()}
                </div>
                <br />
                <div className='' >
                    <h4 className='label'>{this.app.R.CustomizeInvitation}</h4>
                    <div className='labelXXXX'>
                        <SelectList
                            className='language-selector'
                            items={languages}
                            selectedItem={selectedLanguage}
                            onChange={this.onChangeLanguage}
                            variant='secondary'
                            />
                    </div>
                </div>

                <div className="invitation-box">
                    <textarea
                        className="textarea"
                        value={invitationTemplate}
                        onChange={this.changePreparedListOfLanguages}/>
                    
                </div>
                <h4>{this.app.R.SelectUsers}</h4>
                <div>
                    <MultiSelect           
                        items={users.filter(user => !user.excluded)}
                        onChange={(e, item) => this.selectMultiselectorItem(e, item)}
                        label={this.app.R.SelectUsersLong}
                    />
                </div>
                <div>
                    {
                        //this.renderUsers(users.filter(contact => contact.selected))
                        this.renderUsers(this.filter.page.items)
                    }
                </div>

                <Paginator2
                    filter={this.filter}
                    onChange={this.changePage}
                />
                <br />
                {this.renderOkButton()}
                {contextMenu}
            </Fragment>
        );
    }
}
