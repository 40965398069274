
export const QueueType = {
    None: 0,
    Enqueued: 1
}

// task general state
export const TaskState = {
    None: 0,   
    Processing: 1,
    Completed: 2,
}

// task sub-state is used for UI
export const TaskSubState = {
    Error: -1,
    None: 0,    
    Cancelled: 1,
    Waiting: 2,
    WaitingForAuthorization: 3,
    Starting: 4,
    Uploading: 5,
    DocumentProcessing: 6,
    ClickToDownload: 7,
    ClickToDownloadAgain: 8,
    Downloading: 9,
    Downloaded: 10,
    //Completed: 11,
}

export const PolicyType = {    
    All: 1,
    Own: 2,
    Shared: 3,
    Corporative: 4,
    Fav: 5,
    MostUsed: 6,
    Unused: 7
}

export const UserType = {
    All: 1,
    Internal: 2,
    External: 3    
}

export const HomePolicyTypeModels = (app) => [

    { id: PolicyType.All, label: app.R.AllProtections },
    { id: PolicyType.Fav, label: app.R.FavouriteProtections }    
];

export const PolicyTypeModels = (app) => [

    { id: PolicyType.Own, label: app.R.MyProtections },
    { id: PolicyType.Shared, label: app.R.ProtectionsSharedWithMe }
];

// for a moment this one is not used:
export const PolicySubTypeModels = (app) => [

    { id: PolicyType.All, label: app.R.All },
    { id: PolicyType.MostUsed, label: app.R.MostUsed },
    { id: PolicyType.Unused, label: app.R.Unused },
];

export const UserTypeModels = (app) => [

    { id: 1, label: app.R.OrganizationUsers },
    { id: 2, label: app.R.Guests }
];

export const RequestType = {

    None: 0,
    // keep the space of 20 items for each group (1-20, 21-40, 41-60...)
    // When we register the request in the monitoring  DB, we will store the numeric type ID 
    // along with the text type ID: the numeric indexes are faster.
    // 1. The first group of data types: the application data that gets loaded at start.
    FactorySiteCss: 1,
    CustomSiteCss: 2,
    OrgCustomSiteCss: 3,

    FactoryColorsCss: 4,
    CustomColorsCss: 5,
    OrgCustomColorsCss: 6,

    FactoryImagesCss: 7,
    CustomImagesCss: 8,
    OrgCustomImagesCss: 9,

    FactoryConfiguration: 10,
    CustomConfiguration: 11,
    OrgCustomConfiguration: 12,

    FactoryLanguage: 13,
    CustomLanguage: 14,
    OrgCustomLanguage: 15,

    FactoryInvitations: 16,
    CustomInvitations: 17,
    OrgCustomInvitations: 18,

    // 2. The user data that gets loaded when the app is ready and the user is authenticated.
    // In the first call we check if user is ok before we download other data:
    Preauthorize: 100,
    AuthorizeWithCookie: 101,
    AuthorizeWithUserNamePassword: 102,
    GetTokenWithAuthorizationCode: 103,
    GetTokenWithRefreshToken: 104,
    UserIsValid: 105,
    UserConfiguration: 106,
    UserDetails: 107,
    UserGroups: 108,
    UserPolicies: 109, // user policy has Favourite + Counter properties returned
    UserPolicy: 110, // user policy has Favourite + Counter properties returned
    UserClassifierMetadataPolicies: 111,

    // 3. The organization data that gets loaded when the either user or administrator is logged
    OrganizationConfiguration: 200,
    OrganizationGroups: 201,
    CustomPolicyGuid: 202,
    OrganizationPolicies: 203,
    OrganizationUsers: 204,

    // 4. The documents with different filters

    DocumentsProtected: 300,
    DocumentsAccessed: 301,
    DocumentsWarnings: 302, // plural: for all documents for current user
    DocumentInfo: 303,
    DocumentInfoExternal: 304,
    DocumentTracking: 305,
    DocumentWarnings: 306, // single document: warnings for the current document

    // 5. Protection
    CustomProtection: 400,
    CustomProtectionExternal: 401,
    Download: 402,
    DownloadExternal: 403,
    Protect: 404,
    ProtectExternal: 405,
    Unprotect: 406,
    UnprotectExternal: 407,
    

    // 6. Other operations
    EditPolicy: 500,
    NewPolicy: 501,
    RemovePolicy: 502,
    GetRemovedPolicy:503,
    RestorePolicy: 504,
    SetFavouritePolicy: 505,

    AddUsers: 506,
    SendInvitation: 507,
    
    TestProtection: 508,
    SaveUserConfiguration: 509,
    SaveUserDetails: 510,

    NotifyUpdatedPolicy: 511,
    GetUserPermissions: 512,

    //Policy: 98, // for a general use policies, e.g. from admin, without Favourite + Counter properties returned

    //...
}


// user datasets that needs to be loaded before we start working with the application
// Note: it's a constant object but it has dynamic flag inside.
export const UserDataRequestTypes = [

    { type: RequestType.UserConfiguration, completed: false },
    { type: RequestType.UserDetails, completed: false },
    { type: RequestType.UserGroups, completed: false },
    { type: RequestType.UserPolicies, completed: false },
    { type: RequestType.CustomPolicyGuid, completed: false },
    { type: RequestType.OrganizationUsers, completed: false }
];


export const UserRole = {

    None: 0,    
    Protector: 2,    
    Consumer: 3,
    Group: 4,
    Superuser: 5,
}

export const DateType = {
   
    Anytime: 'ANYTIME',   
    Span_24H: '24H',
    Span_7D: '7D',
    Span_30D: '30D',
    Span_3M: '3M',
    Span_6M: '6M'
}

export const DateOptions = (app) => [
    
    { id: DateType.Anytime, label: app.R.DateAnytime },
    { id: DateType.Span_24H, label: app.R.DateLast24Hours },
    { id: DateType.Span_7D, label: app.R.DateLast7Days },
    { id: DateType.Span_30D, label: app.R.DateLast30Days },
    { id: DateType.Span_3M, label: app.R.DateLast3Months },
    { id: DateType.Span_6M, label: app.R.DateLast6Months }
];


export const PageSizeType = {

    Size10: 10,
    Size20: 20,
    Size50: 50,
    Size100: 100
}

export const PageSizeOptions = [

    { id: PageSizeType.Size10, label: '10' },
    { id: PageSizeType.Size20, label: '20' },
    { id: PageSizeType.Size50, label: '50' },
    { id: PageSizeType.Size100, label: '100' },
]

// Desktop Warning types: has error with 'All' type!
//    [Description("All#Todos#Alle#Wszystkie#Tout#Všetko")]
//Any = 0xF,

//    [Description("Access blocked#Acceso bloqueado#Zugang geblockt#Dostęp zablokowany#Accès bloqué#Prístup zablokovaný")]
//AccessBlocked = 0x1,

//    [Description("User added in document#Usuario añadido al documento#Benutzer im Dokument beigefügt#Użytkownik dodany do dokumentu#Utilisateur ajouté dans le document#Pridaný užívateľ do dokumentu")]
//UserAddedInDocument = 0x2,

//    [Description("User added in protection#Usuario añadido a la protección#Benutzer in der Schutz Policy beigefügt#Użytkownik dodany do polityki#Utilisateur ajouté en protection#Pridaný užívateľ do pravidiel ochrany")]
//UserAddedInProtection = 0x4,

//    [Description("Unprotected#Desprotegido#Ungeschützt#Odbezpieczony#Non protégé#Ochrana zrušená")]
//UnprotectWarning = 0x8,

//    [Description("User does not have rights#Usuario sin permisos#Benutzer hat keine Berechtigung#Użytkownik nie ma uprawnień#L'utilisateur n'a pas de droits#Užívateľ bez oprávnenia")]
//UserWithNoRights = 0x10,

//    [Description("Blocked IP#IP Bloqueado#Geblockte IP#Zablokowany IP#IP bloquée#Zablokovaná IP")]
//ExcludedIPWarning = 0x20,

//    [Description("Blocked Office Mobile#Office Mobile Bloqueado#Geblockte Office Mobile#Zablokowany Office Mobile#Office Mobile bloqué#Zablokovaný Office Mobile")]
//MobileOfficeBlockedWarning = 0x40
//--------------------------------------------------------------------------------------------
// convertor (what a waste of time with converting...)
//case SealPath.Monitoring.Client.StatsService.WarningType.AccessDeniedWarning:
//convertedWarningType = SealPath.Entities.WarningType.UserWithNoRights;
//break;
//                case SealPath.Monitoring.Client.StatsService.WarningType.UserBlockedWarning:
//convertedWarningType = SealPath.Entities.WarningType.AccessBlocked;
//break;
//                case SealPath.Monitoring.Client.StatsService.WarningType.DocumentBlockedWarning: //DHA 10/01/2015 Incluimos el Warning de Documento Bloqueado
//convertedWarningType = SealPath.Entities.WarningType.AccessBlocked;
//break;
//                case SealPath.Monitoring.Client.StatsService.WarningType.NewUserWarning:
//convertedWarningType = SealPath.Entities.WarningType.UserAddedInDocument;
//break;
//                case SealPath.Monitoring.Client.StatsService.WarningType.ProtectionModifiedWarning:
//convertedWarningType = SealPath.Entities.WarningType.UserAddedInProtection;
//break;
//                case SealPath.Monitoring.Client.StatsService.WarningType.UnprotectWarning:
//convertedWarningType = SealPath.Entities.WarningType.UnprotectWarning;
//break;
//                case SealPath.Monitoring.Client.StatsService.WarningType.ExcludedIPWarning:
//convertedWarningType = SealPath.Entities.WarningType.ExcludedIPWarning;
//break;
//                case SealPath.Monitoring.Client.StatsService.WarningType.MobileOfficeBlockedWarning:
//convertedWarningType = SealPath.Entities.WarningType.MobileOfficeBlockedWarning;
//break;
//                case SealPath.Monitoring.Client.StatsService.WarningType.All:
//                default:
//convertedWarningType = SealPath.Entities.WarningType.Any;
//break;

// Server Warning types --> use these for web client, so no conversions are needed!
    //WarningBaseValue = 16,
    //AccessDeniedWarning = 16,
    //NewUserWarning = 32,
    //ProtectionModifiedWarning = 64,
    //UnprotectWarning = 128,
    //UserBlockedWarning = 256,
    //DocumentBlockedWarning = 512,
    //ExcludedIPWarning = 1024,
    //MobileOfficeBlockedWarning = 2048,
    //All = AccessDeniedWarning | NewUserWarning | ProtectionModifiedWarning | UnprotectWarning | UserBlockedWarning | DocumentBlockedWarning | ExcludedIPWarning | MobileOfficeBlockedWarning

export const WarningOptions = (app) => [

    { id: WarningType.All /*4080*/, serverWarningType: 'All', label: app.R.All },
    { id: WarningType.AccessDenied /*16*/, serverWarningType: 'AccessDeniedWarning', label: app.R.UserWithNoRights },
    //{ id: 32, serverWarningType: 'NewUserWarning', label: app.R.UserAddedToDocument},
    //{ id: 64, serverWarningType: 'ProtectionModifiedWarning', label: app.R.UserAddedToProtection },
    { id: WarningType.ProtectionModified /*96*/, serverWarningType: 'NewUserWarning + ProtectionModifiedWarning', label: app.R.ProtectionModified },
    { id: WarningType.Unprotected /*128*/, serverWarningType: 'UnprotectWarning', label: app.R.Unprotected },
    //{ id: 256, serverWarningType: 'UserBlockedWarning', label: app.R.UserAccessBlocked },
    //{ id: 512, serverWarningType: 'DocumentBlockedWarning', label: app.R.DocumentBlocked },
    { id: WarningType.AccessBlocked /*768*/, serverWarningType: 'UserBlockedWarning + DocumentBlockedWarning', label: app.R.AccessBlocked },
    { id: WarningType.BlockedIP /*1024*/, serverWarningType: 'ExcludedIPWarning', label: app.R.BlockedIP },
    { id: WarningType.BlockedOfficeMobile /*2048*/, serverWarningType: 'MobileOfficeBlockedWarning', label: app.R.BlockedOfficeMobile},
];

export const WarningType = {

    All: 4080,
    AccessDenied: 16,
    ProtectionModified: 96,
    Unprotected: 128,
    AccessBlocked: 768,
    BlockedIP: 1024,
    BlockedOfficeMobile: 2048
}

//export const WarningOptionsNew = (app) => [

//    { id: WarningType.Anytime, label: app.R.DateAnytime },
//    { id: WarningType.Span_24H, label: app.R.DateLast24Hours },
//    { id: WarningType.Span_7D, label: app.R.DateLast7Days },
//    { id: WarningType.Span_30D, label: app.R.DateLast30Days },
//    { id: WarningType.Span_3M, label: app.R.DateLast3Months },
//    { id: WarningType.Span_6M, label: app.R.DateLast6Months }
//];

// The access permissions are binary flags:
//Nothing = 0x00000000,
//FullControl = 0x00000001,
//View = 0x00000002,
//EditPolicy = 0x00000004,
//Save = 0x00000008,
//Export = 0x00000010,
//Print = 0x00000020,
//Forward = 0x00000040,
//Reply = 0x00000080,
//ReplyAll = 0x00000100,
//Extract = 0x00000200,
//objModel = 0x00000400,
//ViewRightsData = 0x00000800,
//EditRightsData = 0x00001000,
//AddUsers = 0x00002000,
//Future = 0x00004000
// C# code example:
//if ((executorUser.AccessPermissions & Rights.AddUsers) == Rights.AddUsers)

export const AccessPermissionType = { // OR GOOD?
    Nothing: 0,
    FullControl: 1,
    View: 2,
    Edit: 4,
    Save: 8,
    Export: 16,
    Print: 32,
    Forward: 64,
    Reply: 128,
    ReplyAll: 256,
    Extract: 512,
    AllowMacros: 1024,
    ViewRightsData: 2048,
    EditRightsData: 4096,
    AddUsers: 8192,
    Future: 16384
}

export const AccessPermissionModels = (app) => [
    
    { id: AccessPermissionType.View, label: app.R.View, type: 'permission', description: '' },
    { id: AccessPermissionType.Edit, label: app.R.Edit, type: 'permission', description: '' },
    { id: AccessPermissionType.Print, label: app.R.Print, type: 'permission', description: '' },
    // !!! Extract is 256 or 512 ??? - TO DO: figure it out
    // (now I have 2 sources - one says it's 256, other, that it's 512)
    //{ id: 256, label: app.R.CopyAndPaste, type: 'permission', description: '' }, // 'copy and paste' is the 'extract' permission on RMS server
    { id: AccessPermissionType.Extract, label: app.R.CopyAndPaste, type: 'permission', description: '' }, // 'copy and paste' is the 'extract' permission on RMS server
    //{ id: AccessPermissionType.AddUsers, label: app.R.AddUsers, type: 'permission', description: '' },
    { id: AccessPermissionType.FullControl, label: app.R.FullControl, type: 'permission', description: '' }
];


export const MONTHS = (app) => [
    app.R.January,
    app.R.February,
    app.R.March,
    app.R.April,
    app.R.May,
    app.R.June,
    app.R.July,
    app.R.August,
    app.R.September,
    app.R.October,
    app.R.November,
    app.R.December,
];

export const WEEKDAYS_LONG = (app) => [
    app.R.Sunday,
    app.R.Monday,
    app.R.Tuesday,
    app.R.Wednesday,
    app.R.Thursday,
    app.R.Friday,
    app.R.Saturday,
    
];

export const WEEKDAYS_SHORT = (app) => [
    app.R.ShortSunday,
    app.R.ShortMonday,
    app.R.ShortTuesday,
    app.R.ShortWednesday,
    app.R.ShortThursday,
    app.R.ShortFriday,
    app.R.ShortSaturday,
    
];

// copied from 'SealPath.Entities.ResultCode' so we can receieve these result codes and react to it
export const ResultCode = {
    /// Ok
    Ok: 0,
    /// Error
    Error: -1,

    // PROTECTION SERVICE ERRORS
    
    /// Error getting policy rights from the policy Guid 
    ErrorGettingPolicyRightsByGuid: -2,
    /// Generic error getting policies from the server
    ErrorGettingPolicies:  -3,
    /// User has no protection policies
    NoPoliciesForUser:  -4,
    /// Organization not found
    OrganizationNotFound: -5,
    /// Error checking if the file is a protected file
    ErrorCheckingIfIsProtectedFile: -6,
    /// Policy is expired
    ErrorExpirationDatePolicyNotValid: -7,
    /// Error creating protection policy from rights template
    ErrorCreatingProtectionPolicy: 8,
    /// Error protecting or reprotectiong a file because it is blocked by another operation
    ErrorProtectingBlockedFile: -9,
    /// Generic error protecting or reprotecting a file
    ErrorProtectingFile: -10,
    /// Error in a RMS encryption operation
    ErrorRmsOperationGeneric: -11,
    /// User has not enough rights (either to unprotect or to to reprotect) 
    ErrorUserNotEnoughRights: -12,
    /// Error initializing RMS environment
    ErrorRmsInitialization: -13,
    /// Error initializing RMS identity
    ErrorRmsIdentityInitialization: -14,
    /// Error opening a document because protection policy applied to the document has expired
    ErrorExpiredDocumentLicense: -15,
    /// User has not enough rights to read the users' rights of the protection policy applied to the document
    ErrorGettingRightsProtectedDocument: -16,
    /// User has not enough rights to unprotect a protected file
    ErrorUserNotEnoughRightsUnprotect: -17,
    /// Error creating a temporal file from the stream to protect.
    ErrorCreatingTempFileForStreamProtection: -18,
    /// Error accessing stream file before protecting
    ErrorAccessingStreamFile: -19,
    /// Error reading or working with temporal files while protecting stream data.
    ErrorDirectoryNotFoundWhileProtection: -20,
    /// IO error using temporal files to protect streams.
    ErrorIOExceptionWhileUsingTempFile: -21,
    /// Error to obtain WebEditorLicense
    WebEditorLicenseNotFound: -22,
    /// Is not required updated web editor license
    WebEditorLicenseNotUpdateRequired: -23,
    /// File is already protected
    FileIsProtected: -24,
    /// File is not protected
    FileIsNotProtected: -25,
    /// File is not found
    FileNotFound: -26,
    /// User has not enough rights to reprotect a protected file 
    ErrorUserNotEnoughRightsReprotect: -27,

    // IDENTITY SERVICE ERRORS

    /// Error getting current identity
    ErrorGettingCurrentIdentity: -101,
    /// Local server returns null list of logged identities in the local machine
    ErrorGettingIdentityList: -102,
    /// Server function returns an empty list of logged identities in the local machine
    EmptyIdentityList: -103,
    /// Error switching among identities in the local machine
    ErrorSwitchingIdentity: -104,
    /// Identity does not exist
    TryingToLogoutInexistentIdentity: -105,
    /// Identity does not exist
    IdentityNotFound: -106,
    /// Incorrect user or password
    ErrorLoginUserPassword: -107,
    /// Error initializing RMS identity 
    ErrorLoginRmsIdentityInit: -108,
    /// Error creating identity cookie required to do the RMS identity initialization process
    ErrorLoginSettingIdentityCookie: -109,
    /// Generic error in login process
    ErrorLoginGeneric: -110,
    /// The account is not logged in
    ErrorUserNotLogged: -111,
    /// Error switching among identities in the local machine
    ErrorSwitchingCurrentIdentity: -112,

    // COMMUNICATION ERRORS

    /// Local service not running properly
    ErrorLocalServiceWcfException: -301,
    /// Remote service not running properly 
    ErrorRemoteServiceWcfException: -302,
    /// Local service not available 
    ErrorLocalServiceNotAvailable: -303,
    /// Remote service not available 
    ErrorRemoteServiceNotAvailable: -304,
    /// Service is not reachable
    ErrorCommunication: -305,
    /// Service is not running
    ErrorCommunicationServiceNotAvailable: -306,
    /// Service is not answering in a timely manner
    ErrorCommunicationServiceTimeout: -307,


    // PROTECTION API: EXTERNAL STORAGE PROVIDER ERRORS

    /// Box auth error (e.g. expired or wrong token) - auth is needed    
    BoxAuthNeeded: -501,
    /// GoogleDrive auth error (e.g. expired or wrong token) - auth is needed
    GoogleDriveAuthNeeded: -502,
    /// OneDrive auth error (e.g. expired or wrong token) - auth is needed
    OneDriveAuthNeeded: -503,
    /// SharePoint auth error (e.g. expired or wrong token) - auth is needed    
    SharePointAuthNeeded: -504,



    // PROTECTOR APP ERRORS:
    DocumentInfoNotFound: -990,
    //UserWithNoRights: -991, this is WarningType, not the ResultType
    YouHaveNoRights: -992,
    OperationNotSupported: -993,

    // storage provider generic errors:
    GoogleDriveError: -994,
    OneDriveError: -995,
    BoxError: -996,
    SharepointError: -997,
}

export const ResultCodeInfo = (app, code) => {
    let info = '';
    switch (code) {
        case ResultCode.ErrorExpirationDatePolicyNotValid:
            info = app.R.PolicyExpired;
            break;
        case ResultCode.FileIsProtected:        
            info = app.R.FileIsProtected;
            break;
        case ResultCode.FileIsNotProtected:
            info = app.R.FileIsNotProtected;
            break;
        case ResultCode.FileNotFound:
            info = app.R.FileExpired; // we assume in this case that the file has expired
            break; 
        case ResultCode.DocumentInfoNotFound:
            info = app.R.DocumentInfoNotFound;
            break;
        case ResultCode.ErrorUserNotEnoughRights:
            info = app.R.YouHaveNoRightsToUnprotect;
            break; 
        case ResultCode.ErrorUserNotEnoughRightsReprotect:
            info = app.R.YouHaveNoRightsToUnprotect; 
            break; 
        case ResultCode.ErrorUserNotEnoughRightsUnprotect:
            info = app.R.YouHaveNoRightsToUnprotect; 
            break; 
        //case ResultCode.UserWithNoRights:
        //    info = app.R.UserWithNoRights; // this code is used for DocumentEvents: the message cannot say 'you', it needs to be 3rd person
        //    break; 
        case ResultCode.YouHaveNoRights:
            info = app.R.YouHaveNoRights; // used for Doc. Info operation executed by user: the message says 'you'
            break;       
        case ResultCode.OperationNotSupported:
            info = app.R.OperationNotSupported; 
            break;

        // storage providers:
        case ResultCode.GoogleDriveError:
            info = app.R.GoogleDriveError;
            break;

        default:
            info = app.R.RequestError; // a generic user-friendly message
            break;

    }
    return info;
}



export const ExternalStorageConstants = {
   
    GOOGLE_DRIVE_FOLDER: 'application/vnd.google-apps.folder',
}
    