import React, { Component } from 'react';

import { Checkbox, SmallButton } from "../../../components/atoms";
import { Item, ItemColumn, ItemList, Radio } from '../../../components/atoms';

export class PermissionsSection extends Component {

    constructor(props) {
        super(props);
        this.app = window.app;
    }
    // <div className='tab-section clear-left'>
    render() {
        const { hasPermissions, noPermissionsText, permissions } = this.props;
        return (
            <div className='clear-left'>
                <br />
                <Item className='head policies disabled'>                    
                    <ItemColumn
                        key={'col-header-permissions'}
                        className={'item-col one-line '}
                    >
                        {this.app.R.YourPermissions}
                    </ItemColumn >  
                </Item>
                <br />
                <br />
                {
                    !hasPermissions && <p className='margin-left'>{noPermissionsText}</p>
                }
                {
                    permissions && permissions.map(m =>
                        m.selected && <Checkbox
                            enabledX={m.enabled}
                            enabledX={false}
                            transparent={m.transparent}
                            key={'perm-' + m.id}
                            label={m.label}
                            checkboxClass='filter radio'
                            model={m}
                        />
                    )
                }                
            </div>
        )
    }
}