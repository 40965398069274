import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { NavMenu } from './NavMenu/NavMenu';
import { TasksOverlay, Loader } from '../atoms';


export class Layout extends Component {
  displayName = Layout.name

    render() {
        let app = this.props.app;
        let history = this.props.history;
        ////console.log('Layout: this.props.history: ' + this.props.history);

        //<Container fluid>
        //<Container> </Container>
        //<Col sm={3} className='menu'>
        //    <NavMenu app={app} />
        //</Col>
        //    <Col sm={9} className='content'>
        //        {this.props.children}
        //    </Col>
        app.leftColumn = React.createRef(); // create a reference, later assign it in the render function so we can use it to call the React element from code
        app.rightColumn = React.createRef(); // create a reference, later assign it in the render function so we can use it to call the React element from code

        // the loader visiblity is controlled by changing its 'visible' state.
        const loadingIcon = <Loader />;

        return (
            <div className='app'>
                
                <div className='menu' ref={app.leftColumn} >
                    <NavMenu history={history} app={app} />
                </div>

                <div className="content" ref={app.rightColumn} >
                    {this.props.children}
                    {loadingIcon}
                </div >
                
                <TasksOverlay app={app} />
            </div>
        );
    }
}
