import React, { Component } from 'react';
import { Button, Container, Dropdown, Form, Row } from "react-bootstrap";
import {SmallButton} from '../atoms';

export class NumericTextInput extends Component {

    constructor(props) {
        super(props);

        this.app = window.app;
        this.state = {
            filter: this.props.filter        
        };
    }
    
    onTextChange = (e, model) => {

        if (e) e.preventDefault();
        if (e) e.stopPropagation();

        const filter = this.props.filter;
        filter.inputText = e.target.value;
        this.setState({ filter });
    }

    onTextChangeCompleted = (e, model) => {

        if (e) e.preventDefault();
        if (e) e.stopPropagation();

        const { onChange } = this.props;
        const filter = this.props.filter;

        if (e.target) {
            const value = e.target.value;           
            if (value && !isNaN(value)) {
                filter.value = Math.floor(value);
                if (filter.value < filter.min) { filter.value = filter.min; }
                if (filter.value > filter.max) { filter.value = filter.max; }               
                onChange(); // this will call the parent component, the filter.value can get changed
            }
            filter.inputText = filter.value; // after calling parent component, set the text value back
            e.target.value = filter.inputText; // set the formatted text back
            e.target.blur();
            this.setState({ filter });
        }
    }

    render() {
        const { filter } = this.props;        

        // how many characters has the input field? adjust its width dynamically
        let inputText = '' + filter.inputText;
        let inputWidth = inputText.length ? inputText.length + 1 : 1;

        return (
            <div className='pagination-container'>                   
                <div className="paginationB">

                    <div className="label count-label ">

                        <Form.Control
                            style={{ width: '' + inputWidth + '.5rem' }}
                            className='inline page-index'
                            type="name"
                            placeholder={filter.inputText ? filter.inputText : ''}
                            name='page_index'
                            value={filter.inputText ? filter.inputText : ''}
                            onChange={e => { this.onTextChange(e) }}
                            onBlur={e => { this.onTextChangeCompleted(e) }}
                            onKeyPress={e => { if (e.key === "Enter") { this.onTextChangeCompleted(e) } }}
                            >
                        </Form.Control>

                    </div>
                </div>
            </div>
        )
    }
}