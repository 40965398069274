import React, { Component } from 'react';

export class SmallButton extends Component {

    constructor(props) {
        
        super(props);
        this.state = {
            selected: false
        };
        this.selected = false; // I need an accurate value here so it can be immediately used from external callback, this.state.checked will be used only to trigger for rendering
        this.onChange = null;
        this.type = '';
    }

    // now we have an internal method to manage the internal state, which
    // will make a callback to a function passed in props to render function.
    onInternalChange = (e) => {
        
        this.selected = this.selected ? false : true; // this one sets us an immediately available value
        this.setState({ selected: this.selected }); // this will make it re-render
        if (this.props.model) this.props.model.selected = this.selected;
        // callback
        if (this.props.onClick) {
            this.props.onClick(e, this);
        }
    }
    render() {
        
        this.selected = this.props.model ? this.props.model.selected : this.props.selected;

        let growing = true;
        if (this.props.growing) growing = this.props.growing;

        let onClick = null;
        if (this.props.onClick) onClick = this.props.onClick;

        let containerClass = this.props.className ? 'button small ' + this.props.className : 'button small ';
        let contentClass = growing ? 'growing icon ' : 'icon ';

        if (this.props.containerClass) containerClass += this.props.containerClass;
        if (this.props.contentClass) contentClass += this.props.contentClass;

        return (         
            <div className={containerClass} onClick = { this.onInternalChange }>
                <div className={contentClass}></div>
            </div>
        );       
    }
}
    