import React, { Component } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

//import * as authManager from '../managers/authManager';
import { authorize, preauthorize, trimBadKatanaUrl } from '../managers/authManager';
import { saveAppSettings, } from '../managers/appManager';
import { Checkbox, LoadingIcon } from '../components/atoms';

import { routes } from '../common/routes';
import { api } from '../common/api';

import axios from 'axios'; // TO DO: remove when code is factored-in

class LoginComp extends Component {
    constructor(props) {
        super(props);
        
        //console.log('login: constructor');

        this.username = '';
        this.password = '';
        this.code = '';
        this.counter = 0; // this.counter keeps the real-time track, and the state.counter is used to trigger re-rendering
        this.app = window.app;
        this.lastIsPreauthorized = true;

        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'items' state (or other):
        this.app.locator.loginPage = this;
        this.app.auth.tokenAttempts = 0; // reset this after failed login (as it get increased on 401 for 'IsUserValid')

        // before setting the state: get the settings value (could be undefined for older objects in local storage) 
        const rememberMe = this.app.settings.rememberMe ? this.app.settings.rememberMe : false;
        //this.isPreauthorized = false;

        this.state = {
            counter: 0, // used after loading/reloading captcha: this counter is increased to to update the captcha URL and to trigger state update and re-render
            lastTask: this.app.store.state.lastTask,
            loginError: this.app.store.state.loginError,
            codeType: this.app.settings.codeType,//this.app.store.state.codeType,
            sessionId: this.app.settings.sessionId,
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,
            isPreauthorized: this.app.store.state.isPreauthorized,
            authenticating: false,
            username: '',//'adspadmin@lab.com',
            password: '',//'Password10?',
            rememberMe: rememberMe,
            stateId: this.app.stateId,
            error: false,
        };
    }

    componentWillUnmount() {
        window.app.locator.loginPage = null;
        this.app.store.unsubscribe({ property: 'lastTask', component: this });
        this.app.store.unsubscribe({ property: 'login', component: this });
        this.app.store.unsubscribe({ property: 'loginError', component: this });
        this.app.store.unsubscribe({ property: 'codeType', component: this }); 
        this.app.store.unsubscribe({ property: 'sessionId', component: this }); 
        this.app.store.unsubscribe({ property: 'stateId', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this }); 
        this.app.store.unsubscribe({ property: 'isPreauthorized', component: this }); 
    }

    componentDidMount() {
        //console.log('login: component did mount. this.state: ', this.state);
        //console.log('login: component did mount. app.store.state: ', this.app.store.state);
        const { history } = this.props;
        this.app.store.subscribe({ property: 'lastTask', component: this });
        this.app.store.subscribe({ property: 'login', component: this });
        this.app.store.subscribe({ property: 'loginError', component: this });
        this.app.store.subscribe({ property: 'codeType', component: this });
        this.app.store.subscribe({ property: 'sessionId', component: this });
        this.app.store.subscribe({ property: 'stateId', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });
        this.app.store.subscribe({ property: 'isPreauthorized', component: this });
        ////console.log('login: authenticated: ' + this.app.state.authenticated);
        if (this.app.state.authenticated) history.push(routes.home());

        //var element = document.querySelector('div[class="custom-input"]');
        var element = window.document.body;
        this.simulateMouseClick(element);
        
        if (!this.state.isPreauthorized) {
            // update only if different 
            if (this.lastIsPreauthorized != this.state.isPreauthorized) {                 
                let username = this.getUserName();
                preauthorize(username); // currently the username is ignored on server endpoint. TO DO: split Login page in 2 pages: first one only for the username, and enable it on server
            }
        }      
        this.lastIsPreauthorized = this.state.isPreauthorized;
    }

    componentDidUpdate(nextProps, nextState) {

        return; ///!!! this state update is made for Authorization Api returning status 500 on Preauthorize: it enters loop!
        // (we should detect 500 or other bombs and do not update any state!)

        if (nextState.isPreauthorized !== this.state.isPreauthorized) {
            if (!nextState.isPreauthorized) { // if user is not preauthorized, maybe the session has expired: get new session by calling 'preauthorize' again
                let username = this.getUserName();
                preauthorize(username); // currently the username is ignored on server endpoint. TO DO: split Login page in 2 pages: first one only for the username, and enable it on server
            }
        }
    }

    getUserName() {

        let username = '';
        let usernameElement = document.getElementById('formBasicEmail');
        if (usernameElement) username = usernameElement.value;
        return username;
    }

    getPassword() {

        let password = '';
        let passwordElement = document.getElementById('formBasicPassword');
        if (passwordElement) password = passwordElement.value;
        return password;
    }

    getCode() {

        let code = '';
        let codeElement = document.getElementById('formBasicCode');
        if (codeElement) code = codeElement.value;
        return code;
    }

    simulateMouseClick(element) {
        const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log('LOGIN componentDidUpdate = ', prevState);        
        // if (prevState.error !== this.state.error) {
        //     this.setState({
        //         error: true
        //     })
        // }
    }

    onChange = (event) => {

        let haveChange = false;
        if (event.target.type == 'password') {
            //if (this.password != event.target.value) {
            //    haveChange = true;
                this.password = event.target.value;
                this.setState({ password: this.password });

            //}
        }
        else if (event.target.type == 'emailCustom') {
            //if (this.username != event.target.value) {
            //    haveChange = true;
                this.username = event.target.value;
                this.setState({ username: this.username });
            //}
        }
        else if (event.target.type == 'code') {
            //if (this.username != event.target.value) {
            //    haveChange = true;
            this.code = event.target.value;
            this.setState({ code: this.code });
            //}
        }
        //if (haveChange) this.app.store.setState({ loginError: false }); // to reset the error used by login
    }

    onFocus = (event) => {
        this.app.store.setState({ loginError: false }); // to reset the error used by login
    }

    onRememberMeChange = (e, checkbox) => {

        //this.setState((prevState, props) => ({
        //    remember: !prevState.remember
        //}));
        //console.log('onRememberMeChange. remember: ', checkbox.selected);
        this.app.settings.rememberMe = checkbox.selected; // this one to save the last UI state to the local storage (app scope, not the user scope)
        this.app.auth.rememberMe = checkbox.selected; // this one for global state, later used from app manager
        this.setState({ rememberMe: checkbox.selected }); // this one for local rendering

        saveAppSettings(this.app);
    }

    submit = (e, app) => {

        this.onFormSubmitHandler(e);

        e.stopPropagation();
        e.preventDefault();

        // synch the 'remember me' with the auth, in case the checkbox was initially
        // set up with the app.settings state, wasn't touched by the user, then submit was clicked
        this.app.auth.rememberMe = this.state.rememberMe; // this one for global state, later used from app manager

        //let username = this.username;
        //let password = this.password;
        //let code = this.code;

        // second varinat, to fix error when Chrome fills the field automatically but 'on change' event
        // is not dispatched: the state values (used before for the request call) were not updated!
        let username = this.getUserName();
        let password = this.getPassword();
        let code = this.getCode();

        app.store.setState({ loginError: false }); // to reset the error used by login
        //this.setState({ loginError: false })  // we are already subscribed, state will update automatically
        authorize(username, password, code);
    }

    // process the form on submit event
    onFormSubmitHandler(event) {

        //// get the user-entered captcha code value to be validated at the backend side
        //let userEnteredCaptchaCode = this.captcha.getUserEnteredCaptchaCode();

        //// get the id of a captcha instance that the user tried to solve
        //let captchaId = this.captcha.getCaptchaId();

        //let postData = {
        //    // add the user-entered captcha code value to the post data
        //    userEnteredCaptchaCode: userEnteredCaptchaCode,
        //    // add the id of a captcha instance to the post data
        //    captchaId: captchaId
        //};

        //let self = this;
        //let formMessage = document.getElementById('form-messages');

        //// post the captcha data to the backend
        //axios.post('api/webapi/basic', postData)
        //    .then(response => {
        //        if (response.data.success == false) {
        //            // captcha validation failed; show the error message
        //            formMessage.setAttribute('class', 'alert alert-error');
        //            formMessage.innerHTML = 'CAPTCHA validation failed!';
        //            // call the self.captcha.reloadImage()
        //            // in order to generate a new captcha challenge
        //            self.captcha.reloadImage();
        //        } else {
        //            // captcha validation succeeded; proceed with the workflow
        //            self.props.history.push('/basic-success-notify');
        //        }
        //    }).catch(function (error) {
        //        throw new Error(error);
        //    });

        //event.preventDefault();
    }

    // called from render stack: so it will NOT update the state, only will update 'this.counter' value
    getCaptchaUrl(app) {
        //let params = '';//'?client_id=c2dfb371-e54f-4cd2-b6fd-a2930142672c&redirect_uri=sealpath:client';
        //let params = '?r=' + this.counter + '-' + this.state.stateId;//++; // this will update the URL so the element will be reevaluated by react
        //let params = '?id=' + app.settings.sessionId;//++; // this will update the URL so the element will be reevaluated by react
        let params = '?id=' + app.settings.sessionId + '&state=' + this.counter + '-' + this.state.stateId;//++; // this will update the URL so the element will be reevaluated by react
        let result = trimBadKatanaUrl(api().auth.captcha()) + params;
        return result;
    }

    // called after click: will update the state
    getReloadCaptchaUrl(e) {

        if (e) e.stopPropagation();

        //let params = '';//'?client_id=c2dfb371-e54f-4cd2-b6fd-a2930142672c&redirect_uri=sealpath:client';
        //let result = trimBadKatanaUrl(api().auth.captcha()) + params;
        this.counter++; // this.counter keeps the real-time track, and the state.counter is used to trigger re-rendering
        this.setState({ counter: this.counter }); 
        //return result;        
    }

    shouldRenderCodeSection() {

        if (!this.state.isPreauthorized)
            return false;

        let type = this.state.codeType;
        //console.log('shouldRenderCodeSection: type: ', type);   
        if (type !== null) {

            if (type.toLowerCase() == 'captcha') {
                return true;
            }
            else if (type.toLowerCase() == 'two-factor') {
                return true;
            }
        }
        return false;
    }

    renderCodeSection(app) {        
        /*
        <div id="captchaArea" className="hidden" style={{ display: 'block' }}>
            <br />
                <label>Escriba los caracteres</label>
                <div className="clear"></div>
                <div className="captchaContainer"><img src="https://pilot.lab.com/SealPath/Login/GetCaptcha?r=0.724105920091441" /></div>
                    <a className="newCaptcha" href="#">�No se entiende? Carga uno nuevo.</a>
                    <br />
                        <input id="captcha" name="captcha" type="text" value="" />

                            <div className="clear"></div>

        </div>
        */

        //<a className="newCaptcha" href="{this.getReloadCaptchaUrl(app)}">{app.R.ReloadCode}</a>

        let type = this.state.codeType;       
        //console.log('renderCodeSection: type: ', type);
        // !!! looks like "if (type)" sintaxis below never produce 'true' for strings! so "if (type == )" is used (verify online)
        if (type !== null) {
            if (type.toLowerCase() == 'captcha') {
                //console.log('render code section: captcha. ');
                return (
                    <div>                                                                        
                        <div className="clear"></div>
                        <div className="captcha-container"><img src={this.getCaptchaUrl(app)} />
                            <span className="new-captcha-label" onClick={(e) => this.getReloadCaptchaUrl(e)}>{app.R.ReloadCode}</span>
                        </div>                                         
                        <div className="clear"></div>
                    </div>
                ); 
            }
            else if (type.toLowerCase() == 'two-factor') {
                //console.log('render code section: two-factor. ');
                return (
                    <div id="captchaArea" className="hiddenX" style={{ display: 'block' }}>
                        <br />
                        <h3>Code section: two-factor</h3>                            
                    </div>
                ); 
            }           
        }
        return null;
    }  

    render() {
        //console.log('render Login')
        //if (!this.state.userLanguageLoaded)
        if (!this.app.store.state.userLanguageLoaded)
            return null;

        //const { error } = this.state;
        //const { error } = this.app.store.state;
        const loginError = this.app.store.state.loginError;
        //console.log('login : render : loginError: ', loginError);
        let app = window.app;

        // UI triggers
        let usernameEnabled = true;
        let passwordEnabled = true;
        let codeEnabled = this.shouldRenderCodeSection();
        let checkboxEnabled = this.app.store.state.options.appSettings.Local.EnableSavingSessionToLocalStorage;

        //The Bootstrap 4 grid system has five classes:
        //.col - (extra small devices - screen width less than 576px)
        //.col - sm - (small devices - screen width equal to or greater than 576px)
        //.col - md - (medium devices - screen width equal to or greater than 768px)
        //.col - lg - (large devices - screen width equal to or greater than 992px)
        // Note: we are going to override bootstrap styles completely
        return (        
            <div>
                <div className="col-md-7 col-lg-5">
                    <br />
                    <Form onSubmit={(e) => this.submit(e, app)}>

                        {usernameEnabled && 
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{app.R.UserName}</Form.Label>
                                <Form.Control
                                    className='custom-input'
                                    type="emailCustom"
                                    placeholder={app.R.EnterEmail}
                                    onClick={this.onFocus}
                                    onChange={this.onChange} />
                            </Form.Group>
                        }
                        
                        {passwordEnabled &&
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>{app.R.Password}</Form.Label>
                                <Form.Control
                                    className='custom-input'
                                    type="password"
                                    placeholder={app.R.EnterPassword}
                                    onClick={this.onFocus}
                                    onChange={this.onChange} />
                            </Form.Group>
                        }

                        {codeEnabled &&
                            <Form.Group controlId="formBasicCode">
                            <Form.Label>{app.R.Code}</Form.Label>
                                {this.renderCodeSection(app)}
                                <Form.Control
                                    className='custom-input'
                                    type="code"
                                    placeholder={app.R.EnterCode}
                                    onClick={this.onFocus}
                                    onChange={this.onChange} />
                            </Form.Group>
                        }

                        {checkboxEnabled &&
                            <div>                            
                                <Checkbox
                                    enabled={true}
                                    key='rememberme'
                                    className='filter radio'
                                    label={app.R.RememberMe} selected={this.state.rememberMe}
                                    onChange={this.onRememberMeChange} />
                            </div>
                        }

                        <Button variant='primary' type='submit' className='login-button'>
                            {app.R.SignIn}
                        </Button>
                        {loginError && <span className="text-error">{this.app.R.UserNameOrPasswordNotValid}</span>}
                </Form>            
                </div>
            </div >
        );
        //<RadioButtonsList
        //    items={[{ id: 1, label: app.R.RememberMe }]}
        //    onChange={this.onRememberMeChange} />
    }
}

export const Login = withRouter(LoginComp);
