import React, { Component } from 'react';
import DayPickerInput from "./lib/DayPickerInput";
import { formatDate, parseDate } from './lib/moment';

import {
    MONTHS,
    WEEKDAYS_LONG,
    WEEKDAYS_SHORT,
} from '../../../common/constants';

// The Calendar class is based on the react-day-picker:  https://react-day-picker.js.org/api/DayPicker/
// Extra info about the first day of week:
// Monday is the first day of the week according to the international standard ISO 8601, but in the US, Canada, and Japan, it's counted as the second day of the week
// The react-day-picker uses Sunday as day 0, and monday as day 1. Set the firstDayOfWeek to 1 for most countries.

export class Calendar extends Component {
    constructor(props) {
        super(props);

        this.app = window.app;

    }

    render() {
        const { onDayChange, placeholder } = this.props;
        let firstDayOfWeek = 1; // 1 = Monday - first day of the week in most countries
        const code = this.props.languageId;
        if (code == 'en-us' || code == 'en-ca' || code == 'jp')
            firstDayOfWeek = 0; // they use Sunday as the first day of the week

        // Modifiers: https://react-day-picker.js.org/docs/matching-days/
        // disable the past days: https://github.com/gpbl/react-day-picker/issues/283
        //dayPickerProps = {{
        //    disabledDays: { before: new Date() },

        return(
            <DayPickerInput                
                format={ this.props.format }
                formatDate={ formatDate }
                parseDate={ parseDate }
                dayPickerProps={{
                    disabledDays: { before: new Date() },
                    firstDayOfWeek,
                    months: MONTHS(this.app),
                    weekdaysLong: WEEKDAYS_LONG(this.app),
                    weekdaysShort: WEEKDAYS_SHORT(this.app),
                }}
                placeholder={ placeholder || formatDate(new Date()) }
                onDayChange={ onDayChange }
            />
        )
    }
}