

/**
 * This is the Store module.
 * It has a state which keeps all data collections we get from server
 * It has functions to subscribe for state updates, unscubscribe,
 * and to set the state
 */
export class Auth {

    constructor(app) {

        this.app = app;

        this.rememberMe = false; // global switch, taken from login form, later used in the app manager when saving persistent data

        this.tokens = {};

        // With each new token the token id will be increased. Later every task will have the associated token id assigned,
        // so when the task returns 401, we will check if we have a newer token available to relaunch the task 
        // (new token could had been requested and obtained while the task was waiting for the results).
        // If the token id hasn't changed, and if new tokens are not already requested, then we will request new tokens.
        this.tokenId = 0;
        // flag set when a token request is launched, so other tasks that return with 401 wait for relaunch without requesting more tokens.
        this.isWaitingForAuthorization = false;
        this.tokenAttempts = 0;        
        // initialize the state
        this.initialize();
    }

    initialize() {
        //console.log('Auth.initialize()');

        this.isWaitingForAuthorization = false;
        // before setting the state: get the settings value (could be undefined for older objects in local storage) 
        const rememberMe = this.app.settings.rememberMe ? this.app.settings.rememberMe : false;
        this.rememberMe = rememberMe;
        this.codeType = ''; //this one is not reset in 'initialize' method! (kept outside of 'auth'  obejct because the 'auth' that contains tokens gets saved/loaded on restart)
        this.tokenAttempts = 0;
        this.tokenId = 0;
        this.tokens = {};
        
        

    }    
}