import React, { Component } from 'react';
import { DocumentEvents } from './DocumentEvents';
import { DocumentInfo } from './DocumentInfo';
import { Button, Modal, Table } from 'react-bootstrap';

import { goToHomePage, updateAppHistory } from '../../managers/appManager';
import {
    isDocumentOwner,
    loadDocumentInfo,
    loadDocumentWarnings,
    lockDocument
} from '../../managers/documentManager';
import {
    canLockDocument,
    canAddUsers,
    canEditPolicy,
    createNewPermission,
    policyClickedInDocumentInfo,
    restorePolicy
} from '../../managers/policyManager';

import { SmallButton, Loader, ItemHead, ItemList } from '../../components/atoms';
import { CheckboxList, DocumentTypePanel, SelectList, TableGrid } from '../../components/organisms';
import {
    formatDateString, template, formatPermissions,
    getUserPermission,
} from "../../common/format";
import {
    AccessPermissionModels,
    AccessPermissionType,
    DateOptions,
    RequestType,
    WarningOptions,
} from "../../common/constants";
import {
    EditPolicySection,
    GeneralSection,
    LockSection,
    PermissionsSection,
    TrackingSection,
    WarningsSection,
} from "./components";


export class Document extends Component {

    constructor(props) {

        super(props);

        this.app = window.app;

        this.optionsSection = this.props.inSelectedDocumentMode ? 'document' : 'documents';

        this.filter = {
            // search options and params
            //documentId: 0,
            type: this.app.store.getOption({ section: 'document', option: 'type' })            
        };

        this.state = {
            // synchronized with the global store
            customPolicyGuid: this.app.store.state.customPolicyGuid,
            documentInfo: this.app.store.state.documentInfo,
            documentInfoPermissions: this.app.store.state.documentInfoPermissions,
            documentInfoPolicy: this.app.store.state.documentInfoPolicy,
            documentTracking: this.app.store.state.documentTracking,
            documentWarning: this.app.store.state.documentWarning, // this one is an extra waring info from the last page (doc. list), we won't synch with that one
            documentWarnings: this.app.store.state.documentWarnings,
            policies: this.app.store.state.policies,
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,            
            users: this.app.store.state.users,
            groups: this.app.store.state.groups,

            showRestorePolicy: false,
            showRestorePolicyError: false,

            // component with search options (page number, type, name) and the filtered items
            filter: this.filter
        }
    }

    componentDidMount() {

        //console.log('componentDidMount');
        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'items' state (or other):
        window.app.locator.documentInfoPage = this;
        this.app.store.subscribe({ property: 'customPolicyGuid', component: this });
        this.app.store.subscribe({ property: 'documentInfo', component: this });
        this.app.store.subscribe({ property: 'documentInfoPermissions', component: this }); 
        this.app.store.subscribe({ property: 'documentInfoPolicy', component: this }); 
        this.app.store.subscribe({ property: 'documentTracking', component: this });
        this.app.store.subscribe({ property: 'documentWarnings', component: this });
        this.app.store.subscribe({ property: 'policies', component: this });
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });
        this.app.store.subscribe({ property: 'users', component: this });
        this.app.store.subscribe({ property: 'groups', component: this });

        //const { history } = this.props;
        //if (this.app.state.authenticated) history.push(routes.home());
        const { match: { params } } = this.props;
        const docId = params.id;
        const docGuid = this.app.store.state.documentGuid;
        if (!this.app.store.state.documentInfo) {
            //console.log('docId: ' + docId + ', docGuid: ' + docGuid)
            if (docId == 0 && !docGuid) {
                //console.log('return to home screen...')
                // most likely we refreshed browser: return to Home screen
                goToHomePage();
                return; 
            }
            loadDocumentInfo(this.app, null, { DocumentId: docId, DocumentGuid: docGuid }); // the loading endpoint only needs DocumentId or DocumentGuid
        }
    }
    
    //===============================================================
    componentWillUnmount() {

        window.app.locator.documentInfoPage = null;
        this.app.store.unsubscribe({ property: 'customPolicyGuid', component: this });
        this.app.store.unsubscribe({ property: 'documentInfo', component: this });
        this.app.store.unsubscribe({ property: 'documentInfoPermissions', component: this });
        this.app.store.unsubscribe({ property: 'documentInfoPolicy', component: this });
        this.app.store.unsubscribe({ property: 'documentTracking', component: this });
        this.app.store.unsubscribe({ property: 'documentWarnings', component: this });
        this.app.store.unsubscribe({ property: 'policies', component: this });
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });
        this.app.store.unsubscribe({ property: 'users', component: this });
        this.app.store.unsubscribe({ property: 'groups', component: this });
    }
    //===============================================================

    onDocumentTypeChange = (type) => {

        const filter = this.filter;
        if (type !== filter.type) {
            // set the new filter value
            filter.type = type;
            // set the new configuration value
            this.app.store.setOption({ section: 'document', option: 'type', value: type });
            // reset the document events list
            this.app.store.state.documentEvents = null;

            // set the state with the new type: the type will be passed to child compnents as prop.type
            this.setState({ filter: this.filter });
           
        }
    };
    ////===============================================================
    //// RESTORE POLICY
    //closeRestorePolicy = () => {
    //    this.setState({
    //        showRestorePolicy: false
    //    });
    //}

    //showRestorePolicy = () => {
    //    this.setState({
    //        showRestorePolicy: true
    //    });
    //}

    //closeRestorePolicyError = () => {
    //    this.setState({
    //        showRestorePolicyError: false
    //    });
    //}

    //showRestorePolicyError = () => {
    //    this.setState({
    //        showRestorePolicyError: true
    //    });
    //}

    //onRestorePolicyError = () => {
    //    this.showRestorePolicyError();
    //}

    //onRestorePolicyCompleted = () => {
    //    this.closeRestorePolicy();
    //}

    //renderRestorePolicy = () => {

    //    if (!this.state.showRestorePolicy) return;

    //    let app = window.app;
    //    let info = app.R.RestorePolicyInfo;
    //    if (app.store.state.policy) {
    //        // replace the placeholder
    //        let replacements = {
    //            '{n}': app.store.state.policy.Name
    //        };
    //        info = template(info, replacements);
    //    }

    //    return (
    //        <Modal show={true} onHide={this.closeRestorePolicy}>
    //            <Modal.Header closeButton>
    //                <Modal.Title>{app.R.RestorePolicy}</Modal.Title>
    //            </Modal.Header>
    //            <Modal.Body>{info}</Modal.Body>
    //            <Modal.Footer>
    //                <Button variant="secondary" onClick={this.closeRestorePolicy}>
    //                    {app.R.Cancel}
    //                </Button>
    //                <Button variant="primary" onClick={(e) => restorePolicy(e, this, this.state.documentInfo.Policy)}>
    //                    {app.R.RestorePolicy}
    //                </Button>
    //            </Modal.Footer>
    //        </Modal>
    //    );
    //}

    //renderRestorePolicyError = () => {

    //    if (!this.state.showRestorePolicyError) return;

    //    let app = window.app;
    //    let info = app.R.RestorePolicyError;
    //    if (app.store.state.policy) {
    //        // replace the placeholder
    //        let replacements = {
    //            '{n}': app.store.state.policy.name
    //        };
    //        info = template(info, replacements);
    //    }

    //    return (
    //        <Modal show={true} onHide={this.closeRestorePolicyError}>
    //            <Modal.Header closeButton>
    //                <Modal.Title>{app.R.RestorePolicy}</Modal.Title>
    //            </Modal.Header>
    //            <Modal.Body>{info}</Modal.Body>
    //            <Modal.Footer>
    //                <Button variant="secondary" onClick={this.closeRestorePolicyError}>
    //                    {app.R.Close}
    //                </Button>
    //            </Modal.Footer>
    //        </Modal>
    //    );
    //}

    returnBack = (e) => {
        e.stopPropagation();

        if (this.app.history) { this.app.history.goBack() }
    }

    changeDateFilter = (value) => {
        const { match: { params } } = this.props;

        loadDocumentWarnings(this.app, { DocumentId: params.id }, value)
    }

    changeTypeFilter = (value) => {
        const { match: { params } } = this.props;

        loadDocumentWarnings(this.app, { DocumentId: params.id }, value)
    }

    //onDocumentLocked = () => {
    //    console.log('onDocumentLocked');
    //    lockDocument(this.app, this.state.documentInfo.DocumentDetails);
    //}

    //onEditPolicy = (e) => {
    //    if (e) e.stopPropagation();
    //    console.log('onEditPolicy');
    //    //documentManager.lockDocument(this.app, this.state.documentInfo.DocumentDetails);
    //    policyClickedInDocumentInfo(e, this, documentInfo)
    //}

    render() {        
        // wait for the user prefs loaded and language
        if (!this.state.userLanguageLoaded)
            return null;

        const app = this.app;

        const filter = this.filter;
        const { type } = filter;

        updateAppHistory(app, this);

        if (this.state.documentInfo && !this.state.documentInfo.DocumentDetails) {
            // doc info is missing!            
            return (
                <div>
                    <div>
                        <SmallButton contentClass='icon-back' onClick={this.returnBack} />
                        <span className='label extra-space'></span>
                        <h3 className='label'>{`${this.app.R.DocumentInformation}`}</h3>
                    </div>
                    <h4>{this.app.R.DocumentInformationNotFound}</h4>
                </div>
            );
        }
        else if (!this.state.documentInfo) {
            return (
                <div>
                    <div>
                        <SmallButton contentClass='icon-back' onClick={this.returnBack} />
                        <span className='label extra-space'></span>
                        <h3 className='label'>{`${this.app.R.DocumentInformation}`}</h3>
                    </div>
                    <h4>{this.app.R.Loading}</h4>
                </div>
            );
        }
        
        const { documentInfo } = this.state;
        const documentDetails = documentInfo.DocumentDetails;
        // PERMISSIONS
        const permission = documentInfo.UserPermission;
        //
        const hasFullControl = ((permission.AccessPermissions & AccessPermissionType.FullControl) == AccessPermissionType.FullControl) && !documentInfo.IsUserBlocked ;
        const shouldRenderSelectors = hasFullControl;
        const inSelectedDocumentMode = true;
        const shouldRenderDocumentInfoOnly = ((filter.type == RequestType.DocumentInfo) || !hasFullControl) ? true : false;

        return (
            <div>
                <div>
                    <SmallButton contentClass='icon-back' onClick={this.returnBack} />
                    <span className='label extra-space'></span>
                    <h3 className='label'>{`${this.app.R.DocumentInformation}`}</h3>
                </div>
                
                <h4>{documentDetails.DocName}</h4>
                <br/>
                {shouldRenderSelectors && (
                    <DocumentTypePanel
                        documentType={type}
                        onChange={this.onDocumentTypeChange}
                        inSelectedDocumentMode={inSelectedDocumentMode}
                    />
                )}
                
                {
                    //<TrackingSection
                    //    list={currDocumentInfo.tracking.list}

                    ///>
                    //<WarningsSection
                    //    list={currDocumentInfo.warnings.list}
                    //    onDateFilterChange={this.changeDateFilter}
                    //    handleWarningTypeChange={this.changeTypeFilter}
                    ///>

                    // general section
                    shouldRenderDocumentInfoOnly && (
                        <DocumentInfo
                            params={this.props.params}
                        ></DocumentInfo>
                    )
                    
                }
                {
                    // event section (tracking/warnings)
                    !shouldRenderDocumentInfoOnly && (
                        <DocumentEvents                     
                            inSelectedDocumentMode={inSelectedDocumentMode}
                            type={filter.type}
                        ></DocumentEvents>
                    )
                }

            </div>
        )
    }
}
