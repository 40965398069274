import React, { Component } from 'react';

import { Checkbox, Item, ItemColumn, SmallButton } from "../../../components/atoms";
//import { CheckboxList } from "../../../components/organisms";


export class UnprotectSection extends Component {

    constructor(props) {
        super(props);

        this.app = window.app;
    }

    onClicked = (e) => {

        if (e) e.stopPropagation();
        if (e) e.preventDefault();
        const { onDocumentLocked } = this.props;
        /// set local state so button shape is changed
        //
        // callback to the parent component (document info page)
        this.props.onClicked();
    }

    render() {
        //const { IsLocked } = this.props;
        
        //console.log('UnprotectSection: render: is locked: ' + IsLocked);
        // contentClass = { IsLocked? 'icon-locked': 'icon-unlocked' }
        // { IsLocked ? <h4>{this.app.R.DocumentIsLocked}</h4> : '' }
        // className='tab-section clear-left'
        //<Item className='head policies disabled'>
        //    <ItemColumn
        //        key={'col-header-permissions'}
        //        className={'item-col one-line '}
        //    >
        //        {this.app.R.YourPermissions}
        //    </ItemColumn >
        //</Item>
        return (
            <div >                  
                <div className='clickable' onClick={this.onClicked}>
                    <h4 className='label'>{this.app.R.UnprotectDocument}</h4>
                    <SmallButton
                        contentClass='icon-locked'
                        onClick={this.onClicked}
                    />
                </div>        
                <p>{this.app.R.ChangesWillAffectThisDocument}</p>
            </div>
        )
    }
}