import React, { Component } from 'react';
//import { Task } from './Task';
import { template } from '../../common/format'
import { ResultCodeInfo, RequestType, TaskState, TaskSubState } from '../../common/constants'
import { formatTaskTitle, formatTaskInfo, closeTask, onTaskClicked } from '../../managers/taskManager'
import { goToTasksPage } from '../../managers/appManager'

//import { Glyphicon } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';


    // google example:
    //--------------------------------------------------------------------------------------------
    //renderTask(app, task) {
    // google code example for a task element (terrible):
        //return (
        //    <div class="a-Cd a-Cd-Na Hb-ja-Na" tabindex="0" role="dialog" aria-label="Se han subido 2 elementos">
        //        <div class="a-Cd-nm">
        //            <div class="af">
        //                <div class="af-uo"><span class="af-V">Se han subido 2 elementos</span></div>
        //                <div class="af-Yi-j">
        //                    <div class="af-Yi">
        //                        <div class="h-sb-Ic h-R-d a-c-d" role="button" tabindex="0" aria-label="Minimizar" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" style={{ "user-select": "none"}} data-tooltip="Minimizar">
        //                            <div class="a-d-c">
        //                                <svg x="0px" y="0px" width="14px" height="14px" viewBox="0 0 24 24" focusable="false">
        //                                    <path fill="#FFFFFF" d="M21.17,5.17L12,14.34l-9.17-9.17L0,8l12,12,12-12z"></path>
        //                                </svg>
        //                            </div>
        //                        </div>
        //                        <div class="h-sb-Ic h-R-d a-c-d" role="button" tabindex="0" data-tooltip="Cerrar" aria-label="Cerrar" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" style={{ "user-select": "none"}}>
        //                            <div class="a-d-c">
        //                                <svg class="a-qd-va" x="0px" y="0px" width="14px" height="14px" viewBox="0 0 10 10" focusable="false">
        //                                    <polygon class="a-s-fa-Ha-pa" fill="#FFFFFF" points="10,1.01 8.99,0 5,3.99 1.01,0 0,1.01 3.99,5 0,8.99 1.01,10 5,6.01 8.99,10 10,8.99 6.01,5 "></polygon>
        //                                </svg>
        //                            </div>
        //                        </div>
        //                    </div>
        //                </div>
        //            </div>
        //        </div>
        //        <div class="a-Cd-Ba-j">
        //            <div class="le-Ba" style={{"display": "none"}}>
        //                <div class="le-Ba-la-r" aria-label="Completando la subida..." data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" data-tooltip-only-on-overflow="true" data-tooltip="Completando la subida...">Completando la subida...</div>
        //                <div class="le-Ba-d-j">
        //                    <div class="le-Ba-d le-Ba-Jf-di" data-target="toolbarRetryAll" tabindex="0" role="button" aria-label="Reintentar todo" style={{ "display": "none"}}>Volver a intentar</div>
        //                        <div class= "le-Ba-d le-Ba-Ee-di" data-target= "toolbarCancelAll" tabindex="0" role="button" aria-label="Cancelar todo" style={{ "display": "none"}}>Cancelar</div>
        //                </div>
        //            </div>
        //        </div>
        //        <div class="a-Cd-oa">
        //            <div class="a-Cd-Ea-oa">
        //                <div class="Ea-j z-Ea-Vd-ac Ea-Lj" role="group" aria-label="File Upload: how to call with refresh token.rtf" tabindex="0">
        //                    <div class="Ea-oa Ea-G">
        //                        <div class="z-Ea-Qd">
        //                            <div class="z-Ea-hf-c" aria-hidden="true">
        //                                <div class="a-c">
        //                                    <img class="a-Ua-c" src="//ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_word_x16.png" alt="Word" />
        //                                </div>
        //                                </div>
        //                                <div class="z-Ea-r-dd" data-target="taskContainer" tabindex="0" role="button">
        //                                    <span class="z-Ea-r z-Ea-tf-r" data-tooltip="how to call with refresh token.rtf" data-tooltip-unhoverable="true" data-tooltip-delay="500" data-tooltip-only-on-overflow="true" aria-label="how to call with refresh token.rtf">
        //                                        how to call with refresh token.rtf
        //                                        </span>
        //                                    <span class="z-Ea-r z-Ea-gj-r" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" data-tooltip-only-on-overflow="true"></span>
        //                                    <div class="z-Ea-Uc"><div class="z-Ea-Uc-c">
        //                                        <svg class="a-s-fa-Ha-pa" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" focusable="false" fill="#000000">
        //                                            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
        //                                        </svg>
        //                                    </div>
        //                                    </div>
        //                                    <span class="z-Ea-r z-Ea-Lm z-Ea-he-sa" data-tooltip="Versi�n 2" aria-label="Versi�n 2" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true">Versi�n 2</span>
        //                                    <div class="z-Ea-zf-f a-zf-Ti-mf-Eh">
        //                                        <div class="a-f-n a-f-wr-Wq" data-progress="100" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100">
        //                                            <svg><circle cx="10" cy="10" r="10" fill="#ccc"></circle></svg>
        //                                            <div class="f-Jb">
        //                                                <div class="f-xa na">
        //                                                    <div class="f-G">
        //                                                        <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                    </div>
        //                                                </div>
        //                                                <div class="f-xa">
        //                                                    <div class="f-G">
        //                                                        <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                    </div>
        //                                                    <div class="f-G ya">
        //                                                        <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                    </div>
        //                                                </div>
        //                                            </div>
        //                                            <div class="f-Pn">
        //                                                <svg><circle class="a-s-fa-Ha-pa" cx="10" cy="10" r="7" fill="#FFFFFF"></circle></svg>
        //                                            </div>
        //                                        </div>
        //                                    </div>
        //                                </div>
        //                                <div class="z-Ea-ac">
        //                                <div class="h-sb-Ic h-R-d a-c-d W-d" role="button" aria-hidden="true" data-tooltip="Cancelar subida" aria-label="Cancelar subida: 12 KB/12 KB (100%)" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" style={{ "user-select": "none", "display": "none" }}>
        //                                        <div class="a-d-c d-tf-c z-Ea-pd-c">
        //                                            <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" focusable="false">
        //                                                <path class="a-s-fa-Ha-pa" fill="#000000" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10c5.5,0,10-4.5,10-10S17.5,2,12,2z M17,15.6L15.6,17L12,13.4L8.4,17L7,15.6l3.6-3.6   L7,8.4L8.4,7l3.6,3.6L15.6,7L17,8.4L13.4,12L17,15.6z"></path>
        //                                            </svg>
        //                                        </div>
        //                                        <div class="a-f-n d-za a-f-wr-Wq" data-progress="100" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100">
        //                                            <svg><circle cx="10" cy="10" r="10" fill="#ccc"></circle></svg>
        //                                            <div class="f-Jb">
        //                                                <div class="f-xa na">
        //                                                    <div class="f-G">
        //                                                        <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                    </div>
        //                                                </div>
        //                                                <div class="f-xa">
        //                                                    <div class="f-G"><svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                    </div>
        //                                                    <div class="f-G ya">
        //                                                        <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                    </div>
        //                                                </div>
        //                                            </div>
        //                                            <div class="f-Pn">
        //                                                <svg><circle class="a-s-fa-Ha-pa" cx="10" cy="10" r="7" fill="#FFFFFF"></circle></svg>
        //                                            </div>
        //                                        </div>
        //                                        <span class="z-Ea-f-r d-W-ga">
        //                                            <div>12 KB/12 KB (100%)</div>
        //                                        </span>
        //                                    </div>
        //                                <div class="h-sb-Ic h-R-d a-c-d W-d" role="button" aria-hidden="true" data-tooltip="Volver a intentar" aria-label="Volver a intentar" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" style={{ "user-select": "none", "display": "none"}}>
        //                                        <div class="a-d-c d-tf-c z-Ea-Jf-c">
        //                                            <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" focusable="false">
        //                                                <path class="a-s-fa-Ha-pa" fill="#000000" d="M17.6,6.4C16.2,4.9,14.2,4,12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8c3.7,0,6.8-2.6,7.7-6h-2.1c-0.8,2.3-3,4-5.6,4    c-3.3,0-6-2.7-6-6s2.7-6,6-6c1.7,0,3.1,0.7,4.2,1.8L13,11h7V4L17.6,6.4z"></path>
        //                                            </svg>
        //                                        </div>
        //                                        <div class="d-za a-d-c z-Ea-Ga-c">
        //                                            <svg class="a-s-fa-Ha-pa" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000"
        //                                            ><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
        //                                            </svg>
        //                                        </div>
        //                                    </div>
        //                                <div class="h-sb-Ic h-R-d a-c-d W-d" role="button" aria-hidden="false" data-tooltip="Localizar en Mi unidad" aria-label="Localizar en Mi unidad" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" tabindex="0" style={{ "user-select": "none"}}>
        //                                        <div class="a-d-c d-tf-c">
        //                                            <svg class="a-s-fa-Ha-pa" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000">
        //                                                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
        //                                                <path d="M0 0h24v24H0z" fill="none"></path>
        //                                            </svg>
        //                                        </div>
        //                                        <div class="d-za a-d-c z-Ea-Fj-c">
        //                                            <svg class="a-s-fa-Ha-pa" width="24px" height="24px" viewBox="0 0 24 24" fill="#0F9D58">
        //                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        //                                            </svg>
        //                                        </div>
        //                                    </div>
        //                                </div>
        //                            </div>
        //                        <div class="z-Ea-os-Ls-d z-Ea-ce h-d z-Ea-he-sa" tabindex="0" role="button" title="Mantener como archivo independiente" style={{"user-select": "none"}}>Mantener como archivo independiente
        //                                </div>
        //                        </div>
        //                    </div>
        //                    <div class="Ea-j z-Ea-Vd-ac Ea-Lj" role="group" aria-label="File Upload: connection strings.txt" tabindex="0">
        //                        <div class="Ea-oa Ea-G">
        //                            <div class="z-Ea-Qd">
        //                                <div class="z-Ea-hf-c" aria-hidden="true">
        //                                    <div class="a-c">
        //                                        <img class="a-Ua-c" src="//ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_text_x16.png" alt="Texto" />
        //                                    </div>
        //                                    </div>
        //                                    <div class="z-Ea-r-dd" data-target="taskContainer" tabindex="0" role="button">
        //                                        <span class="z-Ea-r z-Ea-tf-r" data-tooltip="connection strings.txt" data-tooltip-unhoverable="true" data-tooltip-delay="500" data-tooltip-only-on-overflow="true" aria-label="connection strings.txt">connection strings.txt</span>
        //                                        <span class="z-Ea-r z-Ea-gj-r" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" data-tooltip-only-on-overflow="true">
        //                                        </span>
        //                                        <div class="z-Ea-Uc">
        //                                            <div class="z-Ea-Uc-c">
        //                                                <svg class="a-s-fa-Ha-pa" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" focusable="false" fill="#000000">
        //                                                    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
        //                                                </svg>
        //                                            </div>
        //                                        </div>
        //                                        <span class="z-Ea-r z-Ea-Lm" data-tooltip="Versi�n 0" aria-label="Versi�n 0" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true">Versi�n 0</span>
        //                                        <div class="z-Ea-zf-f a-zf-Ti-mf-Eh">
        //                                            <div class="a-f-n" data-progress="0" role="progressbar" aria-valuemin="0" aria-valuemax="100">
        //                                                <svg><circle cx="10" cy="10" r="10" fill="#ccc"></circle></svg>
        //                                                <div class="f-Jb">
        //                                                    <div class="f-xa na">
        //                                                        <div class="f-G">
        //                                                            <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                        </div>
        //                                                    </div>
        //                                                    <div class="f-xa">
        //                                                        <div class="f-G">
        //                                                            <svg>
        //                                                                <circle cx="10" cy="10" r="10" fill="#4285f4"></circle>
        //                                                            </svg>
        //                                                        </div>
        //                                                        <div class="f-G ya">
        //                                                            <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                        </div>
        //                                                    </div>
        //                                                </div>
        //                                                <div class="f-Pn">
        //                                                    <svg><circle class="a-s-fa-Ha-pa" cx="10" cy="10" r="7" fill="#FFFFFF"></circle></svg>
        //                                                </div>
        //                                            </div>
        //                                        </div>
        //                                    </div>
        //                                    <div class="z-Ea-ac">
        //                                <div class="h-sb-Ic h-R-d a-c-d W-d" role="button" aria-hidden="true" data-tooltip="Cancelar subida" aria-label="Cancelar subida" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" style={{"user-select": "none", "display": "none"}} >
        //                                        <div class="a-d-c d-tf-c z-Ea-pd-c">
        //                                        <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" focusable="false">
        //                                            <path class="a-s-fa-Ha-pa" fill="#000000" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10c5.5,0,10-4.5,10-10S17.5,2,12,2z M17,15.6L15.6,17L12,13.4L8.4,17L7,15.6l3.6-3.6   L7,8.4L8.4,7l3.6,3.6L15.6,7L17,8.4L13.4,12L17,15.6z"></path>
        //                                        </svg>
        //                                    </div>
        //                                    <div class="a-f-n d-za" data-progress="0" role="progressbar" aria-valuemin="0" aria-valuemax="100">
        //                                        <svg><circle cx="10" cy="10" r="10" fill="#ccc"></circle></svg>
        //                                        <div class="f-Jb">
        //                                            <div class="f-xa na">
        //                                                <div class="f-G">
        //                                                    <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                </div>
        //                                            </div>
        //                                            <div class="f-xa">
        //                                                <div class="f-G">
        //                                                    <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                </div>
        //                                                <div class="f-G ya">
        //                                                    <svg><circle cx="10" cy="10" r="10" fill="#4285f4"></circle></svg>
        //                                                </div>
        //                                            </div>
        //                                        </div>
        //                                        <div class="f-Pn">
        //                                            <svg><circle class="a-s-fa-Ha-pa" cx="10" cy="10" r="7" fill="#FFFFFF"></circle></svg>
        //                                        </div>
        //                                    </div>
        //                                    <span class="z-Ea-f-r d-W-ga"></span>
        //                                </div>
        //                                <div class="h-sb-Ic h-R-d a-c-d W-d" role="button" aria-hidden="true" data-tooltip="Volver a intentar" aria-label="Volver a intentar" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" style={{"user-select": "none", "display": "none"}}>
        //                                    <div class="a-d-c d-tf-c z-Ea-Jf-c"><svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" focusable="false">
        //                                        <path class="a-s-fa-Ha-pa" fill="#000000" d="M17.6,6.4C16.2,4.9,14.2,4,12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8c3.7,0,6.8-2.6,7.7-6h-2.1c-0.8,2.3-3,4-5.6,4    c-3.3,0-6-2.7-6-6s2.7-6,6-6c1.7,0,3.1,0.7,4.2,1.8L13,11h7V4L17.6,6.4z">
        //                                        </path>
        //                                    </svg>
        //                                    </div>
        //                                    <div class="d-za a-d-c z-Ea-Ga-c">
        //                                        <svg class="a-s-fa-Ha-pa" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z">
        //                                        </path>
        //                                        </svg>
        //                                    </div>
        //                                </div>
        //                                <div class="h-sb-Ic h-R-d a-c-d W-d" role="button" aria-hidden="false" data-tooltip="Localizar en Mi unidad" aria-label="Localizar en Mi unidad" data-tooltip-align="b,c" data-tooltip-delay="500" data-tooltip-unhoverable="true" tabindex="0" style={{"user-select": "none"}}>
        //                                    <div class="a-d-c d-tf-c">
        //                                        <svg class="a-s-fa-Ha-pa" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" fill="#000000">
        //                                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
        //                                            <path d="M0 0h24v24H0z" fill="none"></path>
        //                                        </svg>
        //                                    </div>
        //                                    <div class="d-za a-d-c z-Ea-Fj-c">
        //                                        <svg class="a-s-fa-Ha-pa" width="24px" height="24px" viewBox="0 0 24 24" fill="#0F9D58">
        //                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        //                                        </svg>
        //                                    </div>
        //                                </div>
        //                            </div>
        //                        </div>
        //                        <div class="z-Ea-os-Ls-d z-Ea-ce h-d" tabindex="0" role="button" title="Mantener como archivo independiente" style={{ "user-select": "none" }}>Mantener como archivo independiente
        //                        </div>
        //                    </div>
        //                </div>
        //            </div>
        //        </div>
        //    </div>
        //)
    //}  
    //--------------------------------------------------------------------------------------------

export class TasksOverlay extends Component {
        
    constructor(props) {

        super(props);
        this.app = window.app;
        this.state = { tasks: this.app.store.state.tasks };
        this.counter = 0;// used for React key attribute, which must be unique for each item in the collection

    }

    componentDidMount() {

        window.app.locator.tasks = this;
        this.app.store.subscribe({ property: 'tasks', component: this });
    }
    
    componentWillUnmount() {

        window.app.locator.tasks = null;
        this.app.store.unsubscribe({ property: 'tasks', component: this });
    }

    //--------------------------------------------------------------------------------------------
    renderTasks(items) {

        if (!items)
            return null;
        // reverse the list
        // Old way
        //const reversedTasks = items.slice();
        // ES6 way
        const reversedTasks = [...items];
        reversedTasks.reverse();
        return (
            reversedTasks.map(task => //{
                //this.renderTask(task) 
                this.renderTaskAndChildren(task)
                //                 
                //    this.renderTaskAndChildren(task);     
                //}
                //else {
                //    this.renderTask(task);
                //}         
    //}
    )
        );
    }

    renderTaskAndChildren(task) {

        this.counter++;
        // <div key={task.id} > --> replaced by 'counter' property
        return (   
            <div key={this.counter} >
                { this.renderTask(task) }
                { this.renderTasks(task.tasks) }                
            </div>            
        );
    }

    renderTask(task) { 

        // the 'followed' is used for UI when the list of tasks is rendered, and actually
        // means 'not-hidden' - we don't render tasks that are not being 'followed'.
        // TO DO: 'visible' could be better: refaxctor other visibility flags for task so this
        // property can be taken (now the similar props are used for transition state: 
        // maybe create own 'transition' object to keep those states ?)
        if (!task.followed) {
            return null;
        }

        formatTaskTitle(task);
        formatTaskInfo(task);

        //console.log('renderTask: ', task);
        let clickable = '';
        if (task.clickable) {
            clickable = 'button'; // a class to style the clickable task, for now just button class
        }
        let errorClass = '';
        if (task.error && task.status != 401) { // do not show error styles for 401, task will be relaunched after refreshing token, otherwise we should be redirected to the login 
            errorClass = ' error';
        }
        let css_visibility = '';
        if (task.css_visible) { // this state and class will control animation
            css_visibility = ' visible';
        }
        else {
            css_visibility = ' hidden';
        }
        // after setting the initial class that will be apllied ato this render,
        // change the state for tasks that are showing up, so in next render the style will be
        //if (task.css_showing) {
        //    task.css_showing = false;
        //    css_visible = true;
        //}

        console.log('renderTask. task: ', task)
        console.log('renderTask. errorClass: ', errorClass)
        
        console.log('renderTask. task.title: ', task.title)

        if (task.externalFile && task.externalFile.IsFolder) {            
            // FOLDER
            return (
                <div className={'task ' + clickable + errorClass + css_visibility} onClick={(e) => { window.app.taskProcessor.onTaskClicked(e, this.app, task); this.setState({ lastAction: 'task clicked' }); }}>
                    <div className='left-side'>
                        <div className='icon icon-documents'></div>
                    </div>
                    <div className='right-side'>
                        <div className='task-title'>
                            <div className='file-icon-holder'>
                            </div>
                            {task.title}
                        </div>
                        {
                            // button to close the task
                            <div className='task-button close'>
                                <div className='button small' onClick={(e) => { window.app.taskProcessor.closeTask(e, task) }}>
                                    <div className='button growing icon icon-close'></div>
                                </div>
                            </div>
                        }
                        {
                            // button to navigate to tasks page
                            //<div className='task-button close'>
                            //    <div className='button small' onClick={(e) => { goToTasksPage(e, task) }}>
                            //        <div className='button growing icon icon-arrow-forward'></div>
                            //    </div>
                            //</div>
                        }
                        {this.renderTaskState(task)}
                    </div>
                </div>
            );
        }
        else {            
            return (
                // INDIVIDUAL FILE
                <div className={'task ' + clickable + errorClass + css_visibility} onClick={(e) => { window.app.taskProcessor.onTaskClicked(e, this.app, task); this.setState({ lastAction: 'task clicked' }); }}>

                    <div className='task-title'>
                        <div className='file-icon-holder'>
                        </div>
                        {task.title}
                    </div>
                    {
                        // button to close the task
                        <div className='task-button close'>
                            <div className='button small' onClick={(e) => { window.app.taskProcessor.closeTask(e, task) }}>
                                <div className='button growing icon icon-close'></div>
                            </div>
                        </div>
                    }                    
                    {
                        // button to navigate to tasks page
                        //<div className='task-button close'>
                        //    <div className='button small' onClick={(e) => { goToTasksPage(e, task) }}>
                        //        <div className='button growing icon icon-arrow-forward'></div>
                        //    </div>
                        //</div>
                    }
                    {this.renderTaskState(task)}
                </div>
            );
        }
    }


    renderTaskState(task) {

        // TO DO: 
        // and error state? and completed state? (both texts could be composed here if necessary)
        //if (task.type === RequestType.Protect) console.log('render task : task.info: ' + task.info);
        //if (task.type === RequestType.Protect) console.log('render task : task: ', task);
        if (task.state === TaskState.Completed) {
            return (
                <div className='task-state'>                   
                    <div className='task-info'>{task.info}</div>
                </div>
            )
        }
        else {
            return (
                <div className='task-state'>

                    <Spinner animation="border" size="sm" />
                    <div className='task-info'>{task.info}</div>
                </div>
            )
        }
    }
    //-------------------------------------------------------------------------------------

    render() {
        //console.log('Tasks.render');
        const { tasks } = this.state;

        if (this.app.taskProcessor.counter.file.total === 0) {
            return (null);
        }

        // format the title string
        let title = this.app.R.ProcessingDocuments; // plural  
        if (this.app.taskProcessor.counter.file.processing === 1) {
            title = this.app.R.ProcessingDocument; // singular
        }
        // replace the placeholder
        let replacements = {
            '{n}': this.app.taskProcessor.counter.file.processing
        };
        title = template(title, replacements);

        //<div className='tasks over anim-1'>
        //<h4>{title}</h4>
        return (
            <div className='tasks'>
                
                {this.renderTasks(tasks)}
            </div>
        );
    }
}
