const subPath = '';//'/#';

export const routes = {
    login: () => `${subPath}/login`,
    home: () => `${subPath}/`,
    tasks: () => `${subPath}/tasks`,
    policies: {
        list: () => `${subPath}/protections`,
        edit: (id) => `${subPath}/protection/${id}/edit`,
        permissions: () => `${subPath}/permissions`,
        addUsersToPermissions: () => `${subPath}/addusers`,
        select: () => `${subPath}/protections/select`,
    },
    documents: {
        selected: () => `${subPath}/documents/selected`,
        list: () => `${subPath}/documents`,
        info: (id) => `${subPath}/document/${id}`,
    },
    contacts: () => `${subPath}/contacts`,
    invitations: () => `${subPath}/invitations`,
    options: () => `${subPath}/options`,
    identity: () => `${subPath}/identity`,
    test: () => `${subPath}/test`,
}