import { deleteSession, resetApp, saveAppSettings, saveSession } from '../appManager';
//import * as appManager from '../appManager';
import * as authManager from '../authManager';
import { processSelectedDocuments } from '../policyManager';
import { callServer } from '../taskManager';
import { loadAllUserData, loadUserData } from '../userManager';
import { RequestType, ResultCode, ExternalStorageConstants, TaskSubState } from '../../common/constants';
import { routes } from '../../common/routes';
import { api } from '../../common/api';
import loadScript from 'load-script';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

//let scriptLoadingStarted = false;

//export let app = {}; --> THIS DOESN'T WORK! THERE'S NO ACCESS TO A CONST

//export const openGoogleAuthUrlInNewWindow = (e, app, provider) => {

//    /*
//    https://accounts.google.com/o/oauth2/v2/auth?
//     scope=email%20profile&
//     response_type=code&
//     state=security_token%3D138r5719ru3e1%26url%3Dhttps%3A%2F%2Foauth2.example.com%2Ftoken&
//     redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&
//        client_id=client_id
//    */

//    console.log('openGoogleAuthUrlInNewWindow...');

//    var url = 'https://accounts.google.com/o/oauth2/v2/auth?' +
//        //'scope=https://www.googleapis.com/auth/drive&' +
//        'scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive&' + // TODO: disabled for tests: enable the scope back when tests are over
//        //'scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.readonly&' + // TODO: for tests only
//        'response_type=code&' +
//        'state=9876543210-abcdefghijklm&' +
//        'access_type=offline&' +
//        //'redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&' +
//        'redirect_uri=https%3A//pilot.lab.com/protectionapi/api/code&' +
//        'client_id=' + '420562818068-3olq30sktacv2bfaa640s6mlc06m31gg.apps.googleusercontent.com';//provider.clientId;

//    // The following kind of request is blocked by that URL,
//    // it only accepts requests from the the google own origin:
//    //var xhr = new XMLHttpRequest();
//    //xhr.open('GET',
//    //    url
//    //    );
//    //xhr.onreadystatechange = function (e) {
//    //    console.log(xhr.response);
//    //};
//    //xhr.send(null);

//    // this one will work, it is based on the redirect_uri endpoint waiting for the code
//    window.open(url, "_blank");
//}


// https://developers.google.com/identity/sign-in/web/server-side-flow

// called from React component when Google Drive buton is clicked
export const onGoogleProviderClicked = (e, app, provider) => {

    console.log('onGoogleProviderClicked');

    // need to set the global reference for further access, 
    // cannot pass the reference through Google callbacks
    // (looks like the arrow functions do not work!)
    window.app.storageProvider = provider;

    // for tests:
    //sendCode(app, '4/0AY0e-g65k7jZ_uAie1QRZUilZlO68fjoDwAwB4PiEi_B_i_uaPUqDaTr0AKG2cFWewoCiw'); return;


    // go to Protection API to get the access token (or directly to the Data API, as it has EF code?)
    const REFRESH_TOKEN_FLOW_ENABLED = false; // hardcoded for now: a flow without the refresh token will be completed first, given that refresh tokens are missing ProtectionApi to DataApi communication and DB implementation 
    if (REFRESH_TOKEN_FLOW_ENABLED) {

        let task = {
            app: app,
            ajax: {
                url: 'https://pilot.lab.com/protectionapi/api/externaltoken',
                params: {
                    provider: 'google'
                }
            },
            provider: provider,
            // when access token is returned (200)
            onComplete: function (task) {

                console.log('get externalstorage/token result: ', task.result);
                if (task.result && task.result.data) {
                    let access_token = task.result.data;//response.access_token;
                    app.auth.tokens.googleDriveAccessToken = access_token;

                    // get the picker
                    //if (isGoogleReady() && isGooglePickerReady()) {
                    //    openGooglePicker(task.app, task.provider, access_token);
                    //}
                    //else {
                    //    loadGooglePicker2(task.app, task.provider, access_token);
                    //}

                    //                loadGooglePicker(task.app, task.provider, access_token); --> simplify the calls using only one method: 'openGooglePicker'
                    openGooglePicker();
                }
            },
            // when access token is not found (404 or other)
            onError: function (task) {

                console.log('get externalstorage/token error: ', task.error);
                // should initialize the google auth and get the auth code to exchange it on server for refresh token ?
                if (true) {
                    loadGoogleAuth(app);
                }
                else {
                    // other errors
                }
            },
        };
        // this task will be added to the client queue
        task.enqueued = true;

        // push it
        window.app.taskProcessor.push(task);
    }
    else {
        // open the picker
        openGooglePicker();
    }
   
}

function logGoogle() {

    console.log('logGoogle. google: ', window.google);
    if (window.google) {
        console.log('logGoogle. google.picker: ', window.google.picker);
    }
    console.log('logGoogle. gapi: ', window.gapi);
    if (window.gapi) {
        console.log('logGoogle. gapi.auth: ', window.gapi.auth);
        console.log('logGoogle. gapi.auth2: ', window.gapi.auth2);
    }
    const app = window.app;

    console.log('logGoogle. app.googlePicker: ', app.googlePicker);
}

function loadGoogleAuth(app) {

    console.log('loadGoogleAuth');
    logGoogle();
    if (isGoogleReady()) {
        // google api already exists
        // init immediately
        loadGoogleAuthApi(app);
    } else if (!app.scriptLoadingStarted) {
        // load google api and the init
        app.scriptLoadingStarted = true;
        loadScript(GOOGLE_SDK_URL, loadGoogleAuthApi)
    } else {
        // is loading
    }
}

function loadGooglePicker(app, provider, access_token) {

    window.app.storageProvider = provider;
    console.log('loadGooglePicker');
    console.log('loadGooglePicker. app, provider, access_token: ', app, provider, access_token);
    logGoogle();
    if (isGoogleReady()) {
        // google api already exists
        // init immediately
        loadGooglePicker2(app, provider, access_token)
    } else if (!app.scriptLoadingStarted) {
        // load google api and the init
        app.scriptLoadingStarted = true;
        loadScript(GOOGLE_SDK_URL, (app, provider, access_token) => loadGooglePicker2(app, provider, access_token))
    } else {
        // is loading
    }
}

function loadGooglePicker2(app, provider, access_token) {

    console.log('loadGooglePicker2');
    console.log('loadGooglePicker2. app, provider, access_token: ', app, provider, access_token);
    logGoogle();
    //window.gapi.load('picker', (app, provider, access_token) => {
    //    console.log('google picker loaded');
    //    openGooglePicker(app, provider, access_token);
    //});
    window.gapi.load('auth', openGooglePicker);
    window.gapi.load('picker', openGooglePicker);
}

function isGoogleReady() {

    //logGoogle();
    return !!window.gapi;
}

function isGoogleAuthReady() {

    //logGoogle();
    if (!window.gapi)
        return false;
    return !!window.gapi.auth;
}

function isGooglePickerReady() {

    //logGoogle();
    if (!window.google)
        return false;
    return !!window.google.picker;
}

function isGoogleClientReady() {

    if (!window.gapi)
        return false;
    if (!window.gapi.client)
        return false;
    return !!window.gapi.client;
}

function isGoogleDriveReady() {

    if (!window.gapi)
        return false;
    if (!window.gapi.client)
        return false;
    if (!window.gapi.client.drive)
        return false;
    return !!window.gapi.client.drive;
}

function loadGoogleAuthApi(app) {

    console.log('loadGoogleAuthApi');
    logGoogle();
    //window.gapi.load('auth');
    window.gapi.load('auth2', function () {
        console.log('loadGoogleAuthApi: onComplete: google auth2 loaded');
        window.app.auth2 = window.gapi.auth2.init({
            client_id: '420562818068-3olq30sktacv2bfaa640s6mlc06m31gg.apps.googleusercontent.com',
            // Scopes to request in addition to 'profile' and 'email'
            scope: 'https://www.googleapis.com/auth/drive'
        });
        grantOfflineAccessForGoogle(window.app);
    });

}

function grantOfflineAccessForGoogle(app) {

    console.log('grantOfflineAccess');
    logGoogle();
    // signInCallback defined in step 6.
    app.auth2.grantOfflineAccess().then(signInCallback);
}

function signInCallback(authResult) {

    console.log('signInCallback');
    console.log('authResult: ', authResult);
    if (authResult['code']) {

        console.log('authResult[code]: ', authResult['code']);
        // Hide the sign-in button now that the user is authorized, for example:
        //$('#signinButton').attr('style', 'display: none');

        // Send the code to the server
        //$.ajax({
        //    type: 'POST',
        //    url: 'https://pilot.lab.com/protectionapi/api/code',
        //    // Always include an `X-Requested-With` header in every AJAX request,
        //    // to protect against CSRF attacks.
        //    headers: {
        //        'X-Requested-With': 'XMLHttpRequest'
        //    },
        //    contentType: 'application/octet-stream; charset=utf-8',
        //    success: function (result) {
        //        // Handle or verify the server response.
        //        console.log('code endpoint request result: ', result);
        //    },
        //    processData: false,
        //    data: authResult['code']
        //});
        sendCode(window.app, authResult['code']);
    } else {
        // There was an error.
        // TODO: inform about the error, using the global dialog
    }
}


function loadGoogleLibraries(callback) {

    console.log('');
    console.log('**********************************************************************');
    console.log('loadGoogleLibraries. onComplete: ', callback);
    const app = window.app;
    const provider = app.storageProvider;

    logGoogle();

    if (!isGoogleReady()) {
        console.log('loadGoogleLibraries. isGoogleReady: not ready');
        if (!app.scriptLoadingStarted) {
            // load google api
            app.scriptLoadingStarted = true;
            loadScript(GOOGLE_SDK_URL, function () { loadGoogleLibraries(callback) })
        }
        return null;
    }
    if (!isGoogleAuthReady()) {
        console.log('loadGoogleLibraries. isGoogleAuthReady: not ready');
        window.gapi.load('auth', function () { loadGoogleLibraries(callback) });
        return null;
    }
    if (!isGooglePickerReady()) {
        console.log('loadGoogleLibraries. isGooglePickerReady: not ready');
        window.gapi.load('picker', function () { loadGoogleLibraries(callback) });
        return null;
    }
    if (app.PROCESS_EXTERNAL_FOLDERS_ON_CLIENT) {
        if (!isGoogleClientReady()) {
            console.log('loadGoogleLibraries. isGoogleClientReady: not ready');
            window.gapi.load('client', function () { loadGoogleLibraries(callback) });
            return null;
        }
        if (!isGoogleDriveReady()) {
            console.log('loadGoogleLibraries. isGoogleDriveReady: not ready');
            //window.gapi.client.load('drive', 'v2', openGooglePicker);
            window.gapi.client.load('drive', 'v3', function () { loadGoogleLibraries(callback) });
            return null;
        }
    }
    // all Google libraries are loaded
    if (callback) callback();
}

// this is called both as a callback after loading google auth and google picker
function openGooglePicker() {

    console.log('');
    console.log('**********************************************************************');
    console.log('openGooglePicker');
    const app = window.app;
    const provider = app.storageProvider;

    logGoogle();

    if (!isGoogleReady()) {
        console.log('openGooglePicker. isGoogleReady: not ready');
        if (!app.scriptLoadingStarted) {
            // load google api
            app.scriptLoadingStarted = true;
            loadScript(GOOGLE_SDK_URL, openGooglePicker)
        }
        return null;
    }
    if (!isGoogleAuthReady()) {
        console.log('openGooglePicker. isGoogleAuthReady: not ready');
        window.gapi.load('auth', openGooglePicker);
        return null;
    }
    if (!isGooglePickerReady()) {
        console.log('openGooglePicker. isGooglePickerReady: not ready');
        window.gapi.load('picker', openGooglePicker);
        return null;
    }
    if (app.PROCESS_EXTERNAL_FOLDERS_ON_CLIENT) {
        if (!isGoogleClientReady()) {
            console.log('openGooglePicker. isGoogleClientReady: not ready');
            window.gapi.load('client', openGooglePicker);
            return null;
        }
        if (!isGoogleDriveReady()) {
            console.log('openGooglePicker. isGoogleDriveReady: not ready');
            //window.gapi.client.load('drive', 'v2', openGooglePicker);
            window.gapi.client.load('drive', 'v3', openGooglePicker);
            return null;
        }
    }
    //if (!app.googlePicker) {
    if (true) {

        console.log('openGooglePicker. picker is null: create one...');

        //window.gapi.auth.authorize({
        //    client_id: client_id,
        //    scope: scope,
        //    immediate: authImmediate
        //},
        //    callback
        //);

        const languageCode = app.settings.language.id ? app.settings.language.id : app.store.state.options.appSettings.defaultLanguage;

        // TO DO: check if a new one access token is really created each time we call 'gapi.auth.getToken'
        //    --> done: 1. it's reusing token created with the current 'auth' instance. 
        //       2. so, after app restart it will get a new one (token could be saved optionally in the local storage to solve this)

        // for flow development:
        //const badToken = { access_token: 'ya29.a0AfH6SMCsEzsjPCqQINuh1Vf0uF0HEPijD54GvWICFnKb_QmryvxNAWEM3OpF9QSAlA6nT-JW0o9dHCmBJ_e7qpdlHKP_omI3zfShpr1q8znj3OFvm5L5alY_GI515apZckBGbkDVf-VYMVGebszwqGUWgoD3yVB_-f4Ew2mhtm52' };
        //const goodToken = { access_token: 'ya29.a0AfH6SMCkH8BsIwxHcvqG76AldvJsUjDadrEGkCTO_lfc4lTZPMf3iCd85Qm55F_XdYa8oZuiUaCFExS-MgdPnQr1Mfx76paAGVGg_afHddV6wTkD_sTEfxOCQgyFit7Rd_ZfqPJ6YEIU8sjPVQiUFQSFbP84BHhvycdQ1xhO7iw4DA' };
        //let token = badToken;

        // get google token
        let token = window.gapi.auth.getToken();
        console.log('Google token: ', token);


        // define the callback
        let createPicker = (response) => {

            const google = window.google;

            console.log('createPicker.1');
            console.log('createPicker.1. google.picker: ', google.picker);

            // always keep and use the latest access token when pushing the requests to the server later
            app.auth.tokens.googleDriveAccessToken = response.access_token;

            if (!app.docsView) {

                const googleViewId = google.picker.ViewId.DOCS;//FOLDERS;
                app.docsView = new google.picker.DocsView(googleViewId)
                    .setIncludeFolders(true)
                    .setParent('root') // looks like it does the same as 'setOwnedByMe' below(?): TO DO: check docs
                    //.setOwnedByMe(true)
                    // do not set mime-types if need to show all types
                    //.setMimeTypes(['image/png', 'image/jpeg', 'image/jpg'])//'application/vnd.google-apps.folder')
                    .setSelectFolderEnabled(true);
            }

            //if (!app.googlePicker) {
            
            console.log('createPicker.2');
            app.googlePicker = null;
            app.googlePicker = new window.google.picker.PickerBuilder()
                .addView(app.docsView)
                //.setOAuthToken(accessToken)
                .setOAuthToken(response.access_token)
                .setDeveloperKey(provider.developerKey)
                //.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
                .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
                .setLocale(languageCode)
                // Set the preferred dialog size. The dialog is auto-centered. It has a minimum size of (566,350) and a maximum size of (1051,650).
                // https://developers.google.com/picker/docs/reference#Picker
                .setSize(1051, 650) // --> set the maxmium size allowed: it will stretch down in width on small screens, and height will be the 650 (scrollable)
                .setCallback((data) => googleDrivePickerCallback(app, provider, data, google)
                );
            console.log('createPicker.2. app.googlePicker: ', app.googlePicker);
            //showGooglePicker();
            if (app.googlePicker) {
                console.log('showGooglePicker: app.googlePicker.build.setVisible');
                //app.googlePicker.build().setVisible(true);
                let build = app.googlePicker.build();
                console.log('build: ', build);
                let visible = build.setVisible(true);
                console.log('visible: ', visible);
                //if (!visible) {
                //    // try with an updated token
                //    token = window.gapi.auth.getToken();
                //    console.log('Google token 2: ', token);


                //    if (token && token.access_token) {
                //        //createPicker(token);
                //    } else {
                //        doAuth(response => {
                //            // response here is a 'full' google token and it should have the 'access_token' property
                //            console.log('doAuth response: ', response);
                //            if (response.access_token) {
                //                //this.createPicker(response.access_token)
                //                console.log('doAuth success');
                //                createPicker(response)
                //            } else {
                //                console.log('doAuth failed');
                //                onAuthFailed(response);
                //            }
                //        });
                //    }
                //}
            }
            else {
                console.log('showGooglePicker: app.googlePicker is null!');
            }
            //}
        };

        if (token && token.access_token) {
            createPicker(token);
        } else {
            doAuth(response => {
                // response here is a 'full' google token and it should have the 'access_token' property
                console.log('doAuth response: ', response);
                if (response.access_token) {
                    //this.createPicker(response.access_token)
                    console.log('doAuth success');
                    createPicker(response)
                } else {
                    console.log('doAuth failed');
                    onAuthFailed(response);
                }
            });
        }
    }
    //showGooglePicker();
}

function showGooglePicker() {

    const app = window.app;
    if (app.googlePicker) {
        console.log('showGooglePicker: app.googlePicker.build.setVisible');
        app.googlePicker.build().setVisible(true);
        let build = app.googlePicker.build();
        console.log('build: ', build);
        let visible = build.setVisible(true);
        console.log('visible: ', visible);
    }
    else {
        console.log('showGooglePicker: app.googlePicker is null!');
    }
}

function doAuth(callback) {

    console.log('doAuth');
    logGoogle();
    const app = window.app;
    const provider = app.storageProvider;
    if (provider) {
        window.gapi.auth.authorize({
            client_id: provider.clientId,
            scope: provider.scope,
            immediate: false //this.props.authImmediate --> TO DO: check what this param does
        },
            callback
        );
    }
    else {
        console.log('doAuth: provider is null!');
    }
}

function onAuthFailed(response) {
    console.log('onAuthFailed: ', response);
}

/**
 * Callback method for Google Picker event ('on change').
 * We should obtain a list of items selected in the Picker.
 *
 * @param {App} app reference to the current App class instance (TODO: it alwasy points to the static global instance so it could be possibly removed out of the function arguments)
 * @param {object} provider reference to the object with the current external storage provider data (TODO: convert to class) 
 *
 */
function googleDrivePickerCallback(app, provider, data, google) {

    console.log('on Google Drive change:', data);
    var url = 'nothing';
    let docs = null;
    let shouldRenderDocs = false;
    app.storageProvider = provider;
    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {

        // get the selected documents and process them
        let docs = data[google.picker.Response.DOCUMENTS];
        if (docs && docs.length > 0) {
            docs.map(doc => {
                doc.css_visible = true; // add a visibility flag that will be reset on close, when hide transition is completed // TO DO: REMOVE THE RENDERING FLAGS FROM DOC MODELS! APPLY THEM ONLY TO TASKS CREATED FOR THE DOCS!
                console.log('document ' + doc.name + ': ', doc)
                //console.log('document url: ', doc[google.picker.Document.URL])
            })
            // Keep the selected documents in the store using the "set state" call, so any
            // component subscribed to this store property will have its own state updated.
            app.store.setState({ selectedDocuments: docs });

            //Go to the page with selected documents
            window.app.history.push(routes.documents.selected());
        }
    }
}

function sendCode(app, code) {

    console.log('sendCode: ', code);

    // send code to the Protection API
    var url = 'https://pilot.lab.com/protectionapi/api/code';

    var task = {
        onComplete: function (task) {
            console.log('sendCode: callback...', task);
            // TO DO:
            // check if everything is ok
            // now open the picker
            openGooglePicker();

        },
        onError: function (task) {
            console.log('sendCode: on error...', task);
            // TO DO:
            // check if everything is ok
            // now open the picker
            openGooglePicker();

        },
        ajax: {
            url: url,
            params: {
                code: code,
                state: 'abcdefgh12345678',
                scope: 'https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive'//'YT1odHRwcyUzQSUyRiUyRnBpbG90My5zZWFscGF0aC5jb20lMkZhZGZzJnI9YXBpLnJtcy5yZXN0LmNvbQ',
            },
        }
    }
    //pushTask(task);
    callServer(task);
}

/**
 * Retrieve a list of files belonging to a folder.
 *
 * @param {String} folderId ID of the folder to retrieve files from.
 * @param {Function} callback Function to call when the request is complete.
 *
 */

function retrieveAllFilesInFolder(folderId, callback, errorCallback) {

    const gapi = window.gapi;

    // THE CALLBACKS ARE MISSING THE ARGUMENTS!!!
    //if (!isGoogleReady()) {
    //    console.log('retrieveAllFilesInFolder. isGoogleReady: not ready');
    //    if (!app.scriptLoadingStarted) {
    //        // load google api
    //        app.scriptLoadingStarted = true;
    //        loadScript(GOOGLE_SDK_URL, retrieveAllFilesInFolder)
    //    }
    //    return null;
    //}
    //if (!isGoogleAuthReady()) {
    //    console.log('retrieveAllFilesInFolder. isGoogleAuthReady: not ready');
    //    window.gapi.load('auth', retrieveAllFilesInFolder);
    //    return null;
    //}   

    // Note:
    //gapi.client.drive.children.list is a v2 endpoint.
    //in v3, the equivalent is a files.list with a q = { folder_id } in parents.

    // Note: 
    //var request = gapi.client.request({
    //    'path': '/drive/v2/files',
    //    'method': 'GET',
    //    'params': { 'maxResults': '1' }
    //});
    //instead of:
    //var request = gapi.client.drive.files.list({ 'maxResults': 5 });
    //resolves the problem of loading the client dependencies manually

    var retrievePageOfChildren = function (request, result) {

        //request.execute(function (response) {
        request.execute(function (response) {
            if (response.error) {
                // Handle error
                console.error(response.error);
                if (errorCallback) errorCallback(response);                
            } else {
                if (response.files) result = result.concat(response.files);

                console.log('google drive: retrievePageOfChildren callback. request: ', request);
                console.log('google drive: retrievePageOfChildren callback. result: ', result);

                var nextPageToken = response.nextPageToken;
                if (nextPageToken) {
                    request = gapi.client.drive.files.list({
                        //'folderId': folderId, // v2
                        'q': "'" + folderId + "' in parents", // v3
                        'pageSize': 100, // accepts 1-1000, default value = 100
                        'pageToken': nextPageToken
                    });
                    retrievePageOfChildren(request, result);
                } else {
                    callback(result);
                }
            }
        });
    }

    var initialRequest = gapi.client.drive.files.list({
        //'folderId': folderId, // v2
        'q': "'" + folderId + "' in parents", // v3
        'pageSize': 100 // accepts 1-1000, default value = 100
    });
    retrievePageOfChildren(initialRequest, []);
}

export function createExternalFileForGoogleDriveDocument(app, doc) {

    // Prepare the model used in client-server communication.
    // This model will be deserialized on server using the ExternalFile class.

    let isFolder = doc.mimeType === ExternalStorageConstants.GOOGLE_DRIVE_FOLDER;
    let externalFile = {
        AccessToken: app.auth.tokens.googleDriveAccessToken,
        Name: doc.name,
        IsFolder: isFolder,
        //RefreshToken: app.auth.tokens.googleDriveRefreshToken,
        Type: 'GoogleDrive', // TO DO: set it at the begining, as the 'parentTask' or 'currentTask' property,
        GoogleDriveFile: doc
    }
    return externalFile;
}


export function getGoogleDriveFolderItems(app, task) {

    console.log('getGoogleDriveFolderItems: load Google libraries first');

    loadGoogleLibraries(function () { onGetGoogleDriveFolderItems_WhenGoogleIsReady(app, task) });

}

export function onGetGoogleDriveFolderItems_WhenGoogleIsReady(app, task) {

    console.log('onGetGoogleDriveFolderItems_WhenGoogleIsReady');

    const folderId = task.externalFile.GoogleDriveFile.id;
    
    if (!folderId || folderId === '') {
        onGetGoogleDriveFolderItemsError(app, task);
    }
    console.log('onGetGoogleDriveFolderItems_WhenGoogleIsReady. folderId: ', folderId);
    // call the fucntion that works with google client to load folder contents
    retrieveAllFilesInFolder(folderId,
        function (documents) { onGetGoogleDriveFolderItems(app, task, documents) },
        function (error) { onGetGoogleDriveFolderItemsError(app, task, error) });
    
}


export function onGetGoogleDriveFolderItemsError(app, task, error) {
    console.log('onGetGoogleDriveFolderItemsError. error: ', error);

    // update the status of the error task and laucnh the next one 

    // set some special task status?
    // ...
    task.hasError = true;
    task.error = {
        error_code: ResultCode.GoogleDriveError
    };

    app.taskProcessor.onComplete(task);

}


export function onGetGoogleDriveFolderItems(app, task, files) {

    console.log('onGetGoogleDriveFolderItems. task: ', task);
    console.log('onGetGoogleDriveFolderItems. documents: ', files);

    if (!files || files.length === 0) {
        // if no files are returned for the folder - complete the task

        // set some special task status?
        // ...

        app.taskProcessor.onComplete(task);
    }
    else {

        task.substate = TaskSubState.DocumentProcessing;
        // create the tasks out of the files that are returned and launches them
        processSelectedDocuments(app, task.tool, files, task);

    }
}



