import axios from 'axios';

import * as appManager from './appManager';
import { addAuthorization, extractAuthorization, extractSessionAndCodeData, setLoginError, isAuthorizationTask, notifyUnauthorizedTask, onLoginError } from './authManager';

//import { seeDocumentInfo } from './documentManager';
import { routes } from '../common/routes';
import { ExternalStorageConstants, ResultCodeInfo, RequestType, TaskState, TaskSubState } from '../common/constants';
import { getGoogleDriveFolderItems } from './storage/googleDrive';


// (new behavior) instead of calling 'callServer' immediately, this intermediate method will check
// if the task needs any other data - it is made for external folders, where the folder items need
// to be retrieved and pushed as subtasks.
// The folder operations are JIT to avoid loading folder contents long before actually launching it.
export function launchEnqueuedTask(task) {

    //console.log('launchEnqueuedTask');

    const app = window.app;
    let hasSubTasks = false;

    if (task.type === RequestType.ProtectExternal || task.type === RequestType.CustomProtectionExternal || task.type === RequestType.UnprotectExternal) {
        //console.log('launchEnqueuedTask 1');

        if (task.provider.id === 'googledrive') {
            //console.log('launchEnqueuedTask 2' );

            if (task.externalFile) {
                //console.log('launchEnqueuedTask 3');

                // update the token (the task could have spent some time waiting
                // in the queue, and the token could have been updated meanwhile)
                task.externalFile.AccessToken = app.auth.tokens.googleDriveAccessToken;

                // is it a folder? (TODO: check if google API provides a const property for that mime type, e.g. gapi.constants.FOLDER)
                if (task.externalFile.GoogleDriveFile && task.externalFile.GoogleDriveFile.mimeType === ExternalStorageConstants.GOOGLE_DRIVE_FOLDER) {
                    //console.log('launchEnqueuedTask 4');

                    // get the folder items and add them as subtasks
                    //...
                    getGoogleDriveFolderItems(app, task);
                    hasSubTasks = true;
                }               
            }
        }        
    }

    if (hasSubTasks) {
        // when the folder contents are loaded (async), a callback will insert the subtasks to the task queue
        // do nothing more here for now, later consider updating task or UI state somehow here.
    }
    else {
        callServer(task);
    }
}

// the task.title (+ task.substate) field is used for the UI rendering
export function formatTaskTitle(task) {
    //console.log('formatTaskTitle', task)
    const app = window.app;
    if (!task.hasError && task.state === TaskState.Completed) {
        //console.log('formatTaskTitle: completed. task.type: ', task.type)
        if (task.type === RequestType.Protect ||
            task.type === RequestType.ProtectExternal ||
            (task.type === RequestType.Download && task.previousType === RequestType.Protect) ||
            (task.type === RequestType.Download && task.previousType === RequestType.ProtectExternal)
        ) {

            task.title = app.R.Protected + ': ' + task.fileName;
            //console.log('formatTaskTitle. Protect or ProtectExternal. title: ', task.title)
        }
        else if (task.type === RequestType.CustomProtection ||
            task.type === RequestType.CustomProtectionExternal ||
            (task.type === RequestType.Download && task.previousType === RequestType.CustomProtection) ||
            (task.type === RequestType.Download && task.previousType === RequestType.CustomProtectionExternal)
        ) {
            task.title = app.R.Protected + ': ' + task.fileName;
            //console.log('formatTaskTitle. CustomProtection or CustomProtectionExternal. title: ', task.title)
        }
        else if (task.type === RequestType.Unprotect ||
            task.type === RequestType.UnprotectExternal ||
            (task.type === RequestType.Download && task.previousType === RequestType.Unprotect) ||
            (task.type === RequestType.Download && task.previousType === RequestType.UnprotectExternal)
        ) {
            task.title = app.R.Unprotected + ': ' + task.fileName;
            //console.log('formatTaskTitle. Unprotect or UnprotectExternal. title: ', task.title)
        }
        else if (task.type === RequestType.DocumentInfo) {

            task.title = app.R.Document + ': ' + task.fileName;
        }
    }
    else {

        if (task.type === RequestType.Protect ||
            task.type === RequestType.ProtectExternal ||
            task.type === RequestType.CustomProtection ||
            task.type === RequestType.CustomProtectionExternal ||
            task.type === RequestType.TestProtection) {

            task.title = app.R.Protect + ': ' + task.fileName;
        }
        else if (task.type === RequestType.Unprotect ||
            task.type === RequestType.UnprotectExternal) {

            task.title = app.R.Unprotect + ': ' + task.fileName;
        }
        else if (task.type === RequestType.DocumentInfo) {

            task.title = app.R.Document + ': ' + task.fileName;
        }
        //else if (task.type === RequestType.ProtectExternal ||
        //    task.type === RequestType.UnprotectExternal ||
        //    task.type === RequestType.CustomProtectionExternal) {
        //    if (task.externalFile && task.externalFile.IsFolder) {
        //        task.title = app.R.Protect + ': ' + task.externalFile.Name; // Name: Upperscore (server model)
        //        //task.title = app.R.ProtectFolder + ': ' + task.externalFile.Name; // Name: Upperscore (server model)
        //        // TO DO: clean R.ProtectFolder if not used eventually
        //    }
        //    else {
        //        task.title = app.R.Protect + ': ' + task.externalFile.Name; // Name: Upperscore (server model)
        //    }
        //}
    }

}


export function formatTaskInfo(task) {

    const app = window.app;

    // STATE: NONE (here used as WAITING)
    if (task.state === TaskState.None) {

        task.info = app.R.Waiting;
    }
    // STATE: PROCESSING
    else if (task.state === TaskState.Processing) {

        task.info = '';

        // USE SUB-STATE TO FORMAT STATUS TEXT
        //if (task.substate === TaskSubState.None) {
        //    task.info = '';
        //} else


        if (task.substate === TaskSubState.Waiting) {
            task.info = app.R.Waiting;
        }
        else if (task.substate === TaskSubState.WaitingForAuthorization) {
            // hide the authorizing under the 'starting' message
            task.info = app.R.Starting;
        }
        else if (task.substate === TaskSubState.Starting) {
            task.info = app.R.Starting;
        }
        else if (task.substate === TaskSubState.Uploading) {
            task.info = app.R.Uploading + ' ' + task.loadProgress + '%';
        }
        else if (task.substate === TaskSubState.DocumentProcessing) {

            if (task.externalFile && task.externalFile.IsFolder) {
                // FOLDER
                task.info = app.R.Completed + ' ' + task.progress + '%';
            }
            else {
                // NON-FOLDER
                if (task.type === RequestType.DocumentInfo) {
                    task.info = app.R.GettingDocumentInfo;
                }
                else {
                    //if (task.type === RequestType.Protect) console.log('set the *processing* text. task: ', task)
                    task.info = app.R.Processing;
                }
            }
        }
        else if (task.substate === TaskSubState.Downloading) {
            task.info = app.R.Downloading + ' ' + task.loadProgress + '%';
        }
    }
    // STATE: COMPLETED
    else if (task.state === TaskState.Completed) {

        // default info
        task.info = app.R.Completed;

        if (task.substate === TaskSubState.ClickToDownload) {
            task.info = app.R.ClickToDownload;
        }
        else if (task.substate === TaskSubState.ClickToDownloadAgain) {
            task.info = app.R.ClickToDownloadAgain;
        }
        else if (task.substate === TaskSubState.Downloaded) {
            task.info = app.R.Downloaded;
        }
        else if (task.substate === TaskSubState.Cancelled) {
            task.info = 'cancelled'; // TO DO: detect case of use for visible tasks and add the localized string if needed
        }
    }

    //----------------------------------------------------------
    // FOLDER
    else if (task.externalFile && task.externalFile.IsFolder) {
        //if (task.substate === TaskSubState.Waiting) {
        //    task.info = app.R.Waiting;
        //}

        //if (task.state === TaskState.None) {
        //    task.info = app.R.Waiting;
        //}
        //else if (task.state === TaskState.Processing) {
        //    task.info = app.R.Completed + ' ' + task.progress + '%';
        //}
        //else if (task.state === TaskState.Completed) {
        //    task.info = app.R.Completed + ' ' + task.progress + '%';
        //}            
    }

    //----------------------------------------------------------
    // ERROR (will overwrite the previous status. could be placed in if (COMPLETED) or here, so even the tasks being processed now can render error status, e.g. folders with partial results that have error subtasks)
    if (task.error && task.status !== 401) {
        // try to set a task error message depending on the result code
        // Remember: we don't want users to get scared by too much technical information, if they 
        // can't do much to resolve the error, just tell them that request couldn't be processed.            
        let info = '';
        //if (task.error) {
        //console.log('render task: error: ' + task.error);
        if (task.error.error_code < 0) {
            info = ResultCodeInfo(app, task.error.error_code);
            if (info !== '') {
                //task.info = app.R.Error + ' : ' + info;
            }
            else if (task.error.error_description) {
                let message = task.error.error_description;
                task.info = app.R.Error + ' : ' + message;
                //console.log('error: custom message: ', message);
            }
        }
        //console.log('render task: error code: ' + task.error.error_code + ', info: ' + info);
        //}            
        if (info && info !== '') {
            //task.info = app.R.Error + ' : ' + info;
            task.info = info;
        }
    }
}

// auxiliar method
export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

//-------------------------------------------------------------------

// 'task' object will be parsed here before we send it to the server to be received
// as the "Action.cs" object, which has all properties starting with a capital letter.
export function convertTaskToFormData(task) {

    let key, value;
    // Build formData object.
    let formData = new FormData();

    if (task.type) {
        formData.append('Type', task.type);
    }
    if (task.typeName) {
        formData.append('TypeName', task.typeName);
    }

    if (task.file) {
        formData.append('File', task.file);
    }
    if (task.filePath) {
        formData.append('FilePath', task.filePath);
    }
    if (task.fileName) {
        formData.append('FileName', task.fileName);
    }
    if (task.tool) {
        if (task.tool.Guid) { // guid has a preference as a API param, it is used in 'better-quality' endpoints and when we can provide it - the task id is not necessary
            formData.append('ToolGuid', task.tool.Guid);
        }
        else { // there's no guid, attach the id
            formData.append('ToolId', task.tool.id);
        }      
        // the tool.policy is set in the 'initializeFileTask' method, called from Home page tools: the protection tools set the policy
        if (task.tool.policy) {
            if (task.tool.policy.Guid) { // guid has a preference as a API param, it is used in 'better-quality' endpoints and when we can provide it - the task id is not necessary
                formData.append('ToolGuid', task.tool.policy.Guid);
            }
            else { // there's no guid, attach the id
                formData.append('ToolId', task.tool.policy.id);
            }
        }
    }
    
    if (task.policy) { // this will be used for custom policy
        value = JSON.stringify(task.policy); // we need to convert object to string to use it as a form data value
        ////console.log('appending stringified policy', value);
        formData.append('PolicyString', value);        
    }
    if (task.targetBoolean) {
        formData.append('TargetBoolean', task.targetBoolean);
    }   
    return formData;
}

// 'task' object will be parsed here before we send it to the server to be received
// as the "Action.cs" object, which has all properties starting with a capital letter.
export function convertProtectionTaskParamsToFormData(task) {

    //console.log('convertProtectionTaskParamsToFormData');
    let key, value;
    // Build formData object.
    let formData = new FormData();

    if (task.type) {
        formData.append('Type', task.type);
    }
    if (task.typeName) {
        formData.append('TypeName', task.typeName);
    }

    if (task.file) {
        formData.append('File', task.file);
    }

    if (task.externalFile) {
        //formData.append('ExternalFile', task.externalFile);
        formData.append('ExternalFileSerialized', JSON.stringify(task.externalFile));
    }

    if (task.filePath) {
        formData.append('FilePath', task.filePath);
    }
    if (task.fileName) {
        formData.append('FileName', task.fileName);
    }
    if (task.tool) {
        if (task.tool.Guid) { // guid has a preference as a API param, it is used in 'better-quality' endpoints and when we can provide it - the task id is not necessary
            formData.append('PolicyGuid', task.tool.Guid);
        }
        else if (task.tool.id) { // there's no guid, attach the id
            formData.append('PolicyId', task.tool.id);
        }
        // the tool.policy is set in the 'initializeFileTask' method, called from Home page tools: the protection tools set the policy
        if (task.tool.policy) {
            if (task.tool.policy.Guid) { // guid has a preference as a API param, it is used in 'better-quality' endpoints and when we can provide it - the task id is not necessary
                formData.append('PolicyGuid', task.tool.policy.Guid);
            }
            else if (task.tool.policy.id){ // there's no guid, attach the id
                formData.append('PolicyId', task.tool.policy.id);
            }
        }
    }

    if (task.policy) { // this will be used for custom policy
        value = JSON.stringify(task.policy); // we need to convert object to string to use it as a form data value
        ////console.log('appending stringified policy', value);
        formData.append('PolicySerialized', value);
    }
    var options = task.options;
    if (options) {
        if (options.DeleteFileAfterDownload) {
            formData.append('DeleteFileAfterDownload', options.DeleteFileAfterDownload);
        }
        if (options.AutoFileDownload) {
            formData.append('AutoFileDownload', options.AutoFileDownload);
        }
        if (options.Operation) {
            formData.append('Operation', options.Operation);
        }
    }
    
    return formData;
}

function convertObjectToFormData(obj, rootName, ignoreList) {

    var formData = new FormData();

    function appendFormData(data, root) {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
                //////console.log('form data append. key: ' + root + ', data: ' + data);
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '.' + key);
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                    //////console.log('form data append. key: ' + root + ', data: ' + data);
                }
            }
        }
    }

    function ignore(root) {
        return Array.isArray(ignoreList)
            && ignoreList.some(function (x) { return x === root; });
    }

    appendFormData(obj, rootName);

    return formData;
}

//-------------------------------------------------------------------
// We need to convert the task data to SealPathRequest - a model used by the server.
// Basically we need to convert some properties to uppercase, and exclude other properties,
// We can't just send a 'task' object directly, because it has extra properties that
// create the circular dependencies at JSON conversion (the 'task.ajax.data = task' - it won't work!) 
//export function convertTaskToAjaxData(task) {
    
//    // the JSON object with SealPathRequest model to be returned:
//    let model = {};

//    model.TypeId = task.type;
//    model.TypeName = task.typeName;

//    if (task.filter) {
//        // need to convert a bigger filter model used in components to smaller server model
//        let serverFilter = {
//            DateFilterId: task.filter.selectedDateFilter.id,
//            Text: task.filter.text,
//            PageIndex: task.filter.page.index,
//            PageSize: task.filter.page.size,
//        }
//        model.Filter = serverFilter;
//    }
//    if (task.policy) {
//        model.Policy = task.policy;
//    }
//    if (task.targetGuid) {
//        model.TargetGuid = task.targetGuid;
//    }
//    if (task.targetId) {
//        model.TargetId = task.targetId;
//    }
//    if (task.targetName) {
//        model.TargetName = task.targetName;
//    }
//    if (task.targetValue) {
//        model.TargetValue = task.targetValue;
//    }
//    if (task.targetNames) {
//        model.TargetNames = task.targetNames;
//    }
//    if (task.targetBoolean) {
//        model.TargetBoolean = task.targetBoolean;
//    }
    
//    if (task.tool) {
//        if (task.tool.id) {
//            model.ToolId = task.tool.id;
//        }
//        if (task.tool.guid) {
//            model.ToolGuid = task.tool.guid;
//        }
//    }
//    //// move the file posting from FormData to object sent in body?
//    // NOTE: file sent in request body will break the controller and return 500 error code
//    //if (task.file) {
//    //    model.File = task.file;
//    //}
//    return model;
//}

// do not call this method directly: do call 'push(task)' first, it will prepare the task and the counters and it will 'call server' if needed
export const callServer = (task) => {

    const app = window.app;
    const auth = app.auth;
    // TO DO: first convert the task type from string to int, to send the id, not the string
    //task.type: = action[task.type];
    //////console.log('task type.id for ' + task.type + ' is: ' + task.type:);

    //-----------------------------------------------------------
    // prepare the HTTP request:

    let request = task.ajax;

    if (task.type == RequestType.Download) { // this should be always a blob
        //console.log('set the response type to blob');
        request.responseType = 'blob';
    }
    else if ((task.type == RequestType.Protect || task.type == RequestType.CustomProtection || task.type == RequestType.Unprotect) && app.store.state.options.appSettings.Server.AutoFileDownload) {
        //console.log('set the response type to blob');
        request.responseType = 'blob';
    }
    else {
        //console.log('request.headers : ', request.headers);
        if (!request.headers) {
            request.headers = {
                // test if adding headers is really needed in axios: could be it adds the headers automatically
                //'Accept': 'application/json',
                //'Content-Type': 'application/json'            
            };
        }        
    }
    // add tokens or authorization code to the HTTP request
    addAuthorization(app, task);

    if (task.type === RequestType.Protect || task.type === RequestType.CustomProtection || task.type === RequestType.Unprotect || task.type === RequestType.Download) {
        task.loadProgress = 0;

        //request.timeout = 30000;
        // `onUploadProgress` allows handling of progress events for uploads
        request.onUploadProgress = (progressEvent) => {
            // Do whatever you want with the native progress event
            if (progressEvent.lengthComputable) {
                //if (task.type === RequestType.Protect) console.log('upload progress: loaded: ' + progressEvent.loaded + ', total: ' + progressEvent.total);
                task.loadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                //if (task.type === RequestType.Protect) console.log('upload progress: ', task.loadProgress);
            }
            else {
                task.loadProgress = 0;
            }
            // update the state: first set the default 'uploading' state, then check if have 
            // Except when the task is waiting for authorization: we don't want to see any upload/processing states yet) 
            if (task.substate !== TaskSubState.WaitingForAuthorization) {
                task.substate = TaskSubState.Uploading;
                if (task.loadProgress === 100) {
                    //if (task.type === RequestType.Protect) console.log('set the *processing* text in callServer. task: ', task)
                    task.substate = TaskSubState.DocumentProcessing;
                }
            }
            
            app.store.setState({ tasks: app.taskProcessor.tasks });
        };
        request.onDownloadProgress = (progressEvent) => {
            // Do whatever you want with the native progress event
            if (progressEvent.lengthComputable) {
                ////console.log('download progress: loaded: ' + progressEvent.loaded + ', total: ' + progressEvent.total);
                task.loadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                ////console.log('download progress: ', percentCompleted)
            }
            else {
                task.loadProgress = 0;
            }// Except when the task is waiting for authorization: we don't want to see any upload/processing states yet) 
            if (task.substate !== TaskSubState.WaitingForAuthorization) {
                task.substate = TaskSubState.Downloading;
            }
            app.store.setState({ tasks: app.taskProcessor.tasks });
        };
    }     

    //request.responseType = 'text';
    //-----------------------------------------------------------

    //axios.get('some api url', { withCredentials: true });
    //request.withCredentials = true;
    //axios.defaults.withCredentials = true;

    // make the request:    
    axios(request)
        .then(response => {      
            // the 'completed' flag can be set to true before receiving the response,
            // to abort further processing of the task (without relaunching). E.g. we do it
            // for the pending document tasks when another document type is selected to be loaded
            if (task.state === TaskState.Completed)
                return;

            task.result = response.data;
            task.response = response; // save it so later we can extract the headers when we donwload file streams
            // extract the authorization data (auth code, access token or refresh token) from the HTTP response headers and response data:
            if (task.type == RequestType.Preauthorize ||
                task.type == RequestType.AuthorizeWithCookie ||
                task.type == RequestType.AuthorizeWithUserNamePassword ||
                task.type == RequestType.GetTokenWithAuthorizationCode ||
                task.type == RequestType.GetTokenWithRefreshToken) {

                extractAuthorization(task, response);

                if (task.type === RequestType.Preauthorize) {
                    extractSessionAndCodeData(app, task, response); // maybe captcha or two-factor are required now (sent in the 'code-type' header)
                    //onLoginError(app, task);
                }
            }
                 
            window.app.taskProcessor.onComplete(task); // we call this even if task has been cancelled: the 'onComplete' will update the task UI and then abort further execution
        })
        .catch(error => {

            //console.log('ERROR: ');
            //console.log(error);
            let status = error.status;
            let message = ''; // initialize the message text

            // set the task error flag      
            task.hasError = true;
            task.error = error; // this error object is the browser's (the highest level) error object

            // if we have cancelled the task manually, and marked it to be relaunched: we cancel all 
            // pending tasks (those with 'completed' == false), when one of them gets 401 (unauthorized)
            // so the new tokens can be obtained and all tasks with 'waitingForAuthorization = true' relaunched
            if (task.waitingForAuthorization) {
                // abort further processing if task has been cancelled:
                //task.info = 'wait for relaunch';
                window.app.taskProcessor.onComplete(task); // we call this even if task has been cancelled: the 'onComplete' will update the task UI and then abort further execution
                return;
            }

            if (error.message) {
                // assign the initial message, later overwrite it with the custom
                // error description if it's returned from server in the response
                message = error.message;
                //console.log('error: ', error.message);
                task.status = status;
                //task.info = 'error ' + status + ' : ' + message;
            }

            // CASE 2: the request was made and the server responded with a status code that falls out of the range of 2xx
            if (error.response) {
                
                status = error.response.status; // TO DO: is it necessary? is this param always set in error.status?
                task.status = status; // TO DO: keep this reference for later use (e.g. for UI)?
                // if there's an error response, it means that the request was processed, and the server
                // should return a json with our custom 'error' object as 'error.response.data':
                //console.log('error.response: ', error.response);
                ////console.log(error.response.data); // this is the custom object that we return from server with our error data
                ////console.log(error.response.status);
                ////console.log(error.response.headers);

                if (task.type == RequestType.UserIsValid
                    // the following tasks can get into loop as well during the initial authorization check ('isauthorized' endpoint)
                    || task.type == RequestType.Protect
                    || task.type == RequestType.Unprotect
                    || task.type == RequestType.CustomProtection) {
                    // for both 401 and 404 errors: the token attempts counter is used to avoid entering a loop.
                    // when 401 keeps returned from 'IsUserValid' call, when: 
                    // 1. user got authorized with username / password
                    // 2. the token was returned BUT it was signed with a different certificate than the certs defined in the API
                    // (e.g. auth server got wrong thumbprint in the configuration, or the API had the wrong keys)
                    // 3. 'IsUserValid' was called with the new token, token signature was rejected and 401 returned
                    // 4. after receiving 401 the app knows it has a refresh token, so it calls for refreshing the access token and we go to the point 2 again!
                    // 
                    // For 404: when tokens are ok , but the SQL server cannot be connected by the API, when we call 'is user valid',
                    // and the 404 is returned, refresh token is used again, succesfully, then it goes to 'user valid' which returns the same 404, and so on.
                    //
                    // Solution: use a counter so this loop will be detected. The counter is reset on a correct 'IsUserValid',
                    // so later the refresh tokens can be requested again. If 'IsUserValid' is wrong, we don't reset the counter to let it keep increasing.
                    // Make a call only with refresh token only if we haven't reached the maximum counter limit (which actaully should be = 1)
                    auth.tokenAttempts++; // increase the counter
                }

                if (error.response.status == 401) {
                    ////console.log('401 - ' + error.message + ':  call login...');

                    //message = 'unauthorized'; // TO DO: check if it's better to leave the 'error.response.data.error.error_description' here

                    //let headers = error.response.headers;
                    ////console.log(headers);
                    //if (headers) {
                    //    if (headers['www-authenticate']) {
                    //        message += ': ' + headers['www-authenticate'];
                    //    }
                    //}

                    // TO DO: remove the following hack when the production flow is established:
                    //if (task.type != RequestType.UserIsValid &&
                    //    task.type != RequestType.Preauthorize &&
                    //    task.type != RequestType.AuthorizeWithCookie &&
                    //    task.type != RequestType.AuthorizeWithUserNamePassword &&
                    //    task.type != RequestType.GetTokenWithAuthorizationCode &&
                    //    task.type != RequestType.GetTokenWithRefreshToken) {
                    if (!isAuthorizationTask(task)) {
                        // set the task state to "paused", so it will be relaunched after the access token is obtained                       
                        task.waitingForAuthorization = true;
                    }                    
                    
                    window.app.taskProcessor.onComplete(task);                   
                    notifyUnauthorizedTask(app, task);
                    // is it login?
                    //if (task.type === RequestType.AuthorizeWithUserNamePassword) {
                    //    extractCodeTypeAndSetLoginError(app, task, error.response); // maybe captcha or two-factor are required now (sent in the 'code-type' header)
                    //    //onLoginError(app, task);       
                    //}                
                    return;
                }
                else if (error.response.status >= 400 && error.response.status < 500) {
                    
                    //// token endpoint will return 400 if refresh token is invalid:
                    //if (task.type == RequestType.GetTokenWithRefreshToken ||
                    //    // bad user (e.g. after server is changed, but old tokens reused) could produce 404 error
                    //    task.type == RequestType.UserIsValid) { --> reduced to a single call that includes all authorizaton tasks -->
                    if (isAuthorizationTask(task)) {
                        
                        window.app.taskProcessor.onComplete(task);

                        // this will as well take care of not valid refresh tokens used for 'get token' 
                        // calls that return 400 (consider the current 'token' task as unauthorized) -- > 
                        notifyUnauthorizedTask(app, task); 

                        // is it login?
                        if (task.type === RequestType.AuthorizeWithUserNamePassword) {
                            extractSessionAndCodeData(app, task, error.response); // maybe captcha or two-factor are required now (sent in the 'code-type' header)
                            setLoginError(app, task, error.response, true); // maybe captcha or two-factor are required now (sent in the 'code-type' header)
                            //onLoginError(app, task);
                        }
                        return;
                    }

                    // for controlled errors we should have the error description returned: process it
                    let data = error.response.data;
                    if (data) {
                        // CASE 1: for blob response (for protect/unprotect/download tasks):
                        if (task.ajax.responseType == 'blob') {
                            //console.log('reading error from blob');
                            const reader = new FileReader();

                            // This fires after the blob has been read/loaded.
                            reader.addEventListener('loadend', (e) => {
                                let text = e.srcElement.result;
                                //console.log(text);
                                //text = text.replace('"', '\"');
                                //text = text.replace('-', '');
                                //let errorObject = eval(text); //this gives error
                                text = text.replace('"', '');
                                text = text.replace(':', '');
                                let parts = text.split('"error_code":');
                                ////console.log('parts: ', parts);
                                if (parts && parts.length > 1) {
                                    let partsB = parts[1].split(','); // the second part is the one that was following 'error_code' : split ',' and extract error code from the first fragment:
                                    if (partsB && partsB.length) {
                                        let error_code = partsB[0];
                                        error_code = parseInt(error_code);
                                        ////console.log('error_code: ', error_code);
                                        task.error = { error_code };
                                    }
                                }
                                window.app.taskProcessor.onComplete(task);
                            });

                            // Start reading the blob as text.
                            reader.readAsText(data);
                            return;
                        }
                        // CASE 2: for json response
                        if (data.error) {
                            // this data.error object is a custom object sent from server with extra information,
                            // result code and message when 400 + error is returned (it's on different level
                            // than the browser error object that can be accessed for 500 + errors that
                            // don't have any controller response).
                            task.error = data.error; 
                        }
                    }
                    window.app.taskProcessor.onComplete(task);
                    return;
                }
                else if (error.status == 500) {
                    // ...
                    // server error?
                    //error.response = response;
                    //throw error;
                }
                else {
                    //error.response = response;
                    //throw error;
                }
            }

            window.app.taskProcessor.onComplete(task);

            if (app.test.mode) {
                // update the 'tests' component state:
                //app.setComponentState(app.locator.tasksPage, { tasks: app.taskProcessor.tasks });
                app.store.setState({ tasks: app.taskProcessor.tasks });
            }
            //if (!error.status) {
            //console.log(' SHOW NETWORK ERROR')
                //app.setComponentState(app, { showError: true, errorTask: task });
                app.setState({ showError: true, errorTask: task });
            //}
            
            ////console.log(error.config);
        })
   
}
