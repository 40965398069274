import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

//import DatePicker, { registerLocale } from 'react-datepicker';
//import es from 'date-fns/locale/es';

import { Contacts } from '../Contacts';

import {
    Calendar,
    Checkbox,    
    Item,
    ItemColumn,
    ItemHead,
    ItemList,
    MultiSelectWithSubList,
    Radio,
    SmallButton
} from '../../components/atoms';
import {
    NumericTextInput,
    Paginator2,
    SearchFilter,
    SelectList    
} from '../../components/organisms';
import {
    clickPermission,
    clickPolicy,
    findOption,
    findUserNameAndFullName,
    goToHomePage,
    onReturnBack,    
    setOptionError,
    updateAppHistory,
    unselectItems,
} from '../../managers/appManager';
import {
    addPermission,    
    canEditPolicy,
    canFinishWizard,
    editPermission,
    getNewPolicy,
    loadPolicy,
    onEditPolicyConfirmed,
    removePermissions
} from '../../managers/policyManager';
import { accessPermissionModels, PageSizeOptions, RequestType } from '../../common/constants';
import {    
    convertSelectedPermissionsToText,
    convertShareOptionsToText,
    formatDateString,
    formatPermissions, 
    template
} from '../../common/format';
//import * as Store from '../../managers/Store';
import {
    formatDate,
    parseDate
} from '../../components/atoms/Calendar/lib/moment';
//registerLocale('es', es)


export class EditPolicy extends Component {

    constructor(props) {

        super(props);
        //console.log('edit policy . constructor');
        this.app = window.app;

        this.policy = null;
        this.offlineAccessDaysText = '';

        this.willSaveCustomPolicy = false; // for converting custom policy into a regular policy and saving it in DB
        
        this.policyOptions = [
            { type: 'name', error: false, selected: false },
            { type: 'description', error: false, selected: false },
            { type: 'expiration', error: false, selected: false },
            { type: 'offline_access', error: false, selected: false },
            { type: 'watermark', error: false, selected: false },
            { type: 'save_custom_policy', error: false, selected: false }          

        ];

        this.filter = {
            // search options and params
            //policyType: policyTypes(this.app)[0],
            //selectedPolicyFilter: selectedPolicyFilter || policySubTypes(this.app)[0],
            text: '',            
            items: this.app.store.state.policies || [], // (initially it's better to keep it here as 'this.filter' (synchronously) instead of within state (asynchronously updated). 
            // pagination
            page: {
                index: 0,    
                inputText: '1',
                //size: PageSizeOptions[0].id,
                size: this.app.store.getOption({ section: 'page', option: 'size' }),
                //sizeModel: PageSizeOptions[0], // default page size option = 10 items per page           
                items: [], // page items ,
                showPageInput: true,
                showTotalPages: true,
                totalPages: 0        
            }
        };

        this.offlineDaysFilter = {
            min: 1,
            max: 365,
            value: 5,
            inputText: '1'            
        };

        this.state = {
            // synchronized with the global store
            groups: this.app.store.state.groups,
            policies: this.app.store.state.policies,
            policy: this.app.store.state.policy,
            users: this.app.store.state.users,
            userLanguageLoaded: this.app.store.state.userLanguageLoaded,

            // component with search properties (page number, type, name) and the filtered items
            filter: this.filter,

            showContactMenu: false,
            showContextMenu: false,
            showDeleteButton: false,
            showEditButton: false,
            showAddPermissionMenu: false,
            showEditPermissionMenu: false,            
            showEditPolicyError: false,
            startDate: new Date(),
            selectedDay: undefined,
            permissions: [],
            itemsPerPage: [],
            top: 0,
            skip: 10,
            text: '',
            pageSize: PageSizeOptions[0].id,
            pageSizeModel: PageSizeOptions[0] // default page size option = 10 items per page

        };

        this.options = {
            accessTypeModels: null,
            share: null,
        }
    }
    //===============================================================
    componentDidMount() {

        //console.log('edit policy: componentDidMount');
        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'items' state (or other):
        this.app.locator.editPolicyPage = this;
        this.app.store.subscribe({ property: 'groups', component: this });
        //this.app.store.subscribe({ property: 'policies', component: this });
        this.app.store.subscribe({ property: 'policy', component: this });
        this.app.store.subscribe({ property: 'users', component: this });        
        this.app.store.subscribe({ property: 'userLanguageLoaded', component: this });

        if (!this.policy) this.initializePolicyForEdition();

        if (this.policy) {
            // error check to mark culprit fields in red            
            //canFinishWizard(this.policy, this);
                    
            // we reset it here, so when we come back from permission editor, the items will be unselected
            unselectItems(this.policy.Users); // reset the selections before we start selecting contacts            
            this.filterItems();
            this.fillPage();
        }

    }

    //componentDidMount() {
    //    //console.log('edit policy: componentDidMount');
    //    //const policy = this.getPolicy(this.state.policies, this.props.match.params.id);
    //    //const policy = this.app.store.state.policyCopy;
    //    //this.loadData({
    //    //    permissions: policy && policy.Users || [],
    //    //    PageIndex: 0,
    //    //    PageSize: this.state.skip,
    //    //})
    //}

    componentDidUpdate(prevProps, prevState) {
        //console.log('edit policy: componentDidUpdate: prevState: ', prevState);

        
        if (prevState.policy !== this.state.policy) {     
            //console.log('edit policy: componentDidUpdate: prevState policies are different than current state policies');

            if (!this.policy) this.initializePolicyForEdition();
            //const policy = this.getPolicy(this.state.policies, this.props.match.params.id);
            //const policy = this.app.store.state.policyCopy;
            //console.log('edit policy: componentDidUpdate. policy: ' + policy);
            //if (policy) {
                this.filterItems();
                this.fillPage();
            //}
            //this.loadData({
            //    permissions: policy && policy.Users || [],
            //    PageIndex: 0,
            //    PageSize: this.state.skip,
            //})
        }
    }
    
    componentWillUnmount() {
        //console.log('edit policy: componentWillUnmount');

        // save the policy here? or from componentWillUnmount?
        //let hasChanges = this.checkIfPolicyChanged();
        //if (hasChanges) {
        //    if (this.policy.IsCustomProtection) {
        //        let ok = onEditPolicyConfirmed(null, this);
        //        //if (ok) onReturnBack(e);
        //    }
            
        //}
        //else {
        //    //onReturnBack(e);
        //}

        this.app.locator.editPolicyPage = null;
        this.app.store.unsubscribe({ property: 'groups', component: this });
        //this.app.store.unsubscribe({ property: 'policies', component: this });
        this.app.store.unsubscribe({ property: 'policy', component: this });
        this.app.store.unsubscribe({ property: 'users', component: this }); 
        this.app.store.unsubscribe({ property: 'userLanguageLoaded', component: this });

        if (this.policy) unselectItems(this.policy.Users); // reset the selections before we start selecting contacts
        // now it's not necessary here
        //unselectItems(this.app.store.state.users); // reset the selections before we start selecting contacts

    }

    //===============================================================
    
    filterItems = () => {
        ////console.log('policies : filter items... store.state.policies: '); //console.log(this.app.store.state.policies);
       const filter = this.filter;
        if (!this.policy) return;

        filter.items = this.policy.Users; // get the permissions for this policy
        //console.log('Edit Policy : filter items... filter: ', filter); //console.log(this.filter);

        if (!filter.items) return;

        // step 1 (quicker): policy type filter (my policies/shared with me)
        //filter.items = filter.items
        //            .filter(policy => { return(this.filterPolicyType(this.app, policy, filter.policyType.id)) });
        //    //console.log('filter.items after filtering policy type: ');
        //    //console.log(filter.items);

        // step 2 (slower): text filter for polices search
        //if (filter.text !== '') {
        //    filter.items = filter.items
        //        .filter(policy => `${policy.Name}${this.getPolicyUserNamesForSearch(this.app, policy)}`.toLocaleLowerCase()
        //            .search(filter.text.toLocaleLowerCase()) > -1)
        //}

        // step 2 (slower): text filter for permissions search
        if (filter.text !== '') {
            try {
                filter.items = filter.items
                    //.filter(item => `${item.UserName}${item.FullName}`.toLocaleLowerCase() // a user model variant
                    //.filter(item => `${item.UserName}`.toLocaleLowerCase() // this is a simple search variant fpr permission model
                    .filter(item => findUserNameAndFullName(item.UserId).toLocaleLowerCase() // this is a more expensive variant that finds a user model for the current permission to search in full name too
                        .search(filter.text.toLocaleLowerCase()) > -1)
            }
            catch {
                // wrong search string: mark the input field in red?
            }            
        }
        // sort the policies: alphabetically and by favourite option (favs go first) 
        //sortPolicies(filter.items);
        filter.items.sort((a, b) => a.UserName.localeCompare(b.UserName));
        //console.log('Edit Policy : filter items... filter AFTER: ', filter); //console.log(this.filter);

        if (!filter.items) { filter.items = [] }; // for security, to not to process and render null list later
        //console.log('edit policy: filterItems: set state: ');

        this.setState({ filter: this.filter });
    };

    fillPage = () => {

        const { items, page } = this.filter;
        if (!items) return;
        page.items = items
            .slice(
                (page.index * page.size),
                (page.index * page.size) + page.size,
        );
        //console.log('edit policy: fillPage: set state: ');

        this.setState({ filter: this.filter });
    };

    //loadData = (search) => {
    //    const itemsPerPage = search.permissions
    //        .slice(
    //            (search.PageIndex * search.PageSize),
    //            (search.PageIndex * search.PageSize) + search.PageSize,
    //        );

    //    this.setState({
    //        permissions: search.permissions,
    //        itemsPerPage: itemsPerPage,
    //        top: search.PageIndex,
    //        skip: search.PageSize,
    //    })
    //};

    //changePagination = ({ PageIndex, PageSize }) => {
    //    this.loadData({
    //        permissions: this.state.permissions,
    //        PageIndex,
    //        PageSize,
    //    })
    //}
   
    //===============================================================

    onShareOptionChange = (e, component) => {

        e.stopPropagation();

        const model = component.props.model;
        if (model) {
            const permission = model.permission;
            //console.log('onShareOptionChange: before: ' + permission.IsVisible);
            //console.log(model);
            if (!permission.IsAuthor) { // the owner permission is disabled from UI action
                permission.IsVisible = model.selected; // toggle the 'Share With' option
            }
            //console.log('onShareOptionChange: after: ' + permission.IsVisible);
            this.setState({});
        }
    }

    handleChange = (dateValue) => {
        this.setState({
            startDate: dateValue,
        });
    }

    handleDayChange = (day) => {
        this.setState({
            selectedDay: day,
        });
        this.policy.ContentExpirationEndDate = day;
        //this.policy.ContentExpirationInitDate = new Date(Date.now()).getUTCDate();
        //this.policy.ContentExpirationInitDate = formatDate(new Date());
        var d1 = new Date();
        //var d1converted = d1.toUTCString();
        this.policy.ContentExpirationInitDate = d1;
    }

    //===============================================================
    // ADD CONTACT SELECTION MENU
    closeContactMenu = () => {
        this.setState({ showContactMenu: false});
    }

    showContactMenu = () => {
        this.setState({ showContactMenu: true });
    }

    // EDIT PERMISSIONS
    closeEditPermissionMenu = () => {
        this.setState({ showEditPermissionMenu: false });
    }

    showEditPermissionMenu = () => {
        this.setState({ showEditPermissionMenu: true });
        unselectItems(window.app.store.state.users); // reset the selections before we start selecting contacts
    }

    // ERROR ALERT
    closeEditPolicyError = () => {
        this.setState({ showEditPolicyError: false });
    }

    showEditPolicyError = () => {
        this.setState({ showEditPolicyError: true });
    }
    //===============================================================
    // CALLBACKS

    onEditPolicyError = () => {
        this.showEditPolicyError();
    }

    onEditPolicyCompleted = () => {
        //this.closeEditPolicyModal();
        // return back to the previous page
    }

    onPermissionsRemoved = () => {
        // set new 'permissions' state
        //const policy = this.getPolicy(this.state.policies, this.props.match.params.id);
        const policy = this.app.store.state.policyCopy;
        this.filterItems();
        this.fillPage();
        //this.loadData({
        //    permissions: policy && policy.Users || [],
        //    PageIndex: 0,
        //    PageSize: this.state.skip,
        //})
    }

    //-----------------------------------------------------------------------

    onOptionChange = (e, component) => {

        //console.log('edit policy . on Option Change: control selected:' + component.selected);

        if (component.props.model) {
            const model = component.props.model;
            
            if (model.type === 'expiration') {
                this.policy.IsContentExpirationActive = model.selected;
            }
            else if (model.type === 'offline_access') {
                this.policy.IsOfflineAccessActive = model.selected;
            }
            else if (model.type === 'watermark') {
                this.policy.Watermark = model.selected;
            }
        }
        // error check, to highlight UI fields
        //if (!canFinishWizard(this.policy, this)) {
        //    this.setState({ filter: this.filter });
        //}
        
        // check the controls and apply the current options to the policy or the permissions
        // ...
    }

    onTextChange = (e, model) => {

        e.preventDefault();
        ////console.log('edit policy . on Text Change: control selected:' + control.selected);

        //console.log(model);

        //if (control.props.model) {
        //    //console.log(control.props.model)
        //}

        // check the controls and apply the current options to the policy or the permissions
        // ...
        if (e.target) {

            const name = e.target.name;
            const value = e.target.value;
            //console.log('onTextChange: value: ' + value);
            //console.log('onTextChange : name: ' + name);

            if (name === 'name') { // the control for policy name has changed
                this.policy.Name = value;
                setOptionError(this.policyOptions, 'name', false);
            }
            else if (name === 'description') { // policy description has changed
                this.policy.Description = value;
                setOptionError(this.policyOptions, 'description', false);
            }
            else if (name === 'offline_days') { // offline access days

                this.offlineAccessDaysText = ''; // default text to output
                if (isNaN(value)) {
                    this.policy.OfflineAccessDays = 1;
                }
                else {
                    let days = parseInt(value);
                    //this.offlineAccessDaysText = '' + days;
                    if (days) {
                        this.policy.OfflineAccessDays = days;
                    }
                    else {
                        this.policy.OfflineAccessDays = 5; // default value
                    }
                }
            }

            this.setState({ policy: this.app.store.state.policy });
        }

    }

    onPolicyOptionChanged = () => {

        // check the controls and apply the current options to the policy or the permissions
        // ...

    }

    onSavePolicy = (e) => {
        //console.log('on save policy');
        e.stopPropagation();

    }

    onTextFilterChange = (value) => {

        const filter = this.filter;
        if (filter.text != value) {
            filter.text = value; // set the new filter value
            this.filterItems(); // apply the new filter to the items collection            
            this.changePage({ index: 0 }); // go to the first page of the new search result  
        }
    };

    changePage = (model) => {

        const filter = this.filter;
        const { page } = filter;
        if (model) { page.index = model.index; }
        page.inputText = page.index + 1; // set the text for the input control 
        if (this.policy)
            unselectItems(this.policy.Users); // we don't keep selected items from other pages for this component        
        this.setState({ showContextMenu: false }); // hide the conetxt menu after unselecting and set state with the current filter
        this.fillPage();
    };

    changeOfflineDays = () => {

        const filter = this.offlineDaysFilter;
        this.policy.OfflineAccessDays = filter.value;
        //filter.page.inputText = page.index + 1; // set the text for the input control    
        //this.setState({ filter: this.filter });
    };

    //changePage = ({ PageIndex, PageSize }) => {
    //    //console.log('change page: ' + PageIndex, ', size: ', PageSize);
    //    this.loadData({
    //        items: this.state.policies,
    //        PolicyFilter: this.state.selectedPolicyFilter,
    //        PolicyType: this.state.selectedPolicyType,
    //        TextFilter: this.state.text,
    //        PageIndex,
    //        PageSize,
    //    })
    //};

    changePageSize = (e, option) => {
        e.stopPropagation();
        const { pageSize, top } = this.state;
        // TO DO: when we change the page size, the 'top' (page index) is actually wrong
        // we should start from the first page, or we should recalculate the 'top' 
        // so it starts from the last "first" item(the one that was first on page)
        if (pageSize !== option.id) {
            this.setState({
                top: 0,
                pageSizeModel: option, // used to render the drop down menu
                pageSize: option.id
            })
        }
        this.changePage({ PageIndex: top, PageSize: option.id });
    }


    //==========================    AUXILIAR FUNCTIONS    =============================


    createPolicy = () => {
        let policy = {
            rightsPolicyId: 0,
            name: '',
            description: ''
        };
        return policy;
    }

    // the polciies now are simplified models: the full policy model needs to be downloaded individually!
    getPolicy = (policies, id) => {

        let result = null;
        if (policies) {
            policies.map(policy => {
                // 'id' comes from url path and it is a string: convert 'policy.rightsPolicyId' to string before comparing
                if ('' + policy.RightsPolicyId === id) {
                    result = policy;
                }
            });
        }
        return result;
    }
   

    renderPolicyForm = (app, policy) => {

        return (
            <Form>                
                {this.renderPolicyForm1General(app, policy)}
                
                {this.renderCustomPolicySaveOption(app, policy)}
                
                {this.renderPolicyForm2Permissions(app, policy)}
                
                {this.renderPolicyForm3Options(app, policy)}                 
            </Form>
        );
    }
    //===============================================================
    renderPolicyForm1General = (app, policy) => {

        // <h3>{app.R.General}</h3> -- try to remove the section names to reduce the size: give more importanceto the proper field names
        // prepare the models for this text controls
        //console.log('render policy form 1');
        //console.log('policy name: ' + policy.Name);
        //console.log('policy description: ' + policy.Description);
        // <hr />
        // placeholders: removed for now
        //placeholder={app.R.NamePlaceholder}
        //placeholder={app.R.DescriptionPlaceholder}
        //console.log('policyOtions', this.policyOptions);
        const editionEnabled = canEditPolicy(this.app.user, this.policy);
        let nameError = findOption(this.policyOptions, 'name').error ? 'error' : '';
        let descriptionError = findOption(this.policyOptions, 'description').error ? 'error' : '';
        
        return ((!this.policy.IsCustomProtection || this.willSaveCustomPolicy) && (
            <Form.Group>
                
                <Form.Group controlId="formName">
                    <Form.Label><h4>{app.R.Name}</h4></Form.Label>
                    <Form.Control
                        className={nameError}
                        type="name"
                        disabled={!editionEnabled}
                        name='name'
                        value={policy.Name}
                        placeholder={this.app.R.ProtectionNameTip}
                        onChange={(e) => { this.onTextChange(e) }}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formDescription">
                    <Form.Label><h4>{app.R.Description}</h4></Form.Label>
                    <Form.Control
                        className={descriptionError}
                        as="textarea"
                        rows="2"
                        disabled={!editionEnabled}
                        name='description'                        
                        value={policy.Description}
                        placeholder={this.app.R.ProtectionDescriptionTip}
                        onChange={(e) => { this.onTextChange(e) }}
                    >

                    </Form.Control>
                </Form.Group>
            </Form.Group>
            )
        );
    }
    //===============================================================
    renderPolicyForm2Permissions = (app, policy) => {

        const { itemsPerPage, top, skip, pageSize, pageSizeModel, text} = this.state;
        //console.log('renderPolicyForm2 - Permissions');
        let showPageSizeSelector = false;

        return (
            <Form.Group>
                
                <div className='filters'>                   
                    
                        <h4 className='label'>{app.R.Permissions}</h4>
                        {this.renderAddPermissionButton(policy)}
                               
                    <SearchFilter
                        app={app}
                        showSearchIcon
                        placeholder={app.R.Search}   
                        value={this.filter.text}
                        minCharacters={3}
                        onChange={this.onTextFilterChange}
                    />
                </div>
                <ItemList>
                    <Item className='head disabled'>
                        <ItemColumn className='xs-col-12 xs-border-bottom s-col-4 m-col-4'>{app.R.User}</ItemColumn>
                        <ItemColumn className='xs-col-12 s-col-4 m-col-4'>{app.R.AccessPermissions}</ItemColumn>
                        <ItemColumn className='xs-col-12 s-col-4 m-col-4'>{app.R.ShareOptions}</ItemColumn>     
                    </Item>

                    {this.renderAllUserPermissions(app, this.filter.page.items)}

                    {/*<Paginator
                        items={itemsPerPage}
                        top={top}
                        skip={skip}
                        loadData={this.changePagination}
                    />*/}                    
                    
                </ItemList>

                <Paginator2
                    filter={this.filter}
                    onChange={this.changePage}
                />

                {showPageSizeSelector && (<SelectList
                    title={app.R.ResultsPerPage}
                    variant="secondary"
                    items={this.pageSizeModels}
                    selectedItem={pageSizeModel}
                    onChange={this.changePageSize}
                />)}
                

            </Form.Group>
        );
    }

    renderAllUserPermissions = (app, permissions, sharedWith) => {

        // The policy model has a property 'Users' which contains an array of user permissions.
        // We need to map each property to a screen.
        // The 'isVisible' property means it's "shared with" this user
        if (!permissions) return;
        // set the 'excluded' flag for each user from this list, so it won't be shown later on users lists
        // NOTE: now we allow to choose existing users: we update their permissions with new values
        //setExcludedForItems(permissions, true);
        return (
            permissions.map(permission => this.renderUserPermission(app, permission))
        );
    }

    renderUserPermission = (app, permission) => {
        
        // format the name for the output
        let name = permission.UserName;
        name = permission.IsAuthor ? name + ' (' + app.R.Author + ')' : name;
       
        // for 'policy.AccessPermissions': we need to convert the number (representing the permission flags)
        // to a list of options, and then we will check/uncheck the corresponding options.
        let permissionList = formatPermissions(permission.AccessPermissions);        
        let permissionText = convertSelectedPermissionsToText(permissionList);

        let shareOptionsText = convertShareOptionsToText(app, permission);

        if (permission.IsAuthor || permission.UserName === app.user.U) {
            return (
                <Item
                    key={name}
                    className='disabled'                    
                >
                    <ItemColumn className='xs-col-12 xs-border-bottom s-col-4 m-col-4'>{name}</ItemColumn>                    
                    <ItemColumn className='xs-col-12 s-col-4 m-col-4'>{permissionText}</ItemColumn>
                    <ItemColumn className='xs-col-12 s-col-4 m-col-4'>{shareOptionsText}</ItemColumn>
                </Item>
            )
        }

        if (permission.selected) {
            return (
                <Item
                    key={name}
                    className='selected'
                    onClick={(e) => { clickPermission(e, this.policy, permission) }}
                >
                    <ItemColumn className='xs-col-12 xs-border-bottom s-col-4 m-col-4'>{name}</ItemColumn>
                    <ItemColumn className='xs-col-12 s-col-4 m-col-4'>{permissionText}</ItemColumn>
                    <ItemColumn className='xs-col-12 s-col-4 m-col-4'>{shareOptionsText}</ItemColumn>
                </Item>
            )
        }
        else {
            return (
                <Item
                    key={name}
                    onClick={(e) => { clickPermission(e, this.policy, permission) }}
                >
                    <ItemColumn className='xs-col-12 xs-border-bottom s-col-4 m-col-4'>{name}</ItemColumn>
                    <ItemColumn className='xs-col-12 s-col-4 m-col-4'>{permissionText}</ItemColumn>
                    <ItemColumn className='xs-col-12 s-col-4 m-col-4'>{shareOptionsText}</ItemColumn>
                </Item>
            )
        }
    }
    
    //===============================================================
    renderCustomPolicySaveOption = (policy) => {
        return null;

        //if (!this.policy.IsCustomProtection)
        //    return null;

        //const app = this.app;
      
        //const model = findOption(this.policyOptions, 'save_custom_policy');
        ////console.log('rendering calendar: language, this.app.settings.language.firstDayOfWeek: ', this.app.settings.language, this.app.settings.language.firstDayOfWeek)
        //return (
        //    <div>               
        //        <Checkbox
        //            enabled={true}
        //            key={'opt-' + 4}
        //            label={app.R.SaveCustomPolicy}
        //            model={model}
        //            onChange={this.onOptionChange}
        //        />                
        //    </div>
        //);
    }
    //===============================================================
    renderPolicyForm3Options = (app, policy) => {

        // do not render this section if user has only AddUser right, call the 'canEditPolicy'
        // which checks if user has FullControl or is author:
        if (canEditPolicy(this.app.user, policy)) {
            return (
                <Form.Group className='clear-left'>
                    <Form.Label><h4>{app.R.Options}</h4></Form.Label>
                    {this.renderPolicyOptions()}
                </Form.Group>
            );
        }
        else {
            return null;
        }
    }
   
    renderPolicyOptions = (permission) => {

        const app = this.app;

        const modelA = findOption(this.policyOptions, 'expiration');
        const modelB = findOption(this.policyOptions, 'offline_access');
        const modelC = findOption(this.policyOptions, 'watermark');

        modelA.selected = this.policy.IsContentExpirationActive;
        modelB.selected = this.policy.IsOfflineAccessActive;
        modelC.selected = this.policy.Watermark;

        //this.offlineAccessDaysText = this.policy.OfflineAccessDays
        //    ? '' + this.policy.OfflineAccessDays
        //    : '';
        //const days = this.offlineAccessDaysText;

        
        if (this.policy.OfflineAccessDays) {
            this.offlineDaysFilter.value = this.policy.OfflineAccessDays;
            this.offlineDaysFilter.inputText = this.policy.OfflineAccessDays;
        }
        else {
            this.offlineDaysFilter.inputText = '';
        }

        //const datePlaceholder = this.policy.ContentExpirationEndDate ? formatDateString(this.policy.ContentExpirationEndDate, true) : this.app.R.ChooseDate;
        const datePlaceholder = this.policy.ContentExpirationEndDate ? formatDate(this.policy.ContentExpirationEndDate, this.app.settings.language.dateFormat) : this.app.R.ChooseDate;
        let expirationError = modelA.error ? ' error' : '';
        let offlineError = modelB.error ? ' error' : '';
        //console.log('rendering calendar: language, this.app.settings.language.firstDayOfWeek: ', this.app.settings.language, this.app.settings.language.firstDayOfWeek)
        return (
            <div>
                <div >
                    <Checkbox
                        enabled={true}
                        key={'opt-' + 1}
                        label={app.R.SetExpirationDate}
                        className='filterX radioX'
                        model={modelA}
                        onChange={this.onOptionChange}
                    />
                    {
                        // https://react-day-picker.js.org/
                    }
                    <div className={'inline label calendar' + expirationError} onClick={this.enableExpiration}>
                        <Calendar
                            format={this.app.settings.language.dateFormat}
                            placeholder={datePlaceholder}
                            onDayChange={this.handleDayChange}
                            customOverlay={Contacts}
                            languageId={this.app.settings.language.id}
                        />
                    </div>
                </div>

                <div className=''>
                    <Checkbox
                        enabled={true}
                        key={'opt-' + 2}
                        label={app.R.AllowOfflineAccessEditor}
                        className='filterX radioX'
                        model={modelB}
                        onChange={this.onOptionChange}
                    />
                    {/*<div className='offline-control' onClick={this.enableOfflineAccess}>*/}
                    <div className={'inline label calendar' + offlineError} onClick={this.enableOfflineAccess}>
                        {
                        //<Form.Control
                        //    className='offline-control'                            
                        //    type="name"
                        //    placeholder={days}
                        //    name='offline_days'
                        //    value={days}
                        //    onChange={(e) => { this.onTextChange(e) }}                            
                        //    >                        
                        //</Form.Control>
                        }
                        <NumericTextInput className='offline-control' filter={this.offlineDaysFilter} onChange={this.changeOfflineDays} />
                    </div>
                    {/*</div> onClick={this.enableOfflineAccess} */}
                </div>

                <Checkbox
                    enabled={true}
                    key={'opt-' + 3}
                    label={app.R.AddWatermark + app.R.ForPdfAndImages}
                    className='watermark'
                    labelClass='watermark ellipsis'
                    model={modelC}
                    onChange={this.onOptionChange}
                />                
            </div>
        );
    }

    enableExpiration = () => {
        this.policy.IsContentExpirationActive = true;
        // Remove the error from the field, if any. Later, on confirmation, it will be validated again
        setOptionError(this.policyOptions, 'expiration', false);
        this.setState({ filter: this.filter });
    }

    enableOfflineAccess = () => {
        this.policy.IsOfflineAccessActive = true;
        // Remove the error from the field, if any. Later, on confirmation, it will be validated again        
        setOptionError(this.policyOptions, 'offline_access', false);
        this.setState({ filter: this.filter });
    }
    //CustomOverlay({ classNames, selectedDay, children, ...props }) {
    //return (
    //    <div
    //        className={classNames.overlayWrapper}
    //        style={{ marginLeft: -100 }}
    //        {...props}
    //    >
    //        <div className={classNames.overlay}>
    //            <h3>Hello day picker!</h3>
    //            <p>
    //                <input />
    //                <button onClick={() => //console.log('clicked!')}>button</button>
    //            </p>
    //            <p>
    //                {selectedDay
    //                    ? `You picked: ${selectedDay.toLocaleDateString()}`
    //                    : 'Please pick a day'}
    //            </p>
    //            {children}
    //        </div>
    //    </div>
    //);
    //} 
    //===============================================================
    //renderPolicyForm4Share = (app, policy) => {

    //    return (
    //        <Form.Group>               
    //            <h3>{app.R.Share}</h3>
    //            {this.renderUserPermissions(app, policy, true)}

    //        </Form.Group>
    //    );
    //}

    renderEditPolicyError = () => {

        let app = window.app;
        let info = app.R.EditPolicyError;
        if (app.store.state.policy) {
            // replace the placeholder
            let replacements = {
                '{n}': app.store.state.policy.name
            };
            info = template(info, replacements);
        }

        return (
            <Modal show={this.state.showEditPolicyError} onHide={this.closeEditPolicyError}>
                <Modal.Header closeButton>
                    <Modal.Title>{app.R.EditPolicy}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{info}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeEditPolicyError}>
                        {app.R.Close}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    ////-----------------------------------------------------------------------
    //renderProtection(policy) {

    //    let shared = '';
    //    let counter = 0;
    //    policy.Users.map(user => {
    //        if (counter === 0) {
    //            shared += user.UserName;
    //        }
    //        else {
    //            shared += ', ' + user.UserName;
    //        }
    //        counter++;
    //    })

    //    let documentCount = 33;

    //    if (policy.selected) {
    //        ////console.log('render policy: policy is selected');
    //        return (
    //            <tr key={policy.name} className='policy selected' onClick={(e) => { clickPolicy(e, policy, this) }}>
    //                <td className='narrow'>{this.renderFavButton(policy)}</td>
    //                <td className='item-col one-line'>{policy.Name}</td>
    //                <td className='item-col one-line shared'>{shared}</td>
    //                <td className='item-col one-line'>{documentCount}</td>
    //            </tr>
    //        )
    //    }
    //    else {
    //        ////console.log('render policy: not selected');
    //        return (
    //            <tr key={policy.RightsPolicyId} onClick={(e) => { clickPolicy(e, policy, this) }}>
    //                <td className='narrow'>{this.renderFavButton(policy)}</td>
    //                <td className='item-col one-line'>{policy.Name}</td>
    //                <td className='item-col one-line shared'>{shared}</td>
    //                <td className='item-col one-line'>{documentCount}</td>
    //            </tr>
    //        )
    //    }
    //}


    //==========================    BUTTONS    =============================

    renderEditButton = (permission) => {

        if (this.state.showEditButton) {
            return (
                <div className='context-menu-button edit'>
                    <SmallButton contentClass={'context icon-edit-2'} onClick={(e) => editPermission(e, permission, this)} />
                </div>
            );
        }
        else { // render it, but disabled
            return (
                <div className='context-menu-button edit'>
                    <SmallButton className='disabled' contentClass={'context icon-edit-2'} />
                </div>
            );
        }        
    };

    renderAddPermissionButton = (policy) => {
    
        return (            
            <SmallButton
                contentClass='icon-add'
                onClick={(e) => { addPermission(e, this) }}
            />
        );
    }

    // A. When rendered as an internal item button, the 'permission' will be assigned.
    // B. When rendered from context menu the permission will be null, in this case
    // a list of selected permissions will be assigned later in the policy manager
    renderDeleteButton = (permission) => {

        if (this.state.showDeleteButton) {
            return (
                <div className='context-menu-button delete'>
                    <SmallButton contentClass={'context icon-delete'} onClick={(e) => { removePermissions(e, permission, this) }} />
                </div>
            );
        }
        else {
            return ('');
        }
    }

    checkIfPolicyChanged = () => {

        const policyA = this.app.store.state.policy;
        const policyB = this.app.store.state.policyCopy;

        // before comparing, we need to remove some extra fields added to copy, or we can set them in the original,
        // otherwise the first comparison between original database poolicy and the cloned one will say it's changed
        policyB.Users.map(u => u.selected = undefined); // undefined value fields will be ignored during stringify
        // if the 'undefined' trick starts failing in future, just copy extra props back to original model:
        // ... (TO DO)
        let result = true;

        // a new policy? we always save it so return 'true'
        if (policyB.RightsPolicyId == 0) return result;
        if (policyB.IsCustomProtection) return result;

        let stringA = JSON.stringify(policyA);
        let stringB = JSON.stringify(policyB);
        
        if (stringA === stringB) result = false; 
        //console.log('checkIfPolicyChanged: ');
        //console.log(stringA);
        //console.log(stringB);
        //console.log(result);
        return result;
    }

    initializePolicyForEdition() {

        //console.log('initialize policy for edition. this.state.policy: ', this.state.policy);

        this.policy = null; // reset it and later check this value in render function to see if should continue
        
        let params = this.props.match.params;
        let originalPolicy = null;
        //console.log(this.props.match);
        //console.log(params);
        if (params.id < 1) { // this is a new or a custom policy
            const delayedTaskType = this.app.store.state.delayedTaskType;
            if (params.id === -1) { // a custom policy
                // check if we have a delayed task, otherwise it could be a browser page reload and then we need to abort                
                if (delayedTaskType === RequestType.CustomProtection) {
                }
                else {
                    goToHomePage();
                }
            }
            //console.log('get new policy...');
            originalPolicy = getNewPolicy();
            // is it a custom policy?
            if (delayedTaskType === RequestType.CustomProtection) {
                originalPolicy.IsCustomProtection = true;
                // set the custom policy guid for the user's organization
                originalPolicy.Guid = this.app.store.state.customPolicyGuid;
                // set the policy id:
                //originalPolicy.RightsPolicyId = -1;
                // Note:
                // we are using a regular policy endpoint in the fileserver (ProtectInNameOfUser):
                // the protection fails if policy id = -1 or 0, but when we choose other id higher then 0, it gets protected and a protected document is returned with id = -1.
                // It looks like the regular policy endpoint is not prepared to deal with id < 1.
                // For now: use any hardcoded id > 0 to make it work:                     
                originalPolicy.RightsPolicyId = 10;
                //console.log('a custom protection detected');
            }            
        }       
        else {
            //const willLoadPolicy = true;
            //if (willLoadPolicy) {
                // the 'state.policy' is synched with the store and set when the policy is loaded from server
                if (!this.state.policy) { 
                    // load the policy from server
                    //originalPolicy = this.getPolicy(this.state.policies, params.id);
                    loadPolicy(params.id);
                }
                else {
                    originalPolicy = this.state.policy;
                }
            //}
            //else {
            //    // try to find the policy to edit from the current policies list
            //    // (required for refreshing page in browser, when references are lost)
            //    originalPolicy = this.getPolicy(this.state.policies, params.id);
            //}
        }
        if (!originalPolicy) {
            // should I subscribe for the policies?
            // ...
            return;
        }
        // set the app.store.state.policy if it's null 
        // (required for refreshing page in browser, when references are lost)
        this.app.store.state.policy = originalPolicy;

        // if it's the first render call in the current editing process
        // (after page reload or navigation): initialize the cloned policy
        if (!this.app.store.state.policyCopy) {
            // clone the policy before we edit it:
            //app.store.state.policyCopy = Object.assign({}, originalPolicy); // shallow copy - don't use for complex objects
            this.app.store.state.policyCopy = JSON.parse(JSON.stringify(originalPolicy)) //deep copy here
        }
        // assign a local reference so we can access it easily later from form's event functions
        this.policy = this.app.store.state.policyCopy;

    }

    //-----------------------------------------------------------------------
    renderBackButton = () => {

        return (
            <SmallButton
                contentClass='icon-close-negative'
                onClick={e => this.onReturnBack(e)}
            />
        );
    }
    onReturnBack = (e) => {

        // save the policy here? or from componentWillUnmount?
        //let hasChanges = this.checkIfPolicyChanged();
        //if (hasChanges) {
        //    let ok = onEditPolicyConfirmed(e, this);
        //    if (ok) onReturnBack(e);
        //}
        //else {
        onReturnBack(e);
        //}

    }
    renderOkButton = (showLabel) => {

        let labelText = this.app.R.SaveChanges;
        const delayedTaskType = this.app.store.state.delayedTaskType;
        if (delayedTaskType === RequestType.CustomProtection) {
            // for custom protection
            labelText = this.app.R.Accept;
        }

        let label = showLabel ?
            <h3 className=' label '>{labelText}</h3>
            :
            <h3 className=' label inline-hide-for-small'>{labelText}</h3>

        return (
            <div className='label-and-button align-right inline clickable' onClick={(e) => { onEditPolicyConfirmed(e, this) }}>
                {label}
                <SmallButton contentClass='icon-accept' label={this.app.R.Accept} />
            </div >
        );
        //return (
        //    <div className='align-right inline clickable inline-hide-for-small' onClick={(e) => { onEditPolicyConfirmed(e, this) }}>
        //    <Button variant='primary' type='submitX' className='login-button'>
        //        {labelText}
        //        </Button>
        //    </div >
        //);
    }

    renderOkButtonOnLeft = (showLabel) => {
        let label = showLabel ?
            <h3 className=' label '>{this.app.R.SaveChanges}</h3>
            :
            <h3 className=' label inline-hide-for-small'>{this.app.R.SaveChanges}</h3>

        return (
            <div className='inline clickable' onClick={(e) => { onEditPolicyConfirmed(e, this) }}>
                {label}
                <SmallButton contentClass='icon-accept' label={this.app.R.Accept} />
            </div >
        );
    }
    renderBackButtonOnRight = () => {
        //<div className='right-fix'>{this.renderBackButton()}</div>
        return (
            <div className='align-right inline clickable'>
                <SmallButton contentClass='icon-close-negative' onClick={e => this.onReturnBack(e)} />
            </div >
        );
    }
    renderContextMenu = () => {

        // render the context menu
        let contextMenu = '';
        if (this.state.showContextMenu) {
            var divStyle = {
                display: 'block',
                position: 'absolute',
                left: this.app.clientX,
                top: this.app.clientY
            };
            //contextMenu = <div style={divStyle}><StaggeredMenu2  /></div>;
            contextMenu = <div className='over' style={divStyle}>
                {this.renderDeleteButton()}
                {this.renderEditButton()}
            </div>;            
        }
        return contextMenu;
    }

    getTitle = (app, params) => {

        // choose the title: Edit Policy, New Policy, Custom Policy
        //let policyName = this.policy ? this.policy.Name : ''; // don't put the policy name in titles (for this UI version)
        let title = app.R.EditPolicy;// + ': ' + policyName;
        if (params.id < 1) {
            title = app.R.NewProtection;
            const delayedTaskType = this.app.store.state.delayedTaskType;
            if (delayedTaskType === RequestType.CustomProtection) {
                title = app.R.CustomProtection;
            }
        }
        return title;
    }

    renderTitleBar = (app, params) => {

        let title = this.getTitle(app, params);

        // we need a policy model (to be rendered) and users (so permissions can be defined)
        // is the policy and users loaded? if not, show the 'loading' text or icon:
        if (!this.policy || !app.store.state.users || !app.store.state.groups) {
            return (
                <div>
                    {this.renderBackButton()}
                    <span className='label extra-space'></span>
                    <h3 className='label'>{title + ': ' + app.R.Loading}</h3>
                </div>
            );
        }
        
        // config with cancel to the left and apply to the right 
        //<div className='extra-margin-bottom'>
        //    {this.renderBackButton()}
        //    <span className='label extra-space'></span>
        //    <h3 className='label'>{title}</h3>
        //    {this.renderOkButton(false)}
        //</div>

        // cancel to the right, apply to the left:
        //<div className='extra-margin-bottom'>
        //    {this.renderOkButtonOnLeft(false)}
        //    {this.renderBackButtonOnRight()}
        //</div>;

        let titleBar =
            <div className='extra-margin-bottom'>
                {this.renderBackButton()}
                <span className='label extra-space'></span>
                <h3 className='label'>{title}</h3>
                {this.renderOkButton(false)}
            </div>
        return titleBar;
    }


    //-----------------------------------------------------------------------

    render() {

        //console.log('EditPolicy.render');

        // If this render is a state update after UI changed, the previous 'EditPolicy' component instance is gone.
        // That's why we don't keep the edited policy in local component props or state but rather point to an external reference.
        // wait for the user prefs loaded and language

        if (!this.state.userLanguageLoaded)
            return null; 

        //console.log('EditPolicy.render 1');

        const app = window.app;
        const params = this.props.match.params;

        updateAppHistory(app, this);
      
        // We need a policy model (to be rendered) and users + groups (so permissions can be defined).
        // Are the policy and users loaded? If not, return only the title bar: when
        // we eventually get the policy model and users, the component state will
        // be updated and this render method will be called again.
        //console.log('EditPolicy.render 2');

        if (!this.policy || !app.store.state.users || !app.store.state.groups) {
            return (this.renderTitleBar(app, params));
        }

        //console.log('EditPolicy.render 3');

        return (
            <div>                
                {this.renderTitleBar(app, params)}                
                {this.renderPolicyForm(app, this.policy)}
                {this.renderEditPolicyError()}
                {this.renderOkButton(true)}
                <br className='clear-left' /><br /><br />
                {this.renderContextMenu()}
            </div>
        );
    }
}
