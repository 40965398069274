import React, { Component } from 'react';

export class Loader extends Component {

    constructor(props) {
        //console.log('Loader . constructor');
        super(props);

        this.state = {
            visible: false
        };

        // set the reference to this object in app.locator, so later other
        //  modules can have access to it to change the 'visible' state:
        window.app.locator.loader = this;
    }

    componentWillUnmount() { 

        //window.app.locator.loader = null; -->
        // this was causing the component to disappear after first hide, as the same
        // loader instance is used in Layout component (excluded from navigation which
        // triggers new instances), but the reference gets lost when set to null
    }

    render() {
        //console.log('Loader . render. this.state.visible: ' + this.state.visible);
        if (this.state.visible) {
            return (
                <div className='loading-icon-container'>
                    <div className="spinner-border loading-icon"></div>
                </div>
            )
        }             
        return (null); 
    }
}
