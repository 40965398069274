import {
    checkIfUserIsValid,
    setAuthenticated,
    signOut
} from './authManager'; 

import { canDeletePolicy } from './policyManager';

import * as userManager from './userManager'; 
import { saveUserDetails } from './userManager';
import * as documentManager from './documentManager';
import { LocalStorage } from '../common/localStorage';

import {routes} from "../common/routes";
import { RequestType, UserRole } from "../common/constants";


// --------------------         LOAD SYSTEM AND CUSTOMIZATIONS        -------------------------
//
// Both for the global (per-server) contents and for the per-organization contents: 
// it's important to separate the default texts from the custom texts, 
// so the default texts would go in one file, and the custom texts would 
// go in another file, where only some customized strings would be present.
// This way it would be easier to update the application when new texts are added: 
// we would update the default text file, but the custom texts would stay
// unchanged in another file, this way we wouldn't have to 'WinMerge' the texts 
// every time we publish an update and need to re-apply the customizations again.
//
// Rules: 
// 1. We have a 'content/default' folder with a factory files that should never get customized.
// 2. Then we always have the 'content/custom' folder which will contain the 'appsettings.json' and
// 'languages.json' files, so the manager can edit the per-server configuration in a place 
// located outside of the <untouchable> 'content/default' folder. We have there a 'custom.css' 
// file that can overwrite all styles from the default 'site.css' file (in the 'content/default').
// 3. Then we have per-org customization folders, that can overwrite any per-server contents.
//
// 1. The default texts (like 'XYZ.json') are always loaded first from the
// 'content/default/localization/' folder - these texts shouldn't be edited,
// so the app can be updated anytime without overwriting the user customization.
// The same rule will be applied to the 'site.css': it should never be edited, so it can be
// factory updated without breaking the custom styles, defined in the 'site.custom.css' file. 
// These default text files will be shared among all the customizations (global, and per-org).
//
// 2. Then the custom per-server texts will be loaded to overwrite the default ones.
// For the per-server customizations, the customized texts will be put to:
// 'content/default/localization/XYZ.custom.json'.
//
// 3. Then the custom per-org texts will be loaded to overwrite the previous ones.
// For the per-organization ones, they will be put to:
// 'content/custom/[OrgName]/localization/XYZ.custom.json'
//-------------------------------------------------------------------

//-------------------------------------------------------------------
export const loadAllAppData = (app) => {

    loadAppSettings(app); // load the saved settings before loading the app data, so some old settings can be either used or updated then used (e.g. the organization code) 

    // The following calls will only add css links to the html head...
    loadAppData(RequestType.FactorySiteCss);
    //loadAppData(RequestType.CustomSiteCss);
    //loadAppData(RequestType.OrgCustomSiteCss);
    loadAppData(RequestType.FactoryColorsCss);
    //loadAppData(RequestType.CustomColorsCss);
    //loadAppData(RequestType.OrgCustomColorsCss);
    loadAppData(RequestType.FactoryImagesCss);
    //loadAppData(RequestType.CustomImagesCss);
    //loadAppData(RequestType.OrgCustomImagesCss);

    // ... and this call will actualy start loading from an url in a sequential mode
    loadAppData(RequestType.FactoryConfiguration);
}

 //-------------------------------------------------------------------

export const loadAppData = (type) => {

    const app = window.app;

    // The options object is already initialized and contains the 'app'
    // section which is null at start: it will be loaded later here.
    const options = app.store.state.options; 

    ////console.log('load data: app: ' + app + ', type: ' + type);

    var factoryContentPath = app.path + 'factory/';
    var customContentPath = app.path + 'custom/global/';
    var orgCustomContentPath = '';
    var localizationSubPath = 'localization/';
    
    var factorySiteCssFilename = 'css/site.css';
    var customSiteCssFilename = 'css/custom.site.css';
    var factoryColorsCssFilename = 'css/colors.css';
    var customColorsCssFilename = 'css/custom.colors.css';
    var factoryImagesCssFilename = 'css/images.css';
    var customImagesCssFilename = 'css/custom.images.css';

    var factoryConfigFilename = 'appsettings.json';
    var customConfigFilename = 'custom.appsettings.json';

    var factoryInvitationsFilename = 'invitations.json';
    var customInvitationsFilename = 'custom.invitations.json';

    // get the '?cus=OrgFolderName' parameter
    var organizationCode = getQueryParam('cus');
    if (organizationCode) {
        // assign it to the "app.custom" property so it persists. When I change the language and this function is 
        // called again, it will use the persistent "app.custom" value to load custom resources. The URL query 
        // won't be present in the path anymore so making it persistent is the only way to keep that value.
        // !!! currently it's not persistent if I reload the page, the default website gets loaded:
        // maybe I could use a cookie to store the customOrg and then restore it on page reload?
        // or maybe I could just eliminate the routing?
        // Let's say we have 2 options: 
        // 1. send it to the server so the customOrg gets stored in the session object
        // 2. store it in a browser cookie
        
        //console.log('custom org code: ', customOrgCode);
        //if (customOrg.length > 0) {
        //    let lastChar = [customOrg.length - 1];
        //    if (lastChar == '/') {
        //        customOrg = customOrg.slice(0, -1);
        //    }
        //}        
        var trimmed = organizationCode.replace(/\/+$/g, '');    // trim the '/' character (it can be here if /# is used after the params instead of only #), to make sure there's only one '/' when it's added later,   
        if (trimmed && trimmed !== '') {
            app.settings.organizationCode = trimmed;
            saveAppSettings(app);
            //console.log('organizationCode: ', app.settings.organizationCode);
        }        
    }
    
    if (app.settings.organizationCode) { // it could have been either set in the last session, or couple of lines above
        // assign the custom org path
        orgCustomContentPath = 'custom/org/' + app.settings.organizationCode + '/'; ////console.log('custom org is defined:' + app.customOrg);
    }

    //console.log('app.settings.organizationCode: ', app.settings.organizationCode);
    //console.log('orgCustomContentPath: ', orgCustomContentPath);

    // prepare our universal 'task' object, so we call push 
    // which will take care of asynchronous data fetch.
    let task = {
        type: type,
        onComplete: onAppDataLoaded
    };

    if (type == RequestType.FactorySiteCss
        || type == RequestType.CustomSiteCss
        || type == RequestType.OrgCustomSiteCss
        || type == RequestType.FactoryColorsCss
        || type == RequestType.CustomColorsCss
        || type == RequestType.OrgCustomColorsCss
        || type == RequestType.FactoryImagesCss
        || type == RequestType.CustomImagesCss
        || type == RequestType.OrgCustomImagesCss) {

        var link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
 // remove this for now to load all css at once! -->
 //link.onload = () => onDataLoaded(task, null);
        // set the href location
        if (type == RequestType.FactorySiteCss) { // load the site.css from the default folder:
            link.href = factoryContentPath + factorySiteCssFilename;
        }
        else if (type == RequestType.CustomSiteCss) { // load the site.custom.css from the custom folder:        
            link.href = customContentPath + customSiteCssFilename;
        }
        else if (type == RequestType.OrgCustomSiteCss) { // load the site.custom.css from the organization's custom folder:        
            link.href = orgCustomContentPath + customSiteCssFilename;
        }

        else if (type == RequestType.FactoryColorsCss) { // load the colors.css from the default folder:
            link.href = factoryContentPath + factoryColorsCssFilename;
        }
        else if (type == RequestType.CustomColorsCss) { // load the colors.custom.css from the custom folder:        
            link.href = customContentPath + customColorsCssFilename;
        }
        else if (type == RequestType.OrgCustomColorsCss) { // load the colors.custom.css from the organization's custom folder:        
            link.href = orgCustomContentPath + customColorsCssFilename;
        }

        else if (type == RequestType.FactoryImagesCss) { // load the images.css from the default folder:
            link.href = factoryContentPath + factoryImagesCssFilename;
        }
        else if (type == RequestType.CustomImagesCss) { // load the images.custom.css from the custom folder:        
            link.href = customContentPath + customImagesCssFilename;
        }
        else if (type == RequestType.OrgCustomImagesCss) { // load the images.custom.css from the organization's custom folder:        
            link.href = orgCustomContentPath + customImagesCssFilename;
        }
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    else if (type == RequestType.FactoryConfiguration) {
        // load the appsettings.json from the default folder:
        task.ajax = {
            url: factoryContentPath + factoryConfigFilename
        }
    }
    else if (type == RequestType.CustomConfiguration) {
        // load the custom.appsettings.json from the custom folder:
        task.ajax = {
            url: customContentPath + customConfigFilename
        }
    }
    else if (type == RequestType.OrgCustomConfiguration) {
        // load the custom.appsettings.json from the organization's custom folder:
        task.ajax = {
            url: orgCustomContentPath + customConfigFilename
        }
    }

    else if (type == RequestType.FactoryInvitations) {
        // load the invitations.json from the default folder:
        task.ajax = {
            url: factoryContentPath + localizationSubPath + factoryInvitationsFilename
        }
    }
    else if (type == RequestType.CustomInvitations) {
        // load the custom.invitations.json from the custom folder:
        task.ajax = {
            url: customContentPath + localizationSubPath + customInvitationsFilename
        }
    }
    else if (type == RequestType.OrgCustomInvitations) {
        // load the custom.invitations.json from the organization's custom folder:
        task.ajax = {
            url: orgCustomContentPath + localizationSubPath + customInvitationsFilename
        }
    }

    else if (type == RequestType.FactoryLanguage
        || type == RequestType.CustomLanguage
        || type == RequestType.OrgCustomLanguage) {

        // set the language: if we don't load it earlier with the settings, 
        // we need to assign the default language from the configuration
        if (!app.settings.language || !app.settings.language.id) { // the last one is for compatibility with old settings saved with '.code', not '.id'
            const languageId = options.appSettings.defaultLanguage;
            setLanguage(languageId, false);
        }

        var factoryLanguageFilename = app.settings.language.id + '.json';
        var customLanguageFilename = 'custom.' + app.settings.language.id + '.json';

        //var factoryLanguageFilename = options.appSettings.language.id + '.json';
        //var customLanguageFilename = 'custom.' + options.appSettings.language.id + '.json';

        if (type == RequestType.FactoryLanguage) {
            // load the XYZ.json language from the default folder:
            task.ajax = {
                url: factoryContentPath + localizationSubPath + factoryLanguageFilename
            }
        }
        else if(type == RequestType.CustomLanguage) {
            // load the XYZ.json language from the custom folder:
            task.ajax = {
                url: customContentPath + localizationSubPath + customLanguageFilename
            }
        }
        else if (type == RequestType.OrgCustomLanguage) {
            // load the XYZ.json language from the organization's custom folder:
            task.ajax = {
                url: orgCustomContentPath + localizationSubPath + customLanguageFilename
            }
        }
    }    
    // set the method to GET before launching the task (otherwise it will be set to
    // POST automatically, given the the rest of calls send data in the request body)
    if (task.ajax)
        task.ajax.method = 'GET';
    // launch the task
    app.taskProcessor.push(task);
}
//-------------------------------------------------------------------

const onAppDataLoaded = (task) => {

    let app = window.app; 

    // The options object is already initialized and contains the 'app'
    // section which is null at start: it will be set up later here.
    const options = app.store.state.options; 

    let result = task.result;
    let type = task.type;   
    //task.info = 'ok';

    // next block is skipped because we add the css links to the header all at once
    
    //if (type == RequestType.FactorySiteCss) {     
        
    //    loadAppData(RequestType.CustomSiteCss);
    //}
    //else if (type == RequestType.CustomSiteCss) {  
        
    //    loadAppData(RequestType.FactoryColorsCss);
    //}
    //else if (type == RequestType.FactoryColorsCss) { 
        
    //    loadAppData(RequestType.CustomColorsCss);
    //}
    //else if (type == RequestType.CustomColorsCss) {  
        
    //    loadAppData(RequestType.FactoryImagesCss);
    //}
    //else if (type == RequestType.FactoryImagesCss) { 
        
    //    loadAppData(RequestType.CustomImagesCss);
    //}
    //else if (type == RequestType.CustomImagesCss) {   
        
    //    loadAppData(RequestType.FactoryConfiguration);
    //}
    //else 
    if (type == RequestType.FactoryConfiguration) {  
        
        options.appSettings = result ? result : {};    
        //console.log('appSettings loaded: ', options.appSettings);
        loadAppData(RequestType.CustomConfiguration);
    }
    else if (type == RequestType.CustomConfiguration) {
        if (result) {
            for (var key in result) {
                let value = result[key];
                //console.log('custom global - options.appSettings [' + key + '] = ' + value);
                options.appSettings[key] = value;
            }
        }               
        loadAppData(RequestType.OrgCustomConfiguration);
    }
    else if (type == RequestType.OrgCustomConfiguration) {
        if (result) {
            for (var key in result) {
                let value = result[key];
                //console.log('custom org - options.appSettings [' + key + '] = ' + value);
                options.appSettings[key] = value;
            }
        }
        // only after loading the configuration (as it can have features enabled/disabled), and
        // before we load other data like language, try to load the saved application settings:
        //loadAppSettings(app); --> moved before loading app data, so some app.settings params can be used or updated on the go (e.g. the last organization code)
        //console.log('loadAllAppData: settings: ');
        //console.log(window.app.settings);

        loadAppData(RequestType.FactoryLanguage);
    }  
    else if (type == RequestType.FactoryLanguage) {

        app.R = result ? result : {};
        loadAppData(RequestType.CustomLanguage);        
    }
    else if (type == RequestType.CustomLanguage) {

        if (result) {
            for (var k in result) app.R[k] = result[k];
        }        
        loadAppData(RequestType.OrgCustomLanguage);
    }
    else if (type == RequestType.OrgCustomLanguage) {

        if (result) {
            for (var k in result) app.R[k] = result[k];
        }
        // set language state
        // was it user language loading? (we start loadung it after loading user settings)
        if (app.store.state.userLanguageLoading) {
            // pages subscribe for the 'userLanguageLoaded' state: update it
            app.store.setState({ userLanguageLoading: false });
            app.store.setState({ userLanguageLoaded: true });
        }
        loadAppData(RequestType.FactoryInvitations);
    }
    else if (type == RequestType.FactoryInvitations) {

        app.invitations = result ? result : {};
        loadAppData(RequestType.CustomInvitations);
    }
    else if (type == RequestType.CustomInvitations) {

        if (result) {
            for (var k in result) app.invitations[k] = result[k];
        }
        loadAppData(RequestType.OrgCustomInvitations);
    }
    // this is the last task in of the sequence: app data is loaded at this point, so we can proceed with other things
    else if (type == RequestType.OrgCustomInvitations) {

        if (result) {
            for (var k in result) app.invitations[k] = result[k];
        }
        // no more data to load:
        // are we loading this app data on start-up?
        if (app.state.initializing) {
            // this was the last one - call the 'all loaded' method
            onAllAppDataLoaded();
        }
        else if (app.isloadingCustomAppData) {
            // the 'isloadingCustomAppData' flag is set when 'isValid' response is received            
            // It is set to 'true' if a custom organization code is returned with the
            // 'is valid' response, and the custom content needs to be loaded.
            // In this case, setting the 'authenticated' flag is delayed untill
            // the custom data is loaded, so it will jump from 'Login' to the 'Home'
            // screen when the custom CSS and images are ready, not before.
            app.isloadingCustomAppData = false;
            // the 'authenticated' call was delayed, waiting for the custom content
            setAuthenticated(app, true); // update the state only if it's not yet true, otherwise it will be re-rendering everything without need
        }
        else {
            // we are reloading languages, just re-render:            
            app.setComponentState(app, result);
        }
    }
}

//-----------------------------------------------------------------------
function getQueryParam(variable) {

    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}



const createProviders = (providersFromConfig) => {

    let providers = [];
    providersFromConfig.map(source => {

        //let provider = { name: source.name, id: source.id, class: source.class };
        let provider = {};
        for (var k in source) provider[k] = source[k];
        
        // create the root node
        // (note: root is created for custom folder rendering: currently not used)
        let root = { name: provider.name, id: '0', type: 'folder', provider: provider, nodes: [], loading: false, loaded: false };

        // assign the root to the storage provider 
        provider.root = root;

        // create the storage views list:
        // (note: views are created for custom folder rendering: currently not used)
        provider.views = [];

        providers.push(provider);
    })
    return providers;
}

//-----------------------------------------------------------------------------

export const onAllAppDataLoaded = () => {

    let app = window.app;

    // create a "dynamic" copy of the provider models defined in options, so the ones from options don't get modified
    app.providers = createProviders(app.store.state.options.appSettings.providers);
    
    // It's time for the user - try to retrieve the stored credentials: 
    // get the last user, then check if that user has the session stored on this machine.
    // Note: the last user is saved in the app settings as 'lastUserName'
    
    loadSession(app.settings.lastUserName);
    //console.log('onAllAppDataLoaded: loaded session tokens: ');
    //console.log(app.tokens);
    app.loadingAllAppData = false;
    // When we restore credentials, we want to make sure the user is still a valid SealPath user.
    
    // Currently this would be the first entry point to 'check if user is valid' method,
    // just after restoring tokens from local storage. At this point the call could fail and return 401:
    // maybe there are no tokens restored from storage or access token could be expired.
    // The second moment would be when we obtain new token - from 'on get token completed' method.

    // Note: Actually, we could check this only once - here, when application starts. Later,
    // after refreshing tokens, we could skip this check assuming that the user is still valid.
    // But having this check allows to have the user disabled/blocked and the user client would
    // be informed as soon as the tokens are refreshed. If performance isn't an issue, we keep it like this.
    
    checkIfUserIsValid(app); 

}

// LOAD / SAVE PERSISTENT DATA
// called at app start, when all app data is loaded, but before we start loading user data, so we can restore tokens
export const loadSession = (userName) => {

    const app = window.app;
    const auth = app.auth;
    //if (app.useFakeIdentity) {
    //    // create a fake identity to avoid authentication during development
    //    app.getFakeIdentity();
    //    return;
    //}

    if (app.store.state.options.appSettings.Local.EnableSavingSessionToLocalStorage) {

        const sessionName = formatSessionStorageName(app, userName);
        // restore the tokens from the local storage
        let data = LocalStorage.get(sessionName) || {};
        ////console.log('load session: ');
        ////console.log(data);

        // get the user identity from the storage:
        if (data.user) {
            //console.log('loadSession: setting new app.user: ', app.user, data.user)
            app.user = data.user;
        }
        if (data.options) {
            //console.log('loadSession: setting new options: ', app.store.state.options, data.options)
            //!!! this could break an existing options models initialized by code if it has more properties than stored one!
            //app.store.state.options = data.options; 
            // instead use a more selective method that will keep the exisitng options model and only set new values
            app.store.setOptions(data.options);

            // should dispatch the set state event?

        }

        //console.log('loadSession: app.auth.rememberMe: ', app.auth.rememberMe);
        if (data.tokens) { // this object is always saved, whether session is persistent or not (empty object is saved then)
            //console.log('loadSession: data.tokens: ', data.tokens);
            if ((data.tokens.access_token && data.tokens.access_token !== '')
                ||
                (data.tokens.refresh_token && data.tokens.refresh_token !== '')) {
                // we have some tokens saved in the session object, it means that the last session was persistent:
                // set the 'rememberMe' to true so it keeps being persistent (otherwise it won't keep persisting and 'saveSession' will not work next time we try to save)
                app.auth.rememberMe = true;
                auth.tokens = data.tokens;
                //console.log('loadSession: app.auth.rememberMe after: ', app.auth.rememberMe);

                //if (data.codeType) { // this object is always saved, whether session is persistent or not (empty object is saved then)
                //    console.log('loadSession: data.codeType: ', data.codeType);
                //    if (data.codeType !== null) {                  
                //        app.auth.codeType = data.codeType;                    
                //        console.log('loadSession: app.auth.codeType after: ', app.auth.codeType);
                //    }
                //    ////console.log('restored tokens: access token: ' + app.tokens.access_token);
                //    ////console.log('restored tokens: refresh token: ' + app.tokens.refresh_token);
                //}        
                ////console.log('restored tokens: access token: ' + app.tokens.access_token);
                ////console.log('restored tokens: refresh token: ' + app.tokens.refresh_token);
            }
            // TO DO: load the custom organization code (it can be stored in a cookie too):
            // 
            // ..
        }
    }
}

export const saveSession = (app) => {

    if (app.store.state.options.appSettings.Local.EnableSavingSessionToLocalStorage) {

        //console.log('save session');//. app.rememberMe: ' + app.rememberMe);
        //console.log('app.auth.rememberMe: ' + app.auth.rememberMe);
        ////console.log('app.tokens: ' + app.tokens);
        ////console.log(app.tokens);
        ////console.log('app.user: ' + app.user);
        ////console.log(app.user);
        const sessionName = formatSessionStorageName(app);
        let data = {};
        // initialize the 'saveData' object properties before saving it in local storage
        if (app.auth.rememberMe) {
            data.tokens = app.auth.tokens;            
        }
        data.user = app.user;
        data.time = new Date().getTime();
        data.options = app.store.state.options;
        //data.codeType = app.auth.codeType;
        //console.log('saveSession', data);
        LocalStorage.set(sessionName, data);
    }
}

export const deleteSession = (app, userName) => {

    ////console.log('deleteSession');
    //if (app.store.state.options.appSettings.localStorageSessionEnabled) {
    const sessionName = formatSessionStorageName(app, userName);
    LocalStorage.remove(sessionName);
    //}
}

function formatSessionStorageName(app, userName) {

    if ((!userName || userName === '') && app.user)
        userName = app.user.U;

    return userName + '.data';
}

//--------------------------------------------------------------------------

// this data we load after we loaded the congiguration, from 'app data loaded',
// before we start loading other app data, like a language.
export const loadAppSettings = (app) => {

    //if (app.store.state.options.appSettings.enableLocalStorage) {
        let settings = LocalStorage.get('app.data');
        if (settings) {
            app.settings = settings;       
            if (app.settings.codeType === undefined || app.settings.codeType === null)
                app.settings.codeType = '';
        }
        //console.log('loadAppSettings: ');
        //console.log(settings);
    //}
}

export const saveAppSettings = (app) => {

    //if (app.store.state.options.appSettings.enableLocalStorage) {
        LocalStorage.set('app.data', app.settings);

        //console.log('saveAppSettings: ', app.settings); //console.log(window.app.settings);
    //}
}

//--------------------------------------------------------------------------

//export const loadUserSettings = () => {

//    console.log('loadUserSettings');
//    const app = window.app;
//    let userLanguageLoaded = true;
//    if (app.store.state.options.appSettings.enableLocalStorage) {
//        // restore the user preferences from the local storage
//        let data = LocalStorage.get('SealPathUserSettings-' + app.user.UserName) || {};
//        //console.log('loadUserSettings: '); //console.log(data);
//        let ENABLED = false;
//        if (ENABLED) {
//            let items = app.store.state.policies;
//            if (items) { // restore the policies' favourite levels
//                if (data.policies) {
//                    data.policies.map(savePolicy => {
//                        for (var i = 0; i < items.length; i++) {
//                            if (items[i].Guid == savePolicy.Guid) {
//                                items[i].IsFavourite = savePolicy.IsFavourite; // restore the fav level
//                                // format, to avoid undefined after code has been changed
//                                if (!savePolicy.IsFavourite) items[i].IsFavourite = false;
//                                break;
//                            }
//                        }
//                    });
//                    app.store.setState({ policies: app.store.state.policies });
//                }
//            }
//        } 
        
//        // language
//        if (data.settings) { // we save the app.settings, which currently has 'language' property
//            if (data.settings.language) {
//                // compare it to the current language: will it need to be loaded?
//                if (app.settings.language) {
//                    if (app.settings.language.id != data.settings.language.id) {
//                        userLanguageLoaded = false;
//                        // mark it to update 'userLanguageLoaded' state when language is loaded
//                        app.store.setState({ userLanguageLoading: true });
//                        // load the user language
//                        setLanguage(data.settings.language.id, true);
//                    }
//                    else { // set the language again, so we use the possibly updated language from configuration loaded from server, not the locally saved model
//                        setLanguage(data.settings.language.id, false);
//                    }
//                }
//        //        else { THIS SHOULDN'T HAPPEN, BUT TEST BEFORE REMOVAL...
//        //            userLanguageLoaded = false;
//        //            // load the user language

//        //            setLanguage({ id: item.id, label: item.label, selected: item.selected })
//        //            // ...
//        //            // then call the: 
//        //            app.store.setState({ userLanguageLoaded: true });
//        //        }
                
//            }
//        }
//        if (userLanguageLoaded) {
//            app.store.setState({ userLanguageLoaded: true });
//        }
//    }
//}

export const setUserLanguage = (language) => {

    //console.log('setUserLanguage', language);
    const app = window.app;
    let userLanguageLoaded = true;

    let needsToBeLoaded = true;
    // compare it to the current language: will it need to be loaded?
    if (app.settings.language) {
        if (app.settings.language.id == language.id) {
            needsToBeLoaded = false;
        }
    }
    if (needsToBeLoaded) {
        userLanguageLoaded = false;
        //// mark it to update 'userLanguageLoaded' state when language is loaded
        //app.store.setState({ userLanguageLoading: true }); --> moved to setLanguage function
        app.store.setState({ userLanguageLoaded: false });

        // load the user language
        setLanguage(language.id, true);
    }
    else { // set the language again, so we use the possibly updated language from configuration loaded from server, not the locally saved model
        setLanguage(language.id, false);
    }
    
    if (userLanguageLoaded) {
        app.store.setState({ userLanguageLoaded: true });
    }
}

//export const saveUserSettings = () => {

//    const app = window.app;
//    // skip the local storage saving, but do save some things on the server
//    //saveUserDetails();

//    //if (app.store.state.options.appSettings.enableLocalStorage) {
//    //    // does user object exist?
//    //    if (!app.user) return;
//    //    // is user fully initialized?
//    //    if (!app.user.UserName) return;

//    //    // prepare the 'saveData' object before storing it in local storage
//    //    let data = {
//    //        policies: [],
//    //        settings: app.settings // we save the current app settings for this user to restore them on when this user logs again
//    //    };
//    //    // save the policies' favourite level:
//    //    let items = app.store.state.policies;
//    //    if (items) {
//    //        items.map(policy => {
//    //            data.policies.push({ Guid: policy.Guid, IsFavourite: policy.IsFavourite });
//    //        });
//    //    }
//    //    ////console.log('data: '); //console.log(data);
//    //    LocalStorage.set('SealPathUserSettings-' + app.user.UserName, data);
//    //}
//}

//--------------------------------------------------------------------------

export const setLanguage = (code, forceLoading) => {

    //console.log('setLanguage: ', code);
    //console.log('setLanguage: forceLoading: ', forceLoading);

    
    const app = window.app;
    // loop over all languages from configuration
    app.store.state.options.appSettings.languages.forEach((language) => {
        if (language.id == code) {
            language.selected = true; // this is used for language UI (checkbox) rendering
            app.settings.language = language;            
            //console.log('setLanguage: app.settings.language: ', language)
        }
        else {
            language.selected = false;
        }
    });

    saveAppSettings(app);
    //saveUserSettings();

    if (forceLoading) {
        // reset the state flags so the subscribed components wait with rendering for the language
        app.store.setState({ userLanguageLoading: true });        
        loadAppData(RequestType.FactoryLanguage);
    }
}

export const setSuperFavPolicy = (policy) => {

    let app = window.app;
    app.lastSuperFavPolicy = app.superFavPolicy;
    app.superFavPolicy = policy;
}

//export const restoreSuperFavPolicy = (policy) => {

//    let app = window.app;
//    // remove it from the big tool, restoring the previous one if any
//    if (app.lastSuperFavPolicy === policy) {
//        app.superFavPolicy = null; // the last super fav is the same policy - unselect this one and select none
//        app.lastSuperFavPolicy = null;
//    }
//    else {
//        app.superFavPolicy = app.lastSuperFavPolicy;
//    }
//    if (app.superFavPolicy !== null) { app.superFavPolicy.favouriteLevel = 2; }
//}


// how to get mouse coords in React:
// https://stackoverflow.com/questions/42182481/getting-mouse-coordinates-in-react

// more about MouseEvent:
// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/screenX

export const setClickPosition = (e) => {
    
    var elementOffset = {
        x: 0,
        y: 0
    } 
    var doc = document.documentElement;
    var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    ////console.log('left: ' + left + ', top: ' + top);
    // screenX
    // clientX
    // pageX
    // offsetX
    //////console.log('mouse coordinates: ' + e.pageX + ', ' + e.pageY);
    //this.setState({ x: e.clientX, y: e.clientY });
    let app = window.app;
    let offset = 40 * 0.5; // half of the small button size: used to precisly position the buttons around the click
    let el = app.rightColumn.current;
    // Note: we're accessing "current" to get the DOM node
    if (el) {
        if (el.scrollLeft) {
            elementOffset.x = el.scrollLeft;
        }
        if (el.scrollTop) {
            elementOffset.y = el.scrollTop;
        }
        // this can't be used here - the context menu is placed inside of the container we use as a reference
        //if (el.offsetLeft) {
        //    elementOffset.x -= el.offsetLeft;
        //}
        //if (el.offsetTop) {
        //    elementOffset.y -= el.offsetTop;
        //}
        ////console.log('element offset x:' + elementOffset.x);
        ////console.log('element offset x:' + elementOffset.y);

        // find the right column position
        let rect = el.getBoundingClientRect();
        //logObject(rect, 'right column bounding rectangle');
        app.clientX = e.pageX - rect.x - offset + elementOffset.x - left;// - 290;
        app.clientY = e.pageY - rect.y - offset + elementOffset.y - top;
    }
    else {
        app.clientX = e.pageX - offset - elementOffset.x - left;// - 290;
        app.clientY = e.pageY - offset - elementOffset.y - top;// - 60;
    }
}

export const getSpinnerStyle = () => {

    let app = window.app;
    //var w = window.innerWidth;
    var h = window.innerHeight;
    //var x = w * 0.5;
    var y = h * 0.5;
    // add the offset for the left column (navbar):
    //let el = app.leftColumn.current; // Note: we're accessing "current" to get the DOM node
    //if (el) {
    //    let rect = el.getBoundingClientRect();
    //    //console.log('left column bounding rectangle.w: ' + rect.width + ', h: ' + rect.height);
    //    if (rect.height < 200) {
    //        //console.log('left column is short: should be over the content');
    //        y = (h - rect.height) * 0.5;
    //    }
    //    else { //console.log('left column should be filling all height'); }       
    //}
    
    var style = {
        //display: 'block',
        position: 'absolute',
        //left: x,
        top: y - 40
    };
    return style;
}
//-------------------------------------------------------------------

export const countSelectedItems = (items) => {

    let total = 0;
    if (!items)
        return total;

    items.map(item => {
        if (item.selected) total++;
    });
    return total;
}

export const findSelectedItems = (items) => {

    let selectedItems = [];
    items.map(item => {
        if (item.selected) selectedItems.push(item);
    });
    return selectedItems;
}

export const clickPolicy = (e, policy, component) => {

    const app = window.app;
    const store = app.store;
    
    // NEW CODE (untill we implemenent the policy-level buttons): allow only one selection for policy: unselect other policies in case you are going to select the new one
    //if (!policy.selected)
    //    unselectItems(store.state.policies); --> commented out so we can have multiple selection

    clickItemAndSelect(e, policy); // this will toggle the item state

    // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
    // render the context menu buttons we use that model to check if we can edit/delete
    // (with multiselection the policy reference passed to the rendering functions was null,
    // so no direct checks could be made)
    policy.selected ? store.setState({ policy }) : store.setState({ policy: null })

    // When we select a policy/permission we have 2 options to enable tools:
    // A. render individual buttons in the item row, controlling the render state 
    // on a local level, from the item's render method and using its 'selected' prop,
    // B. render the context menu, controlling the render state on a global page level.

    // this code is needed if we use the context menu, we have to count the selected items:
    const total = countSelectedItems(store.state.policies)
    let showDeleteButton = true; 
    store.state.policies.map(p => {
        if (p.selected && !canDeletePolicy(app.user, p)) // one that cannot be deleted and the context menu button is disabled
            showDeleteButton = false;
    });
    if (total === 1) {
        //console.log('SHOW CONTEXT MENU (1 item)')
        // show the context menu, enable both the delete button and the edit button
        component.setState({ showContextMenu: true, showDeleteButton: showDeleteButton,  showEditButton: true });
    }
    else if (total > 1) {
        //console.log('SHOW CONTEXT MENU (' + total +' items)')
        // show the context menu, enable the delete button (if all policies can be deleted) and disable the edit button
        component.setState({ showContextMenu: true, showDeleteButton: showDeleteButton, showEditButton: false });
    }
    else {
        //console.log('HIDE CONTEXT MENU')
        // hide context menu
        component.setState({ showContextMenu: false });
    }
    // now update the policies page state
    //app.setComponentState(locator.policiesPage, { items: store.state.policies });
    app.store.setState({ policies: app.store.state.policies });
}
export const clickPolicyInSelectionMode = (e, policy, component) => {

    const app = window.app;
    const store = app.store;

    // NEW CODE (untill we implemenent the policy-level buttons): allow only one selection for policy: unselect other policies in case you are going to select the new one
    //if (!policy.selected)
    //    unselectItems(store.state.policies); --> commented out so we can have multiple selection
    // only one can be selected in selection mode (selection mode is used when one policy needs to be selected for protection of files)
    unselectItems(store.state.policies);  // multiple selection is not allowed: unselect all before selecting the new item

    clickItemAndSelect(e, policy); // this will toggle the item state

    // NEW CODE (untill we implemenent the policy-level buttons): store the reference to the single selected policy in store, so when we
    // render the context menu buttons we use that model to check if we can edit/delete
    // (with multiselection the policy reference passed to the rendering functions was null,
    // so no direct checks could be made)
    policy.selected ? store.setState({ policy }) : store.setState({ policy: null })

    // When we select a policy/permission we have 2 options to enable tools:
    // A. render individual buttons in the item row, controlling the render state 
    // on a local level, from the item's render method and using its 'selected' prop,
    // B. render the context menu, controlling the render state on a global page level.

    // this code is needed if we use the context menu, we have to count the selected items:
    const total = countSelectedItems(store.state.policies)
    let showDeleteButton = true;
    store.state.policies.map(p => {
        if (p.selected && !canDeletePolicy(app.user, p)) // one that cannot be deleted and the context menu button is disabled
            showDeleteButton = false;
    });
    if (total === 1) {
        //console.log('SHOW CONTEXT MENU (1 item)')
        // show the context menu, enable both the delete button and the edit button
        component.setState({ showContextMenu: true, showDeleteButton: showDeleteButton, showEditButton: true });
    }
    else if (total > 1) {
        //console.log('SHOW CONTEXT MENU (' + total +' items)')
        // show the context menu, enable the delete button (if all policies can be deleted) and disable the edit button
        component.setState({ showContextMenu: true, showDeleteButton: showDeleteButton, showEditButton: false });
    }
    else {
        //console.log('HIDE CONTEXT MENU')
        // hide context menu
        component.setState({ showContextMenu: false });
    }
    // now update the policies page state
    //app.setComponentState(locator.policiesPage, { items: store.state.policies });
    app.store.setState({ policies: app.store.state.policies });
}
export const clickPermission = (e, policy, permission) => {

    const app = window.app;
    const store = app.store;
    const locator = app.locator;
    const permissions = policy.Users;
    //console.log('clickPermission: policy: ', policy, ', permissions: ', permissions);
    clickItemAndSelect(e, permission); // this will toggle the item state
    
    // When we select a policy/permission we have 2 options to enable tools: 
    // A. render individual buttons in the item row, controlling the render state 
    // on a local level, from the item's render method and using its 'selected' prop,
    // B. render the context menu, controlling the render state on a global page level.

    //console.log('locator: ')
    //console.log(locator)
    // this code is needed if we use the context menu, we have to count the selected items:
    const total = countSelectedItems(permissions);
    if (total === 1) {
        // show the context menu, enable both the delete button and the edit button
        app.setComponentState(locator.editPolicyPage, { showContextMenu: true, showDeleteButton: true, showEditButton: true });
    }
    else if (total > 1) {
        // show the context menu, enable the delete button and disable the edit button
        app.setComponentState(locator.editPolicyPage, { showContextMenu: true, showDeleteButton: true, showEditButton: false });
    }
    else {
        // hide context menu
        app.setComponentState(locator.editPolicyPage, { showContextMenu: false });
    }
    // now update the edit policy page state
    //app.setComponentState(locator.editPolicyPage, { items: store.state.policies });
    app.store.setState({ policies: app.store.state.policies });
}

export const clickPermissionUser = (e, permission, user) => {

    const app = window.app;
    const store = app.store;
    const locator = app.locator;
    const users = app.store.state.permissionUsers;
    //console.log('clickPermissionUser: permission: ', permission, ', user: ' + user);
    clickItemAndSelect(e, user); // this will toggle the item state

    // When we select a policy/permission we have 2 options to enable tools: 
    // A. render individual buttons in the item row, controlling the render state 
    // on a local level, from the item's render method and using its 'selected' prop,
    // B. render the context menu, controlling the render state on a global page level.

    ////console.log('locator: ')
    ////console.log(locator)
    // this code is needed if we use the context menu, we have to count the selected items:
    const total = countSelectedItems(users);
    if (total === 1) {
        // show the context menu, enable both the delete button and the edit button
        app.setComponentState(locator.permissionsPage, { showContextMenu: true, showDeleteButton: true, showEditButton: true });
    }
    else if (total > 1) {
        // show the context menu, enable the delete button and disable the edit button
        app.setComponentState(locator.permissionsPage, { showContextMenu: true, showDeleteButton: true, showEditButton: false });
    }
    else {
        // hide context menu
        app.setComponentState(locator.permissionsPage, { showContextMenu: false });
    }
    // now update the edit policy page state
    //app.setComponentState(locator.editPolicyPage, { items: store.state.policies });
    app.store.setState({ permissionUsers: users });
}

export const selectPermissionUserFromMultiSelector = (e, user) => {

    const store = window.app.store;
    const { state } = store;
    if (!state.permissionUsers) state.permissionUsers = [];
    const permissionUsers = state.permissionUsers;
    // check if user is already on the list?
    let found = false;
    permissionUsers.map(u => { if (u === user) found = true; });
    if (!found) permissionUsers.push(user);
    user.selected = false;
    user.excluded = true; // set this flag so user is excluded now from the search list
   
    store.setState({ permissionUsers });    
}

export const selectInvitiationUserFromMultiSelector = (e, user) => {

    let store = window.app.store;
    if (!store.state.invitationUsers) store.state.invitationUsers = [];
    const invitationUsers = store.state.invitationUsers;
    // check if user is already on the list?
    let found = false;
    invitationUsers.map(u => { 
        if (u === user) found = true;
    });
    if (!found) invitationUsers.push(user);
    user.selected = false;
    user.excluded = true; // set this flag so user is excluded now from the search list
   
    store.setState({ invitationUsers });    
}

export const clickUserFromContactList = (e, item, component) => {

    if (!component) return;
    if (!component.props.invitationMode) return; // the following code is only for the invitation mode    
    // decide if we should show the context menu with 'invite' button
    const app = window.app;
    const store = app.store;
    const locator = app.locator;
    const users = app.store.state.users; // this works with the main users list
    //console.log('clickPermissionUser: permission: ', permission, ', user: ' + user);
    clickItemAndSelect(e, item); // this will calculate the click's position

    // When we select a policy/permission we have 2 options to enable tools: 
    // A. render individual buttons in the item row, controlling the render state 
    // on a local level, from the item's render method and using its 'selected' prop,
    // B. render the context menu, controlling the render state on a global page level.

    ////console.log('locator: ')
    ////console.log(locator)
    // this code is needed if we use the context menu, we have to count the selected items:
    const total = countSelectedItems(users);
    //if (total === 1) {
    //    // show the context menu, enable both the delete button and the edit button
    //    component.setState({ showContextMenu: true, showDeleteButton: true, showEditButton: true });
    //}
    //else
    if (total > 0) {
        // show the context menu, disable the delete button and enable the edit (here: invite) button
        component.setState({ showContextMenu: true, showDeleteButton: false, showEditButton: true });
    }
    else {
        // hide context menu
        component.setState({ showContextMenu: false });
    }
    
}

export const clickUserFromPermissionList = (e, item, component) => {

    const app = window.app;
    const store = app.store;
    const locator = app.locator;
    const users = app.store.state.permissionUsers;
    //console.log('clickPermissionUser: permission: ', permission, ', user: ' + user);
    clickItemAndSelect(e, item); // this will calculate the click's position

    // When we select a policy/permission we have 2 options to enable tools: 
    // A. render individual buttons in the item row, controlling the render state 
    // on a local level, from the item's render method and using its 'selected' prop,
    // B. render the context menu, controlling the render state on a global page level.

    ////console.log('locator: ')
    ////console.log(locator)
    // this code is needed if we use the context menu, we have to count the selected items:
    const total = countSelectedItems(users);
    //if (total === 1) {
    //    // show the context menu, enable both the delete button and the edit button
    //    component.setState({ showContextMenu: true, showDeleteButton: true, showEditButton: true });
    //}
    //else
    if (total > 0) {
        // show the context menu, enable the delete button and disable the edit button
        component.setState({ showContextMenu: true, showDeleteButton: true, showEditButton: false });
    }
    else {
        // hide context menu
        component.setState({ showContextMenu: false });
    }
    //console.log('clickUserFromPermissionList : component, countSelectedItems: ', component, total);

    // now update the edit policy page state
    //app.setComponentState(locator.editPolicyPage, { items: store.state.policies });
    //app.store.setState({ permissionUsers: users });
}

export const clickUserFromInvitationsList = (e, item, component) => {

    const app = window.app;
    const store = app.store;
    const locator = app.locator;
    const users = app.store.state.invitationUsers;
    //console.log('clickPermissionUser: permission: ', permission, ', user: ' + user);
    clickItem(e, item); // this will calculate the click's position

    // When we select a policy/permission we have 2 options to enable tools: 
    // A. render individual buttons in the item row, controlling the render state 
    // on a local level, from the item's render method and using its 'selected' prop,
    // B. render the context menu, controlling the render state on a global page level.

    ////console.log('locator: ')
    ////console.log(locator)
    // this code is needed if we use the context menu, we have to count the selected items:
    const total = countSelectedItems(users);
    //if (total === 1) {
    //    // show the context menu, enable both the delete button and the edit button
    //    component.setState({ showContextMenu: true, showDeleteButton: true, showEditButton: true });
    //}
    //else
    if (total > 0) {
        // show the context menu, enable the delete button and disable the edit button
        component.setState({ showContextMenu: true, showDeleteButton: true, showEditButton: false });
    }
    else {
        // hide context menu
        component.setState({ showContextMenu: false });
    }
    //console.log('clickUserFromInvitationsList : component, countSelectedItems: ', component, total);

    // now update the edit policy page state
    //app.setComponentState(locator.editPolicyPage, { items: store.state.policies });
    //app.store.setState({ invitationUsers: users });
}

export const clickDocument = (e, item) => {

    let app = window.app;
    let store = app.store;
    let locator = app.locator;
    //clickItem(e, item); // do not select the document, only stop propagation
    e.stopPropagation();
    //we need to make a server call to get the document information    
    documentManager.loadDocumentInfo(app, null, item);
}

export const clickItem = (e, item) => {

    // calculate the click position and store it in the app object's clientX/clientY:
    setClickPosition(e);
}

export const clickItemAndSelect = (e, item) => { 
    
    clickItem(e, item);
    if (item)
        item.selected = !item.selected;
}

export const unselectItems = (items) => {

    //console.log('unselect items: ');
    //console.log(items);

    if (items) {
        // unselect all
        items.map(item => {
            item.selected = false;
        })
    }
}


export const addSelectedUsersToInvitiationsList = (state) => {

    if (!state.invitationUsers) {
        state.invitationUsers = [];
    }
    addSelectedItemsToList(state.users, state.invitationUsers);
}

export const addSelectedUsersToPermissionsList = (state) => {

    if (!state.permissionUsers) {
        state.permissionUsers = [];
    }
    addSelectedItemsToList(state.users, state.permissionUsers);    
}

export const addSelectedItemsToList = (sourceList, targetList) => {

    const sourceItems = sourceList.filter(i => i.selected);
    sourceItems.map(item => {
        // look for the user we want to add, to avoid duplicates
        let found = false;
        targetList.map(i => { if (i === item) found = true; });
        if (!found) { targetList.push(item); }
    });
}

//export const ClientWarningType = {

//    Any: 15,//0xF,
//    AccessBlocked: 1,//0x1,
//    UserAddedInDocument: 2,//0x2,
//    UserAddedInProtection: 4,//0x4,
//    UnprotectWarning: 8,//0x8,
//    UserWithNoRights: 16,// AccessDeniedWarning
//    ExcludedIPWarning: 32, //0x20,
//    MobileOfficeBlockedWarning: 64//0x40
//}

    // conversion made from server type to client type in SP Desktop:
    //UserWithNoRights: ServerWarningType.AccessDeniedWarning,
    //AccessBlocked: ServerWarningType.UserBlockedWarning,
    //AccessBlocked: ServerWarningType.DocumentBlockedWarning,
    //UserAddedInDocument: ServerWarningType.NewUserWarning,
    //UserAddedInProtection: ServerWarningType.ProtectionModifiedWarning,
    //UnprotectWarning: ServerWarningType.UnprotectWarning,
    //ExcludedIPWarning: ServerWarningType.ExcludedIPWarning,
    //MobileOfficeBlockedWarning: ServerWarningType.MobileOfficeBlockedWarning,
    //Any: ServerWarningType.All,
    //    default:
    //Any: ServerWarningType.All

// StatsService.WarningTypes on server:
export const ServerWarningType = {

    WarningBaseValue: 16,
    AccessDeniedWarning: 16,
    NewUserWarning: 32,
    ProtectionModifiedWarning: 64,
    UnprotectWarning: 128,
    UserBlockedWarning: 256,
    DocumentBlockedWarning: 512,
    ExcludedIPWarning: 1024,
    MobileOfficeBlockedWarning: 2048,
    All: 4080 // used only for DB search, we don't register value like this in DB
}


export function formatWarning(warningType) {

    //console.log('format warning: ' + warningType);
    let warning = '';
    if (!warningType) return warning;
    let app = window.app;

    switch (warningType) {
        //case ServerWarningType.All: // client warning: Any: we don't store it in DB, it's used for search filter only
        //    warning = app.R.Any
        //    break
        case ServerWarningType.UserBlockedWarning: // client warning: AccessBlocked
            warning = app.R.AccessBlocked
            break
        case ServerWarningType.DocumentBlockedWarning: // client warning: AccessBlocked
            warning = app.R.AccessBlocked
            break
        case ServerWarningType.NewUserWarning: // client warning: UserAddedInDocument:
            warning = app.R.UserAddedToDocument
            break
        case ServerWarningType.ProtectionModifiedWarning: //client warning: UserAddedInProtection:
            warning = app.R.UserAddedToProtection
            break
        case ServerWarningType.UnprotectWarning: // the same as client
            warning = app.R.Unprotected;
            break
        case ServerWarningType.AccessDeniedWarning: // client warning: UserWithNoRights
            warning = app.R.UserWithNoRights
            break
        case ServerWarningType.ExcludedIPWarning: // the same as client
            warning = app.R.ExcludedIP
            break
        case ServerWarningType.MobileOfficeBlockedWarning: // the same as client
            warning = app.R.MobileOfficeBlocked
            break
        default:
            warning = app.R.Unknown
    }
    return warning;
}


export const goToHomePage = (e) => {

    if (e) e.stopPropagation();
    let app = window.app;
    if (app.history) { app.history.push(routes.home()) }
}

export const goToPermissionsPage = (e) => {

    if (e) e.stopPropagation();
    window.app.history.push(routes.policies.permissions());
}

export const goToAddUsersToPermissionsPage = (e) => {

    if (e) e.stopPropagation();
    window.app.history.push(routes.policies.addUsersToPermissions());
}

export const goToContactsPage = (e) => {

    if (e) e.stopPropagation();
    window.app.history.push(routes.contacts());
}

export const goToInvitationsPage = () => {

    window.app.history.push(routes.invitations());
}

export const goToTasksPage = (e, task) => {

    if (e) e.stopPropagation();
    let app = window.app;
    if (app.history) { app.history.push(routes.tasks()) }
}


export function onReturnBack(e) {

    if (e) e.stopPropagation();
    let app = window.app;
    if (app.history) { app.history.goBack() }
}

export function setExcludedForItems(items, flag) {
    if (!items) return;
    items.map(i => { i.excluded = flag });
}


//===============================================================
export const getUserNameAndFullName = (user) => {

    let result = user.U;//UserName
    if (user.F) {//FullName
        result = result + ' ' + user.F; //FullName
    }
    return result;
}

//===============================================================
export const findUserNameAndFullName = (userId) => {

    let result = '';//userName;
    const app = window.app;
    //1. try to find a full user model with this username in the items list (this
    //  list  doesn't contain the current user, as he was removed from there):
    const items = app.store.state.users;
    if (!items) return result;
    for (let i = 0; i < items.length; i++) {
        let user = items[i];
        // is it the user we are looking for?
        if (user && userId == user.Id) {
            result = user.U;
            if (user.F) {
                result = result + ' ' + user.F;
                return result;
            }                       
        }
    }
    // 2. try to find a full name for the current user:
    let user = app.user;
    // is it the user we are looking for?
    if (user && userId == user.Id) {
        result = user.U;
        if (user.F) {
            result = result + ' ' + user.F;
            return result;
        }
    }
    return result;
}

export const updateAppHistory = (app, component) => {

    if (component.props.history) { app.history = component.props.history }
}      

export const setOptionError = (options, type, hasError) => {

    let option = findOption(options, type);
    if (option) {
        option.error = hasError;   
        //console.log('set option error: ', type, hasError);
    }
        
}

export const findOption = (options, type) => {

    for (let i = 0; i < options.length; i++) {
        let option = options[i];
        if (option.type === type)
            return option;
    }
    return null;
}      

export const findObjectWithId = (models, id) => {

    if (!models) return null;
    for (let i = 0; i < models.length; i++) {
        let model = models[i];
        if (model.id === id)
            return model;
    }
    return null;
}      

//export function findUser(app, userId) {

//    if (!userId || userId < 1)
//        return null;

//    // look in current user
//    let user = app.user;
//    if (userId === user.UserId) {
//        return user;
//    }
//    // look in the rest of users
//    const users = app.store.state.users;
//    (users || []).filter(user => {
//        if (userId === user.UserId) {
//            return user;
//        }
//    })
//    return null;
//}

export function getServerFromUrl(url) {

    var parts = url.split('/');
    for (let i = 0; i < parts.length; i++) {
        let part = parts[i];
        if (part == 'https:' || part === 'https' || part == 'http' || part == '')
            continue;
        return part;        
    }
    return '';
}

export function isProtector(app) {

    if (!app) return false;
    if (!app.user) return false;
    if (app.user.R === UserRole.Protector)
        return true;
    return false;
}

export function canCreateProtections(app) {

    if (!app) return false;
    if (app.store.getOption({ key: 'CanCreateProtections' }))
        return true;
    return false;
}

export function canSendInvitations(app) {
    
    // exclude external users. TODO: should we really render Contacts then?
    if (!app.user.O) {
        // for external users: if there are no users in their organization, we won't get 
        // the current user's properties(we only know the UserName - from the login or session)
        return false;
    }
    if (app.user.O < 2) {
        // this have to be an external user who's not in any organization
        return false;
    }

    let canSend = app.store.getOption({ key: 'SendInvitationPermission' });
    // ... some more checks here?
    return canSend;
}


export function initializeIdleTimer(app) {

    //function Timer(callback, time) {
    //    this.setTimeout(callback, time);
    //}

    //Timer.prototype.setTimeout = function (callback, time) {
    //    var self = this;
    //    if (this.timer) {
    //        clearTimeout(this.timer);
    //    }
    //    this.finished = false;
    //    this.callback = callback;
    //    this.time = time;
    //    this.timer = setTimeout(function () {
    //        self.finished = true;
    //        callback();
    //    }, time);
    //    this.start = Date.now();
    //}

    //Timer.prototype.add = function (time) {
    //    if (!this.finished) {
    //        // add time to time left
    //        time = this.time - (Date.now() - this.start) + time;
    //        this.setTimeout(this.callback, time);
    //    }
    //}
    //if (!app.timer || app.timer === null)
    //    app.timer = new Timer(function () { // init timer with 5 seconds
    //        alert('foo');
    //    }, 5000);

    // DOM Events
    const events = [
        'mousemove',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mouseWheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove',
        'visibilitychange'
    ]

    const element = document
    const capture = true
    const passive = true
    events.forEach(e => {
        element.addEventListener(e, resetTimer)
        //    , {
        //    capture,
        //    passive
        //})
    })

    var timer = {
        elapsed: 0, // in milliseconds, used to check if maximum time limit is reached
        id: 0, // for referencing the id of JavaScript native timer
        interval: 5000, // 5 seconds for tick
        lastUpdate: null,
        reset: resetTimer,
        start: startTimer,
        stop: stopTimer
    }

    function startTimer() {
        timer.elapsed = 0;
        timer.lastUpdate = new Date();
        timer.id = setInterval(updateTimer, timer.interval);
        //console.log('start idle timer. timer: ', timer)
    }

    function stopTimer() {
        clearInterval(timer.id);
        //console.log('stop idle timer. timer: ', timer)
    }

    function resetTimer() {
        //console.log('reset idle timer. timer: ', timer)
        timer.elapsed = 0;
        //timer.lastUpdate = new Date(); // it will be reset on the next tick (every 5 seconds), ...
        // ...we have this margin so we skip this update/initialization, as it can be very frequent
    }

    // ticking every x seconds to update the elapsed time and see if expired
    function updateTimer() {
        //console.log('update idle timer. timer: ', timer);
        // update timer only if user is authenticated
        if (app.state.authenticated) {

            let now = new Date()
            timer.elapsed += (now - timer.lastUpdate);
            timer.lastUpdate = now;

            // set the time limit
            let idleLimitInMilliseconds = 10 * 60 * 1000 // default: 5 minutes
            const config = window.app.store.state.options.appSettings;
            if (config) {
                if (config.Local.UserInactivityLimitInMinutes) {
                    idleLimitInMilliseconds = config.Local.UserInactivityLimitInMinutes * 60 * 1000
                }
            }
            // check if time is expired
            if (timer.elapsed >= idleLimitInMilliseconds) {
                //console.log('update idle timer. time limit reached! timer: ', timer);
                timer.elapsed = 0;
                trySigningOut();
            }
        }
    }

    function trySigningOut() {

        if (app.auth.rememberMe) {
            //console.log('check if user is valid on idle. timer:  ', timer)
            // Check if user is still valid, so after a potential password reset,
            // when his access tokens expires, he will be logged out.            
            checkIfUserIsValid(app)
        }
        else {
            //console.log('logout on idle. timer:  ', timer)
            // logout and close session
            signOut(app);

            //show the dialog with information about timeout
            const errorObject = {
                title: app.R.SessionClosed,
                message1: app.R.SessionClosedDueTo
            };
            app.setState({ showError: true, errorTask: null, errorObject });
        }
    }

    return timer;
}


export const resetAppSession = (app) => {

    // reset the app session references?: new one will be obtained on Login start
    app.settings.codeType = '';
    app.settings.sessionId = ''; // keep the session id(?) there are no benefits in re-using the session on server after the sign-out
    app.settings.codeId = '';
}

export const resetApp = (app) => {

    //console.log('resetApp');
    app.setState({ authenticated: false });
    app.user = {};
    app.lastUser = {};
    app.shouldLoadUserData = true; // so next time we load all user data again after user validation

    // reset tasks
    app.taskProcessor.initialize();

    // reset the super fav policy (the super fav policy button is not used at this moment)
    app.superFavouritePolicy = null;

    // reset the authorization singleton
    app.auth.initialize();

    // reset the data store singleton
    app.store.initialize();

    // reset the idle (inactivity) timer
    if (app.idleTimer)
        app.idleTimer.stop();

    // Reset the language to the server settings.   
    // Get the language id
    const options = app.store.state.options;
    const languageId = options.appSettings.defaultLanguage;

    // Set the user language (pass a fake language object with id only)
    //setLanguage(languageId, false);    
    setUserLanguage({ id: languageId });
}


//-------------------------------------------------------------------
export const removeItemFromDisplayList = (e, items, item, callback) => {

    //console.log('removeItemFromDisplayList', item);
    if (e) e.stopPropagation();
    let app = window.app;

    if (!item.css_showing) {

        removeTaskInner();
    }
    // wait some time to let it show before we strat the hide transition,
    // otherwise the task disappears at once instead of hiding
    else {
        //console.log('closeTask. wait until shown, then -removeTaskInner-');
        // set timeout to remove the item when the hide transition is done
        setTimeout(() => {
            removeTaskInner();
        }, 450);
    }

    function removeTaskInner() {
        //console.log('removeTaskInner');
        // now remove the task from the list
        //app.tasks.items = app.tasks.items.filter(item => item !== task); -->
        // rather then removing it directly: set a hidden style to have a css transition 
        // (triggered by 'visible' flag on task rendering), then remove it after a timeout
        item.css_visible = false;
        // set timeout to remove the item when the hide transition is done
        setTimeout(() => {
            //console.log('removeTaskInner after timeout 1. items: ', items);
            if (items && items.length > 0) {
                //console.log('removeTaskInner after timeout 2. filtering items... ');
                items = items.filter(ite => ite !== item);
            }
            //console.log('removeTaskInner after timeout 2. items: ', items);
            //updateTaskCounter(app);
            if (callback)
                callback(items);
            //return items;
        }, 250);

        //updateTaskCounter(app); // this will force the state update
        // it eventually does the following in the original method:
        //app.store.setState({ tasks: tasks.items });
    }
}
