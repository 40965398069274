import React, { Component } from 'react';

export class ItemList extends Component {

    constructor(props) {
        super(props);       
    }

    render() {
        const containerClass = this.props.className ? 'item-list ' + this.props.className : 'item-list';
        return (
            <div className={containerClass}>
                {this.props.children}
            </div>
        );

    }
}
    