import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

import { SmallButton } from '../atoms';
import { template } from '../../common/format';


export class SearchFilter extends Component {

    constructor(props) {
        //console.log('SearchFilter. constructor');
        super(props);

        this.inputConfirmed = false;

        // Default text value
        let value = '';
        // Try to take the initial value from the props (e.g. we could have restored
        // the text value from configuration before rendering this component):
        if (this.props.value)
            value = this.props.value;   
        //console.log('SearchFilter. constructor. this.props.value: ', this.props.value);
        //console.log('SearchFilter. constructor. this.props: ', this.props);
        // set the state
        this.state = {
            error: false,
            text: value,
        };
    }

    // when the input text changes
    onChange = (e) => {

        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        const value = e.target.value;
        //if (value.length === 0)
        //    this.confirmed = false;
        //if (this.confirmed)
        //    this.validateInput(value);
        this.setState({ text: value });
    }

    // when user presses a key
    onKeyPress = (e) => {

        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        const value = e.target.value;
        //this.setState({
        //    text: value,
        //});
        //if (this.inputConfirmed)
        //    this.validateInput(value);
        if (e.keyCode === 13 || e.which === 13) {
            this.inputConfirmed = true;
            if (this.validateInput(value))
                this.props.onChange(value);              
        }
    }
    // when user blurs the input field
    onBlur = (e) => {

        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        this.inputConfirmed = true;
        const value = this.state.text;
        if (this.validateInput(value))
            this.props.onChange(value);            
    }
    // when user focuses the input field
    onFocus = (e) => {

        //if (e) e.preventDefault();
        //if (e) e.stopPropagation();

        // remove the error state and reset the 'inputConfirmed' flag back to
        // the initial 'false', so errors are not rendered when user is typing
        this.inputConfirmed = false; 
        this.setState({ error: false });
    }
    // when user clicks on the 'search' button
    onInputConfirmed = () => {

        this.inputConfirmed = true;
        let value = this.state.text;
        if (this.validateInput(value))
            this.props.onChange(value);
    }

    validateInput = (value) => {

        if (this.inputConfirmed && value.length > 0 && value.length < this.props.minCharacters) {
            this.setState({ error: true });
            return false;
        }
        else {
            this.setState({ error: false });
            return true;
        }
    }

    render() {
        //console.log('SearchFilter. render');
        const app = this.props.app ? this.props.app : window.app;
        const { showAddIcon, showSearchIcon, labels, placeholder, value } = this.props;
        //let text = this.state.text;// ? this.state.text : value;
        //if (value && value !== '') text = value;
        //console.log('SearchFilter. render. text: ', this.state.text);
        let className = 'text-input';
        let errorDiv = null;
        if (this.state.error) {
            className = 'text-input error';
            let replacements = {
                '{0}': this.props.minCharacters
            };
            let errorText = template(app.R.MinXCharacters, replacements);
            errorDiv = <div className='absolute-error-text'>{errorText}</div>
        }
      
        return (
            <div className='filter search'>
                {errorDiv}
                <Form.Control
                    className={className}
                    type='text'
                    value={this.state.text}
                    placeholder={placeholder}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                />
                {showAddIcon && (
                    <div className='search-button' onClick={this.onInputConfirmed} >
                        <SmallButton contentClass='icon-add-negative'/>
                    </div>
                )}
                {showSearchIcon && (
                    <div className='search-button' onClick={this.onInputConfirmed} >
                        <SmallButton contentClass='icon-search' />
                    </div>
                )}
            </div>
        )
    }
}
