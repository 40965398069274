import { DateType, PageSizeType, PolicyType, RequestType, UserType, WarningType } from './constants';

// The user configuration properties (when persistent) will be saved to 
// the local storage (UI settings), some to the DB. The next time the
// app is started the config is loaded and last filters are restored.
//
// Note: Ideally all user settings would be saved in database, so next time
// we connect from another device we would have all our prefs restored,
// the UI included, but for now we just use local storage for the UI.

export const createOptions = () => {

    return ({
        // The 'options' object is divided into sections (also objects), each section
        // contains individual options. Each section property (except the app, which
        // loads the appsettings.json object) is an 'option' object that have the value
        // property, and optionally some some other props, like 'persistent' which
        // decides if the property gets stored, or 'key' used to map options when
        // the configuration is loaded from the server.

        // *** GENERAL CONFIGURATION SECTIONS ***
        // The 'appSettings' section will reference the 'appsettings.json' object when loaded from server
        appSettings: {
            // set an empty object at start here, to avoid errors when code
            // tries to access some props before the actual settings are loaded
        },
        // here we will load the user/organization configuration from server
        userConfiguration: {
            // set by admin
            sendInvitationPermission: { value: true, persistent: false, key: 'SendInvitationPermission' },
            canCreateProtections: { value: true, persistent: true, key: 'CanCreateProtections' },
            // set by user
            autoSendInvitation: { value: true, persistent: true, key: 'AutoSendInvitation' },
            webProtectorWebClientPageSize: { value: true, persistent: true, key: 'WebProtector.WebClient.PageSize' },

            loaded: { value: false, persistent: false, key: 'UserConfigurationLoaded' }
        },
        // *** UI SECTIONS ***
        // contacts page filters
        contacts: {
            type: { value: UserType.All, persistent: true },
            text: { value: '', persistent: false }
        },
        // home page filters
        homeProtections: {
            type: { value: PolicyType.All, persistent: true },
            text: { value: '', persistent: false }
        },
        // user documents page filters
        // Note: when the store is created the localized texts are not loaded yet: the text in DateOptions(app)[0] will be undefined!
        // that's why we store only the date id in the options, full models with texts are formatted later (at rendering)
        documents: {
            date: { value: DateType.Anytime, persistent: true },
            type: { value: RequestType.DocumentsProtected, persistent: true },
            text: { value: '', persistent: false },
            warningType: { value: WarningType.All, persistent: true }
        },     
        // document page with document info and events (warning/tracking) selector
        document: {
            date: { value: DateType.Anytime, persistent: true },
            type: { value: RequestType.DocumentInfo, persistent: true },            
            text: { value: '', persistent: false },
            warningType: { value: WarningType.All, persistent: true }
        },
        invitations: {
            language: { value: '', persistent: true }            
        },
        // protections page filters
        protections: {
            type: { value: PolicyType.Own, persistent: true },
            text: { value: '', persistent: false }
        },
        // other UI configuration
        page: {
            size: { value: PageSizeType.Size10, persistent: true }
        }
    });
}
// options (like the user configuration above) is an object with different properties - 'sections', 
// which are also objects. Each section property is an 'option' object, 
// which follows a pattern
export const findOption = (options, key) => {

    // find the option with the given key:
    let foundOption = null;
    for (var sectionName in options) {
        let section = options[sectionName]; // options are divided into sections
        for (var optionName in section) {
            let option = section[optionName];
            if (option.key) {
                if (option.key === key) {
                    foundOption = option;
                    break;
                };
            }
        }
    }
    return foundOption;    
}

export const setOption = (options, key, value) => {

    let option = findOption(options, key);
    if (option) {
        option.value = value;    
        console.log('set option for key <' + key + '> in ', options);
    }
        
    else
        console.log('cannot find the option with key <' + key + '> in ', options);
}

export const getOptionValue = (options, key) => {

    // find the option with the given key:
    let option = findOption(options, key);

    // get the option value
    if (option)
        return option.value;
    else
        return null;
}
