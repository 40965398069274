import React, { Component } from 'react';
import { Checkbox, Item, ItemColumn, SmallButton } from "../../../components/atoms";

export class RestorePolicySection extends Component {

    constructor(props) {
        super(props);
        this.app = window.app;
    }

    render() {
        
        return (
            <div>                  
                <div className='clickable' onClick={this.props.onClicked}>
                    <h4 className='label'>{this.app.R.RestorePolicy}</h4>
                    <SmallButton
                        contentClass='icon-edit-negative'
                        onClick={this.props.onClicked}
                    />
                </div>        
                {
                    //<p>{this.app.R.ChangesWillAffectThisDocument}</p>
                }                
            </div>
        )
    }
}