// None of the following Babel, etc worksfor arrow functions:  IE11 still fails!
// Looks like arrow function doesn't get trans-compiled automatically
// Though, the "const" error is gone in old IE but it doens't help, code needs to be written in ES5.
//https://stackoverflow.com/questions/40216015/why-doesnt-this-arrow-function-work-in-ie-11

// other source:
//https://github.com/facebook/create-react-app/issues/914

// https://levelup.gitconnected.com/why-you-should-add-babel-polyfill-for-every-react-application-1997bdb8a524
//import "core-js/stable"; --> recommended (in a build message)

// babel
//https://babeljs.io/docs/en/babel-polyfill
//import 'babel-polyfill'; --> deprecated (in a build message)

// polyfill for IE9
//https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
// This must be the first line in src/index.js
//import 'react-app-polyfill/ie11';

import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/css/bootstrap-theme.css';
import './index.css';

//import React from 'react';
//import ReactDOM from 'react-dom';
import {
    //BrowserRouter,
    HashRouter
} from 'react-router-dom';

import { App } from './App';
//import registerServiceWorker from './registerServiceWorker';
import * as serviceWorker from './serviceWorker';

// for React 18
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";


//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
////console.log('HashRouter: basUrl: ' + baseUrl);
const baseUrl = '';

//// React 17
//ReactDOM.render(
//    <HashRouter basename={baseUrl}>
//        <App />
//    </HashRouter>,
//    document.getElementById('root'));

// React 18 
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <HashRouter basename={baseUrl}>
            <App />
        </HashRouter>
    </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA,
// and: https://create-react-app.dev/docs/making-a-progressive-web-app/
//serviceWorker.unregister();
serviceWorker.register();

