import { createNewPermission, editPolicy, findPolicy, hasFullControlInPermissionModel } from './policyManager';
import * as appManager from './appManager';
import { RequestType, ResultCode } from '../common/constants';
import { api } from '../common/api';
import { routes } from '../common/routes';


export const onGetLocalDocumentInfoCompleted = (e, task, licenseInfo, document) => {

    console.log('onGetLocalDocumentInfoCompleted...');
    const app = window.app;
    if (e) e.stopPropagation();
    const { history } = app;
    // before that set the previous reference to null
    app.store.state.documentInfo = null;
    app.store.state.documentId = null;
    app.store.state.documentGuid = null;

    let documentId = 0;
    if (document) {
        documentId = document.DocumentId;
    }

    let documentGuid = null;
    if (licenseInfo) {
        documentGuid = licenseInfo.guid;
    }
    let licenseStringBase64 = "";
    if (licenseInfo) {
        licenseStringBase64 = licenseInfo.licenseStringBase64;
    }
    console.log('onGetLocalDocumentInfoCompleted... licenseInfo: ', licenseInfo);
    app.store.state.documentId = documentId;
    app.store.state.documentGuid = documentGuid; 

    // DO NOT GO TO DOC INFO PAGE WITH ID = 0! --> GET THE DOC INFO FROM SERVER FIRST - USING THE GUID, 
    // THEN SHOW THE DOCUMENT INFO PAGE, FILLING THE ID PARAMETER IN THE URL
    //history.push(routes.documents.info(documentId));
    loadDocumentInfo(app, task, { DocumentId: documentId, DocumentGuid: documentGuid, ExtractedLicense: licenseStringBase64 });
};

export const goToDocumentInfoPage = (e, document, guid, filter) => {

    //console.log('goToDocumentInfoPage...');
    const app = window.app;
    if (e) e.stopPropagation();
    const { history } = app;
    // before that set the previous reference to null
    app.store.state.documentInfo = null;
    app.store.state.documentId = null;
    app.store.state.documentGuid = null;
    app.store.state.documentWarning = null;

    let documentId = 0;
    if (document) {
        documentId = document.DocumentId;
        if (filter) {
            if (filter.type === RequestType.DocumentsWarnings) {
                // save the reference to the document model, as it contains the warning information
                app.store.state.documentWarning = document;
            }
        }
    }
    else {
        app.store.state.documentId = 0;
        app.store.state.documentGuid = guid;
    }
    //appManager.clickDocument(e, document); // only push new component route - the document will load from componentDidMount
    history.push(routes.documents.info(documentId));
};


export const loadDocumentInfo = (app, task, document) => {

    console.log('loadDocumentInfo');

    // reset the previous references
    app.store.setState({ documentInfo: null });
    app.store.setState({ documentInfoPermissions: null });
    app.store.setState({ documentInfoPolicy: null });
    app.store.setState({ documentEvents: null });

    // prepare the 'task' object
    if (!task) {
        // when we call this method without passing the existing task reference, we need to create a new object
        task = {
            app: app,
            type: RequestType.DocumentInfo, // set the new task type
            onComplete: onDocumentInfoLoaded
        };
    }
    else {
        // when we call this method passing the existing task reference (from 'get Local Doc Info', most likely visible on the task list) only update some props
        //task.type = RequestType.DocumentInfo; !!! do not change it, keep the original task type (e.g. type could be 'AddUsers')
        task.onComplete = onDocumentInfoLoaded;        
    }

    task.ajax = { // create the request object for HTTP client
        url: api().document.info(),
        headers: {},
        data: {
            // do not set it like this, create the properties only if the value exists! (look further below)
            //TargetId: document.DocumentId, // TO DO: make sure that document has this prop, not the DocId
            //TargetGuid: document.DocumentGuid // when doc id = 0, we can use the doc guid
        }        
    };
    // create the data properties only if the value exists
    if (document.DocumentId)
        task.ajax.data.TargetId = document.DocumentId

    if (document.DocumentGuid)
        task.ajax.data.TargetGuid = document.DocumentGuid

    if (document.ExtractedLicense)
        task.ajax.data.TargetValue = document.ExtractedLicense

    app.taskProcessor.push(task);
}

// this function is called when we click on a document from the documents list:
const onDocumentInfoLoaded = (task) => {

    console.log('onDocumentInfoLoaded : ', task);
    let app = window.app;

    // process the result
    let result = task.result;
    if (!result) return; // ?? TO DO: show a error message?

    let type = task.type;
    //task.info = 'ok';
    let documentInfo = eval(result);

    //documentInfo.DocumentDetails = null; // for tests only!

    if (!documentInfo) {
        task.hasError = true;
    }
    else if (!documentInfo.DocumentDetails) {
        // we should render information abut the lack of results!
        task.hasError = true;
        task.error = { error_code: ResultCode.DocumentInfoNotFound }; // fill it with error code that will translate into a proper message
        // abort if has error
        return;
    }
    // FOR TESTS ONLY !!!
    //documentInfo.IsUserBlocked = true;

    //processUserPermissions(app, documentInfo, task);
    let permissions = documentInfo.UserPermissions;
    if (permissions) {
        //console.log('permissions loaded: ', permissions);
        // check the user permissions:        
        if (permissions.AccessPermissions < 1) {
            task.hasError = true;
            if ((documentInfo.IsPolicyNonPersistent || documentInfo.IsPolicyDeleted)) {
                task.error = { error_code: ResultCode.OperationNotSupported }; // fill it with error code that will translate into a proper message
            }
            else {
                task.error = { error_code: ResultCode.YouHaveNoRights }; // fill it with error code that will translate into a proper message
            }
            
            //return; // do not return: instead go to the doc. info and show only the 'protected by'/'protection date'/'protection name'
        }
    }
    if (documentInfo.IsUserBlocked) {
        task.hasError = true;
        task.error = { error_code: ResultCode.YouHaveNoRights }; // fill it with error code that will translate into a proper message
    }
    // store the doc info and the task so we can modify its state later
    app.store.setState({ documentInfo, documentInfoTask: task });    

    // remove the task from notification list if no errors, but keep the error ones visible so user can react to it and close it
    if (!task.hasError)
        window.app.taskProcessor.closeTask(null, task);

    // ADD USERS TASK:
    if (task.type == RequestType.AddUsers) {
        //app.store.state.addUsersTask = task; --> documentInfoTask is already set, it should be used when needed
        //initPolicyToAddUsers();
    }
    // DOCUMENT INFO TASK:
    else {
        // set the document type used in filter to the Doc. Info (the first checkbox), as it could be set to WARNINGS or ACCESSESS
        app.store.setOption({ section: 'document', option: 'type', value: RequestType.DocumentInfo });
       
        // if doc id = 0, navigate to a doc info page with an id that we just obtained from server by using guid
        // (not the most direct way but it will allow to refresh the browser, as it URL path is using the doc id)
        if (app.store.state.documentId === 0 && documentInfo.DocumentDetails.DocId > 0) {
            app.history.push(routes.documents.info(documentInfo.DocumentDetails.DocId));
        }
    }    
}

function initPolicyToAddUsers() {

    //let app = window.app;
    //const policy = app.store.state.documentInfoPolicy;
    //const task = app.store.state.documentInfoTask;
    //console.log('onDocumentInfoLoaded: AddUsers mode. Policy: ', policy);
    //if (policy) {
    //    app.store.unsubscribe({ property: 'documentInfoPolicy', onComplete: initPolicyToAddUsers }); // just in case
    //    // !!! instead of task we put task.type when calling editPolicy method
    //    //editPolicy(null, policy, task); // --> this will open the edit policy page
    //    editPolicy(null, policy, RequestType.AddUsers); // --> this will open the edit policy page
    //}
    //else {
    //    // subscribe for the policy:
    //    app.store.subscribe({ property: 'documentInfoPolicy', onComplete: initPolicyToAddUsers });
    //}
}

export const lockDocument = (app, documentDetails) => {

    //console.log('lock document: ', documentDetails, documentDetails.IsLocked);

    // prepare the 'task' object
    let task = {
        documentDetails,
        type: RequestType.DocumentInfo,
        onComplete: onDocumentLocked
    };

    task.ajax = { // create the request object for HTTP client
        url: api().document.lock(),
        headers: {},
        data: {           
            TargetId: documentDetails.DocId,
            TargetBoolean: !documentDetails.IsLocked
        }
    };

    app.taskProcessor.push(task);
}


const onDocumentLocked = (task) => {

    //console.log('task.result  ====  ', task.result);

    let app = window.app;
    let result = task.result;

    let type = task.type;
    //task.info = 'ok';

    // update the document.IsLocked state
    // TODO: the server has to return the new state, if not, don't modify anything 
    task.documentDetails.IsLocked = !task.documentDetails.IsLocked;

    app.store.setState({ documentInfo: app.store.state.documentInfo });
}
//---------------------------------------------------------

export const loadDocumentTracking = (app, document) => {

    //console.log('load document tracking');  

    // prepare the 'task' object
    let task = {
        app: app,
        type: RequestType.DocumentTracking,
        onComplete: onDocumentTrackingLoaded        
    };

    task.ajax = { // create the request object for HTTP client       
        url: api().document.tracking(),
        headers: {},
        data: {
            TargetId: document.DocumentId // TO DO: make sure that document has this prop, not the DocId
        }
    };

    app.taskProcessor.push(task);
}
// tracking model:
// [{"AccessedBy":"Adspadmin <adspadmin@lab.com>","UserId":1,"Date":"2019-05-17T14:05:13.017","IP":"172.22.115.161","UserType":1}]
const onDocumentTrackingLoaded = (task) => {

    //console.log('on document tracking loaded: ', task.result);

    let app = window.app;
    let result = task.result;

    let type = task.type;
    //task.info = 'ok';
    let documentTracking = eval(result);

    // find the policy used to protect this document by Guid:
    if (documentTracking) {
        app.store.setState({ documentTracking });
    }
    else {
        app.store.setState({ documentTracking: [] });
    }
}

//-------------------------------------------------------------------

export const loadDocumentWarnings = (app, document, filter) => {

    //console.log('load document warnings filter = ', filter);

    // prepare the 'task' object
    let task = {
        app: app,
        type: RequestType.DocumentWarnings,
        onComplete: onDocumentWarningsLoaded,
        filter: filter        
    };

    task.ajax = { // create the request object for HTTP client
        url: api().document.warnings(),
        data: {
            TargetId: document.DocumentId // TO DO: make sure that document has this prop, not the DocId
        }
    };

    app.taskProcessor.push(task);
}

const onDocumentWarningsLoaded = (task) => {

    //<b: WarningInfo>
    //<b: AdditionalInfo/>
    //<b: DateTime>2018-11-30T14:20:16.083</b: DateTime >
    //<b: DocumentId>2332593</b: DocumentId >
    //<b: DocumentName>powerpoint-prubea.pptx</b: DocumentName >
    //<b: DocumentTypeId>34</b: DocumentTypeId >
    //<b: FileTypeId>0</b: FileTypeId >
    //<b: Id>229793804</b: Id >
    //<b: UserFullName>Daniel Marcin &lt;daniel.marcin@sealpath.com&gt;</b: UserFullName >
    //<b: WarningType>128</b: WarningType >
    //</b: WarningInfo >

    //console.log('on document warnings loaded: ', task.result);

    let app = window.app;
    let result = task.result;

    let type = task.type;
    //task.info = 'ok';
    let documentWarnings = eval(result);

    // mock of model of WarningInfo
    documentWarnings = [
        {
            AdditionalInfo: '',
            DateTime: '2018-11-30T14:20:16.083',
            DocumentId: 2332593,
            DocumentName: 'powerpoint-prubea1.pptx',
            DocumentTypeId: 34,
            FileTypeId: 0,
            Id: 229793804,
            UserFullName: 'Daniel Marcin <daniel.marcin@sealpath.com>',
            WarningType: 128
        },
        {
            AdditionalInfo: 'Some info',
            DateTime: '2018-12-15T14:20:16.083',
            DocumentId: 233259398,
            DocumentName: 'powerpoint-prubea2.pptx',
            DocumentTypeId: 34,
            FileTypeId: 0,
            Id: 229793805,
            UserFullName: 'Daniel Marcin <daniel.marcin@sealpath.com>',
            WarningType: 128
        },
        {
            AdditionalInfo: '',
            DateTime: '2018-12-30T14:20:16.083',
            DocumentId: 233259399,
            DocumentName: 'powerpoint-prubea3.pptx',
            DocumentTypeId: 34,
            FileTypeId: 0,
            Id: 229793806,
            UserFullName: 'Daniel Marcin <daniel.marcin@sealpath.com>',
            WarningType: 128
        },
    ];

    if (documentWarnings) {
        app.store.setState({ documentWarnings });
    }
    else {
        app.store.setState({ documentWarnings: [] });
    }
}

//-------------------------------------------------------------------

export function isDocumentOwner(app, documentDetails) {

    if (documentDetails) {
        const ownerId = documentDetails.OwnerId;
        if (app && app.user && app.user.Id === ownerId) {
            return true;
        }
    }
    return false;
}     


// sorts the policies: alphabetically and by favourite option (favs go first) 
export function sortDocuments(items) {
    //console.log('sortDocuments')
    items.sort((a, b) => a.name.localeCompare(b.name));    
    //console.log('ordered documents: ', items);
}

