import React, { Component } from 'react';


export class RadioButtonsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: this.props.items || [],
            selectedItemId: this.props.selectedItemId || this.props.items[0].id
        }
    }

    selectItem = (e, item) => {

        e.stopPropagation();

        const { selectedItemId, items } = this.state;
        const { onChange } = this.props;

        // unselect items
        items.map((i) => { i.selected = false })

        // select the current item (RadioButtonsList always have at least one item selected)
        item.selected = true;
        this.setState({ selectedItemId: item.id });    
        
        // make a callback if a new item is selected
        if (selectedItemId !== item.id) onChange(item);
    }

    render() {
        const { selectedItemId, items } = this.state;

        let containerClassName = this.props.className ? this.props.className : '';
        return (
            <div className={containerClassName}>
                {items.map(item => (
                    <div className={`radio `} key={`redio-buttons-${item.id}`} onClick={(e) => this.selectItem(e, item)}>
                        <div className="button small">
                            <div
                                className={`'growing icon ${item.selected || selectedItemId === item.id
                                    ? 'icon-checked'
                                    : 'icon-unchecked'}`}>
                            </div>
                        </div>
                        <div className='label'>{item.label}</div>
                    </div>
                ))}
            </div>
        )
    }
}