
import * as appManager from './appManager'; // TO DO: import only used functions here 
import { findSelectedItems, setOptionError } from './appManager'; 
import { isDocumentOwner } from './documentManager'; 
import { getLocalDocumentInfo, prepareFileTaskForLaunch, pushFileTask, onFilesDrop } from './fileManager'; // TO DO: import only used functions here 

import { api } from '../common/api';
import { routes } from "../common/routes"; 
import { AccessPermissionType, PolicyType, RequestType } from "../common/constants"; 
import { findUserById, loadUserData } from './userManager';
import { createExternalFileForGoogleDriveDocument } from './storage/googleDrive';

export const toggleFavourite = (e, policy) => {

    if (e) e.stopPropagation();
    const app = window.app;
    policy.Fav = !policy.Fav;
    //appManager.saveUserSettings(); // store the new policies' fav levels to the local storage (if enabled)
    setFavouritePolicy(policy); // this will call the API to save this value in the DB
    app.store.setState({ policies: app.store.state.policies });
}

export const setFavouritePolicy = (policy) => {

    let app = window.app;
    let type = RequestType.SetFavouritePolicy;
    let task = {        
        type: type,
        onComplete: onSetFavouritePolicyCompleted,
    };

    task.ajax = { // create the request object for HTTP client
        url: api().policy.setFavouritePolicy(),
        data: {
            TargetId: policy.Id,
            TargetBoolean: policy.Fav,
        }
    };

    app.taskProcessor.push(task);
}

const onSetFavouritePolicyCompleted = (task) => {
    // for now ignore the errors here
    // TO DO: render a error message if sth went wrong
}

//********************* edit policy ***************************

// the policy could be a simple model selected from the list,
// or the full model sent from DocumentInfo - the former one is using RightsPolicyId

export const editPolicy = (e, app, /* full policy */ policy, /* simple policy */ simplePolicy, type, /* is new policy */ isNewPolicy) => {
    //console.log('edit policy. policy: ', policy);
    //console.log('edit policy. simplePolicy: ', simplePolicy);
    if (e) { e.stopPropagation(); }  

    // when navigationg: reset the reference to the original and to the copy
    app.store.state.policy = null;
    app.store.state.policyCopy = null;
    //storeState.policy = null;
    //storeState.policyCopy = null;
    // set the task type ('none' or 'custom protection'/'add users')
    app.store.state.delayedTaskType = type;

    // The policy id
    let policyId = 0; // default value, this id will be detected in EditPolicy render method to create a new policy 

    if (type === RequestType.CustomProtection || type === RequestType.AddUsers) {
        policyId = -1; // need to differentiate it from new policies (id = 0), so on refresh, as all references will be gone, we won't stay on the edit policy page but rather will be kicked back to home page
    }

    // Option A: (not implemented now)
    // we have clicked the inner item button, and the 'simple policy' reference is passed here

    // Option B:
    // we have clicked the policy of the document info - currently the complete model is passed here from Document Info BUT with empty usrs list.
    // we only get the policy 'id' from there, we reset the current 'policy' and 'policyCopy' models in store, and then load the complete policy from the server

    // Option C:
    // we have clicked the context menu button, and we have to find a reference to the
    // currently selected simple policy (there should be only one, so we will grab the first):

    // no reference is passed, so get the selected one from the simple policies list
    if (!policy && !simplePolicy && !isNewPolicy) {

        const items = appManager.findSelectedItems(app.store.state.policies);
        if (items.length) {
            simplePolicy = items[0];
        }
    }
    //console.log('edit policy. simplePolicy: ', simplePolicy);
    // get policy id  
    if (policy) {
        policyId = policy.RightsPolicyId; // full model   
        
        // reset the references so the model will be loaded by the EditPolicy page after navigating there
        app.store.setState({ policy: null, policyCopy: null });                     
    }
    else if (simplePolicy) {
        policyId = simplePolicy.Id; // simple model
    }
    //console.log('edit policy. pushing page... ');
    // show edition page
    app.history.push(routes.policies.edit(policyId));
};

// ADD USERS:
//
// Option 1: regular policy:
// - get the policy data
// - check if user has the 'add users' right?
//   yes: edit that policy (full control) or only the rights (add users right), send the new policy model (or just the rights information) to the server, notify the changed policy to the Protector API
//   no: show error dialog
//
// Option 2A (for now): custom policy
// show info that the policy is custom and that it cannot be modified
//
// Option 2B (TODO): custom policy
// obtain the user rights from PL, from server
// - check if user has the 'add users' right?
//   yes: edit that policy (full control) or only the rights (add users right), send the new custom policy model to the Protector API to reprotect the document
//   no: show error dialog

export const addUsersToPolicy = (e, policy, task, isNewPolicy) => {

    //console.log('addUsersToPolicy');

    if (e) { e.stopPropagation(); }

    const app = window.app;


    //let policyId = 0; // default value, this id will detected in EditPolicy render method to create a new policy 
    //// store the reference to the current task, so we continue with it when edition is done
    //if (task && task.type == RequestType.AddUsers) {
    //    app.store.state.currentTask = task; // TODO: change the param name in this assignement to relate it with its purpose 
    //    policyId = -1;
    //}
    
    getLocalDocumentInfo(task);

    return; // --> when document info is loaded, it will redirect to the editPolicy method if user has rights

    //// Option A: (not implemented now)
    //// we have clicked the inner item button, and the policy reference is passed here 
    //// Option B: (current mode)
    //// we have clicked the context menu button, and we have to find a reference to the 
    //// currently selected policy (there should be only one, so we will grab the first):
    //if (!policy && !isNewPolicy) {
    //    const items = appManager.findSelectedItems(app.store.state.policies);
    //    if (items.length) {
    //        policy = items[0];
    //    }
    //}
    //// reset the reference to the copy, so next time we enter the edit page it will be created again.
    //// we control the copy creation in 'EditPolicy.render' function so it works well when page is refreshed
    //// in browser and the references are lost
    //app.store.state.policyCopy = null;

    //// get policy id and show edition page 
    
    //if (policy) {
    //    policyId = policy.RightsPolicyId;
    //}

    //app.history.push(routes.policies.edit(policyId));
};

export const policyClickedInDocumentInfo = (e, app, component, documentInfo) => {
    //console.log("policyClickedInDocumentInfo. component: ", component);
    if (e) e.stopPropagation();
    if (documentInfo.IsPolicyDeleted) { // show the recover policy modal with (yes/no)
        //window.alert("Restore policy ...");
        //console.log("Restore policy modal...");
        component.showRestorePolicy();
    }
    else { // go to policy edit page
        // TODO : check if we should find the policy in our store using the guid (seems like Protection SDK doesn't return a policy id with document info!)
        //let policy = policyManager.findPolicy(policy.guid);
        editPolicy(e, app, /* full policy */ documentInfo.Policy, /* simple policy */ null, RequestType.None, /* is new policy */ false);
    }
}

export const restorePolicy = (e, component, policy, documentInfo) => {
    //console.log("restorePolicy. component: ", component);
    e.stopPropagation();

    let app = window.app;
    //app.store.state.policy = policy;

    // error-proof formatting:
    if (policy)
        if (!policy.Description || policy.Description === '')
            policy.Description = app.R.RestoredPolicy;


    let type = RequestType.RestorePolicy;
    let task = {
        component,
        documentInfo,
        policy,
        type: type,
        onComplete: onRestorePolicyCompleted,
    };

    task.ajax = { // create the request object for HTTP client       
        url: api().policy.restore(),
        data: {
            //Policy: policy, // before, for recovering we would send the whole policy, but the server endpoint will only use its GUID, and then will restore the DB model with 1 author permission
            TargetGuid: policy.Guid // now we only send the GUID, the policy will be automatically restored with 1 user permission: for the author (IsAuthor = true, with Full Control)
        }
    };

    app.taskProcessor.push(task);

}

export const onRestorePolicyCompleted = (task) => {

    const app = window.app;

    if (task.hasError) {
        task.component.onRestorePolicyError(task);
    }
    else {
        //task.info = 'ok';

        const policy = task.policy;
        if (policy) {
            // After recovery the RightsPolicyId will be different then before removal - 
            // the client will have to update it:
            policy.RightsPolicyId = task.result.RightsPolicyId;

            // update the document info
            const documentInfo = task.documentInfo;
            if (documentInfo) {
                documentInfo.IsPolicyDeleted = false;
                app.store.setState({ documentInfo });
            }
            else {
                task.hasError = true;
            }

            // add it to the policies list
            const policies = app.store.state.policies;
            if (policies) {
                // double check to avoid adding it twice:
                let found = false;
                policies.map(p => {
                    if (p.RightsPolicyId === policy.RightsPolicyId) {
                        found = true;
                    }
                    return true;
                });
                if (!found) {
                    policies.push(policy);
                    //app.store.setState({ policies }); // this calls triggers a complete re-render and user data is loaded again!
                }
            }
        }
        else {
            task.hasError = true;
        }

        //task.hasError = true; // FOR TESTS ONLY !!!

        //console.log('onRestorePolicyCompleted. calling modal. task.component: ', task.component)
        if (task.component) {
            if (task.hasError) {
                task.component.showRestorePolicyError(task);
            }
            else {
                // inform the component that the dialog panel can be closed                
                task.component.onRestorePolicyCompleted(task);
            }
        }      
           
    }    
}
export const findPolicy = (guid) => {

    let policy = null;
    let items = window.app.store.state.policies;
    if (items) {
        let length = items.length;
        let i;
        for (i = 0; i < length; i++) {
            if (items[i].Guid === guid) {
                policy = items[i];
                break;
            }
        }
    }
    return policy;
}

//export const addPolicy = (e, component) => {

//    e.stopPropagation();

//    let app = window.app;
//    let policy = getNewPolicy();
//    app.store.state.policy = policy;
//    editPolicy(e, policy); // we pass a refererence to the new policy model: 
//    // mind it's not yet on the policies list: when we confirm the edition,
//    // it will have to be added there!    
//}

export const getNewPolicy = () => {

    let app = window.app;
    const storeState = app.store.state;    
    const user = app.user;
    let policy = {
        Guid: '00000000-0000-0000-0000-000000000000',
        Name: '', //app.R.NewProtection, // placeholder name,
        Description: '', //'description',
        RightsPolicyId: 0, // assigned by the server
        Users: [], // user access permissions
        OrganizationId: 0, // assigned by the server, using user's organization id, so it can't be hacked to add policies to other organizations
        IsFavourite: false,
        IsCustomProtection: false,
        IsCorporative: false,
        IsContentExpirationActive: false,
        IsOfflineAccessActive: false,
        OfflineAccessDays: 5,
        Watermark: false
    };
  
    const permission = createNewPermission(null, user);
    permission.AccessPermissions = 1; // full control
    policy.Users.push(permission);

    return policy;
}

export const loadPolicy = (id) => {

    loadUserData(RequestType.UserPolicy, { id });
}

export const addPermission = (e, component) => {

    if (e) e.stopPropagation();

    let permission = getEmptyPermission();
    
    window.app.store.state.permission = null; // for new permission model we don't have the original one, so set a null reference
    window.app.store.state.permissionCopy = permission;
    
    appManager.goToPermissionsPage();
}
//********************* save policy ***************************

// original method from SP Desktop (ProtectionManager):

/// <summary>
/// Lanza un Background Wroker que guarda una política.
/// </summary>
/// <param name="rightsPolicy">Política.</param>
/// <param name="isEdit">Indica si es una política en edición.</param>
//[System.Diagnostics.CodeAnalysis.SuppressMessage("Microsoft.Reliability", "CA2000:Dispose objects before losing scope", Justification = "Dispose is correctly done in CloseConnection. Also in lambda expression, when adding objects to a Collection we cannot call Dispose")]
//internal static void SaveRightsPolicy(RightsPolicy rightsPolicy, bool isEdit, bool isRecover, DocumentInformation documentInfo, RunWorkerCompletedEventHandler workerCompleted)
//{
//    BackgroundWorker bw = new BackgroundWorker();
//    bw.WorkerReportsProgress = true;
//    bw.DoWork += new DoWorkEventHandler((sender, e) => {
//        SavePolicyArgument arg = e.Argument as SavePolicyArgument;
//        // En el caso de establecerse fecha de expiración, seguimos adelante sólo si es válida
//        if (ValidateRightsPolicy(arg.RightsPolicy, false)) {
//            using(DesktopServiceClient dsc = ConfigureDesktopService.Configure())
//            // using (DesktopServiceClient dsc = new DesktopServiceClient())
//            {
//                if (arg.IsEdit) {
//                    dsc.UpdatePolicy(arg.RightsPolicy);
//                    //TODO: esto no tiene sentido
//                    if (arg.DocumentInformation != null) {
//                        dsc.ReportWarning(arg.DocumentInformation, WarningType.UserAddedInProtection);
//                    }
//                }
//                else if (arg.IsRecover) {
//                    dsc.RecoverPolicy(arg.RightsPolicy);

//                    //TODO: esto no tiene sentido
//                    if (arg.DocumentInformation != null) {
//                        dsc.ReportWarning(arg.DocumentInformation, WarningType.UserAddedInProtection);
//                        arg.DocumentInformation.IsDeletedRightsPolicy = false;
//                        arg.DocumentInformation.IsRightsPolicyInLocalCache = true;
//                    }
//                }
//                else {
//                    e.Result = dsc.CreatePolicy(arg.RightsPolicy);
//                }
//            }
//        }
//    });
//    if (workerCompleted != null) {
//        bw.RunWorkerCompleted += new RunWorkerCompletedEventHandler(workerCompleted);
//    }

//    bw.RunWorkerAsync(new SavePolicyArgument() { RightsPolicy = rightsPolicy, IsEdit = isEdit, IsRecover = isRecover, DocumentInformation = documentInfo });
//}

// used for selecting a policy for protecting external files
export const unprotectSelectedDocuments = (e, component, parentTask) => {

    const tool = { action: "UnprotectExternal" };
    //console.log("onSelectPolicyConfirmed");
    if (e) e.stopPropagation();

    const app = window.app;
    const state = app.store.state;
    
    console.log('unprotecting the following external files');
    const selectedDocuments = app.store.state.selectedDocuments;
    if (selectedDocuments && selectedDocuments.length > 0) {
        selectedDocuments.map((doc, i) => {
            console.log('document ' + i + ': ', doc);
        });
        selectedDocuments.map((doc, i) => {
            console.log('document ' + i + ': ', doc);
            let externalFile = {
                AccessToken: app.auth.tokens.googleDriveAccessToken,
                //RefreshToken: app.auth.tokens.googleDriveRefreshToken,
                Type: 'GoogleDrive', // TO DO: set it at the begining, as the 'parentTask' or 'currentTask' property,
                GoogleDriveFile: doc
            }
            onFilesDrop(app,
                /* acceptedFiles */[],
                /* rejectedFiles */[],
                /* tool */ { action: "UnprotectExternal" },
                /* externalFiles*/[externalFile]
            );
        });
    }    
}

// Called from Policies.js page when Policies component works as a selector of a
// policy for protecting external files, after clicking the Protect (OK)? button.
export const protectSelectedDocuments = (e, component, parentTask) => {

    //console.log("onSelectPolicyConfirmed");
    if (e) e.stopPropagation();

    const app = window.app;
    const state = app.store.state;
    let simplePolicy;
    const selectedItems = findSelectedItems(state.policies);
    if (selectedItems) {
        if (selectedItems.length > 0) {
            simplePolicy = selectedItems[0];
        }
    }
     
    if (!simplePolicy) {
        // error alert
        // ... this shouldn't happen really
        return true;
    }
    console.log('protecting the following files with the policy: ', simplePolicy);

    // prepare the 'tool' object to be used with this operation
    const tool = { action: "ProtectExternal", policy: simplePolicy };


    const documents = app.store.state.selectedDocuments;
    // call the innert method to preapre the doc models before sending the
    // requests to the universal 'onFilesDrop' method of the file manager.
    processSelectedDocuments(app, tool, documents, parentTask);
}

// Inner method used from both protecting and unprotecting methods (above)
export const processSelectedDocuments = (app, tool, documents, parentTask) => {

    // reference to the current storage provider
    const provider = app.storageProvider;

    if (documents && documents.length > 0) {
        //selectedDocuments.map((doc, i) => {
        //    console.log('document ' + i + ': ', doc);

        //});
        documents.map((doc, i) => {

            console.log('document ' + i + ': ', doc);

            let externalFile = {};

            if (provider.id === 'googledrive') {
                // in case doc is a folder - get the folder's items, recursively, to get subfolder items too

                // a folder could have it's own task and the items will be subtasks.

                // --> refactor onFilesDrop so it call an inner method for adding a single to from the queue
                // , then, when the queue will launch the task, the folder items will be obtained nad the tasks created and "inserted", or rather rendered below the node of the folder task 

                // Prepare the model used in client-server communication.
                // This model will be deserialized on server using the ExternalFile class.                
                externalFile = createExternalFileForGoogleDriveDocument(app, doc); // defined in googleDrive.js file
            }
            else if (provider.id === 'onedrive') {
                //externalFile = createExternalFileForOneDrive(); // defined in oneDrive.js file
            }
            
            //onFilesDrop(app,
            //    /* acceptedFiles */[],
            //    /* rejectedFiles */[],
            //    /* tool */ tool,
            //    /* externalFiles*/[externalFile],
            //    provider
            //);
            var task = { provider, externalFile, tool };

            if (parentTask) {
                // parent task is a folder task here: all folder items (subfolders and files) are converted to subtasks.
                // when the parent task is provided, the new task created here will be a child task.
                // Question: The task processor will have the child tasks inserted into the queue,
                // or rather should process children automatically, without the need of inserting it on the list -->
                // the second option would require an alternative way of adding the children tasks to the display list -->
                // maybe the display list should render the children tasks automatically with full recursive? (then
                // the tasks shouldnot' be added with the call 'onFileDrop', or that methods should be refactored? --> 'initializeFileTask' method created)
                var task = { provider, externalFile, tool, parent: parentTask };
                if (!parentTask.tasks) { parentTask.tasks = []; }
                parentTask.tasks.push(task);

                // prepare the task
                prepareFileTaskForLaunch(app, task);
                // 'pushFileTask' function either do:
                // 1. adds the task to delayed tasks(tasks that frst need a result of other operation or request)
                // 2. or calls the 'task processor.push' that initializes the task before the launch and adds it
                // the task processor list (only when the task is not a "sub-task" and does not have a parent).
                pushFileTask(task);
            }
            else {
                var task = { provider, externalFile, tool };
                //var task = { file: file, app: app, type: type, tool: tool};     

                // prepare the task
                prepareFileTaskForLaunch(app, task);
                // 'pushFileTask' function either do:
                // 1. adds the task to delayed tasks(tasks that frst need a result of other operation or request)
                // 2. or calls the 'task processor.push' that initializes the task before the launch and adds it
                // the task processor list (only when the task is not a "sub-task" and does not have a parent).
                pushFileTask(task);
            }
        });
    }
}

export const onEditPolicyConfirmed = (e, component) => {

    //console.log("onEditPolicyConfirmed");
    if (e) e.stopPropagation();

    const app = window.app;
    const state = app.store.state;
    const policy = state.policyCopy;
    if (!policy) {
        // error alert
        // ... this shouldn't happen really
        return true;
    }
    //let hasChanges = component.checkIfPolicyChanged();
    //if (!hasChanges) { return }

    //if (policy.OrganizationId < 1) { --> the server will assign the organization id to not have it hacked
    //    policy.OrganizationId = app.user.OrganizationId;
    //}

    // check if we are editing a custom policy?
    const delayedTaskType = state.delayedTaskType;

    if (delayedTaskType === RequestType.CustomProtection) {
        // pre-format the policy name and description before validating, otherwise the fields are empty
        policy.Name = app.R.CustomProtection;
        policy.Description = app.R.CustomProtection;
    }
    
            
    if (!canFinishWizard(policy, component)) {
        // render errors
        //console.log('EDIT ERROR: property is missing');
        // set the component state to force the rendering of errors
        component.setState({ filter: component.filter });
        return false;
    }

    // VARIANT 1: check if we are editing a custom policy?

    if (delayedTaskType === RequestType.CustomProtection) {
        // variant 1: the policy stays as a custom policy (user didn't checked the 'convert to regular policy' button)
        if (true) {            
            state.delayedTasks.map(task => {
                task.policy = policy; // assign the custom policy to this task
                //currentTask.ajax.data = taskManager.convertTaskToFormData(currentTask); --> do not convert it here, first we will call 'isauthorized' endpoint and then the file and policy will be sent
                //app.taskProcessor.push(task);
                app.taskProcessor.push(task);
                return true;
            })
           
            // TODO: this is a quirky fix to avoid interference: the delayed tasks could be resolved in a
            // different manner, using parent task reference, or their proper type for execution, rather 
            // than some global 'state.delayedTaskType' (below)

             // clean the task type, so it won't interfere later when other policy operations are executed:
            state.delayedTaskType = RequestType.None;

            // navigate back
            if (app.history) { app.history.goBack() }
            return true;
        }
        // variant 2: user did checked the 'convert to regular policy' button
        else {
            // step 1: save the policy to the DB and wait for the response with the policy GUID
            // then, from callback, call 'Protect' sending the policy GUID, not the custom policy model, as the protection is not custom anymore
            // ...
            
            // navigate back
            if (app.history) { app.history.goBack() }
            return true;
        }
    }
    else if (delayedTaskType === RequestType.AddUsers) {
        // 'Add Users' flow for persistent policies:
        // 1. Send the updated policy to the Data API
        // 2. Notify the Protection API that the policy has changed

        // 'Add Users' flow for non-persistent policies:
        // 1. Send the file to the Protection API to be re-protected

        //let task = {
        //    type: RequestType.EditPolicy,
        //    onComplete: onEditPolicyCompleted,
        //};
        //task.ajax = { // create the request object for HTTP client
        //    url: api().policy.updatePermissions(),
        //    data: {
        //        //TargetValue: JSON.stringify(state.userDetails), //serialize user permissions here,
        //    }
        //};
        //app.taskProcessor.push(currentTask);

        // clean the task type, so it won't interfere later when other policy operations are executed
        //state.delayedTaskType = RequestType.None;

        //// navigate back 
        //if (app.history) { app.history.goBack() }
        //return true;
    }
    else {

        // save the policy on server
        let task = {
            type: RequestType.EditPolicy,
            onComplete: onEditPolicyCompleted,
        };

        task.ajax = { // create the request object for HTTP client
            url: api().policy.editPolicy(),
            data: {
                Policy: policy
            }
        };

        // HACK
        // for creating a big number of policies for development tests:
        // add fake users
        const createFakePolicies = false;
        if (createFakePolicies) {
            for (let i = 0; i < 50; i++) {

                var permission = getEmptyPermission();
                permission.UserName = 'daniel.marcin.fake' + i + '@sealpath.com';
                policy.Users.push(permission);
            }
            // multiply the policy
            for (let i = 0; i < 300; i++) {
                app.taskProcessor.push(task);
            }
        }        

        app.taskProcessor.push(task);        
    }    
}

// copied from SP Desktop (PolicyWizardViewModel)
export const canFinishWizard = (policy, component) => {

    //console.log('canFinishWizard?');
    let hasError = false;

    const options = component.policyOptions;    
    // validate the form
    // we keep id equal to 0 for edition path, but on server it should be negative for a new policy
    //if (policy.RightsPolicyId == 0) policy.RightsPolicyId = -1; 

    // fix an empty name (until we start to validate that field and force adding some text there)
    if (!policy.Name || policy.Name === '') {
        setOptionError(options, 'name', true);
        hasError = true;
    }
    else {
        setOptionError(options, 'name', false);
    }

    // fix an empty description (until we start to validate that field and force adding some text there)
    if (!policy.Description || policy.Description === '') {
        setOptionError(options, 'description', true);
        hasError = true;
    }
    else {
        setOptionError(options, 'description', false);
    }

    if (policy.IsContentExpirationActive) {
        if (!policy.ContentExpirationEndDate || policy.ContentExpirationEndDate === '') {
            setOptionError(options, 'expiration', true);
            hasError = true;            
        }
        else {
            // validate the date
            // ..
            // if (ok) 
            //component.policyOptions[0].error = false;
            setOptionError(options, 'expiration', false);
        }
    }

    if (policy.IsOfflineAccessActive) {
        if (!policy.OfflineAccessDays || policy.OfflineAccessDays === '') {
            // should set a day count!
            
            setOptionError(options, 'offline_access', true);
            hasError = true;
        }
        else {
            // validate the date
            // ..
            // if (ok)
            //component.policyOptions[1].error = false;
            setOptionError(options, 'offline_access', false);
        }
    }
    else { // for disabled offline access make sure that the offline days is not a string or null! 
        if (!policy.OfflineAccessDays || policy.OfflineAccessDays === '') policy.OfflineAccessDays = 5;
    }

    return !hasError;

    //return (policy != null
    //    && policy.Name && policy.Description && policy.Users.length > 0
    //    && (policy.IsContentExpirationActive == false || (policy.IsContentExpirationActive && policy.ContentExpirationEndDate.HasValue))
    //    && (policy.IsOfflineAccessActive == false || (policy.IsOfflineAccessActive && (policy.OfflineAccessDays > 0 && policy.OfflineAccessDays <= 365))));
    //DHA 20/05/2015: Modificada la limitacion de 30 dias de OfflineAccessDays en la validacio del guardado de la politica, pasandolo el limite a 365 dias(1 año). Tambien 
    //he modificado la propiedad Maximum del control RadNumericUpDown que esta en PolicyOptionsPageView.xaml para permitir llegar a la misma cifra de 365 dias(1 año)
}

export const onEditPolicyCompleted = (task) => {

    //console.log('edit policy completed: ');
    let app = window.app;
    const policy = app.store.state.policyCopy;
    const state = app.store.state;

    if (task.result.error) {
        task.component.onEditPolicyError();
        return;
    }
    else {
        // server should return the new policy id, guid and org id:
        policy.RightsPolicyId = task.result.RightsPolicyId;
        policy.Guid = task.result.Guid;
        policy.OrganizationId = task.result.OrganizationId;
        
        //task.component.onEditPolicyCompleted();
    }

    // replace the original policy in the policies list for the edited copy
    let found = false;
    let policies = state.policies;
    if (policies !== undefined && policies !== null) {
        for (let i = 0; i < policies.length; i++) {
            let simplePolicy = policies[i];
            if (simplePolicy.Guid === policy.Guid) {
                found = true;
                // create new simple model: 
                simplePolicy = makeSimplePolicy(policy);
                // update the reference on the simple policies list
                policies[i] = simplePolicy;
            }
        }
    }
   
    if (!found) { // this has to be a new policy; add it to the list
        const simplePolicy = makeSimplePolicy(policy);
        //initializeSimplePolicy(simplePolicy);
        //console.log('adding a new policy to the list: before: ', policies)
        policies.push(simplePolicy);
        //console.log('adding a new policy to the list: after: ', policies)
    }

    // for policy edited from Document Info and that are not on the policies
    // list: update the DocumentInfo.Policy so we can go back and
    if (state.documentInfo) {
        const documentPolicy = state.documentInfo.Policy;
        if (documentPolicy) {
            if (documentPolicy.RightsPolicyId === policy.RightsPolicyId) {
                // we have edited the doc. info policy
                state.documentInfo.Policy = policy;
            }
        }
    }

    //console.log('user permission: ');
    // add new guest users to the contact list
    policy.Users.map(u => {
        //console.log(u);
        if (u.IsGuest) {
            
            // look for this one in the contacts
            let selected = app.store.state.users.filter(u2 => u2.U == u.U);
            if (selected && selected.length) {
                // user is found
            } else {
                // this is a new user: add him
                //console.log('add new user to contacts list');
                app.store.state.users.push({ U: u.U, IsGuest: true, UserIdFather: app.user.Id });
            }
        }
        return true;
    });

    notifyUpdatedPolicy(policy);

    app.store.setState({ policies }); // do it so other components that are subscribed to policies can update their state

    // reset the reference to the original and the copy
    // FOR AUTOMATIC SAVE DO NOT RESET IT (as it will get lost when we go to the permission page _ find a better workaround and flow for automatic save )
    //app.store.state.policy = null;
    //app.store.state.policyCopy = null;    

    // navigate back 
    if (app.history) { app.history.goBack() }

}

function notifyUpdatedPolicy(policy) {

    // inform the file server that the policy has been updated (deprecated)
    //if (!policy.IsCustomProtection) {
    // notify updated policy always, for the future persistence of custom protections and removed policies
        let task = {
            type: RequestType.NotifyUpdatedPolicy,
            onComplete: onNotifyUpdatedPolicyCompleted
    };

        task.ajax = { // create the request object for HTTP client
            url: api().file.notifyUpdatedPolicy(),
            data: {
                TargetGuid: policy.Guid
            }
        };
        window.app.taskProcessor.push(task);
    //}
}


export const onNotifyUpdatedPolicyCompleted = (task) => {

   // the file server has been notified - do nothing on client (at least for now, later we could add some error processing)
}

//export const initializeSimplePolicy = (policy) => {

//    policy.action = RequestType.Protect;
//    policy.id = policy.Id; // check if it's necesary to set the id (now the id param is used by UI buttons, keep it like this until it gets changed)
//    policy.selected = false;
//    policy.class = '';
//}

function makeSimplePolicy(policy) {

    return {
        Corp: policy.IsCorporative,
        Count: 0,
        End: policy.IsContentExpirationActive ? policy.ContentExpirationEndDate : null,
        Fav: policy.IsFavourite,
        Guid: policy.Guid,
        Id: policy.RightsPolicyId,
        Name: policy.Name,
        Users: policy.Users
    };
}
// export const editPolicy = (e, component) => {
//
//     ////console.log('edit policy: ');
//     let app = window.app;
//     let policy = app.selectedItem;//selectedPolicy;
//     // link to the 'edit' page
//     component.props.history.push('/edit/' + policy.RightsPolicyId);
//
//     e.stopPropagation();
// }

export function canLockDocument(app, policy, documentDetails) {

    // abort if null
    if (!app || !app.user) return false;

    // case 1: user is the owner
    if (isDocumentOwner(app, documentDetails)) return true;

    // case 2: user has permissions in the policy
    if (!policy) return false;
    //const user = app.user;
    //let permissionModels = getUserPermissionModels(user, policy)
    //if (permissionModels != null) {
    //    for (let i = 0; i < permissionModels.length; i++) {
    //        let permissionModel = permissionModels[i];
    //        // check the permissions
    //        if (isAuthorInPermissionModel(user, permissionModel)) return true
    //        if (hasFullControlInPermissionModel(user, permissionModel)) return true
    //    }
    //}
    if (isAuthorOrHasFullControlInPolicy(app.user, policy)) return true;
    return false
}

export const canAddUsers = (user, policy) => {

    if (!user || !policy ) return false;
    let permissionModels = getUserPermissionModels(user, policy) // --> this will return a list with user permissions and user's group permissions
    if (permissionModels != null) {
        for (let i = 0; i < permissionModels.length; i++) {
            let permissionModel = permissionModels[i];
            // check the permissions
            if (canAddUsersInPermissionModel(user, permissionModel)) return true;
            //if (hasEditRightsInPermissionModel(user, permissionModel)) return true // currently not used by SP Desktop, only respected in Policy Management: should we respect this right here?
            // --> answer: if we don't have any UI to enable this permission, obviously we won't have permissions that have this right: DISABLE IT!
        }
    }
    return false
}

export const isAuthorOrHasFullControlInPolicy = (user, policy) => {

    //return false; // for tests
    if (!user || !policy) return false;
    let permissionModels = getUserPermissionModels(user, policy) // --> this will return a list with user permissions and user's group permissions
    if (permissionModels != null) {
        for (let i = 0; i < permissionModels.length; i++) {
            let permissionModel = permissionModels[i];
            // check the permissions
            if (isAuthorInPermissionModel(user, permissionModel)) return true;
            if (hasFullControlInPermissionModel(user, permissionModel)) return true;
            //if (canAddUsersInPermissionModel(user, permissionModel)) return true;
        }
    }
    return false
}

// "CanEditPolicy": Desktop client code:

//// Los permisos del usuario sobre la política serán una combinación de los suyos y los de sus grupos de ActiveDirectory (si los hubiese)                        
//UserPermission.CanEditPolicyValue canEditPolicy = policy.GetCanEditPolicyInPolicyByUsername(userName, ViewModelLocator.GroupsStatic.ListGroups.ToList());
//if (canEditPolicy == UserPermission.CanEditPolicyValue.None) {
//                            // If 'CanEditPolicy' is equal to 'None', then it's a policy in PRE format.           
//                            // We need to check 'Full control' in order to determine the permission for editing the policy.
//                            // --- MODIFICATION -------------------------------------------------------------
//                            // Set CanEditPolicy only to true if ((FULLCONTROL AND ISVISIBLE) OR (ISAUTHOR)).
//                            // ------------------------------------------------------------------------------
//                            Rights UserRights = policy.GetUserRightsInPolicyByUsername(userName, ViewModelLocator.GroupsStatic.ListGroups.ToList());
//                            bool fullControl = ((UserRights & Rights.FullControl) == Rights.FullControl);
//                            bool isVisible = policy.Users.Exists(u => u.IsVisible == true && u.UserName.Equals(userName, StringComparison.CurrentCultureIgnoreCase));
//                            bool isAuthor = policy.Users.Exists(u => u.IsAuthor == true && u.UserName.Equals(userName, StringComparison.CurrentCultureIgnoreCase));
//    return ((fullControl && isVisible) || isAuthor);
//}
//else if (canEditPolicy == UserPermission.CanEditPolicyValue.Unknown) {
//    // At this stage, 'CanEditPolicy' should never be equal to 'Unknown', because 'Resident' has a method to apply conversion to 'True' or 'False'.
//    // We log a message and return 'False'.
//    LogHelper.Log(LogLevel.Debug, string.Format("In: {0} - Unknown value found for user: {1} in policy: {2}.", System.Reflection.MethodBase.GetCurrentMethod().Name, userName, policy.Name), null);
//    return false;
//}
//else // 'True' o 'False'
//{
//    return (canEditPolicy == UserPermission.CanEditPolicyValue.True);
//}                    

// server code:
export const CanEditPolicyValue = {
    None: 0,
    Unknown: 1,
    False: 2,
    True: 3
}

// Note: this check doesn't include the 'AddUsers' right
export const canEditPolicy = (user, policy) => {

    //return false; // for tests
    if (!user || !policy) return false;
    let permissionModels = getUserPermissionModels(user, policy) // --> this will return a list with user permissions and user's group permissions
    //console.log('canEditPolicy: ', user, permission)
    if (permissionModels != null) {
        for (let i = 0; i < permissionModels.length; i++) {
            let p = permissionModels[i];
            // check the permissions
            if (isAuthorInPermissionModel(user, p)) return true;
            
            //if (hasFullControlInPermissionModel(user, p)) return true; -> Full Control is not enough anymore!!!

            //check if model has the new "CanEditPolicy" property
            if (p !== undefined && p !== null) {
                if (p.CanEditPolicy !== undefined && p.CanEditPolicy !== null && p.CanEditPolicy !== CanEditPolicyValue.None && p.CanEditPolicy !== CanEditPolicyValue.Unknown) {
                    // new server version
                    if (p.CanEditPolicy === CanEditPolicyValue.True) return true;
                }
                else {
                    // old server version: fallback behavior:
                    //Set CanEditPolicy only to true if ((FULLCONTROL AND ISVISIBLE) OR(ISAUTHOR)).
                    if (hasFullControlInPermissionModel(user, p) && p.IsVisible) return true; 
                }
            }
            //if (canAddUsersInPermissionModel(user, permissionModel)) return true;
        }
    }
    return false
}

//export const canEditPolicyOrAddUsers = (user, policy) => {

//    if (!user || !policy) return false;
//    let permissionModels = getUserPermissionModels(user, policy) // --> this will return a list with user permissions and user's group permissions
//    //console.log('canEditPolicyOrAddUsers: ', user, permission)
//    if (permissionModels != null) {
//        for (let i = 0; i < permissionModels.length; i++) {
//            let permissionModel = permissionModels[i];
//            // check the permissions
//            if (isAuthorInPermissionModel(user, permissionModel)) return true;
//            if (hasFullControlInPermissionModel(user, permissionModel)) return true;
//            if (canAddUsersInPermissionModel(user, permissionModel)) return true;
//            //if (hasEditRightsInPermissionModel(user, permissionModel)) return true // currently not used by SP Desktop, only respected in Policy Management: should we respect this right here?
//            // --> answer: if we don't have any UI to enable this permission, obviously we won't have policies that would use this right: IGNORE IT!
//        }
//    }
//    return false
//}

export const canDeletePolicy = (user, policy) => {

    if (!user || !policy) return false;
    let permissionModels = getUserPermissionModels(user, policy)
    //console.log('canDeletePolicy: ', user, permission)
    if (permissionModels != null) {
        for (let i = 0; i < permissionModels.length; i++) {
            let permissionModel = permissionModels[i];
            // is author?
            if (isAuthorInPermissionModel(user, permissionModel))
                return true;            
        }
    }
    return false;
}

// check if policy is shared with the user or with the user groups: 
// used by listst to filter out the "own" policies from "shared with me" ones.
export const isPolicySharedAndNotAuthor = (user, policy) => {

    if (!user || !policy) return false;
    let permissionModels = getUserPermissionModels(user, policy) // --> this will return a list with user permissions and user's group permissions
    if (permissionModels != null) {
        for (let i = 0; i < permissionModels.length; i++) {
            let permissionModel = permissionModels[i];
            // is shared?
            // what about groups permissions, each with IsVisible set to true, and each of them is !IsAuthor,
            // the nwe have the user's permission, where he's the author.
            //if (permissionModel.IsVisible && !permissionModel.IsAuthor) {
            if (permissionModel.IsVisible && !permissionModel.IsAuthor) {
                // not only the permission 'isAuthor' should be true - the policy cannot 
                // be owned by the current user (this could happen if the user's groups 
                // have this policy shared: it would be seen both on the OWN and on the SHARED list (this one)!)
                if (!isAuthorInPolicy(user, policy)) // !!! this is redundant, althought this change seems to have resolved a problem that Juanjo found with policies that were showing on both lists (could be some test errors?)
                    return true;
            }
        }
    }
    return false
}

export const isAuthorInPolicy = (user, policy) => {

    if (!user || !policy) return false;
    let permissionModels = getUserPermissionModels(user, policy)
    //console.log('isAuthorInPolicy: ', user, permission)
    if (permissionModels != null) {
        for (let i = 0; i < permissionModels.length; i++) {
            let permissionModel = permissionModels[i];
            // check the permissions
            if (isAuthorInPermissionModel(user, permissionModel))
                return true;
        }
    }
    return false;
}

export const isAuthorInPermissionModel = (user, permissionModel) => {

    if (permissionModel != null) {
        if (permissionModel.IsAuthor) return true
    }
    return false
}

export const hasFullControlInPermissionModel = (user, permissionModel) => {

    if (permissionModel != null) {
        if ((permissionModel.AccessPermissions & AccessPermissionType.FullControl) === AccessPermissionType.FullControl)       
            return true
    }
    return false
}

/// <summary>
/// Edit Rights: If this right is granted, the AD RMS client allows a user to edit the user rights
/// that are assigned by the license.
/// </summary>
// currently not used by SP Desktop, only respected in Policy Management: should we respect this right here?
// --> answer: if we don't have any UI to enable this permission, obviously we won't have permissions that have this right: DISABLE IT!
//export const hasEditRightsInPermissionModel = (user, permissionModel) => {

//    if (permissionModel != null) {
//        if ((permissionModel.AccessPermissions & AccessPermissionType.EditRightsData) == AccessPermissionType.EditRightsData)
//            return true
//    }
//    return false
//}

/// <summary>
/// Add Users: This is a custom right of SealPath. It is a more granular version than Edit Rights. 
/// The user with this permission can only add other users (not remove) with the same permissions
/// than has been assigned to him/her (Edit, View, etc.).
/// </summary>

export const canAddUsersInPermissionModel = (user, permissionModel) => {
    
    if (permissionModel != null) {
        if ((permissionModel.AccessPermissions & AccessPermissionType.AddUsers) === AccessPermissionType.AddUsers)
            return true
    }
    return false
}

export const getUserPermissionModels = (user, policy) => {

    // actually we should return the permissions which are a sum of user permission flags + group permission flags, but given 
    // that we return the entire model here, not the AccessPermission value, it's better to return a list of models
    let userPermissionModels = []
    if (policy.Users != null) {
        const policyPermissionModels = policy.Users;
        if (policyPermissionModels.length) {
            for (let i = 0; i < policyPermissionModels.length; i++) {
                let p = policyPermissionModels[i]
                //console.log('checking the permission: ', p)
                if (p.UserName === user.U) {
                    userPermissionModels.push(p)
                    break // there is only one permission object for one user in a policy, so we break the searching loop
                }
            }
            // now check the groups: they come in a simple format of string with group email
            let groups = window.app.store.state.groups;
            if (groups != null) {
                groups.map(group => {
                    //Buscamos el grupo en la política
                    //UserPermission GroupPermission = this.Users.Find(p => p.UserName.Equals(group, StringComparison.CurrentCultureIgnoreCase));
                    //if (GroupPermission != null) {
                    //    UserRights |= GroupPermission.AccessPermissions;
                    //}
                    for (let i = 0; i < policyPermissionModels.length; i++) {
                        let p = policyPermissionModels[i]
                        if (p.UserName === group) {
                            //console.log('getUserPermissionModels: add group <' + group + '> permission to the user permissions in the policy:', policy) 
                            userPermissionModels.push(p)
                            break // there are no replicated permission for one user in policy
                        }
                    }
                    return true;
                })
            }
        }
    }
    return userPermissionModels
}

export const getUserPermission2 = (user, policy) => {

}

//Rights GetUserRightsInPolicyByUsername(string username, List < string > UserGroups)
//{
//    Rights UserRights = Rights.Nothing;

//    // Si no hay usuarios en la política, directamente el usuario no tendrá permisos en la política.
//    if (this.Users != null) {
//        // Obtenemos los datos del usuario en la lista de ususarios con permisos en la política. Es posible que no se encuentre
//        UserPermission UserInPolicy = this.Users.Find(p => p.UserName.Equals(username, StringComparison.CurrentCultureIgnoreCase));
//        if (UserInPolicy != null)
//            UserRights = UserInPolicy.AccessPermissions;

//        // Si se han proporcionado los grupos del usaurio, habrá que verificar si existe alguno de los grupos del usuario en la política
//        // y combinar los permisos del grupo con los del usuario.
//        if (UserGroups != null) {
//            foreach(string group in UserGroups)
//            {
//                //Buscamos el grupo en la política
//                UserPermission GroupPermission = this.Users.Find(p => p.UserName.Equals(group, StringComparison.CurrentCultureIgnoreCase));
//                if (GroupPermission != null) {
//                    UserRights |= GroupPermission.AccessPermissions;
//                }
//            }
//        }
//    }
//    return UserRights;
//}

//============================================================================

export const editPermission = (e, permission, component) => {

    if (e) e.stopPropagation();

    const app = window.app;

    // Option A: (not implemented now)
    // we have clicked the inner item button, and the permission reference is passed here
    // Option B: (current mode)
    // we have clicked the context menu button, and we have to find a reference to the 
    // currently selected permission (there should be only one, so we will grab the first):
    if (!permission) {
        if (component.policy) {
            const permissions = appManager.findSelectedItems(component.policy.Users);
            if (permissions.length) {
                permission = permissions[0];
            }
        }        
    }
    if (!permission) return; // we shouldn't really come to this but we check it to not to produce exceptions later

    // before making a copy of permission, we should set its 'selected' state to false
    //... for now: will rather unselect items when we come back to Edit Policy, in the componentWillMount function 

    // set the app.store.state.permission if it's null
    // (required for refreshing page in browser, when references are lost)
    app.store.state.permission = permission;

    // clone the permission before we edit it:
    app.store.state.permissionCopy = JSON.parse(JSON.stringify(permission)) //deep copy here

    // finally go to the new component    
    appManager.goToPermissionsPage();
};


//********************* remove policy ***************************
export const removePolicy = (e, policy, component) => {

    ////console.log('remove policy: ');         
    component.showRemovePolicyModal(policy);
    e.stopPropagation();
}

export const onRemovePolicyConfirmed = (e, policies, component) => {

    ////console.log('remove policy confirmed: ');
    if (e) e.stopPropagation();

    policies.map(policy => {
        if (policy !== null) {
            // call server
            let task = {
                component: component, // the react component that called this method                
                type: RequestType.RemovePolicy,
                onComplete: onRemovePolicyCompleted,
            };            
            task.ajax = { // create the request object for HTTP client
                url: api().policy.remove(),
                data: {
                    TargetGuid: policy.Guid,
                }
            };
            window.app.taskProcessor.push(task);
        }
        return true;
    });
}

export const onRemovePolicyCompleted = (task) => {

    ////console.log('remove policy completed: ');
    if (task.hasError) {
        task.component.onRemovePolicyError(task);
    }
    else {
        // delete it from the policies list
        let policies = window.app.store.state.policies;
        //console.log('onRemovePolicyCompleted: policies before: ', policies)
        if (policies && policies.length) policies = policies.filter(p => p.Guid !== task.ajax.data.TargetGuid);
        //console.log('onRemovePolicyCompleted: policies after : ', policies)
        if (policies) window.app.store.setState({ policies });
        //task.info = 'ok';
        task.component.onRemovePolicyCompleted(task);
    }
}

//NEW POLICY:
//IPolicyService / CreatePolicy

//EDIT POLICY:
//IPolicyService / UpdateWithUserPolicy

//********************* remove permissions ***************************
export const removePermissions = (e, permission, component) => {

    //let app = window.app;
    //component.showRemovePermissionsModal(permission); // no confirmation now
    // currently no confirmation is required, so just 
    // send the permissions list to the next method:
    onRemovePermissionsConfirmed(e, component);
    e.stopPropagation();
}

export const onRemovePermissionsConfirmed = (e, component) => {

    if (e) e.stopPropagation();

    const app = window.app;
    const policy = component.policy;

    if (!policy) return;
    if (!policy.Users) return;

    const permissions = policy.Users;
    for (let i = permissions.length - 1; i >= 0; i--) { // count down because of splice, so we only splice the last one
        let p = permissions[i];
        if (p && p.selected) {
            // delete this permission model
            //permissions.splice(permissions.indexOf(p), 1);
            permissions.splice(i, 1);
            
            // update EditPolicy component state to render the edited policy
            //const items = app.store.state.policies;
            //app.setComponentState(app.locator.editPolicyPage, { items });
            app.store.setState({ policies: app.store.state.policies});
        }
    }
    // reset the store references (if any)
    window.app.store.state.permission = null;
    window.app.store.state.permissionCopy = null;

    // hide context menu
    app.setComponentState(app.locator.editPolicyPage, { showContextMenu: false });

    // now remove the empty (deleted) permissions from the list
    //policy.Users = permissions.filter(item => item !== task);

    // component should update its state.permissions, and paginator should call loadData
    component.onPermissionsRemoved();
}

export const applyNewPermissions = (e, component) => {

    let app = window.app;  
    // assign the sub item copy properties to the original permission model
    const source = app.store.state.permissionCopy;
    const target = app.store.state.permission;
    if (target) {
        for (var k in source) target[k] = source[k]; // copy properties values
    }
    else { // there is no original permission model: it means that
        // this is a new one: add it to the policy we are editing: 
        const policy = app.store.state.policyCopy;
        if (!policy) {
            window.alert('on edit permissions confirmed: policy is missing!');
            return;
        }
        const permissionUsers = app.store.state.permissionUsers;
        if (permissionUsers && permissionUsers.length) {
            // map all selected users and create a new access permission for each
            permissionUsers.map(user => {
                // check if user permission already exists?
                let existingUsers = policy.Users.filter(u => u.UserName === user.U);
                if (existingUsers && existingUsers.length) {
                    // we have an existing user model: update it with the new permission values
                    const u = existingUsers[0];
                    u.AccessPermissions = source.AccessPermissions;
                    u.IsVisible = source.IsVisible;
                }
                else {
                    const permission = createNewPermission(source, user);
                    policy.Users.push(permission);
                }
                return true;
            })
            // user permissions are added: now unselect the users to clean the UI
            appManager.unselectItems(window.app.store.state.users);
            // reset the selected users list
            app.store.state.permissionUsers = [];
        }        
    }
}

export const createNewPermission = (source, user) => {

    const app = window.app;
    const target = getEmptyPermission();

    // copy properties values
    if (source) {
        for (var k in source) target[k] = source[k];
    }
    
    // set user properties
    target.UserName = user.U;
    target.UserId = user.Id;
    target.IsGuest = user.IsGuest; // user.IsGuest doesn't exist when loaded from the server! should we set it client-side? should we ignore this property? --> it is set when adding a non-existing user to the permissions. Is it needed for anything?
    target.OrganizationId = user.O;
    target.RolId = user.R;
    if (app.user.Id === user.Id) {
        target.IsAuthor = true;
        target.IsVisible = true;
        target.CanEditPolicy = CanEditPolicyValue.True;
    }
    else {
        target.UserIdFather = app.user.Id;
    }
    return target;
}

export const getEmptyPermission = () => {

    const permission = {
        "enabled": true,
        "AccessPermissions": 2, // View
        //b CanEditPolicy was set to "false" before, but false is only used for old model of policy,
        // and the "base version" of WP is already using the new model. So we must set it to 
        //"CanEditPolicyValue.False" enum(which is number "2"):
        "CanEditPolicy": CanEditPolicyValue.False, 
        "IsVisible": false,
        "IsGuest": false,
        "UserIdFather": null,
        "IsAuthor": false,
        "UserId": 0,
        "UserName": '',
        "OrganizationId": 0,
        "RolId": 0
    };
    return permission;
}

export const showContextMenu = () => {

    let app = window.app;
    app.showContextMenu = true;
    app.contextMenuX = app.clientX;
    app.contextMenuY = app.clientY;
}

export const hideContextMenu = () => {

    let app = window.app;
    app.showContextMenu = false;
}

// sorts the policies: alphabetically and by favourite option (favs go first) 
export function sortPolicies(items, shouldSortFavs) {
    //console.log('sortPolicies')
    items.sort((a, b) => a.Name.localeCompare(b.Name));

    if (shouldSortFavs) {
        let ordered = [];
        items.map(p => {
            //console.log('checking if policy is fav? ', p.IsFavourite, p)
            if (p.Fav == true) {
                //console.log('pushing a fav policy');
                ordered.push(p);
            }           
        })
        items.map(p => {
            //console.log('checking if policy is fav? ', p.IsFavourite, p)
            if (p.Fav === undefined)
                p.Fav = false // extra check, for policies that doesn't have this flag, to not to make them excluded
            if (p.Fav == false) {
                //console.log('pushing an unfav policy');
                ordered.push(p);
            }       
            return true;
        })
        //console.log('pushing ordered policies: ', ordered);
        items.length = 0; // reset the array items, but KEEP the array!
        ordered.map(p => items.push(p))
    }    
    //console.log('ordered policies: ', items);
}


export const filterPolicyType = (app, policy, type) => {

    const user = app.user;
    if (type === PolicyType.All) {
        return true;
    }
    else if (type === PolicyType.Fav && policy.Fav) {
        return true;
    }
    else if (type === PolicyType.Own && isAuthorInPolicy(user, policy)) {
        return true;
    }
    else if (type === PolicyType.Shared && isPolicySharedAndNotAuthor(user, policy)) {
        return true
    }
    return false;
}

export const getPolicyNameAndUserNamesForSearch = (app, policy) => {

    return '' + policy.Name + getPolicyUserNamesForSearch(app, policy);    
}

export const getPolicyUserNamesForSearch = (app, policy) => {

    //console.log('get Policy User Names For Search. policy: ', policy);
    if (!policy) return '';
    let result = ''; 
    if (policy.IsCorporative) {
        //console.log('get Policy User Names For Search : policy is corporative...');
        // add the corporative text in the current language, so user can search for 'corpo' or similar.
        // starting space is there to separate from policy name (already in the search text in the caller) 
        result += ' ' + app.R.Corporative; 
        //result += ` corp ${app.R.Corporative}`;
        //result += ' corp';
    }
    const users = window.app.store.state.users;
    const permissions = policy.Users ? policy.Users : [];
    // have the rights to search within the policy permissions?
    if (isAuthorOrHasFullControlInPolicy(app.user, policy)) {
        //console.log('get Policy User Names For Search : author or full control...');
        // mode 1: return the 'shared with' user names
        //return permissions
        //    //.filter(permission => permission.IsVisible === true) ! we need all user names, not only the shared with
        //    .map((permission, index) => {

        //        !index ?
        //            permission.UserName
        //            :
        //            `-${permission.UserName}`
        //    });
        // mode 2: return user names from all permissions
        permissions.map((permission) => {
            result += ' ' + findUserFullNameAndUserName(users, permission.UserId, permission.UserName);
            return true;
        });
    }
    else {
        //console.log('get Policy User Names For Search : not author not full control...');
        // he doesn't have rights, but if the policy is shared with him, return:
        // 1. the name of the policy author 
        const author = getAuthor(app, policy, /* isForSearch */ true);
        let hasAuthor = false;
        if (author && author !== '') {
            //console.log('get Policy User Names For Search : add policy author');
            result += ' ' + author;
            hasAuthor = true;
        }
        // 2. father: user that added the current user
        const father = getFather(app, app.user, policy);
        if (father && father !== '' && (hasAuthor && father.localeCompare(author) !== 0)) {
            //console.log('get Policy User Names For Search : add father');
            result += ' ' + father;
        }
        // 3. children: users added by the current user       
        permissions.map((permission) => {
            result += getChildPermissionUserName(app, users, permission);
            return true;
        });        
    }    
    //console.log('get Policy User Names For Search : result: ', result);
    return result;
};

export const getChildPermissionUserName = (app, users, permission) => {

    //console.log('inner map: permissions: ', permissions);
    let result = '';  
    //console.log('inner map 1: permission: ', permission);
    if (permission.UserIdFather) {
        //console.log('inner map 3: UserIdFather: ', permission.UserIdFather);
        if (app && app.User) {
            //console.log('inner map 4: app.User.UserId: ', app.User.UserId);
            if (permission.UserIdFather === app.User.Id) { // current user is the father
                //console.log('inner map: permission: child found');
                let name = findUserFullNameAndUserName(users, permission.UserId, permission.UserName);
                if (name)
                    result += ' ' + name;
            }
        }
    }
    return result;
}

export const checkIfAddUsersIsSetInPermission = (permission) => {
    return (permission.AccessPermissions & AccessPermissionType.AddUsers) === AccessPermissionType.AddUsers;
}

export const getNamesOfUsersThatHavePolicyShared = (app, permissions = []) => {

    //console.log('get Names Of Users That Have Policy Shared')
    const users = app.store.state.users;
    return permissions
        .filter(permission => (permission.IsAuthor === false &&
            (permission.IsVisible === true ||
            checkIfAddUsersIsSetInPermission(permission) || // "IsVisible" should be enough, but must verify it.
            permission.CanEditPolicy === CanEditPolicyValue.True)// "IsVisible" should be enough, but must verify it.
        ))
        .map((permission, index) => !index ?
            findUserFullNameAndUserName(users, permission.UserId, permission.UserName)
            :
            ', ' + findUserFullNameAndUserName(users, permission.UserId, permission.UserName)
            //`, ${getUserFullNameOrUserName(users, permission.UserId, permission.UserName)}`
        );
};

export function findUserFullNameAndUserName(users, userId, userName) {

    //console.log('get User Full Name And User Name: userId, userName: ', userId, userName)
    if (!users)
        return userName; // return the original user name (passed here from the permission model)
    let result = '';
    for (let i = 0; i < users.length; i++) {
        let user = users[i];
        if (user.Id === userId) {
            const result = (user.F !== null && user.F !== '') ? user.F + ' <' + user.U + '>' : '' + user.U + '';
            //console.log('get User Full Name And User Name: result: ', result)
            return result;
        }
    }
    return result;
}

export function getUserFullNameAndUserName(user) {

    const result = (user.F !== null && user.F !== '') ? user.F + ' <' + user.U + '>' : '' + user.U + '';
    return result;

}

export function getUserFullNameOrUserName(users, userId, userName) {
    //console.log('get User Full Name Or User Name: userId, userName: ', userId, userName)
    if (!users)
        return userName; // return the original user name (passed here from the permission model)
    let result = '';
    for (let i = 0; i < users.length; i++) {
        let user = users[i];
        if (user.Id === userId) {
            const result = (user.F !== null && user.F !== '') ? user.F : user.U;
            //console.log('get User Full Name Or User Name: result: ', result)
            return result;
        }
    }
    return result;
}

export const getFather = (app, user, policy) => {

    const users = app.store.state.users;
    let permissionModels = getUserPermissionModels(user, policy);
    if (permissionModels != null) {
        for (let i = 0; i < permissionModels.length; i++) {
            let permissionModel = permissionModels[i];
            // find the permission of the current user
            if (permissionModel && ((user.Id && user.Id === permissionModel.UserId) || permissionModel.UserName === user.U)) { 
                return findUserFullNameAndUserName(users, permissionModel.UserIdFather, '');
            }
        }
    }
    return ''
};

export const getAuthor = (app, policy, isForSearch) => {

    if (policy.IsCorporative) {
        return app.R.Corporative;
    }
    else {
        const users = app.store.state.users;
        let permissionModels = policy.Users;
        if (permissionModels != null) {
            for (let i = 0; i < permissionModels.length; i++) {
                let permissionModel = permissionModels[i];
                // find the permission of the author
                if (permissionModel && permissionModel.IsAuthor) {
                    //if (isForSearch)
                        return findUserFullNameAndUserName(users, permissionModel.UserId, '');
                    //else
                    //    return getUserFullNameOrUserName(users, permissionModel.UserId, '');
                }
            }
        }
    }
    return ''
};

export const isSimplePolicyExpired = (policy) => {

    //console.log('isPolicyExpired: policy: ', policy);
    if (policy.End) {
        try {
            // convert the string to date and compare it to current time  
            const now = new Date();
            const expirationDate = new Date(policy.End);
            //const expirationDate = Date.parse(policy.End);
            //console.log('isPolicyExpired: now: ', now);
            //console.log('isPolicyExpired: expirationDate: ', expirationDate);
            if (now.getTime() > expirationDate.getTime()) {
                return true;
            }
        } catch (error) {
            console.error(error);
            // expected output: ReferenceError: nonExistentFunction is not defined
            // Note - error messages will vary depending on browser
        }        
    }
    return false;
}


//export const updatePolicyCounter = (task) => {

//    if (task.type === RequestType.Protect || task.type === RequestType.ProtectExternal) {

//        console.log('updatePolicyCounter. task:', task);

//        var updatePolicyCounterTask = {
//            type: RequestType.UpdatePolicyCounter,
//            ajax: {
//                url: api().policy.updatePolicyCounter(),
//                data: {
//                    TargetId: task.tool.policy.Id,
//                    TargetValue: 1,
//                },
//            },           
//            //onComplete: onSetFavouritePolicyCompleted,
//            onComplete: (task) => {
//                // for now ignore the errors here
//                // TO DO: render a error message if sth went wrong
//                console.log('updatePolicyCounter completed. task:', task);
//            }

//        }
//        window.app.taskProcessor.push(updatePolicyCounterTask);
//    }
//}

